.card-img-top[data-v-e2039449] {
  height: 530px;
  transition: display 1s;
  object-fit: cover;
}
.image-visible[data-v-e2039449] {
  display: inline;
}
.image-hidden[data-v-e2039449] {
  display: none;
}
.hover-me[data-v-e2039449]{
    border: solid 4px transparent;
}
.hover-me[data-v-e2039449]:hover{
    border: solid 4px #ffffff;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
}
#holder[data-v-e2039449] {
  /* max-width: 200px;
  max-height: 200px; */
  overflow: hidden;
  z-index: -1;
    position: relative;
}
#holder .smooth-image[data-v-e2039449] {
  transform: scale(1.5);
  width: 100%;
  transition: all 0.8s ease;
}
#holder .smooth-image[data-v-e2039449]:hover {
  transform: scale(1);
}