.remove-cart-list-title-bar .modal-title[data-v-7e160d5d] {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey);
}
.remove-cart-list-body-text[data-v-7e160d5d] {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: var(--color-orange);
}
.remove-cart-list-title-bar span[data-v-7e160d5d] {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    line-height: 1.11;
    color: var(--color-dark-black);
    font-size: 18px;
    font-weight: 500;
}
.pp-remove-cart-list-btn-modal[data-v-7e160d5d] {
    width: 100%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent;
    border-color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
}
.pp-remove-cart-list-btn-modal[data-v-7e160d5d]:hover {
    border-color: #fff;
}
.pp-remove-cart-list-btn-light-grey[data-v-7e160d5d] {
    color: var(--color-dark-grey);
}
.pp-remove-cart-list-btn-dark-grey[data-v-7e160d5d] {
    color: var(--color-dark-black);
}
  
  /*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
.remove-cart-list-body-text[data-v-7e160d5d] {
      font-size: 15px;
      line-height: 1.2;
      padding: 0 !important;
}
}
  