.about-first .title[data-v-a9a9c259] {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.4;
}
.about-first .para[data-v-a9a9c259] {
  font-weight: 400;
  color: #8E9298;
  text-align: justify;
  line-height: 1.6;
  font-size: 18px;
}
.yt-review-border[data-v-a9a9c259] {
  border: solid 1px #e6e6e6;
}
.yt-quote-icon[data-v-a9a9c259] {
  fill: #e6e6e6;
  font-size: 30px;
}
.yt-card-text[data-v-a9a9c259] {
  line-height: 1.5;
  min-height: 80px;
}
.yt-card-box-shadow[data-v-a9a9c259] {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 5px 5px 21px 0px rgba(240, 240, 240, 1) !important;
}
