.login-wrapper[data-v-6c0d4c28] {
  margin: 60px auto;
  padding: 20px 30px;
  max-width: 570px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 3px 3px 30px #eee;
  background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-6c0d4c28] {
  border-bottom: 3px solid #8c9095;
  font-weight: bold;
  color: #000;
}
.login-wrapper .nav-item[data-v-6c0d4c28] {
  cursor: pointer;
  color: #989ca2;
}
.yt-forgot-pw-wrap[data-v-6c0d4c28] {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
}
.yt-fpp-mb-5[data-v-6c0d4c28] {
  margin-bottom: 50px;
}
.yt-fpp-mt-5[data-v-6c0d4c28] {
  margin-top: 50px;
}
.yt-pass-info[data-v-6c0d4c28] {
  font-size: 11px;
  line-height: 1.1;
  color: var(--color-black);
  text-align: center;
}
.yt-cp-btn[data-v-6c0d4c28] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  transition: .5s ease;
}
.yt-cp-btn[data-v-6c0d4c28]:hover{
  background-color: black;
  color: white;
}
.yt-fp-top-back-tag-name[data-v-6c0d4c28] {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
}
.yt-fp-back-icn[data-v-6c0d4c28] {
  font-size: 40px;
  margin-left: -12px;
}
.yt-fp-bg-banner[data-v-6c0d4c28] {
  background-image: url(../images/login-image.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-fp-other-info[data-v-6c0d4c28] {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-fp-oi-tag-small[data-v-6c0d4c28] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-fp-oi-tag-big[data-v-6c0d4c28] {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-fp-bdr[data-v-6c0d4c28] {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-fp-sp-now[data-v-6c0d4c28] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}
.yt-fp-tag-line[data-v-6c0d4c28] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 40px;
  margin-top: 16px;
}
.yt-fp-tag-line-2[data-v-6c0d4c28] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 40px;
}
.yt-fp-form .form-group[data-v-6c0d4c28] {
  position: relative;
  margin-bottom: 30px;
}
.yt-fp-icn[data-v-6c0d4c28] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn1[data-v-6c0d4c28] {
  max-width: 24px;
  position: absolute;
  top: 25%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-6c0d4c28] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-form[data-v-6c0d4c28] {
  margin-bottom: 30px;
}
.yt-fp-form .form-group .form-control[data-v-6c0d4c28] {
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.yt-fp-btn[data-v-6c0d4c28] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  border: 1px solid black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
}
.yt-fp-btn[data-v-6c0d4c28]:hover {
  background-color: black;
  color: white;
}
.yt-fp-skip-btn[data-v-6c0d4c28] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.otp-timer[data-v-6c0d4c28] {
  font-size: 22px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}
.yt-resent-otp-btn[data-v-6c0d4c28] {
  font-size: 16px;
  line-height: 1.14;
  color: var(--color-black);
  box-shadow: none !important;
}
.yt-fp-form .error.form-group .form-control[data-v-6c0d4c28] {
  border: solid 1px var(--color-orange);
}
.yt-optp-message-show[data-v-6c0d4c28] {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.yt-fp-form .error.form-group .yt-optp-message-show[data-v-6c0d4c28] {
  color: var(--color-orange);
  display: block;
}
.yt-pas-eye-icn[data-v-6c0d4c28] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-pas-eye-icn2[data-v-6c0d4c28] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-fp-form .success.form-group .yt-optp-message-show[data-v-6c0d4c28] {
  color: var(--color-green);
  display: block;
}
.error.form-group[data-v-6c0d4c28],
.success.form-group[data-v-6c0d4c28] {
  margin-bottom: 43px !important;
}
.invalid[data-v-6c0d4c28] {
  color: var(--color-orange);
}
.invalid-input[data-v-6c0d4c28] {
  color: var(--color-orange);
  border: 1px solid var(--color-orange) !important;
}
.normal-input[data-v-6c0d4c28]:active {
  border: 1px solid var(--color-blue) !important;
}
.yt-forgot-pass-vie-icn[data-v-6c0d4c28] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-forgot-pass-vie-icn2[data-v-6c0d4c28] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.pass_success[data-v-6c0d4c28] {
  color: green;
  text-align: center;
}
.yt-fp-inner-content.yt-banner-col[data-v-6c0d4c28] {
  width: 45%;
}
.yt-fp-inner-content.yt-form-col[data-v-6c0d4c28] {
  padding-left: 40px;
  width: 55%;
}
.invalid[data-v-6c0d4c28] {
  color: var(--color-orange);
  text-align: center;
}
.otp-div-style[data-v-6c0d4c28] {
  text-align: center;
  margin-top: 20px;
}
.invalid-feedback[data-v-6c0d4c28] {
  text-align: center;
}
/**/
@media (min-width: 1400px) {
.yt-frgt-row .yt-banner-col[data-v-6c0d4c28],
  .yt-frgt-row .yt-form-col[data-v-6c0d4c28] {
    width: 50%;
}
.yt-forgot-pw-wrap[data-v-6c0d4c28] {
    max-width: 1340px;
}
.col-xxl-7[data-v-6c0d4c28] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xxl-5[data-v-6c0d4c28] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-forgot-pw-wrap[data-v-6c0d4c28] {
    max-width: 588px;
    padding: 0;
}
.yt-frgt-row .yt-fp-inner-content[data-v-6c0d4c28] {
    width: 100%;
}
.yt-frgt-row .yt-fp-bg-banner[data-v-6c0d4c28] {
    order: 2;
}
.yt-fp-inner-content.yt-form-col[data-v-6c0d4c28] {
    padding: 32px 30px 30px;
}
.yt-fp-top-back-tag-name[data-v-6c0d4c28] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-fp-tag-line[data-v-6c0d4c28] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 20px;
    margin-top: 23px;
}
.yt-fp-tag-line-2[data-v-6c0d4c28] {
    margin-bottom: 34px;
    margin-top: 0;
}
.yt-fp-form .form-group .form-control[data-v-6c0d4c28] {
    padding-top: 18px;
    padding-bottom: 17px;
    font-size: 14px;
    line-height: 1.14;
}
.yt-fp-form .form-group[data-v-6c0d4c28] {
    margin-bottom: 20px;
}
.yt-fp-btn[data-v-6c0d4c28] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
}
.yt-fp-bg-banner[data-v-6c0d4c28] {
    min-height: 211px;
    background-position: 100% 35%;
}
.yt-fp-oi-tag-small[data-v-6c0d4c28] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
}
.yt-fp-oi-tag-big[data-v-6c0d4c28] {
    font-size: 48px;
    line-height: 1.15;
    margin: 0;
}
.yt-fp-sp-now[data-v-6c0d4c28] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 30px;
}
.yt-fp-bdr[data-v-6c0d4c28] {
    bottom: 55px !important;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-fp-inner-content.yt-form-col[data-v-6c0d4c28] {
    padding: 15px;
}
.yt-forgot-pw-wrap[data-v-6c0d4c28] {
    max-width: 335px;
}
.yt-fp-tag-line[data-v-6c0d4c28] {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 10px;
}
.yt-fp-tag-line-2[data-v-6c0d4c28] {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.17;
}
.yt-fp-form .form-group .form-control[data-v-6c0d4c28] {
    padding-top: 13px;
    padding-bottom: 12px;
}
.yt-fp-form[data-v-6c0d4c28] {
    margin-bottom: 15px;
}
.yt-fp-oi-tag-small[data-v-6c0d4c28] {
    font-size: 10px;
    line-height: 1.11;
}
.yt-fp-oi-tag-big[data-v-6c0d4c28] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-fp-bg-banner[data-v-6c0d4c28] {
    min-height: 180px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-fp-btn[data-v-6c0d4c28] {
    font-size: 16px;
}
}
