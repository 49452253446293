@import url(https://fonts.googleapis.com/css2?family=Abhaya+Libre&display=swap);
/*
* Home Page CSS
*/
/*
* Global CSS
*/
.yt-component-wrapper[data-v-9862d71b] {
  position: relative;
}

/*End*/
.deals[data-v-9862d71b] {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
}
.products[data-v-9862d71b] {
  background-color: white;
  padding: 30px;
  margin-bottom: 20px;
}
.more[data-v-9862d71b] {
  float: right;
  font-size: 13px;
  cursor: pointer;
}
.fullscreen[data-v-9862d71b] {
  width: 100%;
}
.oneintwo[data-v-9862d71b] {
  margin-top: 20px;
  margin-bottom: 20px;
}
.shopnowbutton[data-v-9862d71b] {
  width: 140px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.savetext[data-v-9862d71b] {
  color: black;
  font-weight: 600;
  font-size: 20px;
}
.textblock1[data-v-9862d71b] {
  margin-top: -150px;
  margin-left: 80px;
}
.welcometext[data-v-9862d71b] {
  color: black;
  font-weight: 600;
  font-size: 40px;
}
.textblock2[data-v-9862d71b] {
  margin-top: -350px;
  margin-left: 80px;
}
.headtop[data-v-9862d71b] {
  color: white;
  font-weight: 200px;
}
.uptooff[data-v-9862d71b] {
  color: white;
  font-weight: 600;
  font-size: 60px;
}
.textblock3[data-v-9862d71b] {
  margin-top: -300px;
  margin-left: 350px;
}
.logohead1[data-v-9862d71b] {
  margin-left: 30px;
  font-weight: 500px;
  margin-top: -60px;
  font-size: 20px;
  color: white;
}
.shopnowgoldenbutton[data-v-9862d71b] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: -70px;
  float: right;
  margin-right: 10px;
}
.shopnowgoldenbutton1[data-v-9862d71b] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-top: 20px;
}
.dontmiss[data-v-9862d71b] {
  font-size: 30px;
}
.offthirty[data-v-9862d71b] {
  font-size: 70px;
  font-weight: 600;
}
.textbox4[data-v-9862d71b] {
  margin-left: 120px;
  margin-top: -500px;
}
.hurryup[data-v-9862d71b] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.dailydeal[data-v-9862d71b] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.lowprice[data-v-9862d71b] {
  color: white;
}
.textbox5[data-v-9862d71b] {
  margin-left: 50px;
  margin-top: -200px;
}
.happytimes[data-v-9862d71b] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.flat[data-v-9862d71b] {
  font-size: 40px;
  color: white;
  font-weight: 700px;
}
.textbox7[data-v-9862d71b] {
  margin-top: -230px;
  margin-left: auto;
  margin-right: auto;
}
.stores[data-v-9862d71b] {
  width: 30%;
  margin-top: 70px;
  cursor: pointer;
}
.playbuttons[data-v-9862d71b] {
  margin-left: 190px;
  margin-right: auto;
}
.download[data-v-9862d71b] {
  font-size: 40px;
  font-weight: 400;
  color: black;
  margin-left: 100px;
}
.imglast[data-v-9862d71b] {
  width: 100%;
  height: 100%;
}
.home-banner[data-v-9862d71b],
.banner-container[data-v-9862d71b] {
  position: relative;
  min-height: 357px;
}
.home-banner .content[data-v-9862d71b] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.banner-container .content[data-v-9862d71b] {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
}
.home-banner .content .scheme[data-v-9862d71b] {
  font-size: 80px;
  font-weight: 500;
}
.home-banner .content .info[data-v-9862d71b] {
  font-size: 24px;
  color: #000;
}
.banner-container .content .scheme[data-v-9862d71b] {
  font-size: 102px;
  font-weight: bold;
}
.banner-container .content .info[data-v-9862d71b] {
  font-size: 24px;
  color: #8b8f95;
}
.content .btn-shop[data-v-9862d71b] {
  color: white;
  background-color: black;
  padding: 8px 40px;
  border-radius: 6px;
  margin-top: 270px;
  transition: 0.5s ease;
  letter-spacing: 1px;
}
.content .btn-shop[data-v-9862d71b]:hover {
  border: 1px solid black;
  color: black;
  background-color: white;
}
@media (max-width: 1399px) {
.home-banner[data-v-9862d71b],
  .banner-container[data-v-9862d71b] {
    min-height: auto;
}
}
@media (max-width: 1200px) {
.banner-container .content[data-v-9862d71b] {
    top: 37%;
}
}
@media (max-width: 1199px) {
.home-banner .content .scheme[data-v-9862d71b] {
    font-size: 36px;
    margin-top: 0;
}
.home-banner .content .info[data-v-9862d71b] {
    font-size: 18px;
}
.banner-container .content .scheme[data-v-9862d71b] {
    font-size: 36px;
    margin-top: 0;
}
.banner-container .content .info[data-v-9862d71b] {
    font-size: 18px;
}
}
/* for mobile */
@media only screen and (max-width: 992px) {
.shopnowbutton[data-v-9862d71b] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.shopnowgoldenbutton1[data-v-9862d71b] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.savetext[data-v-9862d71b] {
    font-size: 15px;
}
.textblock1[data-v-9862d71b] {
    margin-top: -90px;
    margin-left: 50px;
}
.welcometext[data-v-9862d71b] {
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.textblock2[data-v-9862d71b] {
    margin-top: -200px;
    margin-left: 40px;
    width: 50%;
}
.headtop[data-v-9862d71b] {
    font-weight: 200px;
}
.uptooff[data-v-9862d71b] {
    color: white;
    font-weight: 600;
    font-size: 30px;
}
.textblock3[data-v-9862d71b] {
    margin-top: -250px;
    margin-left: 200px;
    width: 50%;
}
.three[data-v-9862d71b] {
    padding-bottom: 10px;
}
.logohead1[data-v-9862d71b] {
    width: 100%;
}
.hurryup[data-v-9862d71b] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.dailydeal[data-v-9862d71b] {
    font-size: 15px;
    color: white;
    font-weight: 600px;
}
.lowprice[data-v-9862d71b] {
    color: white;
    font-size: 10px;
}
.textbox5[data-v-9862d71b] {
    margin-left: 30px;
    margin-top: -160px;
    width: 50%;
}
.happytimes[data-v-9862d71b] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.flat[data-v-9862d71b] {
    font-size: 25px;
    color: white;
    font-weight: 700px;
}
.stores[data-v-9862d71b] {
    width: 30%;
    margin-top: 0px;
}
.playbuttons[data-v-9862d71b] {
    margin-left: 120px;
    margin-right: auto;
}
.download[data-v-9862d71b] {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 120px;
}
.textbox7[data-v-9862d71b] {
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
}
.imglast[data-v-9862d71b] {
    width: 100%;
    height: 100%;
}
.dontmiss[data-v-9862d71b] {
    font-size: 15px;
}
.offthirty[data-v-9862d71b] {
    font-size: 25px;
    font-weight: 600;
}
.textbox4[data-v-9862d71b] {
    margin-left: 40px;
    margin-top: -150px;
    width: 50%;
}
.banner-container .content[data-v-9862d71b] {
    top: 17%;
}
}

/* for desktop */
@media (max-width: 991px) {
.home-banner > img[data-v-9862d71b],
  .banner-container > img[data-v-9862d71b] {
    height: auto;
    object-fit: cover;
    width: 100%;
}
}
@media (max-width: 767px) {
.home-banner .content .scheme[data-v-9862d71b] {
    font-size: 22px;
    margin-bottom: 15px;
}
.home-banner .content .info[data-v-9862d71b] {
    font-size: 14px;
}
.home-banner > img[data-v-9862d71b],
  .banner-container > img[data-v-9862d71b] {
    height: auto;
}
.banner-container .content[data-v-9862d71b] {
    top: 62%;
}
.banner-container .content .scheme[data-v-9862d71b] {
    font-size: 22px;
    margin-top: 0;
}
.banner-container .content .info[data-v-9862d71b] {
    font-size: 14px;
}
.content .btn-shop[data-v-9862d71b] {
    margin-top: 67px;
    font-size: 8px;
    padding: 4px 16px;
}
}
@media (max-width: 445px) {
.banner-container .content[data-v-9862d71b] {
    top: 47%;
}
}
.featurebarcontainer[data-v-9862d71b] {
  margin: 40px 0px;
}

.yt-main-header .topbar[data-v-ec2e9eae] {
  color: var(--color-black);
  background-color: #f6f8fa;
  padding: 21px 0 17px;
}
.yt-main-header .logocontainer[data-v-ec2e9eae] {
  background-color: #000;
  padding: 30px 0;
}
.yt-main-header .logobox[data-v-ec2e9eae] {
  cursor: pointer;
  background-color: var(--color-dark-black);
}
.yt-header-top-message[data-v-ec2e9eae] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
}
.yt-main-header .supportbox[data-v-ec2e9eae] {
  /* height: 95px; */
  width: 220px;
  /* padding: 50px 0; */
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .support[data-v-ec2e9eae] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .supportimage[data-v-ec2e9eae] {
  height: auto;
  width: 30px;
  margin-right: 10px;
}
.yt-main-header .supportnumber[data-v-ec2e9eae] {
  display: flex;
  flex-direction: column;
}
.yt-main-header .supportnumber .title[data-v-ec2e9eae] {
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  padding-bottom: 5px;
}
.yt-main-header .supportnumber .number[data-v-ec2e9eae] {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}
.yt-main-header .searchcontainer[data-v-ec2e9eae] {
  display: flex;
  align-items: center;
  height: 100%;
}
.yt-main-header .searchinput[data-v-ec2e9eae] {
  border-radius: 6px;
  border: solid 1px #37393b;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0 10px 50px;
  line-height: 1.15;
  color: #757575;
  background-color: #37393b;
  font-size: 20px;
  font-weight: 500;
}
.yt-main-header .searchicon[data-v-ec2e9eae] {
  height: auto;
  width: 40px;
  padding: 10px;
  position: absolute;
  left: 18px;
}
.yt-main-header .cartcontainer[data-v-ec2e9eae] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.yt-main-header .carticon[data-v-ec2e9eae] {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.yt-main-header .loginbutton[data-v-ec2e9eae] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.yt-main-header .menucontainer[data-v-ec2e9eae] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top: 1px solid #37393b;
  background-color: #000;
}
.main_header .menuicon[data-v-ec2e9eae] {
  height: auto;
  width: 16px;
  position: absolute;
  left: 20px;
  color: white;
}
.yt-main-header nav.navbar[data-v-ec2e9eae] {
  padding: 0px;
}
.yt-main-header ul.navbar-nav[data-v-ec2e9eae] {
  width: 100%;
  justify-content: space-around;
  background-color: #000;
}
.yt-main-header .nav-link[data-v-ec2e9eae] {
  font-size: 18px;
  padding: 20px;
  color: #fff !important;
  font-weight: 100 !important;
}
.yt-main-header .nav-link.active[data-v-ec2e9eae] {
  color: #fff !important;
  border-bottom: 3px solid #8b8f95;
  font-weight: bold !important;
}
.yt-main-header .cm-drop-down-wrap[data-v-ec2e9eae] {
  position: static;
}
.yt-main-header .cm-big-drop-menu[data-v-ec2e9eae] {
  width: 100%;
  padding: 52px 60px 37px;
  max-width: 1180px;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto;
}
.yt-main-header .cm_big_dropmenu[data-v-ec2e9eae] {
  min-width: 250px;
  margin: 0;
  padding: 0;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  top: 150% !important;
}
.yt-main-header .cm_big_dropmenu[data-v-ec2e9eae]::-webkit-scrollbar {
  width: 5px;
}
.yt-main-header .cm_big_dropmenu[data-v-ec2e9eae]::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #ccc;
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.yt-main-header .cm_big_dropmenu[data-v-ec2e9eae]::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.yt-main-header .cm_big_dropmenu .category_name[data-v-ec2e9eae] {
  font-size: 20px;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  color: black;
}
.yt-main-header .cm_big_dropmenu .category_name[data-v-ec2e9eae]:hover,
.yt-main-header .cm_big_dropmenu .category_name[data-v-ec2e9eae]:focus {
  background-color: transparent;
}
.cart-divider[data-v-ec2e9eae] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  width: 87%;
  margin: auto;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-ec2e9eae] {
  font-size: 18px;
  padding: 15px 20px;
  color: black;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-ec2e9eae]:hover,
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-ec2e9eae]:focus {
  background-color: transparent;
}
.yt-main-header .cm-big-menu-head .dropdown-divider[data-v-ec2e9eae] {
  border-top: 1px solid var(--color-dark-grey);
}
.main_header .cm_big_dropmenu[data-v-ec2e9eae] {
  position: absolute;
  border-radius: 0 !important;
  top: 150% !important;
}
.main_header .cm-big-menu-inner .dropdown-item[data-v-ec2e9eae] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  padding: 8px 10px;
  margin-bottom: 5px;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-ec2e9eae] {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-main-header .head-dropdown-arrow-icn[data-v-ec2e9eae] {
  font-size: 12px;
  margin-left: 4px;
}
.yt-main-header li.cm-drop-down-wrap.dropdown.show > a[data-v-ec2e9eae] {
  color: #fff !important;
  font-weight: 600;
  border-bottom: 3px solid #8b8f95;
}
.yt-main-header .header-search-field-wrap[data-v-ec2e9eae] {
  position: relative;
}
.yt-recent-search-wrap[data-v-ec2e9eae] {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 50;
  width: 250px;
}
.yt-main-header .recent-search-tag-name[data-v-ec2e9eae] {
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.yt-main-header .recent-search-list-wrap .active[data-v-ec2e9eae] {
  background: transparent;
  color: inherit;
}
.yt-recent-search-wrap[data-v-ec2e9eae] {
  border-radius: 10px;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 32px 20px 27px;
}
.yt-main-header .yt-no-search-found-wrap[data-v-ec2e9eae] {
  padding: 54px 30px 46px;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-ec2e9eae] {
  border-bottom: 2px solid #cae2fe;
  margin: 0;
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  padding: 15px 0 !important;
}
.myIcon[data-v-ec2e9eae] {
  height: auto;
  width: 25px;
  position: absolute;
  left: 20px;
  color: white;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-ec2e9eae]:first-child {
  padding-top: 0 !important;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-ec2e9eae]:last-child {
  padding-bottom: 0 !important;
}
.yt-main-header .search-no-ttl[data-v-ec2e9eae] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-top: 35px;
  margin-bottom: 15px;
}
.yt-main-header .search-no-sub-ttl[data-v-ec2e9eae] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.yt-main-header .yt-main-menu .nav-item[data-v-ec2e9eae] {
  cursor: pointer;
}
.yt-main-header .logoimage[data-v-ec2e9eae] {
  max-width: 280px;
}
.yt-head-login-btn[data-v-ec2e9eae] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  background-color: var(--color-dark-black);
  padding: 15px 26px;
}
.user_Name[data-v-ec2e9eae] {
  cursor: pointer;
}
.yt-mobile-side-nav-wrap[data-v-ec2e9eae] {
  display: none;
}
.cart-item[data-v-ec2e9eae] {
  position: absolute;
  top: 0;
  right: 18px;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.wishlist-item[data-v-ec2e9eae] {
  position: absolute;
  top: 15px;
  /* right: 170px; */
  /* right: 218px; */
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.cartcontainer .nav-link[data-v-ec2e9eae] {
  padding: 0;
  margin-right: 30px;
}
@media (min-width: 1900px) {
.yt-main-header .cm-big-drop-menu[data-v-ec2e9eae] {
    max-width: 1421px;
}
.main_header
    .cm-big-drop-menu
    .yt-main-header
    .cm-big-menu-inner
    .dropdown-item[data-v-ec2e9eae] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-ec2e9eae] {
    font-size: 28px;
    line-height: 1.14;
}
.yt-main-header .searchinput[data-v-ec2e9eae] {
    padding-top: 13px;
    padding-bottom: 13px;
}
}
@media (max-width: 1366px) {
.yt-main-header .logoimage[data-v-ec2e9eae] {
    max-width: 220px;
}
.main_header .navbar .nav-item[data-v-ec2e9eae]:not(:last-child) {
    margin-right: 100px !important;
}
}
@media only screen and (min-width: 992px) {
.yt-main-header .order-sm-1[data-v-ec2e9eae] {
    order: 0;
}
.yt-main-header .searchicon[data-v-ec2e9eae] {
    left: 30px;
}
.yt-main-header ul.navbar-nav[data-v-ec2e9eae] {
    padding: 0 226px;
}
}
@media only screen and (max-width: 768px) {
.yt-only-mobile-vw[data-v-ec2e9eae] {
    display: none;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-ec2e9eae] {
    display: none;
}
}
@media only screen and (max-width: 992px) {
.yt-main-header .order-sm-1[data-v-ec2e9eae] {
    order: 1;
}
.yt-main-header .searchicon[data-v-ec2e9eae] {
    left: 32px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767px) {
.yt-main-header .logocontainer .yt-head-col[data-v-ec2e9eae] {
    flex: 0 0 50%;
    max-width: 50%;
}
.yt-main-header .yt-head-hide-srch-col[data-v-ec2e9eae],
  .yt-main-header .topbar[data-v-ec2e9eae] {
    display: none;
}
.yt-main-header .logocontainer[data-v-ec2e9eae] {
    padding-top: 15px;
    padding-bottom: 15px;
}
.yt-main-header .supportbox[data-v-ec2e9eae] {
    display: none;
}
.yt-main-header .logoimage[data-v-ec2e9eae] {
    max-width: 140px;
}
.yt-main-header .logobox[data-v-ec2e9eae] {
    margin-left: 30px;
}
.yt-main-header .loginbutton span[data-v-ec2e9eae] {
    display: none;
}
.yt-main-header .loginbutton span.yt-head-lgn-btn[data-v-ec2e9eae] {
    display: block !important;
}
.yt-main-header .loginbutton span[data-v-ec2e9eae] {
    font-size: 13px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    color: white;
}
.yt-main-header .loginbutton[data-v-ec2e9eae] {
    background: transparent;
    width: auto;
    height: auto;
    border-radius: 0;
}
.cartcontainer .nav-link[data-v-ec2e9eae] {
    margin-right: 15px;
}
.yt-main-header .carticon[data-v-ec2e9eae] {
    width: 16px;
    height: auto;
}
.yt-main-header .yt-head-seachicn-mb[data-v-ec2e9eae] {
    display: block !important;
    position: inherit;
    margin-right: 13px;
    width: 18px;
    padding: 0;
}
.yt-head-mb-search-panel[data-v-ec2e9eae] {
    display: block !important;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-ec2e9eae] {
    display: block;
}
.yt-mobile-side-nav-wrap[data-v-ec2e9eae] {
    position: fixed;
    background-color: rgb(62 69 79 / 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.yt-mobile-side-inner-content[data-v-ec2e9eae] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 550;
    max-width: 221px;
    overflow: auto;
    height: 100%;
    width: 100%;
}
.yt-mb-user-profile[data-v-ec2e9eae] {
    padding: 20px;
    background-color: var(--color-dark-black);
    color: #fff;
}
.yt-mobile-side-inner-content .yt-inner-cnt-logo[data-v-ec2e9eae] {
    padding: 15px 20px 20px;
}
.yt-mobile-side-inner-content .logobox-mb img[data-v-ec2e9eae] {
    background-color: var(--color-dark-black);
}
.yt-mobile-nav-content[data-v-ec2e9eae] {
    padding: 17px 20px;
}
.yt-mb-user-profile .yt-head-name[data-v-ec2e9eae] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-mb-user-profile .yt-head-email[data-v-ec2e9eae] {
    font-size: 8px;
    line-height: 1.13;
}
.yt-mobile-nav-content li:last-child a[data-v-ec2e9eae] {
    padding-bottom: 0;
}
.yt-mb-user-prfl-lks li:first-child a[data-v-ec2e9eae] {
    padding-top: 0;
}
.yt-mb-user-prfl-lks[data-v-ec2e9eae] {
    border-top: solid 1px #dbdbdb;
    padding: 20px;
}
.yt-main-header .yt-mobile-nav-content .cm-big-drop-menu[data-v-ec2e9eae] {
    padding: 0;
    border: none;
}
.yt-main-header
    .yt-mobile-nav-content
    li.cm-drop-down-wrap.dropdown.show
    > a[data-v-ec2e9eae] {
    border-bottom: none;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider[data-v-ec2e9eae] {
    border: solid 1px #dbdbdb;
}
.main_header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item[data-v-ec2e9eae] {
    font-size: 10px;
    line-height: 1.1;
}
.main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item[data-v-ec2e9eae] {
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-black);
}
.yt-mb-user-profile .yt-mb-nav-login-btn[data-v-ec2e9eae] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    font-weight: 500;
}
.yt-mb-innet-search[data-v-ec2e9eae] {
    padding: 14px 15px 0;
}
.yt-main-header .yt-mb-innet-search .searchicon[data-v-ec2e9eae] {
    top: 50%;
    transform: translateY(-50%);
    max-width: 16px;
    left: 12px;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-ec2e9eae] {
    padding: 10px 10px 10px 38px !important;
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-ec2e9eae]::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-ec2e9eae]::-moz-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-ec2e9eae]:-ms-input-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-ec2e9eae]:-moz-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
}
/* @media (max-width: 992px) {
    .main_header .logocontainer .yt-head-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .main_header .yt-head-hide-srch-col,
    .main_header .topbar {
      display: none;
    }
    .main_header .logocontainer {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .main_header .supportbox {
      display: none;
    }
    .main_header .logoimage {
      max-width: 140px;
    }
    .main_header .logobox {
      margin-left: 30px;
    }
    .main_header .loginbutton span {
      display: none;
    }
    .main_header .loginbutton span.yt-head-lgn-btn {
      display: block !important;
    }
    .main_header .loginbutton span {
      font-size: 13px;
      line-height: 1.14;
      letter-spacing: 0.28px;
      color: white;
    }
    .main_header .loginbutton {
      background: transparent;
      width: auto;
      height: auto;
      border-radius: 0;
    }
    .cartcontainer .nav-link {
      margin-right: 15px;
    }
    .main_header .carticon {
      width: 16px;
      height: auto;
    }
    .main_header .yt-head-seachicn-mb {
      display: block !important;
      position: inherit;
      margin-right: 13px;
      width: 18px;
      padding: 0;
    }
    .yt-head-mb-search-panel {
      display: block !important;
    }
    .yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap {
      display: block;
    }
    .yt-mobile-side-nav-wrap {
      position: fixed;
      background-color: rgb(62 69 79 / 0.8);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 500;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
    .yt-mobile-side-inner-content {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 550;
      max-width: 221px;
      overflow: auto;
      height: 100%;
      width: 100%;
    }
    .yt-mb-user-profile {
      padding: 20px;
      background-color: var(--color-dark-black);
      color: #fff;
    }
    .yt-mobile-side-inner-content .yt-inner-cnt-logo {
      padding: 15px 20px 20px;
    }
    .yt-mobile-side-inner-content .logobox-mb img {
      background-color: var(--color-dark-black);
    }
    .yt-mobile-nav-content {
      padding: 17px 20px;
    }
    .yt-mb-user-profile .yt-head-name {
      font-size: 16px;
      line-height: 1.13;
    }
    .yt-mb-user-profile .yt-head-email {
      font-size: 8px;
      line-height: 1.13;
    }
    .yt-mobile-nav-content li:last-child a {
      padding-bottom: 0;
    }
    .yt-mb-user-prfl-lks li:first-child a {
      padding-top: 0;
    }
    .yt-mb-user-prfl-lks {
      border-top: solid 1px #dbdbdb;
      padding: 20px;
    }
    .main_header .yt-mobile-nav-content .cm-big-drop-menu {
      padding: 0;
      border: none;
    }
    .main_header
      .yt-mobile-nav-content
      li.cm-drop-down-wrap.dropdown.show
      > a {
      border-bottom: none;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider {
      border: solid 1px #dbdbdb;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item {
      font-size: 10px;
      line-height: 1.1;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item {
      font-size: 12px;
      line-height: 1.17;
      color: var(--color-black);
    }
    .yt-mb-user-profile .yt-mb-nav-login-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.28px;
      font-weight: 500;
    }
    .yt-mb-innet-search {
      padding: 14px 15px 0;
    }
    .main_header .yt-mb-innet-search .searchicon {
      top: 50%;
      transform: translateY(-50%);
      max-width: 16px;
      left: 12px;
    }
    .main_header .yt-mb-innet-search .searchinput {
      padding: 10px 10px 10px 38px !important;
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput::-webkit-input-placeholder {
    
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput::-moz-placeholder {
     
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput:-ms-input-placeholder {
    
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput:-moz-placeholder {
      
      font-size: 12px;
      line-height: 1.17;
    }
  } */
.userbox[data-v-ec2e9eae] {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.userbox .usericon[data-v-ec2e9eae] {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
}
.userbox .wishlistIcon[data-v-ec2e9eae] {
  width: 27px;
  /* min-width: 42px; */
  height: 42px;
  /* border-radius: 50%; */
  /* border: 1px solid #fff; */
  display: inline-block;
  color: white;
  margin-right: 22px;
}
.userbox .uname[data-v-ec2e9eae] {
  margin-left: 10px;
  color: #fff;
  max-width: 70px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-main-header .supportnumber .title[data-v-ec2e9eae] {
    font-size: 14px;
}
.yt-main-header .supportnumber .number[data-v-ec2e9eae] {
    font-size: 16px;
}
.yt-main-header .searchinput[data-v-ec2e9eae] {
    font-size: 16px;
}
.yt-main-header .loginbutton[data-v-ec2e9eae] {
    font-size: 16px;
}
.yt-main-header .yt-main-menu .nav-item[data-v-ec2e9eae] {
    font-size: 18px;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-ec2e9eae] {
    font-size: 20px;
}
}
.main_header[data-v-ec2e9eae] {
  width: 100%;
  z-index: 111;
}
@-webkit-keyframes myanimation-data-v-ec2e9eae {
0% {
    background-color: transparent;
}
35% {
    background-color: black;
}
100% {
    background-color: black;
}
}
@keyframes myanimation-data-v-ec2e9eae {
0% {
    background-color: transparent;
}
35% {
    background-color: black;
}
100% {
    background-color: black;
}
}
.myHeaderStyle[data-v-ec2e9eae] {
  background-color: black;
  position: relative;
}
.myHeaderStyle2[data-v-ec2e9eae] {
  position: fixed;
  transform: translateY(10);
  transition: transform 6s ease;
  -webkit-animation: myanimation-data-v-ec2e9eae 3s;
          animation: myanimation-data-v-ec2e9eae 3s;
  background-color: #000000f0;
}
.position-absolute[data-v-ec2e9eae] {
  position: absolute;
}
.main_header .navbar[data-v-ec2e9eae] {
  padding: 15px 80px;
}
.main_header .other_page[data-v-ec2e9eae] {
  box-shadow: 0 3px 99px 0 #d9d9da !important;
}
.main_header .navbar .navbar-brand[data-v-ec2e9eae] {
  padding: 0;
  margin: 0;
}
.main_header .navbar .navbar-brand img[data-v-ec2e9eae] {
  width: auto;
  max-width: 110px;
}
.main_header .navbar .navbar-nav[data-v-ec2e9eae] {
  align-items: center;
}
.main_header .navbar .navbar-nav .nav-item[data-v-ec2e9eae] {
  display: inline-flex;
}
.main_header .navbar .navbar-nav .nav-item[data-v-ec2e9eae]:not(:last-child) {
  margin-right: 125px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-ec2e9eae] {
  padding: 0;
  font-size: 20px;
  color: white;
  letter-spacing: 1px;
  /* display: inline-flex; */
  padding-bottom: 6px;
}
.searchinput[data-v-ec2e9eae] {
  background-color: black;
  color: white;
  border: none;
  font-size: 19px;
  letter-spacing: 2px;
  width: 100%;
}
.searchInput-icon[data-v-ec2e9eae] {
  font-size: 27px;
  color: white;
  margin-right: 6px;
}
.borderTop[data-v-ec2e9eae] {
  border-top: 1px solid #ffffffb3;
  width: 100%;
}
.cancel-search-icon[data-v-ec2e9eae] {
  color: #8b8f95;
  font-size: 20px;
}
.user-name[data-v-ec2e9eae] {
  font-size: 14px;
  left: 0;
  right: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.user-icon[data-v-ec2e9eae] {
  font-size: 30px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon[data-v-ec2e9eae] {
  position: relative;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .nav-link[data-v-ec2e9eae] {
  font-size: 30px;
  cursor: pointer;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-ec2e9eae] {
  border-bottom: 1px solid #e6e6e6;
  position: absolute;
  right: 0;
  left: auto;
  top: 0px;
  display: flex;
  min-width: 225px;
  align-items: center;
  transition: 0.5s linear;
  min-height: 28px;
  opacity: 0;
  visibility: hidden;
  background-color: black;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input.active[data-v-ec2e9eae] {
  opacity: 1;
  visibility: visible;
}
@-webkit-keyframes myDropdown-data-v-ec2e9eae {
0% {
    background-color: transparent;
}
35% {
    background-color: white;
}
100% {
    background-color: white;
}
}
@keyframes myDropdown-data-v-ec2e9eae {
0% {
    background-color: transparent;
}
35% {
    background-color: white;
}
100% {
    background-color: white;
}
}
.main_header .cm_big_dropmenu[data-v-ec2e9eae] {
  min-width: 250px;
  max-height: 600px;
  -webkit-animation: myDropdown-data-v-ec2e9eae 2s;
          animation: myDropdown-data-v-ec2e9eae 2s;
}
.main_header .cm_big_dropmenu .category_name[data-v-ec2e9eae] {
  font-size: 20px;
  padding: 15px 20px;
  font-weight: 500;
  color: black;
}
.main_header .cm_big_dropmenu .category_name[data-v-ec2e9eae]:hover {
  color: #8b8f95;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-ec2e9eae]:hover {
  color: black;
}
.main_header a[data-v-ec2e9eae] {
  display: inline-block;
  text-decoration: none;
}
.main_header a[data-v-ec2e9eae]::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: white;
  transition: width 0.3s ease-out;
}
.main_header a.iamactive[data-v-ec2e9eae]::after {
  width: 100%;
}
.hoverMe[data-v-ec2e9eae]:hover::after {
  width: 100%;
}
.main_header a[aria-expanded="true"][data-v-ec2e9eae]::after {
  width: 100%;
}
.main_header a[aria-expanded="false"] > .iconDown[data-v-ec2e9eae] {
  display: none;
}
.main_header a[aria-expanded="true"] > .iconRight[data-v-ec2e9eae] {
  display: none;
}
.my-cart-divider[data-v-ec2e9eae] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  margin: auto;
  width: 85%;
}
.main_header .cm_big_dropmenu .category_name[data-v-ec2e9eae]:last-child {
  border-bottom: none;
}
.main_header .cm_big_dropmenu .category_name[data-v-ec2e9eae]:hover,
.main_header .cm_big_dropmenu .category_name[data-v-ec2e9eae]:focus {
  background-color: transparent;
}
nav.bg-dark[data-v-ec2e9eae] {
  border-bottom: none;
}
.square[data-v-ec2e9eae] {
  position: absolute;
  top: -5px;
  left: 10px;
  width: 9px;
  height: 9px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  transform: rotate(45deg);
  -webkit-animation: myDropdown-data-v-ec2e9eae 3s;
          animation: myDropdown-data-v-ec2e9eae 3s;
}
@media (max-width: 1774px) {
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-ec2e9eae] {
    min-width: 200px;
}
}
@media (max-width: 1601px) and (min-width: 1428px) {
.main_header .navbar .nav-item[data-v-ec2e9eae]:not(:last-child) {
    margin-right: 60px !important;
}
}
@media (max-width: 1427px) {
.main_header .navbar .nav-item[data-v-ec2e9eae]:not(:last-child) {
    margin-right: 50px !important;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-ec2e9eae] {
    min-width: 150px;
}
}
@media (max-width: 1276px) {
.main_header .navbar .nav-item[data-v-ec2e9eae]:not(:last-child) {
    margin-right: 35px !important;
}
.main_header .navbar .nav-item .nav-link[data-v-ec2e9eae] {
    font-size: 15px;
}
}
@media (max-width: 1201px) {
.main_header .navbar .nav-item[data-v-ec2e9eae]:not(:last-child) {
    margin-right: 25px !important;
}
.main_header .navbar .nav-item .nav-link[data-v-ec2e9eae] {
    font-size: 17px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.user-icon[data-v-ec2e9eae] {
    font-size: 22px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.search-icon[data-v-ec2e9eae] {
    font-size: 22px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-ec2e9eae] {
    min-width: 160px;
}
}
@media (max-width: 992px) {
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-ec2e9eae] {
    min-width: 66px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-ec2e9eae] {
    font-size: 17px;
}
}
@media (max-width: 812px) {
.main_header .navbar .navbar-brand img[data-v-ec2e9eae] {
    max-width: 70px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-ec2e9eae] {
    font-size: 15px;
}
.main_header .navbar[data-v-ec2e9eae] {
    padding: 14px 35px;
}
.main_header .navbar .nav-item[data-v-ec2e9eae]:not(:last-child) {
    margin-right: 15px !important;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-ec2e9eae] {
    min-width: 150px;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.navbar-brand[data-v-ec2e9eae] {
    margin: 0 auto !important;
}
.navbar-collapse[data-v-ec2e9eae] {
    padding-top: 50px !important;
}
.navbar-collapse ul li[data-v-ec2e9eae] {
    padding-top: 5px !important;
}
.main_header .navbar .nav-item[data-v-ec2e9eae]:not(:last-child) {
    margin-right: 0 !important;
}
.navbar-collapse ul:last-child li[data-v-ec2e9eae]:last-child {
    margin-right: 0 !important;
}
.user-name[data-v-ec2e9eae] {
    left: 0;
}
.yt-recent-search-wrap[data-v-ec2e9eae] {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 300px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-ec2e9eae] {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 250px;
    height: 35px;
}
.main_header .cm_big_dropmenu[data-v-ec2e9eae] {
    top: 112% !important;
    transform: translate(-50px, 0px);
}
.square[data-v-ec2e9eae] {
    left: 120px;
}
.main_header .cm_big_dropmenu .category_name[data-v-ec2e9eae] {
    font-size: 18px;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-ec2e9eae] {
    font-size: 16px;
}
}

.yt-main-header .topbar[data-v-5238a39b] {
  color: var(--color-black);
  background-color: #f6f8fa;
  padding: 21px 0 17px;
}
.yt-main-header .logocontainer[data-v-5238a39b] {
  background-color: #000;
  padding: 30px 0;
}
.yt-main-header .logobox[data-v-5238a39b] {
  cursor: pointer;
  background-color: var(--color-dark-black);
}
.yt-header-top-message[data-v-5238a39b] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
}
.yt-main-header .supportbox[data-v-5238a39b] {
  /* height: 95px; */
  width: 220px;
  /* padding: 50px 0; */
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .support[data-v-5238a39b] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .supportimage[data-v-5238a39b] {
  height: auto;
  width: 30px;
  margin-right: 10px;
}
.yt-main-header .supportnumber[data-v-5238a39b] {
  display: flex;
  flex-direction: column;
}
.yt-main-header .supportnumber .title[data-v-5238a39b] {
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  padding-bottom: 5px;
}
.yt-main-header .supportnumber .number[data-v-5238a39b] {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}
.yt-main-header .searchcontainer[data-v-5238a39b] {
  display: flex;
  align-items: center;
  height: 100%;
}
.yt-main-header .searchinput[data-v-5238a39b] {
  border-radius: 6px;
  border: solid 1px #37393b;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0 10px 50px;
  line-height: 1.15;
  color: #757575;
  background-color: #37393b;
  font-size: 20px;
  font-weight: 500;
}
.yt-main-header .searchicon[data-v-5238a39b] {
  height: auto;
  width: 40px;
  padding: 10px;
  position: absolute;
  left: 18px;
}
.yt-main-header .cartcontainer[data-v-5238a39b] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.yt-main-header .carticon[data-v-5238a39b] {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.yt-main-header .loginbutton[data-v-5238a39b] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.yt-main-header .menucontainer[data-v-5238a39b] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top: 1px solid #37393b;
  background-color: #000;
}
.main_header .menuicon[data-v-5238a39b] {
  height: auto;
  width: 16px;
  position: absolute;
  left: 20px;
  color: white;
}
.yt-main-header nav.navbar[data-v-5238a39b] {
  padding: 0px;
}
.yt-main-header ul.navbar-nav[data-v-5238a39b] {
  width: 100%;
  justify-content: space-around;
  background-color: #000;
}
.yt-main-header .nav-link[data-v-5238a39b] {
  font-size: 18px;
  padding: 20px;
  color: #fff !important;
  font-weight: 100 !important;
}
.yt-main-header .nav-link.active[data-v-5238a39b] {
  color: #fff !important;
  border-bottom: 3px solid #8b8f95;
  font-weight: bold !important;
}
.yt-main-header .cm-drop-down-wrap[data-v-5238a39b] {
  position: static;
}
.yt-main-header .cm-big-drop-menu[data-v-5238a39b] {
  width: 100%;
  padding: 52px 60px 37px;
  max-width: 1180px;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto;
}
.yt-main-header .cm_big_dropmenu[data-v-5238a39b] {
  min-width: 250px;
  margin: 0;
  padding: 0;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  top: 150% !important;
}
.yt-main-header .cm_big_dropmenu[data-v-5238a39b]::-webkit-scrollbar {
  width: 5px;
}
.yt-main-header .cm_big_dropmenu[data-v-5238a39b]::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #ccc;
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.yt-main-header .cm_big_dropmenu[data-v-5238a39b]::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.yt-main-header .cm_big_dropmenu .category_name[data-v-5238a39b] {
  font-size: 20px;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  color: black;
}
.yt-main-header .cm_big_dropmenu .category_name[data-v-5238a39b]:hover,
.yt-main-header .cm_big_dropmenu .category_name[data-v-5238a39b]:focus {
  background-color: transparent;
}
.cart-divider[data-v-5238a39b] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  width: 87%;
  margin: auto;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-5238a39b] {
  font-size: 18px;
  padding: 15px 20px;
  color: black;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-5238a39b]:hover,
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-5238a39b]:focus {
  background-color: transparent;
}
.yt-main-header .cm-big-menu-head .dropdown-divider[data-v-5238a39b] {
  border-top: 1px solid var(--color-dark-grey);
}
.main_header .cm_big_dropmenu[data-v-5238a39b] {
  position: absolute;
  border-radius: 0 !important;
  top: 150% !important;
}
.main_header .cm-big-menu-inner .dropdown-item[data-v-5238a39b] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  padding: 8px 10px;
  margin-bottom: 5px;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-5238a39b] {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-main-header .head-dropdown-arrow-icn[data-v-5238a39b] {
  font-size: 12px;
  margin-left: 4px;
}
.yt-main-header li.cm-drop-down-wrap.dropdown.show > a[data-v-5238a39b] {
  color: #fff !important;
  font-weight: 600;
  border-bottom: 3px solid #8b8f95;
}
.yt-main-header .header-search-field-wrap[data-v-5238a39b] {
  position: relative;
}
.yt-recent-search-wrap[data-v-5238a39b] {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 50;
  width: 250px;
}
.yt-main-header .recent-search-tag-name[data-v-5238a39b] {
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.yt-main-header .recent-search-list-wrap .active[data-v-5238a39b] {
  background: transparent;
  color: inherit;
}
.yt-recent-search-wrap[data-v-5238a39b] {
  border-radius: 10px;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 32px 20px 27px;
}
.yt-main-header .yt-no-search-found-wrap[data-v-5238a39b] {
  padding: 54px 30px 46px;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-5238a39b] {
  border-bottom: 2px solid #cae2fe;
  margin: 0;
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  padding: 15px 0 !important;
}
.myIcon[data-v-5238a39b] {
  height: auto;
  width: 25px;
  position: absolute;
  left: 20px;
  color: white;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-5238a39b]:first-child {
  padding-top: 0 !important;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-5238a39b]:last-child {
  padding-bottom: 0 !important;
}
.yt-main-header .search-no-ttl[data-v-5238a39b] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-top: 35px;
  margin-bottom: 15px;
}
.yt-main-header .search-no-sub-ttl[data-v-5238a39b] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.yt-main-header .yt-main-menu .nav-item[data-v-5238a39b] {
  cursor: pointer;
}
.yt-main-header .logoimage[data-v-5238a39b] {
  max-width: 280px;
}
.yt-head-login-btn[data-v-5238a39b] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  background-color: var(--color-dark-black);
  padding: 15px 26px;
}
.user_Name[data-v-5238a39b] {
  cursor: pointer;
}
.yt-mobile-side-nav-wrap[data-v-5238a39b] {
  display: none;
}
.cart-item[data-v-5238a39b] {
  position: absolute;
  top: 0;
  right: 18px;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.wishlist-item[data-v-5238a39b] {
  position: absolute;
  top: 15px;
  /* right: 170px; */
  /* right: 218px; */
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.cartcontainer .nav-link[data-v-5238a39b] {
  padding: 0;
  margin-right: 30px;
}
@media (min-width: 1900px) {
.yt-main-header .cm-big-drop-menu[data-v-5238a39b] {
    max-width: 1421px;
}
.main_header
    .cm-big-drop-menu
    .yt-main-header
    .cm-big-menu-inner
    .dropdown-item[data-v-5238a39b] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-5238a39b] {
    font-size: 28px;
    line-height: 1.14;
}
.yt-main-header .searchinput[data-v-5238a39b] {
    padding-top: 13px;
    padding-bottom: 13px;
}
}
@media (max-width: 1366px) {
.yt-main-header .logoimage[data-v-5238a39b] {
    max-width: 220px;
}
.main_header .navbar .nav-item[data-v-5238a39b]:not(:last-child) {
    margin-right: 100px !important;
}
}
@media only screen and (min-width: 992px) {
.yt-main-header .order-sm-1[data-v-5238a39b] {
    order: 0;
}
.yt-main-header .searchicon[data-v-5238a39b] {
    left: 30px;
}
.yt-main-header ul.navbar-nav[data-v-5238a39b] {
    padding: 0 226px;
}
}
@media only screen and (max-width: 768px) {
.yt-only-mobile-vw[data-v-5238a39b] {
    display: none;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-5238a39b] {
    display: none;
}
}
@media only screen and (max-width: 992px) {
.yt-main-header .order-sm-1[data-v-5238a39b] {
    order: 1;
}
.yt-main-header .searchicon[data-v-5238a39b] {
    left: 32px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767px) {
.yt-main-header .logocontainer .yt-head-col[data-v-5238a39b] {
    flex: 0 0 50%;
    max-width: 50%;
}
.yt-main-header .yt-head-hide-srch-col[data-v-5238a39b],
  .yt-main-header .topbar[data-v-5238a39b] {
    display: none;
}
.yt-main-header .logocontainer[data-v-5238a39b] {
    padding-top: 15px;
    padding-bottom: 15px;
}
.yt-main-header .supportbox[data-v-5238a39b] {
    display: none;
}
.yt-main-header .logoimage[data-v-5238a39b] {
    max-width: 140px;
}
.yt-main-header .logobox[data-v-5238a39b] {
    margin-left: 30px;
}
.yt-main-header .loginbutton span[data-v-5238a39b] {
    display: none;
}
.yt-main-header .loginbutton span.yt-head-lgn-btn[data-v-5238a39b] {
    display: block !important;
}
.yt-main-header .loginbutton span[data-v-5238a39b] {
    font-size: 13px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    color: white;
}
.yt-main-header .loginbutton[data-v-5238a39b] {
    background: transparent;
    width: auto;
    height: auto;
    border-radius: 0;
}
.cartcontainer .nav-link[data-v-5238a39b] {
    margin-right: 15px;
}
.yt-main-header .carticon[data-v-5238a39b] {
    width: 16px;
    height: auto;
}
.yt-main-header .yt-head-seachicn-mb[data-v-5238a39b] {
    display: block !important;
    position: inherit;
    margin-right: 13px;
    width: 18px;
    padding: 0;
}
.yt-head-mb-search-panel[data-v-5238a39b] {
    display: block !important;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-5238a39b] {
    display: block;
}
.yt-mobile-side-nav-wrap[data-v-5238a39b] {
    position: fixed;
    background-color: rgb(62 69 79 / 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.yt-mobile-side-inner-content[data-v-5238a39b] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 550;
    max-width: 221px;
    overflow: auto;
    height: 100%;
    width: 100%;
}
.yt-mb-user-profile[data-v-5238a39b] {
    padding: 20px;
    background-color: var(--color-dark-black);
    color: #fff;
}
.yt-mobile-side-inner-content .yt-inner-cnt-logo[data-v-5238a39b] {
    padding: 15px 20px 20px;
}
.yt-mobile-side-inner-content .logobox-mb img[data-v-5238a39b] {
    background-color: var(--color-dark-black);
}
.yt-mobile-nav-content[data-v-5238a39b] {
    padding: 17px 20px;
}
.yt-mb-user-profile .yt-head-name[data-v-5238a39b] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-mb-user-profile .yt-head-email[data-v-5238a39b] {
    font-size: 8px;
    line-height: 1.13;
}
.yt-mobile-nav-content li:last-child a[data-v-5238a39b] {
    padding-bottom: 0;
}
.yt-mb-user-prfl-lks li:first-child a[data-v-5238a39b] {
    padding-top: 0;
}
.yt-mb-user-prfl-lks[data-v-5238a39b] {
    border-top: solid 1px #dbdbdb;
    padding: 20px;
}
.yt-main-header .yt-mobile-nav-content .cm-big-drop-menu[data-v-5238a39b] {
    padding: 0;
    border: none;
}
.yt-main-header
    .yt-mobile-nav-content
    li.cm-drop-down-wrap.dropdown.show
    > a[data-v-5238a39b] {
    border-bottom: none;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider[data-v-5238a39b] {
    border: solid 1px #dbdbdb;
}
.main_header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item[data-v-5238a39b] {
    font-size: 10px;
    line-height: 1.1;
}
.main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item[data-v-5238a39b] {
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-black);
}
.yt-mb-user-profile .yt-mb-nav-login-btn[data-v-5238a39b] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    font-weight: 500;
}
.yt-mb-innet-search[data-v-5238a39b] {
    padding: 14px 15px 0;
}
.yt-main-header .yt-mb-innet-search .searchicon[data-v-5238a39b] {
    top: 50%;
    transform: translateY(-50%);
    max-width: 16px;
    left: 12px;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-5238a39b] {
    padding: 10px 10px 10px 38px !important;
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-5238a39b]::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-5238a39b]::-moz-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-5238a39b]:-ms-input-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-5238a39b]:-moz-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
}
/* @media (max-width: 992px) {
    .main_header .logocontainer .yt-head-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .main_header .yt-head-hide-srch-col,
    .main_header .topbar {
      display: none;
    }
    .main_header .logocontainer {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .main_header .supportbox {
      display: none;
    }
    .main_header .logoimage {
      max-width: 140px;
    }
    .main_header .logobox {
      margin-left: 30px;
    }
    .main_header .loginbutton span {
      display: none;
    }
    .main_header .loginbutton span.yt-head-lgn-btn {
      display: block !important;
    }
    .main_header .loginbutton span {
      font-size: 13px;
      line-height: 1.14;
      letter-spacing: 0.28px;
      color: white;
    }
    .main_header .loginbutton {
      background: transparent;
      width: auto;
      height: auto;
      border-radius: 0;
    }
    .cartcontainer .nav-link {
      margin-right: 15px;
    }
    .main_header .carticon {
      width: 16px;
      height: auto;
    }
    .main_header .yt-head-seachicn-mb {
      display: block !important;
      position: inherit;
      margin-right: 13px;
      width: 18px;
      padding: 0;
    }
    .yt-head-mb-search-panel {
      display: block !important;
    }
    .yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap {
      display: block;
    }
    .yt-mobile-side-nav-wrap {
      position: fixed;
      background-color: rgb(62 69 79 / 0.8);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 500;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
    .yt-mobile-side-inner-content {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 550;
      max-width: 221px;
      overflow: auto;
      height: 100%;
      width: 100%;
    }
    .yt-mb-user-profile {
      padding: 20px;
      background-color: var(--color-dark-black);
      color: #fff;
    }
    .yt-mobile-side-inner-content .yt-inner-cnt-logo {
      padding: 15px 20px 20px;
    }
    .yt-mobile-side-inner-content .logobox-mb img {
      background-color: var(--color-dark-black);
    }
    .yt-mobile-nav-content {
      padding: 17px 20px;
    }
    .yt-mb-user-profile .yt-head-name {
      font-size: 16px;
      line-height: 1.13;
    }
    .yt-mb-user-profile .yt-head-email {
      font-size: 8px;
      line-height: 1.13;
    }
    .yt-mobile-nav-content li:last-child a {
      padding-bottom: 0;
    }
    .yt-mb-user-prfl-lks li:first-child a {
      padding-top: 0;
    }
    .yt-mb-user-prfl-lks {
      border-top: solid 1px #dbdbdb;
      padding: 20px;
    }
    .main_header .yt-mobile-nav-content .cm-big-drop-menu {
      padding: 0;
      border: none;
    }
    .main_header
      .yt-mobile-nav-content
      li.cm-drop-down-wrap.dropdown.show
      > a {
      border-bottom: none;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider {
      border: solid 1px #dbdbdb;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item {
      font-size: 10px;
      line-height: 1.1;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item {
      font-size: 12px;
      line-height: 1.17;
      color: var(--color-black);
    }
    .yt-mb-user-profile .yt-mb-nav-login-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.28px;
      font-weight: 500;
    }
    .yt-mb-innet-search {
      padding: 14px 15px 0;
    }
    .main_header .yt-mb-innet-search .searchicon {
      top: 50%;
      transform: translateY(-50%);
      max-width: 16px;
      left: 12px;
    }
    .main_header .yt-mb-innet-search .searchinput {
      padding: 10px 10px 10px 38px !important;
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput::-webkit-input-placeholder {
    
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput::-moz-placeholder {
     
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput:-ms-input-placeholder {
    
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput:-moz-placeholder {
      
      font-size: 12px;
      line-height: 1.17;
    }
  } */
.userbox[data-v-5238a39b] {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.userbox .usericon[data-v-5238a39b] {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
}
.userbox .wishlistIcon[data-v-5238a39b] {
  width: 27px;
  /* min-width: 42px; */
  height: 42px;
  /* border-radius: 50%; */
  /* border: 1px solid #fff; */
  display: inline-block;
  color: white;
  margin-right: 22px;
}
.userbox .uname[data-v-5238a39b] {
  margin-left: 10px;
  color: #fff;
  max-width: 70px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-main-header .supportnumber .title[data-v-5238a39b] {
    font-size: 14px;
}
.yt-main-header .supportnumber .number[data-v-5238a39b] {
    font-size: 16px;
}
.yt-main-header .searchinput[data-v-5238a39b] {
    font-size: 16px;
}
.yt-main-header .loginbutton[data-v-5238a39b] {
    font-size: 16px;
}
.yt-main-header .yt-main-menu .nav-item[data-v-5238a39b] {
    font-size: 18px;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-5238a39b] {
    font-size: 20px;
}
}
.main_header[data-v-5238a39b] {
  width: 100%;
  z-index: 111;
}
@-webkit-keyframes myanimation-data-v-5238a39b {
0% {
    background-color: transparent;
}
35% {
    background-color: black;
}
100% {
    background-color: black;
}
}
@keyframes myanimation-data-v-5238a39b {
0% {
    background-color: transparent;
}
35% {
    background-color: black;
}
100% {
    background-color: black;
}
}
.myHeaderStyle[data-v-5238a39b] {
  background-color: black;
  position: relative;
}
.myHeaderStyle2[data-v-5238a39b] {
  position: fixed;
  transform: translateY(10);
  transition: transform 6s ease;
  -webkit-animation: myanimation-data-v-5238a39b 3s;
          animation: myanimation-data-v-5238a39b 3s;
  background-color: #000000f0;
}
.position-absolute[data-v-5238a39b] {
  position: absolute;
}
.main_header .navbar[data-v-5238a39b] {
  padding: 15px 80px;
}
.main_header .other_page[data-v-5238a39b] {
  box-shadow: 0 3px 99px 0 #d9d9da !important;
}
.main_header .navbar .navbar-brand[data-v-5238a39b] {
  padding: 0;
  margin: 0;
}
.main_header .navbar .navbar-brand img[data-v-5238a39b] {
  width: auto;
  max-width: 110px;
}
.main_header .navbar .navbar-nav[data-v-5238a39b] {
  align-items: center;
}
.main_header .navbar .navbar-nav .nav-item[data-v-5238a39b] {
  display: inline-flex;
}
.main_header .navbar .navbar-nav .nav-item[data-v-5238a39b]:not(:last-child) {
  margin-right: 125px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-5238a39b] {
  padding: 0;
  font-size: 20px;
  color: white;
  letter-spacing: 1px;
  /* display: inline-flex; */
  padding-bottom: 6px;
}
.searchinput[data-v-5238a39b] {
  background-color: black;
  color: white;
  border: none;
  font-size: 19px;
  letter-spacing: 2px;
  width: 100%;
}
.searchInput-icon[data-v-5238a39b] {
  font-size: 27px;
  color: white;
  margin-right: 6px;
}
.borderTop[data-v-5238a39b] {
  border-top: 1px solid #ffffffb3;
  width: 100%;
}
.cancel-search-icon[data-v-5238a39b] {
  color: #8b8f95;
  font-size: 20px;
}
.user-name[data-v-5238a39b] {
  font-size: 14px;
  left: 0;
  right: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.user-icon[data-v-5238a39b] {
  font-size: 30px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon[data-v-5238a39b] {
  position: relative;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .nav-link[data-v-5238a39b] {
  font-size: 30px;
  cursor: pointer;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-5238a39b] {
  border-bottom: 1px solid #e6e6e6;
  position: absolute;
  right: 0;
  left: auto;
  top: 0px;
  display: flex;
  min-width: 225px;
  align-items: center;
  transition: 0.5s linear;
  min-height: 28px;
  opacity: 0;
  visibility: hidden;
  background-color: black;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input.active[data-v-5238a39b] {
  opacity: 1;
  visibility: visible;
}
@-webkit-keyframes myDropdown-data-v-5238a39b {
0% {
    background-color: transparent;
}
35% {
    background-color: white;
}
100% {
    background-color: white;
}
}
@keyframes myDropdown-data-v-5238a39b {
0% {
    background-color: transparent;
}
35% {
    background-color: white;
}
100% {
    background-color: white;
}
}
.main_header .cm_big_dropmenu[data-v-5238a39b] {
  min-width: 250px;
  max-height: 600px;
  -webkit-animation: myDropdown-data-v-5238a39b 2s;
          animation: myDropdown-data-v-5238a39b 2s;
}
.main_header .cm_big_dropmenu .category_name[data-v-5238a39b] {
  font-size: 20px;
  padding: 15px 20px;
  font-weight: 500;
  color: black;
}
.main_header .cm_big_dropmenu .category_name[data-v-5238a39b]:hover {
  color: #8b8f95;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-5238a39b]:hover {
  color: black;
}
.main_header a[data-v-5238a39b] {
  display: inline-block;
  text-decoration: none;
}
.main_header a[data-v-5238a39b]::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: white;
  transition: width 0.3s ease-out;
}
.main_header a.iamactive[data-v-5238a39b]::after {
  width: 100%;
}
.hoverMe[data-v-5238a39b]:hover::after {
  width: 100%;
}
.main_header a[aria-expanded="true"][data-v-5238a39b]::after {
  width: 100%;
}
.main_header a[aria-expanded="false"] > .iconDown[data-v-5238a39b] {
  display: none;
}
.main_header a[aria-expanded="true"] > .iconRight[data-v-5238a39b] {
  display: none;
}
.my-cart-divider[data-v-5238a39b] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  margin: auto;
  width: 85%;
}
.main_header .cm_big_dropmenu .category_name[data-v-5238a39b]:last-child {
  border-bottom: none;
}
.main_header .cm_big_dropmenu .category_name[data-v-5238a39b]:hover,
.main_header .cm_big_dropmenu .category_name[data-v-5238a39b]:focus {
  background-color: transparent;
}
nav.bg-dark[data-v-5238a39b] {
  border-bottom: none;
}
.square[data-v-5238a39b] {
  position: absolute;
  top: -5px;
  left: 10px;
  width: 9px;
  height: 9px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  transform: rotate(45deg);
  -webkit-animation: myDropdown-data-v-5238a39b 3s;
          animation: myDropdown-data-v-5238a39b 3s;
}
@media (max-width: 1774px) {
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-5238a39b] {
    min-width: 200px;
}
}
@media (max-width: 1601px) and (min-width: 1428px) {
.main_header .navbar .nav-item[data-v-5238a39b]:not(:last-child) {
    margin-right: 60px !important;
}
}
@media (max-width: 1427px) {
.main_header .navbar .nav-item[data-v-5238a39b]:not(:last-child) {
    margin-right: 50px !important;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-5238a39b] {
    min-width: 150px;
}
}
@media (max-width: 1276px) {
.main_header .navbar .nav-item[data-v-5238a39b]:not(:last-child) {
    margin-right: 35px !important;
}
.main_header .navbar .nav-item .nav-link[data-v-5238a39b] {
    font-size: 15px;
}
}
@media (max-width: 1201px) {
.main_header .navbar .nav-item[data-v-5238a39b]:not(:last-child) {
    margin-right: 25px !important;
}
.main_header .navbar .nav-item .nav-link[data-v-5238a39b] {
    font-size: 17px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.user-icon[data-v-5238a39b] {
    font-size: 22px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.search-icon[data-v-5238a39b] {
    font-size: 22px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-5238a39b] {
    min-width: 160px;
}
}
@media (max-width: 992px) {
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-5238a39b] {
    min-width: 66px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-5238a39b] {
    font-size: 17px;
}
}
@media (max-width: 812px) {
.main_header .navbar .navbar-brand img[data-v-5238a39b] {
    max-width: 70px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-5238a39b] {
    font-size: 15px;
}
.main_header .navbar[data-v-5238a39b] {
    padding: 14px 35px;
}
.main_header .navbar .nav-item[data-v-5238a39b]:not(:last-child) {
    margin-right: 15px !important;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-5238a39b] {
    min-width: 150px;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.navbar-brand[data-v-5238a39b] {
    margin: 0 auto !important;
}
.navbar-collapse[data-v-5238a39b] {
    padding-top: 50px !important;
}
.navbar-collapse ul li[data-v-5238a39b] {
    padding-top: 5px !important;
}
.main_header .navbar .nav-item[data-v-5238a39b]:not(:last-child) {
    margin-right: 0 !important;
}
.navbar-collapse ul:last-child li[data-v-5238a39b]:last-child {
    margin-right: 0 !important;
}
.user-name[data-v-5238a39b] {
    left: 0;
}
.yt-recent-search-wrap[data-v-5238a39b] {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 300px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-5238a39b] {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 250px;
    height: 35px;
}
.main_header .cm_big_dropmenu[data-v-5238a39b] {
    top: 112% !important;
    transform: translate(-50px, 0px);
}
.square[data-v-5238a39b] {
    left: 120px;
}
.main_header .cm_big_dropmenu .category_name[data-v-5238a39b] {
    font-size: 18px;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-5238a39b] {
    font-size: 16px;
}
}

.yt-main-header .topbar[data-v-2969037b] {
  color: var(--color-black);
  background-color: #f6f8fa;
  padding: 21px 0 17px;
}
.yt-main-header .logocontainer[data-v-2969037b] {
  background-color: #000;
  padding: 30px 0;
}
.yt-main-header .logobox[data-v-2969037b] {
  cursor: pointer;
  background-color: var(--color-dark-black);
}
.yt-header-top-message[data-v-2969037b] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
}
.yt-main-header .supportbox[data-v-2969037b] {
  /* height: 95px; */
  width: 220px;
  /* padding: 50px 0; */
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .support[data-v-2969037b] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .supportimage[data-v-2969037b] {
  height: auto;
  width: 30px;
  margin-right: 10px;
}
.yt-main-header .supportnumber[data-v-2969037b] {
  display: flex;
  flex-direction: column;
}
.yt-main-header .supportnumber .title[data-v-2969037b] {
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  padding-bottom: 5px;
}
.yt-main-header .supportnumber .number[data-v-2969037b] {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}
.yt-main-header .searchcontainer[data-v-2969037b] {
  display: flex;
  align-items: center;
  height: 100%;
}
.yt-main-header .searchinput[data-v-2969037b] {
  border-radius: 6px;
  border: solid 1px #37393b;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0 10px 50px;
  line-height: 1.15;
  color: #757575;
  background-color: #37393b;
  font-size: 20px;
  font-weight: 500;
}
.yt-main-header .searchicon[data-v-2969037b] {
  height: auto;
  width: 40px;
  padding: 10px;
  position: absolute;
  left: 18px;
}
.yt-main-header .cartcontainer[data-v-2969037b] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.yt-main-header .carticon[data-v-2969037b] {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.yt-main-header .loginbutton[data-v-2969037b] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.yt-main-header .menucontainer[data-v-2969037b] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top: 1px solid #37393b;
  background-color: #000;
}
.main_header .menuicon[data-v-2969037b] {
  height: auto;
  width: 16px;
  position: absolute;
  left: 20px;
  color: white;
}
.yt-main-header nav.navbar[data-v-2969037b] {
  padding: 0px;
}
.yt-main-header ul.navbar-nav[data-v-2969037b] {
  width: 100%;
  justify-content: space-around;
  background-color: #000;
}
.yt-main-header .nav-link[data-v-2969037b] {
  font-size: 18px;
  padding: 20px;
  color: #fff !important;
  font-weight: 100 !important;
}
.yt-main-header .nav-link.active[data-v-2969037b] {
  color: #fff !important;
  border-bottom: 3px solid #8b8f95;
  font-weight: bold !important;
}
.yt-main-header .cm-drop-down-wrap[data-v-2969037b] {
  position: static;
}
.yt-main-header .cm-big-drop-menu[data-v-2969037b] {
  width: 100%;
  padding: 52px 60px 37px;
  max-width: 1180px;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto;
}
.yt-main-header .cm_big_dropmenu[data-v-2969037b] {
  min-width: 250px;
  margin: 0;
  padding: 0;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  top: 150% !important;
}
.yt-main-header .cm_big_dropmenu[data-v-2969037b]::-webkit-scrollbar {
  width: 5px;
}
.yt-main-header .cm_big_dropmenu[data-v-2969037b]::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #ccc;
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.yt-main-header .cm_big_dropmenu[data-v-2969037b]::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.yt-main-header .cm_big_dropmenu .category_name[data-v-2969037b] {
  font-size: 20px;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  color: black;
}
.yt-main-header .cm_big_dropmenu .category_name[data-v-2969037b]:hover,
.yt-main-header .cm_big_dropmenu .category_name[data-v-2969037b]:focus {
  background-color: transparent;
}
.cart-divider[data-v-2969037b] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  width: 87%;
  margin: auto;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b] {
  font-size: 18px;
  padding: 15px 20px;
  color: black;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b]:hover,
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b]:focus {
  background-color: transparent;
}
.yt-main-header .cm-big-menu-head .dropdown-divider[data-v-2969037b] {
  border-top: 1px solid var(--color-dark-grey);
}
.main_header .cm_big_dropmenu[data-v-2969037b] {
  position: absolute;
  border-radius: 0 !important;
  top: 150% !important;
}
.main_header .cm-big-menu-inner .dropdown-item[data-v-2969037b] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  padding: 8px 10px;
  margin-bottom: 5px;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-2969037b] {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-main-header .head-dropdown-arrow-icn[data-v-2969037b] {
  font-size: 12px;
  margin-left: 4px;
}
.yt-main-header li.cm-drop-down-wrap.dropdown.show > a[data-v-2969037b] {
  color: #fff !important;
  font-weight: 600;
  border-bottom: 3px solid #8b8f95;
}
.yt-main-header .header-search-field-wrap[data-v-2969037b] {
  position: relative;
}
.yt-recent-search-wrap[data-v-2969037b] {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 50;
  width: 250px;
}
.yt-main-header .recent-search-tag-name[data-v-2969037b] {
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.yt-main-header .recent-search-list-wrap .active[data-v-2969037b] {
  background: transparent;
  color: inherit;
}
.yt-recent-search-wrap[data-v-2969037b] {
  border-radius: 10px;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 32px 20px 27px;
}
.yt-main-header .yt-no-search-found-wrap[data-v-2969037b] {
  padding: 54px 30px 46px;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-2969037b] {
  border-bottom: 2px solid #cae2fe;
  margin: 0;
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  padding: 15px 0 !important;
}
.myIcon[data-v-2969037b] {
  height: auto;
  width: 25px;
  position: absolute;
  left: 20px;
  color: white;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-2969037b]:first-child {
  padding-top: 0 !important;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-2969037b]:last-child {
  padding-bottom: 0 !important;
}
.yt-main-header .search-no-ttl[data-v-2969037b] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-top: 35px;
  margin-bottom: 15px;
}
.yt-main-header .search-no-sub-ttl[data-v-2969037b] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.yt-main-header .yt-main-menu .nav-item[data-v-2969037b] {
  cursor: pointer;
}
.yt-main-header .logoimage[data-v-2969037b] {
  max-width: 280px;
}
.yt-head-login-btn[data-v-2969037b] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  background-color: var(--color-dark-black);
  padding: 15px 26px;
}
.user_Name[data-v-2969037b] {
  cursor: pointer;
}
.yt-mobile-side-nav-wrap[data-v-2969037b] {
  display: none;
}
.cart-item[data-v-2969037b] {
  position: absolute;
  top: 0;
  right: 18px;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.wishlist-item[data-v-2969037b] {
  position: absolute;
  top: 15px;
  /* right: 170px; */
  /* right: 218px; */
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.cartcontainer .nav-link[data-v-2969037b] {
  padding: 0;
  margin-right: 30px;
}
@media (min-width: 1900px) {
.yt-main-header .cm-big-drop-menu[data-v-2969037b] {
    max-width: 1421px;
}
.main_header
    .cm-big-drop-menu
    .yt-main-header
    .cm-big-menu-inner
    .dropdown-item[data-v-2969037b] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-2969037b] {
    font-size: 28px;
    line-height: 1.14;
}
.yt-main-header .searchinput[data-v-2969037b] {
    padding-top: 13px;
    padding-bottom: 13px;
}
}
@media (max-width: 1366px) {
.yt-main-header .logoimage[data-v-2969037b] {
    max-width: 220px;
}
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 100px !important;
}
}
@media only screen and (min-width: 992px) {
.yt-main-header .order-sm-1[data-v-2969037b] {
    order: 0;
}
.yt-main-header .searchicon[data-v-2969037b] {
    left: 30px;
}
.yt-main-header ul.navbar-nav[data-v-2969037b] {
    padding: 0 226px;
}
}
@media only screen and (max-width: 768px) {
.yt-only-mobile-vw[data-v-2969037b] {
    display: none;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-2969037b] {
    display: none;
}
}
@media only screen and (max-width: 992px) {
.yt-main-header .order-sm-1[data-v-2969037b] {
    order: 1;
}
.yt-main-header .searchicon[data-v-2969037b] {
    left: 32px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767px) {
.yt-main-header .logocontainer .yt-head-col[data-v-2969037b] {
    flex: 0 0 50%;
    max-width: 50%;
}
.yt-main-header .yt-head-hide-srch-col[data-v-2969037b],
  .yt-main-header .topbar[data-v-2969037b] {
    display: none;
}
.yt-main-header .logocontainer[data-v-2969037b] {
    padding-top: 15px;
    padding-bottom: 15px;
}
.yt-main-header .supportbox[data-v-2969037b] {
    display: none;
}
.yt-main-header .logoimage[data-v-2969037b] {
    max-width: 140px;
}
.yt-main-header .logobox[data-v-2969037b] {
    margin-left: 30px;
}
.yt-main-header .loginbutton span[data-v-2969037b] {
    display: none;
}
.yt-main-header .loginbutton span.yt-head-lgn-btn[data-v-2969037b] {
    display: block !important;
}
.yt-main-header .loginbutton span[data-v-2969037b] {
    font-size: 13px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    color: white;
}
.yt-main-header .loginbutton[data-v-2969037b] {
    background: transparent;
    width: auto;
    height: auto;
    border-radius: 0;
}
.cartcontainer .nav-link[data-v-2969037b] {
    margin-right: 15px;
}
.yt-main-header .carticon[data-v-2969037b] {
    width: 16px;
    height: auto;
}
.yt-main-header .yt-head-seachicn-mb[data-v-2969037b] {
    display: block !important;
    position: inherit;
    margin-right: 13px;
    width: 18px;
    padding: 0;
}
.yt-head-mb-search-panel[data-v-2969037b] {
    display: block !important;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-2969037b] {
    display: block;
}
.yt-mobile-side-nav-wrap[data-v-2969037b] {
    position: fixed;
    background-color: rgb(62 69 79 / 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.yt-mobile-side-inner-content[data-v-2969037b] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 550;
    max-width: 221px;
    overflow: auto;
    height: 100%;
    width: 100%;
}
.yt-mb-user-profile[data-v-2969037b] {
    padding: 20px;
    background-color: var(--color-dark-black);
    color: #fff;
}
.yt-mobile-side-inner-content .yt-inner-cnt-logo[data-v-2969037b] {
    padding: 15px 20px 20px;
}
.yt-mobile-side-inner-content .logobox-mb img[data-v-2969037b] {
    background-color: var(--color-dark-black);
}
.yt-mobile-nav-content[data-v-2969037b] {
    padding: 17px 20px;
}
.yt-mb-user-profile .yt-head-name[data-v-2969037b] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-mb-user-profile .yt-head-email[data-v-2969037b] {
    font-size: 8px;
    line-height: 1.13;
}
.yt-mobile-nav-content li:last-child a[data-v-2969037b] {
    padding-bottom: 0;
}
.yt-mb-user-prfl-lks li:first-child a[data-v-2969037b] {
    padding-top: 0;
}
.yt-mb-user-prfl-lks[data-v-2969037b] {
    border-top: solid 1px #dbdbdb;
    padding: 20px;
}
.yt-main-header .yt-mobile-nav-content .cm-big-drop-menu[data-v-2969037b] {
    padding: 0;
    border: none;
}
.yt-main-header
    .yt-mobile-nav-content
    li.cm-drop-down-wrap.dropdown.show
    > a[data-v-2969037b] {
    border-bottom: none;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider[data-v-2969037b] {
    border: solid 1px #dbdbdb;
}
.main_header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item[data-v-2969037b] {
    font-size: 10px;
    line-height: 1.1;
}
.main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item[data-v-2969037b] {
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-black);
}
.yt-mb-user-profile .yt-mb-nav-login-btn[data-v-2969037b] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    font-weight: 500;
}
.yt-mb-innet-search[data-v-2969037b] {
    padding: 14px 15px 0;
}
.yt-main-header .yt-mb-innet-search .searchicon[data-v-2969037b] {
    top: 50%;
    transform: translateY(-50%);
    max-width: 16px;
    left: 12px;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b] {
    padding: 10px 10px 10px 38px !important;
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b]::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b]::-moz-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b]:-ms-input-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b]:-moz-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
}
/* @media (max-width: 992px) {
    .main_header .logocontainer .yt-head-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .main_header .yt-head-hide-srch-col,
    .main_header .topbar {
      display: none;
    }
    .main_header .logocontainer {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .main_header .supportbox {
      display: none;
    }
    .main_header .logoimage {
      max-width: 140px;
    }
    .main_header .logobox {
      margin-left: 30px;
    }
    .main_header .loginbutton span {
      display: none;
    }
    .main_header .loginbutton span.yt-head-lgn-btn {
      display: block !important;
    }
    .main_header .loginbutton span {
      font-size: 13px;
      line-height: 1.14;
      letter-spacing: 0.28px;
      color: white;
    }
    .main_header .loginbutton {
      background: transparent;
      width: auto;
      height: auto;
      border-radius: 0;
    }
    .cartcontainer .nav-link {
      margin-right: 15px;
    }
    .main_header .carticon {
      width: 16px;
      height: auto;
    }
    .main_header .yt-head-seachicn-mb {
      display: block !important;
      position: inherit;
      margin-right: 13px;
      width: 18px;
      padding: 0;
    }
    .yt-head-mb-search-panel {
      display: block !important;
    }
    .yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap {
      display: block;
    }
    .yt-mobile-side-nav-wrap {
      position: fixed;
      background-color: rgb(62 69 79 / 0.8);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 500;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
    .yt-mobile-side-inner-content {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 550;
      max-width: 221px;
      overflow: auto;
      height: 100%;
      width: 100%;
    }
    .yt-mb-user-profile {
      padding: 20px;
      background-color: var(--color-dark-black);
      color: #fff;
    }
    .yt-mobile-side-inner-content .yt-inner-cnt-logo {
      padding: 15px 20px 20px;
    }
    .yt-mobile-side-inner-content .logobox-mb img {
      background-color: var(--color-dark-black);
    }
    .yt-mobile-nav-content {
      padding: 17px 20px;
    }
    .yt-mb-user-profile .yt-head-name {
      font-size: 16px;
      line-height: 1.13;
    }
    .yt-mb-user-profile .yt-head-email {
      font-size: 8px;
      line-height: 1.13;
    }
    .yt-mobile-nav-content li:last-child a {
      padding-bottom: 0;
    }
    .yt-mb-user-prfl-lks li:first-child a {
      padding-top: 0;
    }
    .yt-mb-user-prfl-lks {
      border-top: solid 1px #dbdbdb;
      padding: 20px;
    }
    .main_header .yt-mobile-nav-content .cm-big-drop-menu {
      padding: 0;
      border: none;
    }
    .main_header
      .yt-mobile-nav-content
      li.cm-drop-down-wrap.dropdown.show
      > a {
      border-bottom: none;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider {
      border: solid 1px #dbdbdb;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item {
      font-size: 10px;
      line-height: 1.1;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item {
      font-size: 12px;
      line-height: 1.17;
      color: var(--color-black);
    }
    .yt-mb-user-profile .yt-mb-nav-login-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.28px;
      font-weight: 500;
    }
    .yt-mb-innet-search {
      padding: 14px 15px 0;
    }
    .main_header .yt-mb-innet-search .searchicon {
      top: 50%;
      transform: translateY(-50%);
      max-width: 16px;
      left: 12px;
    }
    .main_header .yt-mb-innet-search .searchinput {
      padding: 10px 10px 10px 38px !important;
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput::-webkit-input-placeholder {
    
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput::-moz-placeholder {
     
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput:-ms-input-placeholder {
    
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput:-moz-placeholder {
      
      font-size: 12px;
      line-height: 1.17;
    }
  } */
.userbox[data-v-2969037b] {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.userbox .usericon[data-v-2969037b] {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
}
.userbox .wishlistIcon[data-v-2969037b] {
  width: 27px;
  /* min-width: 42px; */
  height: 42px;
  /* border-radius: 50%; */
  /* border: 1px solid #fff; */
  display: inline-block;
  color: white;
  margin-right: 22px;
}
.userbox .uname[data-v-2969037b] {
  margin-left: 10px;
  color: #fff;
  max-width: 70px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-main-header .supportnumber .title[data-v-2969037b] {
    font-size: 14px;
}
.yt-main-header .supportnumber .number[data-v-2969037b] {
    font-size: 16px;
}
.yt-main-header .searchinput[data-v-2969037b] {
    font-size: 16px;
}
.yt-main-header .loginbutton[data-v-2969037b] {
    font-size: 16px;
}
.yt-main-header .yt-main-menu .nav-item[data-v-2969037b] {
    font-size: 18px;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-2969037b] {
    font-size: 20px;
}
}
.main_header[data-v-2969037b] {
  width: 100%;
  z-index: 111;
}
@-webkit-keyframes myanimation-data-v-2969037b {
0% {
    background-color: transparent;
}
35% {
    background-color: black;
}
100% {
    background-color: black;
}
}
@keyframes myanimation-data-v-2969037b {
0% {
    background-color: transparent;
}
35% {
    background-color: black;
}
100% {
    background-color: black;
}
}
.myHeaderStyle[data-v-2969037b] {
  background-color: black;
  position: relative;
}
.myHeaderStyle2[data-v-2969037b] {
  position: fixed;
  transform: translateY(10);
  transition: transform 6s ease;
  -webkit-animation: myanimation-data-v-2969037b 3s;
          animation: myanimation-data-v-2969037b 3s;
  background-color: #000000f0;
}
.position-absolute[data-v-2969037b] {
  position: absolute;
}
.main_header .navbar[data-v-2969037b] {
  padding: 15px 80px;
}
.main_header .other_page[data-v-2969037b] {
  box-shadow: 0 3px 99px 0 #d9d9da !important;
}
.main_header .navbar .navbar-brand[data-v-2969037b] {
  padding: 0;
  margin: 0;
}
.main_header .navbar .navbar-brand img[data-v-2969037b] {
  width: auto;
  max-width: 110px;
}
.main_header .navbar .navbar-nav[data-v-2969037b] {
  align-items: center;
}
.main_header .navbar .navbar-nav .nav-item[data-v-2969037b] {
  display: inline-flex;
}
.main_header .navbar .navbar-nav .nav-item[data-v-2969037b]:not(:last-child) {
  margin-right: 125px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-2969037b] {
  padding: 0;
  font-size: 20px;
  color: white;
  letter-spacing: 1px;
  /* display: inline-flex; */
  padding-bottom: 6px;
}
.searchinput[data-v-2969037b] {
  background-color: black;
  color: white;
  border: none;
  font-size: 19px;
  letter-spacing: 2px;
  width: 100%;
}
.searchInput-icon[data-v-2969037b] {
  font-size: 27px;
  color: white;
  margin-right: 6px;
}
.borderTop[data-v-2969037b] {
  border-top: 1px solid #ffffffb3;
  width: 100%;
}
.cancel-search-icon[data-v-2969037b] {
  color: #8b8f95;
  font-size: 20px;
}
.user-name[data-v-2969037b] {
  font-size: 14px;
  left: 0;
  right: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.user-icon[data-v-2969037b] {
  font-size: 30px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon[data-v-2969037b] {
  position: relative;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .nav-link[data-v-2969037b] {
  font-size: 30px;
  cursor: pointer;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
  border-bottom: 1px solid #e6e6e6;
  position: absolute;
  right: 0;
  left: auto;
  top: 0px;
  display: flex;
  min-width: 225px;
  align-items: center;
  transition: 0.5s linear;
  min-height: 28px;
  opacity: 0;
  visibility: hidden;
  background-color: black;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input.active[data-v-2969037b] {
  opacity: 1;
  visibility: visible;
}
@-webkit-keyframes myDropdown-data-v-2969037b {
0% {
    background-color: transparent;
}
35% {
    background-color: white;
}
100% {
    background-color: white;
}
}
@keyframes myDropdown-data-v-2969037b {
0% {
    background-color: transparent;
}
35% {
    background-color: white;
}
100% {
    background-color: white;
}
}
.main_header .cm_big_dropmenu[data-v-2969037b] {
  min-width: 250px;
  max-height: 600px;
  -webkit-animation: myDropdown-data-v-2969037b 2s;
          animation: myDropdown-data-v-2969037b 2s;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b] {
  font-size: 20px;
  padding: 15px 20px;
  font-weight: 500;
  color: black;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b]:hover {
  color: #8b8f95;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b]:hover {
  color: black;
}
.main_header a[data-v-2969037b] {
  display: inline-block;
  text-decoration: none;
}
.main_header a[data-v-2969037b]::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: white;
  transition: width 0.3s ease-out;
}
.main_header a.iamactive[data-v-2969037b]::after {
  width: 100%;
}
.hoverMe[data-v-2969037b]:hover::after {
  width: 100%;
}
.main_header a[aria-expanded="true"][data-v-2969037b]::after {
  width: 100%;
}
.main_header a[aria-expanded="false"] > .iconDown[data-v-2969037b] {
  display: none;
}
.main_header a[aria-expanded="true"] > .iconRight[data-v-2969037b] {
  display: none;
}
.my-cart-divider[data-v-2969037b] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  margin: auto;
  width: 85%;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b]:last-child {
  border-bottom: none;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b]:hover,
.main_header .cm_big_dropmenu .category_name[data-v-2969037b]:focus {
  background-color: transparent;
}
nav.bg-dark[data-v-2969037b] {
  border-bottom: none;
}
.square[data-v-2969037b] {
  position: absolute;
  top: -5px;
  left: 10px;
  width: 9px;
  height: 9px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  transform: rotate(45deg);
  -webkit-animation: myDropdown-data-v-2969037b 3s;
          animation: myDropdown-data-v-2969037b 3s;
}
@media (max-width: 1774px) {
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 200px;
}
}
@media (max-width: 1601px) and (min-width: 1428px) {
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 60px !important;
}
}
@media (max-width: 1427px) {
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 50px !important;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 150px;
}
}
@media (max-width: 1276px) {
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 35px !important;
}
.main_header .navbar .nav-item .nav-link[data-v-2969037b] {
    font-size: 15px;
}
}
@media (max-width: 1201px) {
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 25px !important;
}
.main_header .navbar .nav-item .nav-link[data-v-2969037b] {
    font-size: 17px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.user-icon[data-v-2969037b] {
    font-size: 22px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.search-icon[data-v-2969037b] {
    font-size: 22px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 160px;
}
}
@media (max-width: 992px) {
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 66px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-2969037b] {
    font-size: 17px;
}
}
@media (max-width: 812px) {
.main_header .navbar .navbar-brand img[data-v-2969037b] {
    max-width: 70px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-2969037b] {
    font-size: 15px;
}
.main_header .navbar[data-v-2969037b] {
    padding: 14px 35px;
}
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 15px !important;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 150px;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.navbar-brand[data-v-2969037b] {
    margin: 0 auto !important;
}
.navbar-collapse[data-v-2969037b] {
    padding-top: 50px !important;
}
.navbar-collapse ul li[data-v-2969037b] {
    padding-top: 5px !important;
}
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 0 !important;
}
.navbar-collapse ul:last-child li[data-v-2969037b]:last-child {
    margin-right: 0 !important;
}
.user-name[data-v-2969037b] {
    left: 0;
}
.yt-recent-search-wrap[data-v-2969037b] {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 300px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 250px;
    height: 35px;
}
.main_header .cm_big_dropmenu[data-v-2969037b] {
    top: 112% !important;
    transform: translate(-50px, 0px);
}
.square[data-v-2969037b] {
    left: 120px;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b] {
    font-size: 18px;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b] {
    font-size: 16px;
}
}

    /* div {
    background-color: green;
} */
.bottombar[data-v-42c881c7] {
  /* height: 40px; */
  color: var(--color-black);
  background-color: #f6f8fa;
  font-size: 14px;
  margin: 6px 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.footercontainer[data-v-42c881c7] {
  padding-top: 50px;
  background-color: #000;
  padding-bottom: 50px;
}
.footer-area[data-v-42c881c7]{
  padding-top: 20px;
  padding-bottom: 20px;
}
.logobox[data-v-42c881c7] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoimage[data-v-42c881c7] {
    width: 139px;
    height: auto;
}
.data_underlogo[data-v-42c881c7] {
  padding-top: 30px;
  height: 95px;
  width: 330px;
}
.navtitle[data-v-42c881c7] {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
  padding-top: 10px;
}
.navlist[data-v-42c881c7] {
  font-weight: normal;
  text-decoration: none;
  color: var(--color-dark-grey);
  padding-top: 20px;
}
.navitem[data-v-42c881c7] {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.promotionsimage[data-v-42c881c7] {
  padding-top: 20px;
  padding-bottom: 10px;
  width: 270px;
  height: 150px;
}
.social_all[data-v-42c881c7] {
  color: var(--color-primary);
  justify-content: flex-end;
    margin-left: auto;
}
.social_all a[data-v-42c881c7]{
  display: inline-block;
}
.social_all a[data-v-42c881c7]:not(:last-child){
  border-right: 1px solid #8b8f95;
}
.social_all a[data-v-42c881c7]:last-child{
  padding-left: 5px;
}
.social_all a .social_logos[data-v-42c881c7] {
  max-width: 25px;
}
.yt-footer-widget-title[data-v-42c881c7] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: #fff;
  margin-bottom: 34px;
}
.yt-footler-menu-links[data-v-42c881c7] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7] {
  font-size: 20px;
  line-height: 2;
  text-align: left;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.footer-divider[data-v-42c881c7] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  margin: 45px 0 45px;
  opacity: 0.3;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child) {
  margin-right: 125px;
}
.yt-ftr-banner[data-v-42c881c7] {
  padding-top: 6px;
}
.yt-text-about[data-v-42c881c7] {
  font-size: 16px;
  line-height: 1.38;
  text-align: left;
  color: #fff;
  padding-top: 36px;
  padding-bottom: 31px;
}
.yt-copyright-text[data-v-42c881c7] {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.13;
  color: #000;
}
@media (max-width: 1280px) {
.container[data-v-42c881c7],
  .container-lg[data-v-42c881c7],
  .container-md[data-v-42c881c7],
  .container-sm[data-v-42c881c7],
  .container-xl[data-v-42c881c7] {
    max-width: 93.3%;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-col.yt-first-col[data-v-42c881c7] {
            flex: 0 0 100%;
            max-width: 100%;
}
.yt-foote-link-col[data-v-42c881c7],
        .yt-last-col[data-v-42c881c7] {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
}
.yt-footer-widget-title[data-v-42c881c7] {
            font-size: 16px;
            line-height: 1.13;
            margin-bottom: 17px;
}
.yt-text-about[data-v-42c881c7] {
            padding-top: 16px;
            padding-bottom: 21px;
            font-size: 14px;
            line-height: 1.57;
}
        /* .social_all {
            padding: 0;
            margin-bottom: 17px;
        } */
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7] {
            font-size: 14px;
            line-height: 1.9;
}
.footer-divider[data-v-42c881c7] {
          margin: 15px 0;
}
}
    /*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-last-col[data-v-42c881c7] {
            flex: 0 0 100%;
            max-width: 100%;
}
.yt-foote-link-col[data-v-42c881c7] {
            flex: 0 0 50%;
            max-width: 50%;
}
.yt-footer-widget-title[data-v-42c881c7],
        .yt-footler-menu-links[data-v-42c881c7] {
            margin-bottom: 20px;
}
.yt-text-about[data-v-42c881c7] {
            padding-top: 12px;
            padding-bottom: 20px;
            font-size: 12px;
            line-height: 1.83;
}
        /* .social_all {
            padding: 0;
            margin-bottom: 20px;
        } */
.yt-main-footer[data-v-42c881c7] {
            padding-top: 20px;
            padding-bottom: 20px;
}
.yt-footer-widget-title[data-v-42c881c7] {
            font-size: 14px;
            line-height: 1.14;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7] {
            font-size: 13px;
            line-height: 1.97;
}
.footer-divider[data-v-42c881c7] {
          margin: 3px 0 7px;
}
}
@media (max-width: 1534px ) and (min-width: 1366px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 100px !important;
}
}
@media( max-width : 1365px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 90px !important;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]{
        font-size: 15px !important;
}
}
@media (max-width: 1184px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 70px !important;
}
}
@media (max-width: 1055px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 50px !important;
}
}
@media (max-width: 927px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 25px !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-footer-widget-title[data-v-42c881c7] {
            font-size: 18px;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7] {
            font-size: 20px;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]{
    font-size: 8px !important;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
    margin-right: 11px !important;
}
.yt-copyright-text[data-v-42c881c7]{
    font-size: 10px !important;
}
.social_all a .social_logos[data-v-42c881c7]{
    max-width: 15px;
}
.logoimage[data-v-42c881c7]{
    width: 90px;
}
}
.navbar[data-v-b04a5803]{
    margin-top: 3px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}
.item[data-v-b04a5803]{
    margin-left: 35px;
    margin-right: 35px;
}
@media only screen and (max-width: 992px) {
.navimage[data-v-b04a5803]{
        width: 100%;
}
.shopnow[data-v-b04a5803]{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        margin-top: -50px;
        width:100px;
        background-color: black;
        color: white;
        padding: 5px;
        border-radius: 5px;
        height: 30px;
        text-align: center;
        cursor: pointer;
}
}
@media only screen and (min-width: 992px) {
.navimage[data-v-b04a5803]{
        width: 100%;
}
.shopnow[data-v-b04a5803]{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        margin-top: -100px;
        width:140px;
        background-color: black;
        color: white;
        padding: 10px;
        border-radius: 5px;
        height: 40px;
        text-align: center;
        cursor: pointer;
}
}
.feature_head[data-v-3f5906c1] {
  font-size: 22px;
  font-weight: 500;
  margin-left: 4px;
  color: #000;
  padding-bottom: 5px;
  line-height: 1.14;
}
.feature_text[data-v-3f5906c1] {
  color: var(--color-dark-grey);
  margin-left: 5px;
  font-size: 14px;
  line-height: 1.14;
}
.first[data-v-3f5906c1] {
  margin-left: 10px;
}
.yt-featured-bar-main[data-v-3f5906c1] {
  margin: 60px 0px;
}
.logoimage[data-v-3f5906c1] {
  width: 40px;
  height: 40px;
  width: auto;
}
.customcontainer > div.row[data-v-3f5906c1] {
  padding: 20px 10px;
}
.divider[data-v-3f5906c1] {
  border-right: 18px solid #e7e7e7;
}
.hp-featured-wrapper[data-v-3f5906c1] {
  padding: 0 15px;
  background: #fff;
  overflow: hidden;
}
.yt-cm-fb-row[data-v-3f5906c1] {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
}
.hp-fb-inner-wrap[data-v-3f5906c1] {
  border-right: 1px solid #e7e7e7;
}
@media (min-width: 992px) {
.border-md-0[data-v-3f5906c1] {
    border-width: 0;
}
}

/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
.feature_head[data-v-3f5906c1] {
    font-size: 28px;
}
.feature_text[data-v-3f5906c1] {
    font-size: 20px;
    line-height: 1.15;
}
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-cm-fb-row .yt-cm-fb-col:last-child .yt-fb-inner-wrap[data-v-3f5906c1] {
    border: none;
}
}
/*
* Screen for 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.yt-featured-bar-main[data-v-3f5906c1] {
    margin: 20px 0 !important;
}
.yt-fb-inner-wrap[data-v-3f5906c1] {
    border-right: solid 2px #cae2fe;
}
.hp-fb-inner-wrap[data-v-3f5906c1] {
    text-align: center;
}
.feature_head[data-v-3f5906c1] {
    font-size: 16px;
    line-height: 1.13;
    margin: 0 !important;
}
.feature_text[data-v-3f5906c1] {
    font-size: 12px;
    line-height: 1.17;
    max-width: 106px;
    margin: 0 auto !important;
}
.yt-ft-img .logoimage[data-v-3f5906c1] {
    display: none;
}
.yt-ft-img .logoimage.logo-white[data-v-3f5906c1] {
    display: block !important;
}
.yt-ft-img[data-v-3f5906c1] {
    background-color: var(--color-primary);
    width: 75px;
    margin: 0 auto;
    padding: 19.5px 0;
    border-radius: 100%;
}
.hp-fb-inner-wrap[data-v-3f5906c1] {
    border: none !important;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.yt-cm-fb-inner-col[data-v-3f5906c1] {
    max-width: 100% !important;
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
}
.feature_head[data-v-3f5906c1] {
    text-align: center;
}
}
/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.yt-cm-fb-col[data-v-3f5906c1] {
    max-width: 50% !important;
    flex: 0 0 50%;
    -ms-flex: 0 0 50%;
}
.yt-cm-fb-row .yt-cm-fb-col:last-child .yt-fb-inner-wrap[data-v-3f5906c1],
  .yt-cm-fb-row .yt-cm-fb-col:nth-child(2) .yt-fb-inner-wrap[data-v-3f5906c1] {
    border-right: none;
}
.yt-cm-fb-row .yt-cm-fb-col:nth-child(2) .yt-fb-inner-wrap[data-v-3f5906c1],
  .yt-cm-fb-row .yt-cm-fb-col:first-child .yt-fb-inner-wrap[data-v-3f5906c1] {
    border-bottom: solid 2px #cae2fe;
}
.yt-fb-inner-wrap[data-v-3f5906c1] {
    padding: 15px 0 0;
}
.yt-ft-img[data-v-3f5906c1] {
    width: 54px;
    padding: 17px 0;
}
.yt-ft-img .logoimage[data-v-3f5906c1] {
    height: 20px;
}
.feature_head[data-v-3f5906c1] {
    line-height: 1.17em;
    font-size: 12px;
}
.feature_text[data-v-3f5906c1] {
    font-size: 10px;
    line-height: 1.1;
    max-width: 100%;
    /* min-height: 37px; */
}
.yt-cm-fb-row.row[data-v-3f5906c1] {
    align-items: flex-start !important;
}
.yt-cm-fb-inner-col[data-v-3f5906c1] {
    padding: 0;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.feature_head[data-v-3f5906c1] {
    font-size: 16px;
}
}

.title-bar[data-v-11ebf0a1] {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title-bar h4[data-v-11ebf0a1] {
    color: #000;
    font-weight: 500;
    font-size: 33px;
}
.title-bar a[data-v-11ebf0a1] {
    color: #000;
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
}
.carousal-arrow[data-v-11ebf0a1] {
	border: 2px solid #94979D;
	padding: 0;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	align-self: center;
}
.product-card[data-v-11ebf0a1] {
    border: none;
    position: relative;
    border-radius: 8px;
}
.product-card .card-img-top[data-v-11ebf0a1] {
    height: 230px;
    object-fit: contain;
}
.product-card .product-name[data-v-11ebf0a1] {
    font-size: 18px;
    font-weight: 400;
    margin: 8px auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    max-width: 230px;
    text-align: center;
}
.product-card .product-price-now[data-v-11ebf0a1]{
    margin: 0 5px;
    font-weight: bold;
}
.product-card .product-price-original[data-v-11ebf0a1] {
    color: #909399;
    margin: 0 5px;
    text-decoration: line-through;
}
.product-card .btn-heart[data-v-11ebf0a1] {
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: center;
    padding: 6px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}
.product-card .discount-tag[data-v-11ebf0a1] {
    position: absolute;
    width: 90px;
    padding: 5px 10px;
    background: #E65E52;
    color: #fff;
    font-weight: bold;
    top: 15px;
    text-align: center;
    border-radius: 0 25px 25px 0;
}
.btn-add-to-cart[data-v-11ebf0a1] {
    display: block;
    width: 100%;
    padding: 10px 0;
    border: 1px solid #000;
    color: #000;
}
.product-card[data-v-11ebf0a1]:hover {
    box-shadow: 1px 3px 20px 0 rgba(0, 0, 0, 0.1);
}
.product-card:hover .btn-add-to-cart[data-v-11ebf0a1] {
    color: #fff;
    background-color: #000;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
@media (max-width:1680px){
.product-card .product-name[data-v-11ebf0a1] {
        max-width: 180px;
}
}
@media (max-width:1399px){
.product-card .product-name[data-v-11ebf0a1] {
        max-width: 160px;
        font-size: 16px;
}
}
@media (max-width:1199px){
.product-card .product-price-now[data-v-11ebf0a1], .product-price-original[data-v-11ebf0a1]{
        font-size: 14px;
}
}
@media (max-width:991px){
.product-card .product-name[data-v-11ebf0a1] {
        font-size: 14px;
}
}
.card-img-top[data-v-e2039449] {
  height: 530px;
  transition: display 1s;
  object-fit: cover;
}
.image-visible[data-v-e2039449] {
  display: inline;
}
.image-hidden[data-v-e2039449] {
  display: none;
}
.hover-me[data-v-e2039449]{
    border: solid 4px transparent;
}
.hover-me[data-v-e2039449]:hover{
    border: solid 4px #ffffff;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
}
#holder[data-v-e2039449] {
  /* max-width: 200px;
  max-height: 200px; */
  overflow: hidden;
  z-index: -1;
    position: relative;
}
#holder .smooth-image[data-v-e2039449] {
  transform: scale(1.5);
  width: 100%;
  transition: all 0.8s ease;
}
#holder .smooth-image[data-v-e2039449]:hover {
  transform: scale(1);
}
.co-title-bar .modal-title[data-v-e81b25c0] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.co-body-text[data-v-e81b25c0] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.co-title-bar span[data-v-e81b25c0] {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.pp-co-btn-modal[data-v-e81b25c0] {
  width: 50%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.pp-co-btn-modal[data-v-e81b25c0]:hover {
  border-color: #fff;
}
.pp-co-btn-light-grey[data-v-e81b25c0] {
  color: var(--color-dark-grey);
}
.pp-co-btn-dark-grey[data-v-e81b25c0] {
  color: var(--color-black);
}
.yt-form-spacer[data-v-e81b25c0] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}
.co-bottom-bar[data-v-e81b25c0] {
  position: relative;
  border-top: 2px solid #dbdbdb;
}

.pr-body-title[data-v-c5b7c01d] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-top: 22px;
}
#product-review-description[data-v-c5b7c01d] {
  min-height: 160px;
  border-radius: 10px;
  margin-top: 30px;
}
.pr-rate-submit-btn[data-v-c5b7c01d] {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  color: var(--color-black) !important;
  border: 2px solid #fff !important;
  box-shadow: none !important;
}
.yt-rating-wrapper.react-stars svg[data-v-c5b7c01d] {
  font-size: 29px;
}
.pr-rate-submit-btn[data-v-c5b7c01d] {
  padding-top: 20px;
  padding-bottom: 20px;
}
.pr-rate-submit-btn[data-v-c5b7c01d]:hover {
  border: 2px solid #fff !important;
  box-shadow: none !important;
  color: var(--color-black);
}
.pr-title-bar span[data-v-c5b7c01d] {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.cm-product-star-rating span[data-v-c5b7c01d]:last-child {
  margin-right: 0;
}
.cm-product-star-rating span[data-v-c5b7c01d]:first-child {
  margin-left: 0;
}
.cm-product-star-rating span[data-v-c5b7c01d] {
  margin: 0 5px;
}
.cm-modal-body[data-v-c5b7c01d] {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.pr-bottom-bar[data-v-c5b7c01d] {
  margin-top: 5px;
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.cm-modal-body[data-v-c5b7c01d] {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.yt-rating-wrapper[data-v-c5b7c01d] {
    margin: 0 auto;
}
.pr-body-title[data-v-c5b7c01d] {
    text-align: center;
}
}

/*
* Add Address Modal CSs
*/
.add-addr-title-bar[data-v-f1fd410a] {
  border-color: #dbdbdb;
  border-width: 2px;
}
.add-addr-title-bar span[data-v-f1fd410a] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.ad-addr-body-wrap .form-control[data-v-f1fd410a] {
  height: auto;
  border: none;
  border-bottom: 2px solid #dbdbdb;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.ad-addr-body-wrap .profile-form-tag[data-v-f1fd410a] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.add-addr-btn[data-v-f1fd410a] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #ffffff;
  margin-top: 30px;
}
.profile-form-field.error .profile-form-tag[data-v-f1fd410a] {
  color: var(--color-orange);
}
.profile-form-field.error .form-control[data-v-f1fd410a] {
  border-color: var(--color-orange);
  color: var(--color-orange);
}
.invalid_error[data-v-f1fd410a] {
  color: var(--color-orange);
}
.fields-active.error[data-v-f1fd410a] {
  padding-top: 5px;
  display: block;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.ad-addr-body-wrap .cm-edit-add-col[data-v-f1fd410a] {
    flex: 0 0 45%;
    max-width: 45%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.add-addr-title-bar span[data-v-f1fd410a] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-add-modal-body[data-v-f1fd410a] {
    padding: 30px 40px 20px !important;
}
.ad-addr-body-wrap .profile-form-tag[data-v-f1fd410a] {
    font-size: 14px;
    line-height: 1.14;
}
.ad-addr-body-wrap .form-control[data-v-f1fd410a] {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px;
    line-height: 1.11;
}
.add-addr-btn[data-v-f1fd410a] {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: normal;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.add-addr-title-bar span[data-v-f1fd410a] {
    font-size: 18px;
    line-height: 1.11;
}
.ad-addr-body-wrap .profile-form-tag[data-v-f1fd410a] {
    font-size: 13px;
    line-height: 1.15;
}
.ad-addr-body-wrap .form-control[data-v-f1fd410a] {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 17px;
    line-height: 1.12;
}
.ad-addr-body-wrap .yt-fp-form .form-group[data-v-f1fd410a] {
    margin-bottom: 20px !important;
}
.yt-add-modal-body[data-v-f1fd410a] {
    padding: 20px 15px !important;
}
.add-addr-btn[data-v-f1fd410a] {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
}

/*
* Add Address Modal CSs
*/
.add-addr-title-bar[data-v-0f5843db] {
  border-color: #dbdbdb;
  border-width: 2px;
}
.add-addr-title-bar span[data-v-0f5843db] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.select-address[data-v-0f5843db]{
  float: right;
  cursor: pointer;
}
.select-address[data-v-0f5843db]:hover{
  text-decoration: underline;
}
.ad-addr-body-wrap .form-control[data-v-0f5843db] {
  height: auto;
  border: none;
  border-bottom: 2px solid #dbdbdb;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.ad-addr-body-wrap .profile-form-tag[data-v-0f5843db] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.add-addr-btn[data-v-0f5843db] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #ffffff;
  margin-top: 30px;
}
.profile-form-field.error .profile-form-tag[data-v-0f5843db] {
  color: var(--color-orange);
}
.profile-form-field.error .form-control[data-v-0f5843db] {
  border-color: var(--color-orange);
  color: var(--color-orange);
}
.invalid_error[data-v-0f5843db] {
  color: var(--color-orange);
}
.fields-active.error[data-v-0f5843db] {
  padding-top: 5px;
  display: block;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.ad-addr-body-wrap .cm-edit-add-col[data-v-0f5843db] {
    flex: 0 0 45%;
    max-width: 45%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.add-addr-title-bar span[data-v-0f5843db] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-add-modal-body[data-v-0f5843db] {
    padding: 30px 40px 20px !important;
}
.ad-addr-body-wrap .profile-form-tag[data-v-0f5843db] {
    font-size: 14px;
    line-height: 1.14;
}
.ad-addr-body-wrap .form-control[data-v-0f5843db] {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px;
    line-height: 1.11;
}
.add-addr-btn[data-v-0f5843db] {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: normal;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.add-addr-title-bar span[data-v-0f5843db] {
    font-size: 18px;
    line-height: 1.11;
}
.ad-addr-body-wrap .profile-form-tag[data-v-0f5843db] {
    font-size: 13px;
    line-height: 1.15;
}
.ad-addr-body-wrap .form-control[data-v-0f5843db] {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 17px;
    line-height: 1.12;
}
.ad-addr-body-wrap .yt-fp-form .form-group[data-v-0f5843db] {
    margin-bottom: 20px !important;
}
.yt-add-modal-body[data-v-0f5843db] {
    padding: 20px 15px !important;
}
.add-addr-btn[data-v-0f5843db] {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
}

/*
* Add Address Modal CSs
*/
.edit-add-addr-title-bar[data-v-1dd0a270] {
  border-color: #dbdbdb;
  border-width: 2px;
}
.edit-add-addr-title-bar span[data-v-1dd0a270] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.edit-add-addr-body-wrap .form-control[data-v-1dd0a270] {
  height: auto;
  border: none;
  border-bottom: 2px solid #dbdbdb;
  border-radius: 0;
  box-shadow: none;
  padding-left: 0;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.edit-editadd-addr-btn[data-v-1dd0a270] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  margin-top: 30px;
}
/* Chrome, Safari, Edge, Opera */
.edit-add-addr-body-wrap input[data-v-1dd0a270]::-webkit-outer-spin-button,
.edit-add-addr-body-wrap input[data-v-1dd0a270]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.edit-add-addr-body-wrap .form-control input[type="number"][data-v-1dd0a270] {
  -moz-appearance: textfield;
}
.edit-add-addr-body-wrap .profile-form-tag[data-v-1dd0a270] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.edit-addr-btn[data-v-1dd0a270] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #ffffff;
  margin-top: 30px;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.edit-add-addr-body-wrap .cm-edit-add-col[data-v-1dd0a270] {
    flex: 0 0 45%;
    max-width: 45%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.edit-add-addr-title-bar span[data-v-1dd0a270] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-edit-add-body[data-v-1dd0a270] {
    padding: 30px 40px !important;
}
.edit-add-addr-body-wrap .profile-form-tag[data-v-1dd0a270] {
    font-size: 14px;
    line-height: 1.14;
}
.edit-add-addr-body-wrap .form-control[data-v-1dd0a270] {
    font-size: 18px;
    line-height: 1.11;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.edit-addr-btn[data-v-1dd0a270] {
    opacity: 0.99;
    border-radius: 6px;
    background-color: var(--color-dark-black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: #ffffff;
    margin-top: 30px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.98px) {
.edit-add-addr-title-bar span[data-v-1dd0a270] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-edit-add-body[data-v-1dd0a270] {
    padding: 20px 15px !important;
}
.edit-add-addr-body-wrap .profile-form-tag[data-v-1dd0a270] {
    font-size: 13px;
    line-height: 1.15;
}
.edit-add-addr-body-wrap .form-control[data-v-1dd0a270] {
    font-size: 17px;
    line-height: 1.12;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}
.edit-addr-btn[data-v-1dd0a270] {
    opacity: 0.99;
    border-radius: 6px;
    background-color: var(--color-dark-black);
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: #ffffff;
    margin-top: 20px;
}
}

.log-out-title-bar .modal-title[data-v-cca23a22] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.log-out-body-text[data-v-cca23a22] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.log-out-title-bar span[data-v-cca23a22] {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.pp-log-out-btn-modal[data-v-cca23a22] {
  width: 48%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.pp-log-out-btn-modal[data-v-cca23a22]:hover {
  border-color: #fff;
}
.pp-log-out-btn-light-grey[data-v-cca23a22] {
  color: var(--color-dark-grey);
}
.pp-log-out-btn-dark-grey[data-v-cca23a22] {
  color: var(--color-dark-black);
}
.log-out-bottom-bar[data-v-cca23a22] {
  position: relative;
  border-top: 2px solid #dbdbdb;
}
.yt-form-spacer[data-v-cca23a22] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}
.yt-log-body-wrap.modal-body[data-v-cca23a22] {
  padding: 33px 45px 49px;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.log-out-title-bar span[data-v-cca23a22] {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
}
.log-out-body-text[data-v-cca23a22] {
    font-size: 16px;
    line-height: 1.13;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.log-out-title-bar span[data-v-cca23a22] {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
}
.yt-log-body-wrap.modal-body[data-v-cca23a22] {
    padding: 22px 5px 30px;
}
.log-out-body-text[data-v-cca23a22] {
    font-size: 15px;
    line-height: 1.2;
}
}

.dis-acc-title-bar .modal-title[data-v-e921d414] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.dis-acc-body-text[data-v-e921d414] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.dis-acc-title-bar span[data-v-e921d414] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.pp-dis-acc-btn-modal[data-v-e921d414] {
  width: 48%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.dis-acc-bottom-bar[data-v-e921d414] {
  position: relative;
  border-top: 2px solid #dbdbdb !important;
}
.pp-dis-acc-btn-modal[data-v-e921d414]:hover {
  border-color: #fff;
}
.pp-dis-acc-btn-light-grey[data-v-e921d414] {
  color: var(--color-dark-grey);
}
.pp-dis-acc-btn-dark-grey[data-v-e921d414] {
  color: var(--color-dark-black);
}
.yt-form-spacer[data-v-e921d414] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.dis-acc-title-bar span[data-v-e921d414] {
    font-size: 18px;
    line-height: 1.11;
}
.dis-acc-body-text[data-v-e921d414] {
    line-height: 1.2;
    font-size: 15px;
    padding-top: 11px !important;
}
.pp-dis-acc-btn-modal[data-v-e921d414] {
    font-size: 15px;
    line-height: 1.2;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.dis-acc-title-bar span[data-v-e921d414] {
    font-size: 18px;
    line-height: 1.11;
}
.dis-acc-body-text[data-v-e921d414] {
    line-height: 1.13;
    font-size: 16px;
    padding-top: 0 !important;
}
.pp-dis-acc-btn-modal[data-v-e921d414] {
    font-size: 15px;
    line-height: 1.2;
}
}

/*
* Add Address Modal CSs
*/
.edit-profile-title-bar span[data-v-ddf5d07a] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.edit-profile-title-bar[data-v-ddf5d07a] {
  position: relative;
}
.myImage[data-v-ddf5d07a]{
  max-height: 107px;
}
.edit-profile-body-wrap .form-control[data-v-ddf5d07a] {
  height: auto;
  border: none;
  border-bottom: 2px solid #f6f8fa;
  box-shadow: none;
  padding-left: 0;
}
.edit-profile-btn[data-v-ddf5d07a] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
/* Chrome, Safari, Edge, Opera */
.edit-profile-body-wrap input[data-v-ddf5d07a]::-webkit-outer-spin-button,
.edit-profile-body-wrap input[data-v-ddf5d07a]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.edit-profile-body-wrap .form-control input[type="number"][data-v-ddf5d07a] {
  -moz-appearance: textfield;
}
.edit-profile-body-wrap .profile-form-tag[data-v-ddf5d07a] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-form-field[data-v-ddf5d07a] {
  position: relative;
  margin-bottom: 20px;
}
.form-control[data-v-ddf5d07a]:disabled{
  background-color: transparent !important;
  cursor: not-allowed;
}
.profile-form-field .form-control[data-v-ddf5d07a] {
  border: none;
  border-bottom: 2px solid #dbdbdb;
  padding-left: 0;
  box-shadow: none;
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-dark-black);
  height: auto;
}
.profile-form-field .profile-form-tag[data-v-ddf5d07a] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-form-field.fields-active .profile-form-tag[data-v-ddf5d07a] {
  top: 0;
}
.img-upload svg[data-v-ddf5d07a] {
  font-size: 26px;
  color: var(--color-dark-black) !important;
  position: absolute;
  top: -15px;
  right: -25px;
  padding: 7px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-sizing: content-box;
  border-radius: 32px;
  border: 6px solid #fff;
  width: 40px;
}
.yt-remove-pic-wrap[data-v-ddf5d07a] {
  cursor: pointer;
  color: #f67054;
}
.img-upload[data-v-ddf5d07a] {
  width: 109px;
  height: 109px;
  border-radius: 6px;
  position: relative;
}
.profile-edit-profile-btn[data-v-ddf5d07a] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-edit-prfl-body[data-v-ddf5d07a] {
  padding: 40px 40px 30px;
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-remove-pic-wrap[data-v-ddf5d07a] {
    font-size: 13px;
    line-height: 1.15;
}
.yt-remove-pic-wrap img[data-v-ddf5d07a] {
    max-width: 16px;
}
.profile-form-field .form-control[data-v-ddf5d07a] {
    padding: 10px 0 !important;
}
.profile-edit-profile-btn[data-v-ddf5d07a] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}
.yt-edit-prfl-body[data-v-ddf5d07a] {
    padding: 40px 40px 20px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.yt-remove-pic-wrap[data-v-ddf5d07a] {
    display: none;
}
.edit-profile-body-wrap .img-upload[data-v-ddf5d07a] {
    margin: 0 auto;
    width: 122px;
    height: 122px;
}
.profile-form-field .profile-form-tag[data-v-ddf5d07a] {
    font-size: 13px;
    line-height: 1.15;
}
.profile-form-field .form-control[data-v-ddf5d07a] {
    font-size: 17px;
    line-height: 1.12;
    padding-top: 11px !important;
    padding-bottom: 6px !important;
}
.yt-edit-prfl-body[data-v-ddf5d07a] {
    padding: 20px 15px;
}
.profile-edit-profile-btn[data-v-ddf5d07a] {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.profile-edit-pass[data-v-ddf5d07a],
  .profile-edit-btn[data-v-ddf5d07a] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
}

.notify-me-title-bar .modal-title[data-v-a7102f6c] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.notify-me-body-text[data-v-a7102f6c] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.notify-me-title-bar span[data-v-a7102f6c] {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.pp-notify-me-btn-modal[data-v-a7102f6c] {
  width: 100%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.pp-notify-me-btn-modal[data-v-a7102f6c]:hover {
  border-color: #fff;
}
.pp-notify-me-btn-light-grey[data-v-a7102f6c] {
  color: var(--color-dark-grey);
}
.pp-notify-me-btn-dark-grey[data-v-a7102f6c] {
  color: var(--color-black);
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.notify-me-body-text[data-v-a7102f6c] {
    text-align: center !important;
}
}

/*
* Add Address Modal CSs
*/
.select-addr-title-bar span[data-v-a60bb7f2] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.select-addr-body-wrap .form-control[data-v-a60bb7f2] {
  height: auto;
  border: none;
  border-bottom: 2px solid #f6f8fa;
  box-shadow: none;
  padding-left: 0;
}
.select-addr -btn[data-v-a60bb7f2] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.select-addr-body-wrap .pp-sa-all-addres-list li[data-v-a60bb7f2] {
  border-radius: 10px;
  border: solid 2px #fff;
}
.select-addr-body-wrap .pp-sa-all-addres-list li.active[data-v-a60bb7f2] {
  border-radius: 10px;
  border: solid 2px var(--color-green);
}
.select-addr-body-wrap .pp-na-ttl[data-v-a60bb7f2] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.select-addr-body-wrap .pp-na-text[data-v-a60bb7f2] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.select-addr-body-wrap .pp-no-addr-btn[data-v-a60bb7f2] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.select-addr-body-wrap .pp-sa-list-none[data-v-a60bb7f2] {
  list-style: none;
}
.select-addr-body-wrap .pp-sa-type[data-v-a60bb7f2] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-black);
}
.select-addr-body-wrap .pp-sa-address[data-v-a60bb7f2] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-grey);
}
.radius-10[data-v-a60bb7f2] {
  border-radius: 10px;
}
.select-addr-body-wrap .profile-pg-mb-30[data-v-a60bb7f2],
.pp-sa-all-addres-list li[data-v-a60bb7f2] {
  margin-bottom: 30px;
}
.pp-sa-all-addres-list li[data-v-a60bb7f2] {
  cursor: pointer;
}
.select-addr-modal-btn[data-v-a60bb7f2] {
  border-radius: 6px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.select-addr-body-wrap .profile-pg-inner-wrapper[data-v-a60bb7f2] {
  min-height: 420px;
}
.select-add-bg-blue[data-v-a60bb7f2] {
  background-color: var(--color-dark-grey);
}
.select-add-bg-black[data-v-a60bb7f2] {
  background-color: var(--color-dark-black);
}
.select-add-bg-black[data-v-a60bb7f2]:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}
@media (min-width: 1366.8px) {
}
@media (max-width: 767.98px) {
  /* .pp-sa-info-wrap {
    margin: 20px 0;
  } */
.yt-sadd-ttl-chek-img[data-v-a60bb7f2] {
    margin-bottom: 5px !important;
}
.pp-sa-delet img[data-v-a60bb7f2] {
    margin-bottom: 0 !important;
}
.yt-cm-sadd-body.modal-body[data-v-a60bb7f2] {
    padding: 14px 10px !important;
}
.yt-cm-sadd-col[data-v-a60bb7f2]:last-child {
    max-width: 80%;
    padding-left: 7.5px;
}
.yt-cm-sadd-col[data-v-a60bb7f2]:first-child {
    width: 20%;
    padding-right: 7.5px;
}
.pp-sa-img-wrap img[data-v-a60bb7f2] {
    max-width: 34px;
    height: auto;
}
.select-addr-body-wrap .pp-sa-type[data-v-a60bb7f2] {
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 5px;
}
.select-addr-body-wrap .pp-sa-address[data-v-a60bb7f2] {
    font-size: 14px;
    line-height: 1.14;
}
.select-addr-modal-btn.select-add-bg-blue[data-v-a60bb7f2] {
    display: none;
}
.yt-mb-add-new-address-nbtn[data-v-a60bb7f2] {
    display: block !important;
    text-align: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    color: var(--color-black);
}
}

.delete-addr-title-bar .modal-title[data-v-d54c24be] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.delete-addr-body-text[data-v-d54c24be] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.delete-addr-title-bar span[data-v-d54c24be] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.delete-addr-bottom-bar[data-v-d54c24be] {
  border-top: 2px solid #dbdbdb !important;
  position: relative;
}
.yt-form-spacer[data-v-d54c24be] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  display: block;
  bottom: 10px;
  width: 2px;
  top: 10px;
  background: #dbdbdb;
}
.pp-delete-addr-btn-modal[data-v-d54c24be] {
  width: 48%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.pp-delete-addr-btn-modal[data-v-d54c24be]:hover {
  border-color: #fff;
}
.pp-delete-addr-btn-light-grey[data-v-d54c24be] {
  color: var(--color-dark-grey);
}
.pp-delete-addr-btn-dark-grey[data-v-d54c24be] {
  color: var(--color-dark-black);
}
.pp-add-edit-btn[data-v-d54c24be] {
  box-shadow: none !important;
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.delete-addr-title-bar span[data-v-d54c24be] {
    font-size: 18px;
    line-height: 1.11;
}
.delete-addr-body-text[data-v-d54c24be] {
    font-size: 16px;
    line-height: 1.13;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.delete-addr-title-bar span[data-v-d54c24be] {
    font-size: 18px;
    line-height: 1.11;
}
.delete-addr-body-text[data-v-d54c24be] {
    font-size: 15px;
    line-height: 1.2;
    padding-top: 0 !important;
}
}

.remove-wh-lst-title-bar .modal-title[data-v-780ad7b9] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.remove-wh-lst-body-text[data-v-780ad7b9] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-orange);
}
.remove-wh-lst-title-bar span[data-v-780ad7b9] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  line-height: 1.11;
  color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
}
.pp-remove-wh-lst-btn-modal[data-v-780ad7b9] {
  width: 100%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.pp-remove-wh-lst-btn-modal[data-v-780ad7b9]:hover {
  border-color: #fff;
}
.pp-remove-wh-lst-btn-light-grey[data-v-780ad7b9] {
  color: var(--color-dark-grey);
}
.pp-remove-wh-lst-btn-dark-grey[data-v-780ad7b9] {
  color: var(--color-dark-black);
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.remove-wh-lst-body-text[data-v-780ad7b9] {
    font-size: 15px;
    line-height: 1.2;
    padding: 0 !important;
}
}

.remove-wh-lst-title-bar .modal-title[data-v-08c0077f] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.remove-wh-lst-body-text[data-v-08c0077f] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-orange);
}
.remove-wh-lst-title-bar span[data-v-08c0077f] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  line-height: 1.11;
  color: var(--color-black);
  font-size: 18px;
  font-weight: 500;
}
.pp-remove-wh-lst-btn-modal[data-v-08c0077f] {
  width: 100%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
}
.pp-remove-wh-lst-btn-modal[data-v-08c0077f]:hover {
  border-color: #fff;
}
.pp-remove-wh-lst-btn-light-grey[data-v-08c0077f] {
  color: var(--color-dark-grey);
}
.pp-remove-wh-lst-btn-dark-grey[data-v-08c0077f] {
  color: var(--color-black);
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.remove-wh-lst-body-text[data-v-08c0077f] {
    font-size: 15px;
    line-height: 1.2;
    padding: 0 !important;
}
}

.custom-modal .close-btn[data-v-ced84b62] {
  cursor: pointer;
}
.custom-modal .custom-body[data-v-ced84b62] {
  padding: 20px;
}
.pw_input[data-v-ced84b62]{
  position: relative;
}
.pw_input svg[data-v-ced84b62]{
  position: absolute;
  right: 0;
  top: 25px;
}
.custom-modal .dark-btn[data-v-ced84b62] {
  display: block;
  color: #fff;
  background-color: #000;
  letter-spacing: 0.5px;
  font-size: 16px;
  padding: 10px 0;
  transition: .5s ease;
}
.dark-btn[data-v-ced84b62]:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}
.validation-error[data-v-ced84b62] {
  font-size: 12px;
  line-height: 1.17;
  color: var(--color-orange);
  text-align: center;
}
.main-form .custom-label[data-v-ced84b62] {
  margin: 0;
  color: #92969B;
  font-size: 14px;
}
.main-form .custom-input[data-v-ced84b62] {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 0;
  color: #000;
  height: 30px;
  font-size: 20px;
}
.main-form .error[data-v-ced84b62] {
  margin: 8px 0 0 -3px;
  font-size: 12px;
  color: red;
}
.main-form .form-control.invalid[data-v-ced84b62] {
  color: red;
  border-color: red;
}
.custom-modal[data-v-909ca115] {
    max-width: 700px;
}
.custom-modal .custom-body[data-v-909ca115] {
    display: flex;
    padding: 50px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.custom-body img[data-v-909ca115] {
    height: 200px;
    width: 200px;
}
.custom-body h1[data-v-909ca115] {
    font-size: 28px;
    font-weight: 500;
}
.custom-body p[data-v-909ca115] {
    color: #8C9096;
    margin: 10px 0;
}
.custom-modal .dark-btn[data-v-909ca115] {
    margin-top: 30px;
    color: #fff;
    background-color: #000;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 25px;
    border-radius: 5px;
}
.remove-cart-list-title-bar .modal-title[data-v-7e160d5d] {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey);
}
.remove-cart-list-body-text[data-v-7e160d5d] {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: var(--color-orange);
}
.remove-cart-list-title-bar span[data-v-7e160d5d] {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    line-height: 1.11;
    color: var(--color-dark-black);
    font-size: 18px;
    font-weight: 500;
}
.pp-remove-cart-list-btn-modal[data-v-7e160d5d] {
    width: 100%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent;
    border-color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
}
.pp-remove-cart-list-btn-modal[data-v-7e160d5d]:hover {
    border-color: #fff;
}
.pp-remove-cart-list-btn-light-grey[data-v-7e160d5d] {
    color: var(--color-dark-grey);
}
.pp-remove-cart-list-btn-dark-grey[data-v-7e160d5d] {
    color: var(--color-dark-black);
}
  
  /*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
.remove-cart-list-body-text[data-v-7e160d5d] {
      font-size: 15px;
      line-height: 1.2;
      padding: 0 !important;
}
}
  
.notify-me-title-bar .modal-title[data-v-fe2c1095] {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey);
}
.notify-me-body-text[data-v-fe2c1095] {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: var(--color-dark-grey);
}
.notify-me-title-bar span[data-v-fe2c1095] {
    position: absolute;
    top: 27px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: var(--color-black);
}
.pp-notify-me-btn-modal[data-v-fe2c1095] {
    width: 100%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent;
    border-color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--color-black);
}
.pp-notify-me-btn-modal[data-v-fe2c1095]:hover {
    border-color: #fff;
}
.pp-notify-me-btn-light-grey[data-v-fe2c1095] {
    color: var(--color-dark-grey);
}
.pp-notify-me-btn-dark-grey[data-v-fe2c1095] {
    color: var(--color-black);
}
  /*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
.notify-me-body-text[data-v-fe2c1095] {
      text-align: center !important;
}
}
  
.quotes-wrapper[data-v-e195f14c] {
  width: 95%;
  margin: 50px auto;
  text-align: center;
}
.quotes-wrapper .title[data-v-e195f14c] {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  margin: 20px 0;
}
.carousal-arrow[data-v-e195f14c] {
  padding: 0;
  height: 30px;
  width: 30px;
  align-self: center;
}
.myReviewbtn[data-v-e195f14c]{
  background-color: black;
  color: white;
}
.myReviewbtn[data-v-e195f14c]:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}
.dont-show-me[data-v-e195f14c] {
  display: none;
}
.show-me[data-v-e195f14c] {
  color: #94979d;
  border: 2px solid #94979d;
  border-radius: 50%;
}
.main-carousal .carousal-item[data-v-e195f14c] {
  min-height: 270px;
  width: 100%;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  box-shadow: -1px 0px 15px 2px #f4f4f4;
  padding: 25px;
  position: relative;
}
.carousal-item img[data-v-e195f14c] {
  width: 20px;
  height: 20px;
}
.carousal-item .summary[data-v-e195f14c] {
  color: #444444;
  margin: 20px 0;
}
.carousal-item .info[data-v-e195f14c] {
  color: #444444;
  line-height: 1.4;
}
.carousal-item .user-info[data-v-e195f14c] {
  display: flex;
  bottom: 25px;
}
.user-info p[data-v-e195f14c] {
  font-weight: bold;
  margin: 0;
  line-height: 1.6;
}
.user-info .location span[data-v-e195f14c] {
  color: #94979d;
  font-size: 12px;
  font-weight: 300;
}
.btn-view-all[data-v-e195f14c] {
  display: block;
  margin: 25px auto;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  padding: 8px 20px;
}
@media (max-width: 974px) {
.dont-show-me[data-v-e195f14c] {
    display: block;
    color: #94979d;
    border: 2px solid #94979d;
    border-radius: 50%;
}
}


.prodimage[data-v-2cf9dbb0] {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
        cursor: pointer;
}
.name[data-v-2cf9dbb0] {
        margin-top: -30px;
        font-weight: 500;
        margin-left: auto;
        margin-right: auto;
        color: white;
}
.yt-slider-left[data-v-2cf9dbb0],
    .yt-slider-right[data-v-2cf9dbb0] {
        position: absolute;
        font-size: 35px;
        cursor: pointer;
        color: var(--color-blue);
}
.collection-card-slider>div>div[data-v-2cf9dbb0] {
        margin: 0 !important;
}
.yt-collection-item[data-v-2cf9dbb0] {
        padding: 15px;
        background: #fff;
        border: 1px solid #cae2fe;
        width: 100%;
        margin: 0 10px;
        border-radius: 4px;
        position: relative;
}
.yt-collection-title[data-v-2cf9dbb0] {
        font-size: 20px;
        text-align: center;
        background-image: linear-gradient( to top, rgba(36, 39, 36, 0.92) 1%, rgba(36, 39, 36, 0) 130%);
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 15px;
        padding: 19px 0;
        line-height: 0.95;
        color: #fff;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
}
.yt-collection-component[data-v-2cf9dbb0] {
        position: relative;
        border-radius: 10px;
}
.yt-collection-component .rec-item-wrapper[data-v-2cf9dbb0] {
        outline: none !important;
        box-shadow: none;
}
.yt-component-more[data-v-2cf9dbb0] {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.13;
        text-align: right;
        border-radius: 0;
        color: var(--color-dark-grey);
        border-bottom: 1px solid var(--color-dark-grey);
        text-decoration: none !important;
}
.yt-comonent-top-title[data-v-2cf9dbb0] {
        font-size: 30px;
        font-weight: 500;
        line-height: 1.13;
        text-align: left;
        color: var(--color-black);
}
.yt-produstslider-info[data-v-2cf9dbb0] {
        padding-bottom: 30px;
}
@media (min-width: 1900px) {
.yt-collection-item[data-v-2cf9dbb0] {
            margin: 0 15px;
}
}
@media (min-width: 1400px) {
.yt-component-more[data-v-2cf9dbb0] {
            display: none;
}
}
@media (min-width: 1280px) {
.yt-collection-item img[data-v-2cf9dbb0] {
            height: 266px;
            width: 370px;
            object-fit: cover;
            object-position: top;
}
}
@media (min-width: 1281px) {
.yt-slider-left[data-v-2cf9dbb0],
        .yt-slider-right[data-v-2cf9dbb0] {
            top: 50%;
            transform: translateY(-50%);
}
.yt-slider-left[data-v-2cf9dbb0] {
            left: -45px;
}
.yt-slider-right[data-v-2cf9dbb0] {
            right: -45px;
}
}
@media (min-width: 1920px) {
.yt-collection-item img[data-v-2cf9dbb0] {
            height: 270px;
            width: 320px;
            object-fit: cover;
            object-position: top;
}
}
@media (max-width: 1280px) {
.yt-slider-left[data-v-2cf9dbb0],
        .yt-slider-right[data-v-2cf9dbb0] {
            top: -56px;
}
.yt-slider-left[data-v-2cf9dbb0] {
            right: 43px;
}
.yt-slider-right[data-v-2cf9dbb0] {
            right: 0;
}
.yt-comonent-link[data-v-2cf9dbb0] {
            padding-right: 97px;
}
}
    /*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-produstslider-info[data-v-2cf9dbb0] {
            padding-bottom: 15px;
}
.yt-comonent-top-title[data-v-2cf9dbb0] {
            font-size: 16px;
            line-height: 1.14;
}
.yt-component-more[data-v-2cf9dbb0] {
            font-size: 14px;
            line-height: 1.14;
}
.yt-slider-left[data-v-2cf9dbb0],
        .yt-slider-right[data-v-2cf9dbb0] {
            font-size: 21px;
            top: -40px;
}
.yt-slider-left[data-v-2cf9dbb0] {
            right: 42px;
}
.yt-slider-right[data-v-2cf9dbb0] {
            right: 20px;
}
.yt-collection-title[data-v-2cf9dbb0] {
            font-size: 14px;
            line-height: 0.71;
            padding-top: 9px;
            padding-bottom: 13px;
            left: 0;
            right: 0;
            bottom: 0;
}
.yt-collection-item[data-v-2cf9dbb0] {
            padding: 0 !important;
            border: none !important;
}
.yt-comonent-link[data-v-2cf9dbb0] {
            padding-right: 55px;
}
.yt-collection-component[data-v-2cf9dbb0] {
            margin-left: -20px;
            margin-right: -20px;
            border-radius: 0 !important;
}
section.hp-collection-slider[data-v-2cf9dbb0] {
            margin: 20px 0 !important;
}
.yt-collection-item img[data-v-2cf9dbb0] {
            height: 223px;
            width: 167px;
            object-fit: cover;
            object-position: top;
}
}
    /*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.yt-collection-item[data-v-2cf9dbb0] {
            width: auto !important;
}
.hp-collection-slider[data-v-2cf9dbb0] {
            position: relative;
}
.product.yt-collection-component.yt-collection-wrap[data-v-2cf9dbb0] {
            padding-top: 52px !important;
}
.yt-produstslider-info[data-v-2cf9dbb0] {
            position: absolute;
            top: 18px;
            left: 20px;
            right: 20px;
            z-index: 5;
}
.yt-slider-left[data-v-2cf9dbb0],
        .yt-slider-right[data-v-2cf9dbb0] {
            top: 17px;
            z-index: 10;
}
.yt-component-more[data-v-2cf9dbb0] {
            border-bottom: none;
            font-size: 15px;
            line-height: 1.2;
            padding: 0 !important;
            margin: 0 !important;
}
.yt-comonent-top-title[data-v-2cf9dbb0] {
            font-size: 17px;
            line-height: 1.12;
}
.yt-slider-left[data-v-2cf9dbb0] {
            right: 41px;
}
.yt-comonent-link[data-v-2cf9dbb0] {
            padding-right: 49px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-collection-title[data-v-2cf9dbb0] {
            font-size: 18px;
}
.yt-comonent-top-title[data-v-2cf9dbb0] {
            font-size: 26px;
}
}
/*
* YT Product Slider CSS
*/
.yt-product-fav[data-v-da3be587] {
  width: 24px;
}
.yt-product-off[data-v-da3be587] {
  background-color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.yt-product-slider-cpnt[data-v-da3be587] {
  border: 1px solid #cae2fe;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding-bottom: 50px;
}
.yt-component-wrapper[data-v-da3be587] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-da3be587] {
  outline: none !important;
  box-shadow: none;
}
.yt-slider-left[data-v-da3be587],
.yt-slider-right[data-v-da3be587] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  cursor: pointer;
}
.yt-item-slider[data-v-da3be587] {
  position: relative;
  width: 100%;
  margin: 0px 10px;
}
.yt-product-add-btn.buttoncart[data-v-da3be587] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  background-color: var(--color-dark-black);
}
.yt-product-title[data-v-da3be587] {
  font-size: 20px;
  line-height: 0.95;
  color: var(--color-black);
}
.yt-component-more[data-v-da3be587] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.yt-comonent-top-title[data-v-da3be587] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.yt-product-slider-img[data-v-da3be587] {
  max-height: 150px;
}
.yt-product-sale-price[data-v-da3be587] {
  color: var(--color-blue);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-product-reg-price2[data-v-da3be587] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-product-add-btn[data-v-da3be587] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
}
@media (min-width: 768px) {
.yt-slider-left[data-v-da3be587] {
    left: -40px;
}
.yt-slider-right[data-v-da3be587] {
    right: -40px;
}
}
@media (max-width: 768px) {
.yt-slider-left[data-v-da3be587] {
    left: 5px !important;
}
.yt-slider-right[data-v-da3be587] {
    right: 5px !important;
}
}

/*
* Hero Banner CSS
*/
.hc-hero-bg-img[data-v-c84661aa] {
  /* background-image: url("../images/heroimage.png"); */
  background-size: cover;
  background-position: 100%;
  min-height: 300px;
  position: relative;
}
.hc-hero-btn[data-v-c84661aa] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #fff;
  max-width: 180px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 60px;
  width: 100%;
}
.home-banner1[data-v-c84661aa] {
  padding-top: 40px;
  padding-bottom: 40px;
}
.banner-title[data-v-c84661aa] {
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 6px;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner-subtitle[data-v-c84661aa] {
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myBannerimg[data-v-c84661aa] {
  height: 720px;
  width: 100%;
  object-fit: cover;
}
.video-container[data-v-c84661aa] {
  height: 720px;
  width: 100%;
  object-fit: cover;
}
.myBannercontent[data-v-c84661aa] {
  color: black;
  position: absolute;
  left: 0;
  right: 0;
  margin: -40px auto;
  bottom: 145px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
  width: 300px;
  line-height: 1.17;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-btn[data-v-c84661aa] {
  opacity: 0.99;
  background-color: transparent;
  border-color: black;
  font-weight: 500;
  line-height: 1.11;
  color: black;
  max-width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 40px;
  width: 200px;
  height: 55px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
}
.display-product-container:hover .my-btn[data-v-c84661aa] {
  background-color: black;
  color: white;
}
.my-btn[data-v-c84661aa]:hover {
  background-color: black;
  color: white;
}
.button[data-v-c84661aa] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.hc-hero-banner[data-v-c84661aa] {
  position: relative;
}
@media only screen and (max-width: 992px) {
.heroimage[data-v-c84661aa] {
    width: 100%;
}
.shopnow[data-v-c84661aa] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -50px;
    width: 100px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
}
}
@media only screen and (min-width: 992px) {
.heroimage[data-v-c84661aa] {
    width: 100%;
}
.shopnow[data-v-c84661aa] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -100px;
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}
}
.herocontainer[data-v-c84661aa] {
  display: flex;
  justify-content: center;
}
.hero[data-v-c84661aa] {
  max-width: 1366px;
}
.hc-hero-banner[data-v-c84661aa] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.hc-hero-banner[data-v-c84661aa] {
    min-height: 200px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 768px) {
.hc-hero-btn[data-v-c84661aa] {
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.hc-hero-banner[data-v-c84661aa] {
    min-height: 200pt;
}
.banner-title[data-v-c84661aa] {
    font-size: 40px;
}
.banner-subtitle[data-v-c84661aa] {
    font-size: 20px;
}
.myBannercontent[data-v-c84661aa] {
    width: 150px !important;
    font-size: 15px;
}
.my-btn[data-v-c84661aa] {
    max-width: 130px !important;
    font-size: 12px !important;
}
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
.hc-hero-banner[data-v-c84661aa] {
    min-height: 300px;
}
}
@media (max-width: 1457px) {
.myBannercontent[data-v-c84661aa] {
    width: 250px;
}
.my-btn[data-v-c84661aa] {
    max-width: 180px;
    font-size: 15px;
}
}
/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
.hc-hero-btn[data-v-c84661aa] {
    bottom: 95px;
}
.hc-hero-banner[data-v-c84661aa] {
    min-height: 422px;
}
}
@media only screen and (max-device-width: 480px) {
.mobilePadding[data-v-c84661aa] {
    margin-top: 30px;
}
.banner-title[data-v-c84661aa] {
    font-size: 30px;
}
.banner-subtitle[data-v-c84661aa] {
    font-size: 18px;
}
}

/*
* Hero Banner CSS
*/
.silver_sec[data-v-5db2c221] {
  position: relative;
  z-index: 0;
  min-height: 760px;
  padding-bottom: 40px;
}
.silver_sec[data-v-5db2c221]:after {
  content:'';
  position: absolute;
  min-width: 1525px;
  min-height: 556px;
  background-color: #f8fbfe;
  right: 0;
  top: 85px;
  z-index: -1;
}
.silver_sec .img_view[data-v-5db2c221]{
  display: flex;
}
.silver_sec .img_view .item[data-v-5db2c221]:first-child{
  margin-bottom: auto;
  margin-right: 52px;
}
.silver_sec .img_view .item[data-v-5db2c221]:last-child{
  margin-top: 228px;
}
.silver_sec .img_view .item img[data-v-5db2c221]{
  max-height: 528px;
}
.silver_sec .content[data-v-5db2c221]{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
}
.silver_sec .content h2[data-v-5db2c221]{
  font-size: 29px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 38px;
}
.my-btn[data-v-5db2c221]{
    background-color: transparent;
    border-color: black;
    font-weight: 500;
    color: black;
    max-width: 200px;
    min-height: 55px;
    font-size: 18px;
    letter-spacing: 3.8px;
    text-align: center;
}
.my-btn[data-v-5db2c221]:hover{
  background-color: black;
  color: white;
}
@media only screen and (max-width: 992px) {
}
@media only screen and (min-width: 992px) {
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
}

/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
}

/*
* Hero Banner CSS
*/
.looks_good_sec[data-v-346450e3] {
  padding-bottom: 40px;
  position: relative;
}
.looks_good_sec[data-v-346450e3]:after {
  content: "";
  position: absolute;
  min-width: 1155px;
  min-height: 556px;
  background-color: #f8fbfe;
  right: 0;
  top: 0;
  z-index: -1;
}
.looks_good_sec .item[data-v-346450e3] {
  margin-top: 137px;
}
.looks_good_sec .content[data-v-346450e3] {
  display: flex;
  flex-direction: column;
  padding-top: 163px;
  padding-left: 30px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.wrap-my-text[data-v-346450e3] {
  overflow: hidden;
  text-overflow: ellipsis;
}
.myImagebanner[data-v-346450e3] {
  object-fit: cover;
}
.looks_good_sec .content h2[data-v-346450e3] {
  font-size: 42px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 18px;
}
.looks_good_sec .content p[data-v-346450e3] {
  font-size: 24px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 4px;
}
.my-btn[data-v-346450e3] {
  background-color: transparent;
  border-color: black;
  font-weight: 500;
  color: black;
  max-width: 200px;
  min-height: 55px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
}
.my-btn[data-v-346450e3]:hover {
  background-color: black;
  color: white;
}
@media only screen and (max-width: 992px) {
}
@media only screen and (min-width: 992px) {
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
}
/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
}
@media only screen and (max-device-width: 480px) {
.looks_good_sec .content h2[data-v-346450e3] {
    font-size: 30px;
}
.looks_good_sec .content p[data-v-346450e3] {
    font-size: 18px;
}
.my-btn[data-v-346450e3] {
    max-width: 150px;
    min-height: 45px;
    font-size: 15px;
}
}

/*
* Hero Banner CSS
*/
.hc-hero-bg-img[data-v-626ad99b] {
  /* background-image: url("../images/heroimage.png"); */
  background-size: cover;
  background-position: 100%;
  min-height: 300px;
  position: relative;
}
.hc-hero-btn[data-v-626ad99b] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #fff;
  max-width: 180px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 60px;
  width: 100%;
}
.home-banner1[data-v-626ad99b] {
  padding-top: 40px;
  padding-bottom: 40px;
}
.myBanner[data-v-626ad99b] {
  width: 450px !important;
}
.banner-title[data-v-626ad99b] {
  font-size: 50px;
  padding-bottom: 2px;
  font-weight: bold;
  letter-spacing: 6px;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner-subtitle[data-v-626ad99b] {
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myBannerimg[data-v-626ad99b] {
  min-height: 560px;
  object-fit: cover;
}
.video-container[data-v-626ad99b] {
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.myBannercontent[data-v-626ad99b] {
  color: black;
  position: absolute;
  left: 0;
  right: 0;
  margin: -40px auto;
  bottom: 150px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
  width: 300px;
  line-height: 1.17;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-btn[data-v-626ad99b] {
  opacity: 0.99;
  background-color: transparent;
  border-color: black;
  font-weight: 500;
  line-height: 1.11;
  color: black;
  max-width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 40px;
  width: 200px;
  height: 55px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
}
.display-product-container:hover .my-btn[data-v-626ad99b],
.mobilePadding:hover .my-btn[data-v-626ad99b] {
  background-color: black;
  color: white;
}
.my-btn[data-v-626ad99b]:hover {
  background-color: black;
  color: white;
}
.button[data-v-626ad99b] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.hc-hero-banner[data-v-626ad99b] {
  position: relative;
}
@media only screen and (max-width: 992px) {
.heroimage[data-v-626ad99b] {
    width: 100%;
}
.shopnow[data-v-626ad99b] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -50px;
    width: 100px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
}
}
@media only screen and (min-width: 992px) {
.heroimage[data-v-626ad99b] {
    width: 100%;
}
.shopnow[data-v-626ad99b] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -100px;
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}
}
.herocontainer[data-v-626ad99b] {
  display: flex;
  justify-content: center;
}
.hero[data-v-626ad99b] {
  max-width: 1366px;
}
.hc-hero-banner[data-v-626ad99b] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.hc-hero-banner[data-v-626ad99b] {
    min-height: 200px;
}
}

/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
.hc-hero-banner[data-v-626ad99b] {
    min-height: 300px;
}
}
@media (max-width: 1457px) {
.myBannercontent[data-v-626ad99b] {
    width: 250px;
}
.myBanner[data-v-626ad99b] {
    width: 250px !important;
}
.my-btn[data-v-626ad99b] {
    max-width: 180px;
    font-size: 15px;
}
}
/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
.hc-hero-btn[data-v-626ad99b] {
    bottom: 95px;
}
.hc-hero-banner[data-v-626ad99b] {
    min-height: 422px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 768px) {
.hc-hero-btn[data-v-626ad99b] {
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.hc-hero-banner[data-v-626ad99b] {
    min-height: 200pt;
}
.banner-title[data-v-626ad99b] {
    font-size: 40px;
}
.banner-subtitle[data-v-626ad99b] {
    font-size: 20px;
}
.myBannercontent[data-v-626ad99b] {
    width: 220px !important;
    font-size: 15px;
}
.myBanner[data-v-626ad99b] {
    width: 220px !important;
}
.my-btn[data-v-626ad99b] {
    max-width: 130px !important;
    font-size: 12px !important;
}
}
@media only screen and (max-device-width: 480px) {
.mobilePadding[data-v-626ad99b] {
    margin-top: 30px;
}
.banner-title[data-v-626ad99b] {
    font-size: 30px;
}
.banner-subtitle[data-v-626ad99b] {
    font-size: 18px;
}
}

.promo-3-img[data-v-b9b76d63] {
  width: 100%;
  margin-left: 0;
  float: left;
  margin-right: 0;
  padding-right: 0;
  margin-bottom: 20px;
}
.promot-3-title[data-v-b9b76d63] {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.15;
  color: #ffffff;
  display: block;
  width: 100%;
}
.promot-3-btn[data-v-b9b76d63] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  width: 100%;
  max-width: 180px;
}
.promo-3-botom-info[data-v-b9b76d63] {
  position: absolute;
  bottom: 40px;
  left: 10px;
  right: 10px;
}
@media (min-width: 992px) {
.promo-3-img[data-v-b9b76d63] {
    height: 100%;
}
}

/*
* Under 1366 Screen 
*/
@media (max-width: 1900px) {
.promo-3-botom-info[data-v-b9b76d63] {
    flex-wrap: wrap;
}
.promot-3-title[data-v-b9b76d63] {
    margin-bottom: 26px;
}
}
@media (min-width: 1280px) and (max-width: 1367.88px) {
.yt-cm-thr-pro-row[data-v-b9b76d63] {
    margin-left: -13.5px;
    margin-right: -13.5px;
}
.yt-cm-col[data-v-b9b76d63] {
    padding-left: 13.5px;
    padding-right: 13.5px;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) and (min-width: 768px) {
.promo-3-img[data-v-b9b76d63] {
    margin-bottom: 0;
}
.promo-3-botom-info[data-v-b9b76d63] {
    bottom: 20px;
}
}
@media (max-width: 1279.92px) {
.promot-3-btn[data-v-b9b76d63] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
}
.promot-3-title[data-v-b9b76d63] {
    font-size: 23px;
    line-height: 1.17;
    margin-bottom: 10px;
}
.yt-cm-thr-pro-row[data-v-b9b76d63] {
    margin-left: -4.5px;
    margin-right: -4.5px;
}
.yt-cm-col[data-v-b9b76d63] {
    padding-left: 4.5px;
    padding-right: 4.5px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.promot-3-btn[data-v-b9b76d63] {
    max-width: 150px;
}
.promo-3-botom-info[data-v-b9b76d63] {
    padding-left: 15px !important;
    padding-right: 25px !important;
    left: 5px !important;
    right: 5px !important;
}
section.yt-theme-promo-component[data-v-b9b76d63] {
    margin: 20px 0 !important;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.promo-3-col[data-v-b9b76d63] {
    order: 3;
}
.yt-cm-col[data-v-b9b76d63] {
    max-width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 0 50%;
}
.yt-cm-col.promo-3-col[data-v-b9b76d63] {
    max-width: 100%;
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
}
.promot-3-title[data-v-b9b76d63],
  .promot-3-btn[data-v-b9b76d63] {
    font-size: 14px;
    line-height: 1.14;
}
.promot-3-btn[data-v-b9b76d63] {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    max-width: 120px;
}
}

.topbar[data-v-a21fb471] {

    height:20px;
    color: var(--color-black);
    background-color: #f6f8fa;
    font-size: 14px;
    margin:6px 0px;
    /*padding-left: 50px;*/
}
.logocontainer[data-v-a21fb471] {
    background-color: white;
}
.logobox[data-v-a21fb471] {
    background-color:var(--color-dark-black);
}
.logoimage[data-v-a21fb471] {
    height:95px;
    width: 190px;
}
.supportbox[data-v-a21fb471] {
    height:95px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.support[data-v-a21fb471] {
    display: flex;
    justify-content: center;
    align-items: center;
}
.supportimage[data-v-a21fb471] {
    height:auto;
    width: 26px;
    margin-right: 10px;
}
.supportnumber[data-v-a21fb471] {
    display: flex;
    flex-direction: column;
}
.supportnumber .title[data-v-a21fb471]{
    font-size: 12px;
    font-weight: normal;
    color: var(--color-dark-grey);
    padding-bottom: 5px;
    margin-top: -5px;
}
.supportnumber .number[data-v-a21fb471]{
    font-weight: 500;
    font-size: 14px;
    color: var(--color-black);
}
.searchcontainer[data-v-a21fb471]{
    display: flex;
    align-items: center;
    height:100%;
}
.searchinput[data-v-a21fb471]{  
    border-radius: 6px;
    border: solid 1px #cae2fe;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 48px;
}
.searchicon[data-v-a21fb471]{
    height: auto;
    width: 14px;
    position: absolute;
    left: 18px;
}
.cartcontainer[data-v-a21fb471]{
    display:flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}
.carticon[data-v-a21fb471]{
    height: auto;
    width: 26px;
    margin-right: 20px;
    cursor: pointer;
}
.loginbutton[data-v-a21fb471]{
    width: 140px;
    height: 36px;
    border-radius: 6px;
    background-color: var(--color-dark-black);
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}
.menucontainer[data-v-a21fb471]{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 2px;
    background-color: white;
}
.menuicon[data-v-a21fb471] {
    height: auto;
    width: 16px;
    position: absolute;
    right: 32px;
    background-color: white;
}
nav.navbar[data-v-a21fb471]{
    padding:0px;
}
ul.navbar-nav[data-v-a21fb471]{
    width:100%;
    justify-content: space-around;
    background-color: white;
}
.nav-link[data-v-a21fb471]{
    color:var(--color-black) !important;
}
.nav-link.active[data-v-a21fb471] {
    color:var(--color-blue) !important;
    text-decoration: underline;
    font-weight: bold;
    text-underline-position: under;
}
@media only screen and (max-width: 992px) {
.order-sm-1[data-v-a21fb471]{
        order:1;
}
.searchicon[data-v-a21fb471]{
        left:32px;
}
}
@media only screen and (min-width: 992px) {
.order-sm-1[data-v-a21fb471]{
        order:0;
}
.searchicon[data-v-a21fb471]{
        left:18px;
}
ul.navbar-nav[data-v-a21fb471]{
        padding:6px 226px;
}
}
.usericon[data-v-a21fb471]{
    width: 42px;
    height: 40px;
}
.uname[data-v-a21fb471]{
    margin-left: 5px;
    color: gray;
}
.userbox[data-v-a21fb471]{
    cursor: pointer;
}


.yt-main-contact-us-pg .contactform[data-v-089c501c] {
  padding: 25px 20px;
}
.yt-main-contact-us-pg .title[data-v-089c501c] {
  font-size: 35px;
  font-weight: bold;
  padding-top: 25px;
  line-height: 1.13;
  margin-bottom: 30px;
  color: #000;
}
.yt-main-contact-us-pg .form[data-v-089c501c] {
  background-color: white;
  padding: 50px 40px;
}
.yt-main-contact-us-pg textarea[data-v-089c501c] {
  border-radius: 8px;
  padding: 12px;
  margin: 20px 15px 0 15px;
  width: 100%;
  border-color: #c0bebe;
  color: #8b8f95;
  font-size: 15px;
  height: 100px;
}
.yt-main-contact-us-pg textarea[data-v-089c501c]::-webkit-input-placeholder {
  color: #8b8f95;
}
.yt-main-contact-us-pg textarea[data-v-089c501c]:-ms-input-placeholder {
  color: #8b8f95;
}
.yt-main-contact-us-pg textarea[data-v-089c501c]::-ms-input-placeholder {
  color: #8b8f95;
}
.yt-main-contact-us-pg textarea[data-v-089c501c]::placeholder {
  color: #8b8f95;
}

/* form starting stylings ------------------------------- */
.yt-main-contact-us-pg .group[data-v-089c501c] {
  position: relative;
  margin-bottom: 30px;
}
.yt-main-contact-us-pg .group.phone[data-v-089c501c]{
  margin-bottom: 0;
}
.yt-main-contact-us-pg input[data-v-089c501c], .yt-main-contact-us-pg select[data-v-089c501c] {
  font-size: 16px;
  padding: 10px 10px 10px 0px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0bebe;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-main-contact-us-pg .select-arrow[data-v-089c501c] {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.yt-main-wrapper .form[data-v-089c501c] {
  border: 1px solid #c0bebe;
  border-radius: 10px;
}
.yt-main-contact-us-pg input[data-v-089c501c]:focus {
  outline: none;
  border-bottom: 1px solid black;
}

/* LABEL ======================================= */
.yt-main-contact-us-pg label[data-v-089c501c] {
  color: #999;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.yt-main-contact-us-pg input:focus ~ label[data-v-089c501c], .yt-main-contact-us-pg select:focus ~ label.select-label[data-v-089c501c],
.yt-main-contact-us-pg input:valid ~ label[data-v-089c501c], .yt-main-contact-us-pg select:valid ~ label.select-label[data-v-089c501c] {
  top: -15px;
  font-size: 14px;
  color: black;
}
.yt-main-wrapper[data-v-089c501c] {
  max-width: 770px;
  margin: 0 auto;
  position: relative;
}
.yt-main-contact-us-pg[data-v-089c501c] {
  /* margin-top: 60px; */
  margin-bottom: 100px;
}
.yt-contact-send-btn[data-v-089c501c] {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin: 20px 0 20px auto;  
  color: white;
  padding: 15px 70px;
  border-radius: 6px;
  background-color: black;
}
.yt-contact-send-btn[data-v-089c501c]:hover{
  border: 1px solid black;
  background-color: #fff;
  color: black;
}
@media (min-width: 1900px) {
}
@media (min-width: 768px) {
.yt-cm-mobile-bread[data-v-089c501c] {
    display: none;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-main-contact-us-pg[data-v-089c501c] {
            margin-bottom: 110px;
}
.yt-main-contact-us-pg .title[data-v-089c501c] {
            font-size: 24px;
            line-height: 1.17;
            margin-bottom: 20px;
}
.yt-main-contact-us-pg .form[data-v-089c501c] {
            padding: 20px 15px;
}
.yt-main-contact-us-pg label[data-v-089c501c] {
            font-size: 12px;
            line-height: 1.17;
}
.yt-contact-send-btn[data-v-089c501c] {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.13;
            letter-spacing: 0.32px;
}
.yt-main-contact-us-pg input[data-v-089c501c] {
            padding: 10px 10px 16px 6px;
}
}
    /*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.pageroute[data-v-089c501c] {
    color: gray;
    font-size: 15px;
    margin-bottom: 10px;
    line-height: 1.17;
}
.yt-profile-mb-ttl.profile-pg-title[data-v-089c501c] {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.11;
}
.yt-cm-mobile-bread[data-v-089c501c] {
    margin-top: 8px;
    margin-bottom: 10px;
}
.yt-main-contact-us-pg[data-v-089c501c] {
    margin-bottom: 20px;
}
.yt-main-contact-us-pg .title[data-v-089c501c] {
    font-size: 18px;
    line-height: 1.11;
    margin-bottom: 10px;
}
.yt-main-contact-us-pg .group[data-v-089c501c] {
    padding: 30px 20px;
}
.yt-main-contact-us-pg label[data-v-089c501c], .yt-main-contact-us-pg label.select-label[data-v-089c501c] {
    font-size: 13px;
    line-height: 1.15;
    padding-left: 20px;
    top: 35px;
}
.yt-main-contact-us-pg input:focus ~ label[data-v-089c501c], .yt-main-contact-us-pg select:focus ~ label.select-label[data-v-089c501c],
  .yt-main-contact-us-pg input:valid ~ label[data-v-089c501c], .yt-main-contact-us-pg select:valid ~ label.select-label[data-v-089c501c] {
    top: -5px;
    font-size: 14px;
    color: black;
}
.yt-main-contact-us-pg input[data-v-089c501c] {
    padding: 7px 10px 7px 0px;
}
.yt-contact-send-btn[data-v-089c501c] {
    position: static;
    position: initial;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    margin-top: 20px;
}
.yt-main-contact-us-pg .group[data-v-089c501c]:last-child {
    margin-bottom: 0;
}
}

/*
* Hero Banner CSS
*/
.hc-hero-bg-img[data-v-c1683d4f] {
  /* background-image: url("../images/heroimage.png"); */
  background-size: cover;
  background-position: 100%;
  min-height: 300px;
  position: relative;
}
.hc-hero-btn[data-v-c1683d4f] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #fff;
  max-width: 180px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 60px;
  width: 100%;
}
.button[data-v-c1683d4f] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.hc-hero-banner[data-v-c1683d4f] {
  position: relative;
}
@media only screen and (max-width: 992px) {
.heroimage[data-v-c1683d4f] {
    width: 100%;
}
.shopnow[data-v-c1683d4f] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -50px;
    width: 100px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
}
}
@media only screen and (min-width: 992px) {
.heroimage[data-v-c1683d4f] {
    width: 100%;
}
.shopnow[data-v-c1683d4f] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -100px;
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}
}
.herocontainer[data-v-c1683d4f] {
  display: flex;
  justify-content: center;
}
.hero[data-v-c1683d4f] {
  max-width: 1366px;
}
.hc-hero-banner[data-v-c1683d4f] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.hc-hero-banner[data-v-c1683d4f] {
    min-height: 200px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.hc-hero-btn[data-v-c1683d4f] {
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.hc-hero-banner[data-v-c1683d4f] {
    min-height: 200pt;
}
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
.hc-hero-banner[data-v-c1683d4f] {
    min-height: 300px;
}
}
/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
.hc-hero-btn[data-v-c1683d4f] {
    bottom: 95px;
}
.hc-hero-banner[data-v-c1683d4f] {
    min-height: 422px;
}
}

.yt-3banner-title[data-v-4796dba5] {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.15;
  margin-top: 0;

  color: var(--color-dark-black);
}
.yt-3banner-title span[data-v-4796dba5] {
  color: var(--color-black);
}
.yt-3banner-sub-title[data-v-4796dba5] {
  font-size: 22px;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-3banner-sp-btn[data-v-4796dba5] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  width: 100%;
  max-width: 180px;
  text-align: center;
  border-color: var(--color-dark-black);
}
.yt-3banner-divider[data-v-4796dba5],
.yt-3banner-big-divider[data-v-4796dba5] {
  height: 2px;
  background: #decdc5;
  width: 100%;
  display: block;
  max-width: 300px;
  margin: 15px 0 20px;
  left: -56px;
  position: relative;
}
.yt-3banner-wrapper[data-v-4796dba5] {
  position: absolute;
  top: 35px;
  left: 56px;
  max-width: 60%;
}
.yt-top-2[data-v-4796dba5] {
  top: auto;
  bottom: 50px;
}
.yt-top-2 .yt-3banner-title[data-v-4796dba5] {
  color: var(--color-black);
  font-size: 50px;
  font-weight: 500;
  line-height: 1.15;
}
.yt-top-2 .yt-3banner-sub-title[data-v-4796dba5] {
  color: var(--color-black);
  font-size: 22px;
  line-height: 1.14;
}
.yt-3banner-big-title[data-v-4796dba5] {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.12;
  color: #ffffff;
}
.yt-3banner-big-sub-title[data-v-4796dba5] {
  font-size: 79px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-3banner-big-divider[data-v-4796dba5] {
  left: 0;
  right: -51px !important;
  margin-left: auto;
  width: 150%;
}
.yt-3banner-last-wrap[data-v-4796dba5] {
  position: absolute;
  bottom: 50px;
  right: 51px;
}
.yt-full-width[data-v-4796dba5] {
  max-width: 100%;
}
.yty-bg-3banner[data-v-4796dba5] {
  min-height: 250px;
  background-size: cover;
  background-position: 100%;
  position: relative;
}
.yt-first-bottom-banner[data-v-4796dba5] {
  min-height: 550px;
  background-size: cover;
  background-position: 100% 100%;
  position: relative;
}
.yt-cm-three-col[data-v-4796dba5] {
  background-size: cover;
  background-position: 50%;
  position: relative;
  overflow: hidden;
}
.yt-cm-three-col[data-v-4796dba5] {
  width: 50%;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-first-bottom-banner[data-v-4796dba5] {
    min-height: 330px;
}
.yty-bg-3banner[data-v-4796dba5] {
    min-height: 150px;
}
.yt-3banner-title[data-v-4796dba5] {
    font-size: 22px;
    line-height: 1.14;
}
.yt-3banner-sub-title[data-v-4796dba5] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-sp-btn[data-v-4796dba5] {
    font-size: 16px;
    line-height: 1.13;
    padding: 10px !important;
}
.yt-top-2 .yt-3banner-title[data-v-4796dba5] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-top-2 .yt-3banner-sub-title[data-v-4796dba5] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-big-title[data-v-4796dba5] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-3banner-big-sub-title[data-v-4796dba5] {
    font-size: 40px;
    line-height: 1.15;
}
.yt-3banner-wrapper[data-v-4796dba5] {
    top: 19px;
}
.yt-3banner-divider.yt-first-divider[data-v-4796dba5] {
    margin: 12px 0;
}
.yt-3banner-divider.yt-second-divider[data-v-4796dba5] {
    margin: 20px 0 16px;
}
.yt-3banner-divider.yt-third-divider[data-v-4796dba5] {
    margin: 23px 0 16px;
}
}

/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.promo-3-botom-info[data-v-4796dba5] {
    bottom: 30px;
}
.promo-3-img[data-v-4796dba5] {
    margin-bottom: 10px !important;
}
.yt-3banner-wrapper[data-v-4796dba5] {
    max-width: 100%;
}
.yt-3banner-big-title[data-v-4796dba5] {
    margin-bottom: 10px;
}
.yty-bg-3banner[data-v-4796dba5] {
    background-position: center;
}
.yt-first-bottom-banner[data-v-4796dba5] {
    background-position: center;
}
.yt-cm-three-col[data-v-4796dba5] {
    width: 100%;
}
.yt-first-bottom-banner[data-v-4796dba5] {
    min-height: 322px;
}
.yt-cm-three-col[data-v-4796dba5] {
    min-height: 379px;
}
.yt-top-2 .yt-3banner-title[data-v-4796dba5] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-3banner-sp-btn[data-v-4796dba5] {
    font-size: 14px;
    line-height: 1.14;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    max-width: 120px;
}
}

.banner-container[data-v-3368272b]  {
  position: relative;
}
.banner-container img[data-v-3368272b] {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.banner-container .content[data-v-3368272b] {
  position: absolute;
  margin-left: 40px;
  top: 50%;
  transform: translateY(-40%);
}
.content .scheme[data-v-3368272b] {
  font-size: 102px;
  font-weight: bold;
}
.content .info[data-v-3368272b] {
  font-size: 24px;
  color: #8B8F95;
}
.content .btn-shop[data-v-3368272b] {
  color: #fff;
  background-color: #000;
  padding: 8px 30px;
  border: 1px solid #000;
  border-radius: 6px;
  margin-top: 30px;
  font-weight: bold;
}
/*
* Double CSS
*/
.yt-doc-inner[data-v-7366e391] {
  position: relative;
}
.yt-doc-tag[data-v-7366e391],
.yt-doc-daily[data-v-7366e391] {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.15;
  text-align: left;
  color: #ffffff;
}
.yt-doc-price-tagline[data-v-7366e391] {
  font-size: 16px;
  line-height: 1.63;
  text-align: left;
  color: #ffffff;
  padding-top: 22px;
}
.yt-doc-btn[data-v-7366e391] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  min-width: 180px;
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  margin-top: 35px;
}
.yt-doc-box[data-v-7366e391] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding: 35px;
}
.yt-double-offer-component[data-v-7366e391] {
  margin: 32px 0 60px;
}
.yt-doc-daily-2[data-v-7366e391] {
  font-size: 42px;
  font-weight: bold;
  line-height: 1.15;
  text-align: left;
  color: #fff;
}
.yt-doc-tag.yt-small-tag[data-v-7366e391] {
  font-size: 28px;
  line-height: 2;
  font-weight: 500;
}
/* .yt-doc-btn {
  position: absolute;
  bottom: 30px;
} */
@media (min-width: 1368px) and (max-width: 1900px) {
.yt-doc-tag[data-v-7366e391],
  .yt-doc-daily[data-v-7366e391] {
    font-size: 24px;
    line-height: 1.46;
}
.yt-doc-daily-2[data-v-7366e391] {
    font-size: 40px;
}
.yt-doc-btn[data-v-7366e391] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
}
}
@media (min-width: 1900px) {
.yt-double-offer-component[data-v-7366e391] {
    margin: 31px 0 80px;
}
.yt-doc-tag[data-v-7366e391],
  .yt-doc-daily[data-v-7366e391] {
    font-size: 40px;
    line-height: 1.15;
}
.yt-doc-price-tagline[data-v-7366e391] {
    font-size: 22px;
    line-height: 1.73;
}
.yt-doc-tag.yt-small-tag[data-v-7366e391] {
    font-size: 40px;
    line-height: 2;
    font-weight: 500;
}
.yt-doc-daily-2[data-v-7366e391] {
    font-size: 60px;
}
.yt-doc-btn[data-v-7366e391] {
    margin-top: 57px;
}
}
@media (min-width: 1600px) {
}
@media (max-width: 1399.98px) {
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-doc-inner[data-v-7366e391] {
    margin-bottom: 0px !important;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-double-offer-component[data-v-7366e391] {
    margin: 10px 0 !important;
}
.yt-doc-tag[data-v-7366e391],
  .yt-doc-daily[data-v-7366e391] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-doc-price-tagline[data-v-7366e391] {
    padding-top: 1px !important;
    font-size: 14px;
    line-height: 0.93;
}
.yt-doc-btn[data-v-7366e391] {
    margin-top: 16px !important;
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px !important;
    padding-bottom: 12px !important;
    width: 100%;
    max-width: 150px;
}
.yt-doc-box[data-v-7366e391] {
    padding: 18px 15px !important;
}
.yt-doc-tag.yt-small-tag[data-v-7366e391] {
    font-size: 14px !important;
    line-height: 2.29 !important;
}
.yt-doc-daily-2[data-v-7366e391] {
    font-size: 20px;
    margin-bottom: 4px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-cm-col[data-v-7366e391] {
    flex: 0 0 100%;
    max-width: 100%;
}
.yt-doc-tag[data-v-7366e391],
  .yt-doc-daily[data-v-7366e391],
  .yt-doc-daily-2[data-v-7366e391] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-doc-price-tagline[data-v-7366e391] {
    padding-top: 5pt !important;
}
.yt-doc-price-tagline[data-v-7366e391],
  .yt-doc-tag.yt-small-tag[data-v-7366e391] {
    font-size: 14px;
    line-height: 0.93;
}
.yt-doc-btn[data-v-7366e391] {
    font-size: 14px;
    line-height: 1.14;
    padding-right: 14px !important;
    padding-bottom: 14px !important;
    max-width: 120px !important;
}
.yt-doc-box[data-v-7366e391] {
    top: 50% !important;
    transform: translateY(-50%);
}
}

/*
* Hero Banner CSS
*/
.hc-hero-bg-img[data-v-f84d2320] {
  /* background-image: url("../images/heroimage.png"); */
  background-size: cover;
  background-position: 100%;
  min-height: 300px;
  position: relative;
}
.hc-hero-btn[data-v-f84d2320] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #fff;
  max-width: 180px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 60px;
  width: 100%;
}
.banner-title[data-v-f84d2320] {
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 6px;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner-subtitle[data-v-f84d2320] {
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-banner1[data-v-f84d2320] {
  padding-top: 40px;
  padding-bottom: 40px;
}
.myBannerimg[data-v-f84d2320] {
  min-height: 550px;
  object-fit: cover;
}
.video-container[data-v-f84d2320] {
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.myBannercontent[data-v-f84d2320] {
  color: black;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 127px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
  width: 300px;
  line-height: 1.17;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myBanner[data-v-f84d2320] {
  width: 450px !important;
}
.my-btn[data-v-f84d2320] {
  opacity: 0.99;
  background-color: transparent;
  border-color: black;
  font-weight: 500;
  line-height: 1.11;
  color: black;
  max-width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 55px;
  width: 200px;
  height: 55px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
}
.display-product-container:hover .my-btn[data-v-f84d2320],
.mobilePadding:hover .my-btn[data-v-f84d2320] {
  background-color: black;
  color: white;
}
.my-btn[data-v-f84d2320]:hover {
  background-color: black;
  color: white;
}
.button[data-v-f84d2320] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.hc-hero-banner[data-v-f84d2320] {
  position: relative;
}
@media only screen and (max-width: 992px) {
.heroimage[data-v-f84d2320] {
    width: 100%;
}
.shopnow[data-v-f84d2320] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -50px;
    width: 100px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
}
}
@media only screen and (min-width: 992px) {
.heroimage[data-v-f84d2320] {
    width: 100%;
}
.shopnow[data-v-f84d2320] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -100px;
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}
}
.herocontainer[data-v-f84d2320] {
  display: flex;
  justify-content: center;
}
.hero[data-v-f84d2320] {
  max-width: 1366px;
}
.hc-hero-banner[data-v-f84d2320] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.hc-hero-banner[data-v-f84d2320] {
    min-height: 200px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 768px) {
.hc-hero-btn[data-v-f84d2320] {
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.hc-hero-banner[data-v-f84d2320] {
    min-height: 200pt;
}
.banner-title[data-v-f84d2320] {
    font-size: 40px;
}
.banner-subtitle[data-v-f84d2320] {
    font-size: 20px;
}
.myBannercontent[data-v-f84d2320] {
    width: 220px !important;
    font-size: 15px;
}
.myBanner[data-v-f84d2320] {
    width: 220px !important;
}
.my-btn[data-v-f84d2320] {
    max-width: 130px !important;
    font-size: 12px !important;
}
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
.hc-hero-banner[data-v-f84d2320] {
    min-height: 300px;
}
}
@media (max-width: 1457px) {
.myBannercontent[data-v-f84d2320] {
    width: 250px;
}
.myBanner[data-v-f84d2320] {
    width: 250px !important;
}
.my-btn[data-v-f84d2320] {
    max-width: 180px;
    font-size: 15px;
}
}
/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
.hc-hero-btn[data-v-f84d2320] {
    bottom: 95px;
}
.hc-hero-banner[data-v-f84d2320] {
    min-height: 422px;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.mobilePadding[data-v-f84d2320] {
    margin-top: 30px !important;
}
.banner-title[data-v-f84d2320] {
    font-size: 30px;
}
.banner-subtitle[data-v-f84d2320] {
    font-size: 18px;
}
}

/*
* YT Product Slider CSS
*/
.yt-sgl-product-fav[data-v-de0838de] {
  width: 24px;
}
.yt-sgl-product-off[data-v-de0838de] {
  background-color: #e65e52;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.noProducts[data-v-de0838de]{
  font-size: 25px;
}
.yt-sgl-product-cpnt[data-v-de0838de] {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  position: relative;
  transition: 0.5s ease;
  margin-bottom: 20px;
}
.yt-sgl-component-wrapper[data-v-de0838de] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-de0838de] {
  outline: none !important;
  box-shadow: none;
}
.yt-sgl-product-add-btn.buttoncart[data-v-de0838de] {
  position: relative;
  bottom: 0;
  width: 30%;
  border-radius: 1px;
  border: 1px solid var(--color-dark-black);
  background-color: transparent;
  font-size: 18px;
  letter-spacing: 2px;
}
.yt-sgl-product-cpnt:hover .yt-sgl-product-add-btn.buttoncart[data-v-de0838de] {
  background-color: var(--color-dark-black);
  border-radius: 0 0 4px 4px;
  color: var(--color-white);
}
.yt-sgl-product-cpnt[data-v-de0838de]:hover {
  border-radius: 10px;
}
.yt-ld-more-prdt[data-v-de0838de] {
  font-size: 30px;
  line-height: 1.13;
  color: var(--color-dark-black);
  border: none;
  box-shadow: none !important;
}
.price.yt-sgl-price-wrap[data-v-de0838de] {
  margin-bottom: 25px;
}
.yt-sgl-product-title[data-v-de0838de] {
  font-size: 25px;
  color: var(--color-dark-black);
  margin: 12px auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  float: left;
}
.yt-product-bg-image[data-v-de0838de] {
  width: 100%;
  height: 252px;
  background-size: cover;
  background-position: 100%;
  cursor: pointer;
  border-radius: 4px;
  border-radius: 4px;
  background-repeat: no-repeat;
}
.yt-fav-circle-wrap[data-v-de0838de] {
  box-shadow: 0 3px 6px 0 rgba(62, 69, 79, 0.1);
}
.yt-sgl-product-img[data-v-de0838de] {
  max-height: 150px;
}
.yt-sgl-component-more[data-v-de0838de] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.yt-sgl-comonent-top-title[data-v-de0838de] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.yt-sgl-product-off[data-v-de0838de] {
  background-color: #e65e52;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.yt-sgl-product-sale-price[data-v-de0838de] {
  color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.11;
}
.yt-sgl-product-reg-price2[data-v-de0838de] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-sgl-product-add-btn[data-v-de0838de] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.trans-fl-ttl[data-v-de0838de]{
  font-size: 20px;
}
/* .yt-cm-row-margin {
  margin: 0 -5px;
} */
.yt-sp-top-fav-act[data-v-de0838de] {
  position: absolute;
  top: 10px;
  right: 0;
  left: 8px;
}
@media (min-width: 1400px) {
  /* .yt-cm-row-margin {
    margin: 0 -10px;
  } */
.cm-xxl-3[data-v-de0838de] {
    flex: 0 0 25%;
    max-width: 25%;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-cm-row-margin .cm-col-prdt-col[data-v-de0838de] {
    flex: 0 0 25%;
    max-width: 25%;
}
.yt-sgl-product-off[data-v-de0838de] {
    font-size: 12px;
    line-height: 1.17;
    min-width: 35px;
}
.yt-sgl-product-title[data-v-de0838de] {
    font-size: 16px;
}
.price.yt-sgl-price-wrap .yt-sgl-product-sale-price[data-v-de0838de],
  .price.yt-sgl-price-wrap .yt-sgl-product-reg-price2[data-v-de0838de] {
    font-size: 12px;
    line-height: 1.17;
    font-weight: 500;
    margin-bottom: 16px;
}
.yt-sgl-product-add-btn.buttoncart[data-v-de0838de] {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    width: 50%;
}
.price.yt-sgl-price-wrap[data-v-de0838de] {
    margin-bottom: 16px;
}
.yt-allproduct-main-wrap[data-v-de0838de] {
    margin-top: 0px !important;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 1199px) {
.yt-sgl-product-title[data-v-de0838de] {
    max-width: 150px;
}
}
@media (max-width: 767.92px) {
.yt-cm-row-margin .cm-col-prdt-col[data-v-de0838de] {
    flex: 0 0 50%;
    max-width: 50%;
}
.yt-sgl-product-title[data-v-de0838de] {
    font-size: 14px;
}
.price.yt-sgl-price-wrap .yt-sgl-product-sale-price[data-v-de0838de],
  .price.yt-sgl-price-wrap .yt-sgl-product-reg-price2[data-v-de0838de] {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 18px;
}
.yt-sgl-product-add-btn.buttoncart[data-v-de0838de] {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: normal;
}
.price.yt-sgl-price-wrap[data-v-de0838de] {
    margin-bottom: 18px;
}
.yt-allproduct-main-wrap[data-v-de0838de] {
    margin-top: 60px !important;
}
  /* .yt-cm-row-margin {
    margin: 0 -5px;
  } */
}
@media (max-width: 576px) {
.yt-cm-row-margin .cm-col-prdt-col[data-v-de0838de] {
    flex: 0 0 100%;
    max-width: 100%;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-sgl-product-add-btn[data-v-de0838de] {
    font-size: 16px;
}
}
@media only screen and (max-device-width: 480px) {
.yt-sgl-product-title[data-v-de0838de] {
    font-size: 20px;
}
}

.yt-filter-title[data-v-eef5380b] {
  font-size: 18px;
  color: var(--color-dark-black);
  line-height: 1.11;
  margin-bottom: 10px;
}
.radius-10[data-v-eef5380b] {
  border-radius: 10px;
}
.yt-box-title[data-v-eef5380b] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-black);
  margin-bottom: 25px;
}
.yt-ul-list-none[data-v-eef5380b] {
  list-style: none;
}
.yt-cat-name[data-v-eef5380b],
.yt-color-name[data-v-eef5380b],
.yt-size-name[data-v-eef5380b] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-main-filter-box ul li[data-v-eef5380b]:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.yt-filter-divider[data-v-eef5380b] {
  display: block;
  height: 2px;
  background: #f6f8fa;
  margin: 7.5px 0;
}
.yt-filter-search-wrap[data-v-eef5380b] {
  position: relative;
}
.yt-search-icn[data-v-eef5380b] {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 22px;
  padding: 11px;
  box-sizing: content-box;
  background: #fff;
  bottom: 2px;
  border-radius: 7px;
  color: #a1a4a9;
}
.yt-color-search-bar[data-v-eef5380b] {
  border-radius: 7px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  width: 100%;
  padding-left: 54px;
}
.yt-filter-checkbox-wrap .yt-filter-label[data-v-eef5380b] {
  position: relative;
  cursor: pointer;
  margin: 0;
}
.yt-filter-checkbox-wrap .yt-filter-label[data-v-eef5380b]:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}
.yt-filter-checkbox-wrap input:checked + label[data-v-eef5380b]:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}
.yt-filter-checkbox-wrap input:checked + label[data-v-eef5380b]:before {
  background-color: #000;
}
.yt-filter-checkbox-wrap input[data-v-eef5380b] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}
.yt-filter-checkbox-wrap[data-v-eef5380b] {
  position: relative;
  text-align: right;
}
.yt-filter-search-filed[data-v-eef5380b] {
  position: relative;
  margin-bottom: 20px;
}
.yt-filter-search-filed .yt-searchicon-img[data-v-eef5380b] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;
}
.yt-filter-search-filed #yt-filter-brands-flt[data-v-eef5380b],
.yt-filter-search-filed #yt-filter-tags-flt[data-v-eef5380b] {
  padding-left: 56px;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-grey);
}
.yt-main-filter-box[data-v-eef5380b] {
  border: solid 1px #dbdbdb;
}
.yt-color-name[data-v-eef5380b] {
  position: relative;
}
.yt-color-name[data-v-eef5380b]::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.yt-color-name.Baby.Pink[data-v-eef5380b]::after {
  background-color: #f4c2c2;
}
.yt-color-name.Beig[data-v-eef5380b]::after {
  background-color: #f5f5dd;
}
.yt-color-name.Black[data-v-eef5380b]::after {
  background-color: #000000;
}
.yt-color-name.Blue[data-v-eef5380b]::after {
  background-color: #0000fe;
}
.yt-color-name.Bottle.Green[data-v-eef5380b]::after {
  background-color: #006a4e;
}
.yt-color-name.Brown[data-v-eef5380b]::after {
  background-color: #d3691f;
}
.yt-color-name.Green[data-v-eef5380b]::after {
  background-color: #008001;
}
.yt-color-name.Indigo[data-v-eef5380b]::after {
  background-color: #4c0182;
}
.yt-color-black[data-v-eef5380b] {
  color: var(--color-black) !important;
}
.yt-main-filter-box .yt-filter-inner-wrap .yt-ul-list-none[data-v-eef5380b] {
  overflow: auto;
}
/* width */
.yt-main-filter-box .yt-filter-inner-wrap .yt-ul-list-none[data-v-eef5380b]::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.yt-main-filter-box
  .yt-filter-inner-wrap
  .yt-ul-list-none[data-v-eef5380b]::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.yt-main-filter-box
  .yt-filter-inner-wrap
  .yt-ul-list-none[data-v-eef5380b]::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
.yt-main-filter-box
  .yt-filter-inner-wrap
  .yt-ul-list-none[data-v-eef5380b]::-webkit-scrollbar-thumb:hover {
  background: #000;
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-filter-inner-wrap.yt-price-filter-wrap[data-v-eef5380b] {
            width: 33.3333333333%;
            float: left;
}
}
    /*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-filter-title[data-v-eef5380b] {
            display: none;
}
        /* .yt-filter-inner-wrap .yt-ul-list-none {
            list-style: none;
            margin: 0 -15px !important;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
        .yt-filter-inner-wrap .yt-ul-list-none li {
            width: 33.3333333333%;
            -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%;
            padding: 0 15px 0 !important;
        } */
.yt-main-filter-box[data-v-eef5380b] {
            border-radius: 10px;
            box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
            border: solid 1px var(--color-dark-black);
}
.yt-filter-divider[data-v-eef5380b] {
            display: none;
}
.yt-product-filter-wrap .yt-main-filter-box[data-v-eef5380b]:after {
            display: block;
            clear: both;
            content: "";
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-box-title[data-v-eef5380b] {
            font-size: 18px;
}
}
@media (max-width: 767px) {
.yt-box-title[data-v-eef5380b]{
        margin-bottom: 15px;
}
}
.input-range__slider {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: var(--color-dark-black);
  border: 2px solid #fff;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(80, 88, 103, 0.45);
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: "Helvetica Neue", san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: var(--color-dark-black);
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}
.input-range__label.input-range__label--min,
.input-range__label.input-range__label--max,
.input-range__label--value {
  display: none;
}
.yt-min-price,
.yt-max-price {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
}
/*# sourceMappingURL=index.css.map */

.login-wrapper[data-v-2542ca7d] {
    /* margin: 25px auto; */
    padding: 20px 30px;
    max-width: 570px;
    /* border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 3px 3px 30px #eee; */
    background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-2542ca7d] {
    border-bottom: solid 1px #212529;
    font-weight: bold;
    color: #000;
}
.tagline-title[data-v-2542ca7d]{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
}
.tagline[data-v-2542ca7d]{
    padding-bottom: 30px;
    text-align: center;
    font-size: 18px;
    color: #8b8f95;
    letter-spacing: 1px;
}
.login-wrapper .nav-item[data-v-2542ca7d] {
    cursor: pointer;
    color: #989CA2;
    width: 50%;
    text-align: center;
    font-size: 24px;
    letter-spacing: 1.5px;
    border-bottom: solid 1px #dbdbdb;
}
.sign-up-form .form-group[data-v-2542ca7d] {
    position: relative;
}
.sign-up-form .form-group img[data-v-2542ca7d]{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 11px;
    margin: 0 20px;
}
.sign-up-form .form-group .eye-icon[data-v-2542ca7d] {
    position: absolute;
    top: 14px;
    right: 25px;
}
.sign-up-form .form-group .form-control[data-v-2542ca7d] {
    padding: 20px 60px 20px 30px;
    font-size: 16px;
    color: #000;
}
.sign-up-form .form-group .form-control[data-v-2542ca7d]:active,
.sign-up-form .form-group .form-control[data-v-2542ca7d]:focus {
    border-color: #000;
}
.sign-up-form .password-hint[data-v-2542ca7d] {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}
.sign-up-form .form-group .error[data-v-2542ca7d] {
    color: red;
    margin-top: 4px;
    font-size: 12px;
}
.sign-up-form .btn-sign-up[data-v-2542ca7d] {
    color: black;
    background-color: #ffffff;
    border: 1px solid black;
    letter-spacing: 2px;
    margin-top: 20px;
    padding: 10px 0;
    font-size: 18px;
    transition: .5s ease;
}
.sign-up-form .btn-sign-up[data-v-2542ca7d]:hover{
    background-color: #000;
    color: white;
}
.other-actions[data-v-2542ca7d] {
    text-align: center;
}
.other-actions .skip-line-1[data-v-2542ca7d]{
    color: #8C9095;
    font-size: 16px;
    text-decoration: none;
}
.other-actions .skip-line-2[data-v-2542ca7d]{
    color: #8C9095;
    margin-top: 40px;
    font-size: 16px;
    margin-bottom: 0;
}
.other-actions .fb-button[data-v-2542ca7d] {
    background: transparent;
    color: #3b5998;
    border: none;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}
.other-actions .login-action[data-v-2542ca7d] {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 40px;
}
.other-actions .google-button[data-v-2542ca7d] {
    background: transparent;
    color: #EA4335;
    border: none;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.other-actions .terms-tagline[data-v-2542ca7d] {
    color: #8C9095;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
}
.forgot-password[data-v-2542ca7d] {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    float: right;
    padding-bottom: 15px;
}
.other-actions .terms a[data-v-2542ca7d]{
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0 5px;
}
.login-wrapper[data-v-367a79f0] {
    /* margin: 25px auto; */
    padding: 20px 30px;
    max-width: 570px;
    /* border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 3px 3px 30px #eee; */
    background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-367a79f0] {
    border-bottom: solid 1px #212529;
    font-weight: bold;
    color: #000;
}
.tagline-title[data-v-367a79f0]{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
}
.tagline[data-v-367a79f0]{
    padding-bottom: 30px;
    text-align: center;
    font-size: 18px;
    color: #8b8f95;
    letter-spacing: 1px;
}
.login-wrapper .nav-item[data-v-367a79f0] {
    cursor: pointer;
    color: #989CA2;
    width: 50%;
    text-align: center;
    font-size: 24px;
    letter-spacing: 1.5px;
    border-bottom: solid 1px #dbdbdb;
}
.sign-up-form .form-group[data-v-367a79f0] {
    position: relative;
}
.sign-up-form .form-group img[data-v-367a79f0]{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 11px;
    margin: 0 20px;
}
.sign-up-form .form-group .eye-icon[data-v-367a79f0] {
    position: absolute;
    top: 14px;
    right: 25px;
}
.sign-up-form .form-group .form-control[data-v-367a79f0] {
    padding: 20px 60px 20px 30px;
    font-size: 16px;
    color: #000;
}
.sign-up-form .form-group .form-control[data-v-367a79f0]:active,
.sign-up-form .form-group .form-control[data-v-367a79f0]:focus {
    border-color: #000;
}
.sign-up-form .password-hint[data-v-367a79f0] {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}
.sign-up-form .form-group .error[data-v-367a79f0] {
    color: red;
    margin-top: 4px;
    font-size: 12px;
}
.sign-up-form .btn-sign-up[data-v-367a79f0] {
    color: black;
    background-color: #ffffff;
    border: 1px solid black;
    letter-spacing: 2px;
    margin-top: 20px;
    padding: 10px 0;
    font-size: 18px;
    transition: .5s ease;
}
.sign-up-form .btn-sign-up[data-v-367a79f0]:hover{
    background-color: #000;
    color: white;
}
.other-actions[data-v-367a79f0] {
    text-align: center;
}
.other-actions .skip-line-1[data-v-367a79f0]{
    color: #8C9095;
    font-size: 16px;
    text-decoration: none;
}
.other-actions .skip-line-2[data-v-367a79f0]{
    color: #8C9095;
    margin-top: 40px;
    font-size: 16px;
    margin-bottom: 0;
}
.other-actions .fb-button[data-v-367a79f0] {
    background: transparent;
    color: #3b5998;
    border: none;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}
.other-actions .login-action[data-v-367a79f0] {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 40px;
}
.other-actions .google-button[data-v-367a79f0] {
    background: transparent;
    color: #EA4335;
    border: none;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.other-actions .terms-tagline[data-v-367a79f0] {
    color: #8C9095;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
}
.forgot-password[data-v-367a79f0] {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    float: right;
    padding-bottom: 15px;
}
.other-actions .terms a[data-v-367a79f0]{
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0 5px;
}
.login-wrapper[data-v-372d90a7] {
    /* margin: 25px auto; */
    padding: 20px 30px;
    max-width: 570px;
    /* border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 3px 3px 30px #eee; */
    background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-372d90a7] {
    border-bottom: solid 1px #212529;
    font-weight: bold;
    color: #000;
}
.tagline-title[data-v-372d90a7]{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
}
.tagline[data-v-372d90a7]{
    padding-bottom: 30px;
    text-align: center;
    font-size: 18px;
    color: #8b8f95;
    letter-spacing: 1px;
}
.login-wrapper .nav-item[data-v-372d90a7] {
    cursor: pointer;
    color: #989CA2;
    width: 50%;
    text-align: center;
    font-size: 24px;
    letter-spacing: 1.5px;
    border-bottom: solid 1px #dbdbdb;
}
.sign-up-form .form-group[data-v-372d90a7] {
    position: relative;
}
.sign-up-form .form-group img[data-v-372d90a7]{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 11px;
    margin: 0 20px;
}
.sign-up-form .form-group .eye-icon[data-v-372d90a7] {
    position: absolute;
    top: 14px;
    right: 25px;
}
.sign-up-form .form-group .form-control[data-v-372d90a7] {
    padding: 20px 60px 20px 30px;
    font-size: 16px;
    color: #000;
}
.sign-up-form .form-group .form-control[data-v-372d90a7]:active,
.sign-up-form .form-group .form-control[data-v-372d90a7]:focus {
    border-color: #000;
}
.sign-up-form .password-hint[data-v-372d90a7] {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}
.sign-up-form .form-group .error[data-v-372d90a7] {
    color: red;
    margin-top: 4px;
    font-size: 12px;
}
.sign-up-form .btn-sign-up[data-v-372d90a7] {
    color: black;
    background-color: #ffffff;
    border: 1px solid black;
    letter-spacing: 2px;
    margin-top: 20px;
    padding: 10px 0;
    font-size: 18px;
    transition: .5s ease;
}
.sign-up-form .btn-sign-up[data-v-372d90a7]:hover{
    background-color: #000;
    color: white;
}
.other-actions[data-v-372d90a7] {
    text-align: center;
}
.other-actions .skip-line-1[data-v-372d90a7]{
    color: #8C9095;
    font-size: 16px;
    text-decoration: none;
}
.other-actions .skip-line-2[data-v-372d90a7]{
    color: #8C9095;
    margin-top: 40px;
    font-size: 16px;
    margin-bottom: 0;
}
.other-actions .fb-button[data-v-372d90a7] {
    background: transparent;
    color: #3b5998;
    border: none;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}
.other-actions .login-action[data-v-372d90a7] {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 40px;
}
.other-actions .google-button[data-v-372d90a7] {
    background: transparent;
    color: #EA4335;
    border: none;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.other-actions .terms-tagline[data-v-372d90a7] {
    color: #8C9095;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
}
.forgot-password[data-v-372d90a7] {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    float: right;
    padding-bottom: 15px;
}
.other-actions .terms a[data-v-372d90a7]{
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0 5px;
}
.login-wrapper[data-v-6c0d4c28] {
  margin: 60px auto;
  padding: 20px 30px;
  max-width: 570px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 3px 3px 30px #eee;
  background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-6c0d4c28] {
  border-bottom: 3px solid #8c9095;
  font-weight: bold;
  color: #000;
}
.login-wrapper .nav-item[data-v-6c0d4c28] {
  cursor: pointer;
  color: #989ca2;
}
.yt-forgot-pw-wrap[data-v-6c0d4c28] {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
}
.yt-fpp-mb-5[data-v-6c0d4c28] {
  margin-bottom: 50px;
}
.yt-fpp-mt-5[data-v-6c0d4c28] {
  margin-top: 50px;
}
.yt-pass-info[data-v-6c0d4c28] {
  font-size: 11px;
  line-height: 1.1;
  color: var(--color-black);
  text-align: center;
}
.yt-cp-btn[data-v-6c0d4c28] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  transition: .5s ease;
}
.yt-cp-btn[data-v-6c0d4c28]:hover{
  background-color: black;
  color: white;
}
.yt-fp-top-back-tag-name[data-v-6c0d4c28] {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
}
.yt-fp-back-icn[data-v-6c0d4c28] {
  font-size: 40px;
  margin-left: -12px;
}
.yt-fp-bg-banner[data-v-6c0d4c28] {
  background-image: url(/static/media/login-image.82f445ed.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-fp-other-info[data-v-6c0d4c28] {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-fp-oi-tag-small[data-v-6c0d4c28] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-fp-oi-tag-big[data-v-6c0d4c28] {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-fp-bdr[data-v-6c0d4c28] {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-fp-sp-now[data-v-6c0d4c28] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}
.yt-fp-tag-line[data-v-6c0d4c28] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 40px;
  margin-top: 16px;
}
.yt-fp-tag-line-2[data-v-6c0d4c28] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 40px;
}
.yt-fp-form .form-group[data-v-6c0d4c28] {
  position: relative;
  margin-bottom: 30px;
}
.yt-fp-icn[data-v-6c0d4c28] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn1[data-v-6c0d4c28] {
  max-width: 24px;
  position: absolute;
  top: 25%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-6c0d4c28] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-form[data-v-6c0d4c28] {
  margin-bottom: 30px;
}
.yt-fp-form .form-group .form-control[data-v-6c0d4c28] {
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.yt-fp-btn[data-v-6c0d4c28] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  border: 1px solid black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
}
.yt-fp-btn[data-v-6c0d4c28]:hover {
  background-color: black;
  color: white;
}
.yt-fp-skip-btn[data-v-6c0d4c28] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.otp-timer[data-v-6c0d4c28] {
  font-size: 22px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}
.yt-resent-otp-btn[data-v-6c0d4c28] {
  font-size: 16px;
  line-height: 1.14;
  color: var(--color-black);
  box-shadow: none !important;
}
.yt-fp-form .error.form-group .form-control[data-v-6c0d4c28] {
  border: solid 1px var(--color-orange);
}
.yt-optp-message-show[data-v-6c0d4c28] {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.yt-fp-form .error.form-group .yt-optp-message-show[data-v-6c0d4c28] {
  color: var(--color-orange);
  display: block;
}
.yt-pas-eye-icn[data-v-6c0d4c28] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-pas-eye-icn2[data-v-6c0d4c28] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-fp-form .success.form-group .yt-optp-message-show[data-v-6c0d4c28] {
  color: var(--color-green);
  display: block;
}
.error.form-group[data-v-6c0d4c28],
.success.form-group[data-v-6c0d4c28] {
  margin-bottom: 43px !important;
}
.invalid[data-v-6c0d4c28] {
  color: var(--color-orange);
}
.invalid-input[data-v-6c0d4c28] {
  color: var(--color-orange);
  border: 1px solid var(--color-orange) !important;
}
.normal-input[data-v-6c0d4c28]:active {
  border: 1px solid var(--color-blue) !important;
}
.yt-forgot-pass-vie-icn[data-v-6c0d4c28] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-forgot-pass-vie-icn2[data-v-6c0d4c28] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.pass_success[data-v-6c0d4c28] {
  color: green;
  text-align: center;
}
.yt-fp-inner-content.yt-banner-col[data-v-6c0d4c28] {
  width: 45%;
}
.yt-fp-inner-content.yt-form-col[data-v-6c0d4c28] {
  padding-left: 40px;
  width: 55%;
}
.invalid[data-v-6c0d4c28] {
  color: var(--color-orange);
  text-align: center;
}
.otp-div-style[data-v-6c0d4c28] {
  text-align: center;
  margin-top: 20px;
}
.invalid-feedback[data-v-6c0d4c28] {
  text-align: center;
}
/**/
@media (min-width: 1400px) {
.yt-frgt-row .yt-banner-col[data-v-6c0d4c28],
  .yt-frgt-row .yt-form-col[data-v-6c0d4c28] {
    width: 50%;
}
.yt-forgot-pw-wrap[data-v-6c0d4c28] {
    max-width: 1340px;
}
.col-xxl-7[data-v-6c0d4c28] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xxl-5[data-v-6c0d4c28] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-forgot-pw-wrap[data-v-6c0d4c28] {
    max-width: 588px;
    padding: 0;
}
.yt-frgt-row .yt-fp-inner-content[data-v-6c0d4c28] {
    width: 100%;
}
.yt-frgt-row .yt-fp-bg-banner[data-v-6c0d4c28] {
    order: 2;
}
.yt-fp-inner-content.yt-form-col[data-v-6c0d4c28] {
    padding: 32px 30px 30px;
}
.yt-fp-top-back-tag-name[data-v-6c0d4c28] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-fp-tag-line[data-v-6c0d4c28] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 20px;
    margin-top: 23px;
}
.yt-fp-tag-line-2[data-v-6c0d4c28] {
    margin-bottom: 34px;
    margin-top: 0;
}
.yt-fp-form .form-group .form-control[data-v-6c0d4c28] {
    padding-top: 18px;
    padding-bottom: 17px;
    font-size: 14px;
    line-height: 1.14;
}
.yt-fp-form .form-group[data-v-6c0d4c28] {
    margin-bottom: 20px;
}
.yt-fp-btn[data-v-6c0d4c28] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
}
.yt-fp-bg-banner[data-v-6c0d4c28] {
    min-height: 211px;
    background-position: 100% 35%;
}
.yt-fp-oi-tag-small[data-v-6c0d4c28] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
}
.yt-fp-oi-tag-big[data-v-6c0d4c28] {
    font-size: 48px;
    line-height: 1.15;
    margin: 0;
}
.yt-fp-sp-now[data-v-6c0d4c28] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 30px;
}
.yt-fp-bdr[data-v-6c0d4c28] {
    bottom: 55px !important;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-fp-inner-content.yt-form-col[data-v-6c0d4c28] {
    padding: 15px;
}
.yt-forgot-pw-wrap[data-v-6c0d4c28] {
    max-width: 335px;
}
.yt-fp-tag-line[data-v-6c0d4c28] {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 10px;
}
.yt-fp-tag-line-2[data-v-6c0d4c28] {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.17;
}
.yt-fp-form .form-group .form-control[data-v-6c0d4c28] {
    padding-top: 13px;
    padding-bottom: 12px;
}
.yt-fp-form[data-v-6c0d4c28] {
    margin-bottom: 15px;
}
.yt-fp-oi-tag-small[data-v-6c0d4c28] {
    font-size: 10px;
    line-height: 1.11;
}
.yt-fp-oi-tag-big[data-v-6c0d4c28] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-fp-bg-banner[data-v-6c0d4c28] {
    min-height: 180px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-fp-btn[data-v-6c0d4c28] {
    font-size: 16px;
}
}

.login-wrapper[data-v-9e00d57f] {
  margin: 60px auto;
  padding: 20px 30px;
  max-width: 570px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 3px 3px 30px #eee;
  background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-9e00d57f] {
  border-bottom: 3px solid #8c9095;
  font-weight: bold;
  color: #000;
}
.login-wrapper .nav-item[data-v-9e00d57f] {
  cursor: pointer;
  color: #989ca2;
}
.yt-forgot-pw-wrap[data-v-9e00d57f] {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
}
.yt-fpp-mb-5[data-v-9e00d57f] {
  margin-bottom: 50px;
}
.yt-fpp-mt-5[data-v-9e00d57f] {
  margin-top: 50px;
}
.yt-pass-info[data-v-9e00d57f] {
  font-size: 11px;
  line-height: 1.1;
  color: var(--color-black);
  text-align: center;
}
.yt-cp-btn[data-v-9e00d57f] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  transition: .5s ease;
}
.yt-cp-btn[data-v-9e00d57f]:hover{
  background-color: black;
  color: white;
}
.yt-fp-top-back-tag-name[data-v-9e00d57f] {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
}
.yt-fp-back-icn[data-v-9e00d57f] {
  font-size: 40px;
  margin-left: -12px;
}
.yt-fp-bg-banner[data-v-9e00d57f] {
  background-image: url(/static/media/login-image.82f445ed.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-fp-other-info[data-v-9e00d57f] {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-fp-oi-tag-small[data-v-9e00d57f] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-fp-oi-tag-big[data-v-9e00d57f] {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-fp-bdr[data-v-9e00d57f] {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-fp-sp-now[data-v-9e00d57f] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}
.yt-fp-tag-line[data-v-9e00d57f] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 40px;
  margin-top: 16px;
}
.yt-fp-tag-line-2[data-v-9e00d57f] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 40px;
}
.yt-fp-form .form-group[data-v-9e00d57f] {
  position: relative;
  margin-bottom: 30px;
}
.yt-fp-icn[data-v-9e00d57f] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn1[data-v-9e00d57f] {
  max-width: 24px;
  position: absolute;
  top: 25%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-9e00d57f] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-form[data-v-9e00d57f] {
  margin-bottom: 30px;
}
.yt-fp-form .form-group .form-control[data-v-9e00d57f] {
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.yt-fp-btn[data-v-9e00d57f] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  border: 1px solid black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
}
.yt-fp-btn[data-v-9e00d57f]:hover {
  background-color: black;
  color: white;
}
.yt-fp-skip-btn[data-v-9e00d57f] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.otp-timer[data-v-9e00d57f] {
  font-size: 22px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}
.yt-resent-otp-btn[data-v-9e00d57f] {
  font-size: 16px;
  line-height: 1.14;
  color: var(--color-black);
  box-shadow: none !important;
}
.yt-fp-form .error.form-group .form-control[data-v-9e00d57f] {
  border: solid 1px var(--color-orange);
}
.yt-optp-message-show[data-v-9e00d57f] {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.yt-fp-form .error.form-group .yt-optp-message-show[data-v-9e00d57f] {
  color: var(--color-orange);
  display: block;
}
.yt-pas-eye-icn[data-v-9e00d57f] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-pas-eye-icn2[data-v-9e00d57f] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-fp-form .success.form-group .yt-optp-message-show[data-v-9e00d57f] {
  color: var(--color-green);
  display: block;
}
.error.form-group[data-v-9e00d57f],
.success.form-group[data-v-9e00d57f] {
  margin-bottom: 43px !important;
}
.invalid[data-v-9e00d57f] {
  color: var(--color-orange);
}
.invalid-input[data-v-9e00d57f] {
  color: var(--color-orange);
  border: 1px solid var(--color-orange) !important;
}
.normal-input[data-v-9e00d57f]:active {
  border: 1px solid var(--color-blue) !important;
}
.yt-forgot-pass-vie-icn[data-v-9e00d57f] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-forgot-pass-vie-icn2[data-v-9e00d57f] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.pass_success[data-v-9e00d57f] {
  color: green;
  text-align: center;
}
.yt-fp-inner-content.yt-banner-col[data-v-9e00d57f] {
  width: 45%;
}
.yt-fp-inner-content.yt-form-col[data-v-9e00d57f] {
  padding-left: 40px;
  width: 55%;
}
.invalid[data-v-9e00d57f] {
  color: var(--color-orange);
  text-align: center;
}
.otp-div-style[data-v-9e00d57f] {
  text-align: center;
  margin-top: 20px;
}
.invalid-feedback[data-v-9e00d57f] {
  text-align: center;
}
/**/
@media (min-width: 1400px) {
.yt-frgt-row .yt-banner-col[data-v-9e00d57f],
  .yt-frgt-row .yt-form-col[data-v-9e00d57f] {
    width: 50%;
}
.yt-forgot-pw-wrap[data-v-9e00d57f] {
    max-width: 1340px;
}
.col-xxl-7[data-v-9e00d57f] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xxl-5[data-v-9e00d57f] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-forgot-pw-wrap[data-v-9e00d57f] {
    max-width: 588px;
    padding: 0;
}
.yt-frgt-row .yt-fp-inner-content[data-v-9e00d57f] {
    width: 100%;
}
.yt-frgt-row .yt-fp-bg-banner[data-v-9e00d57f] {
    order: 2;
}
.yt-fp-inner-content.yt-form-col[data-v-9e00d57f] {
    padding: 32px 30px 30px;
}
.yt-fp-top-back-tag-name[data-v-9e00d57f] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-fp-tag-line[data-v-9e00d57f] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 20px;
    margin-top: 23px;
}
.yt-fp-tag-line-2[data-v-9e00d57f] {
    margin-bottom: 34px;
    margin-top: 0;
}
.yt-fp-form .form-group .form-control[data-v-9e00d57f] {
    padding-top: 18px;
    padding-bottom: 17px;
    font-size: 14px;
    line-height: 1.14;
}
.yt-fp-form .form-group[data-v-9e00d57f] {
    margin-bottom: 20px;
}
.yt-fp-btn[data-v-9e00d57f] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
}
.yt-fp-bg-banner[data-v-9e00d57f] {
    min-height: 211px;
    background-position: 100% 35%;
}
.yt-fp-oi-tag-small[data-v-9e00d57f] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
}
.yt-fp-oi-tag-big[data-v-9e00d57f] {
    font-size: 48px;
    line-height: 1.15;
    margin: 0;
}
.yt-fp-sp-now[data-v-9e00d57f] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 30px;
}
.yt-fp-bdr[data-v-9e00d57f] {
    bottom: 55px !important;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-fp-inner-content.yt-form-col[data-v-9e00d57f] {
    padding: 15px;
}
.yt-forgot-pw-wrap[data-v-9e00d57f] {
    max-width: 335px;
}
.yt-fp-tag-line[data-v-9e00d57f] {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 10px;
}
.yt-fp-tag-line-2[data-v-9e00d57f] {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.17;
}
.yt-fp-form .form-group .form-control[data-v-9e00d57f] {
    padding-top: 13px;
    padding-bottom: 12px;
}
.yt-fp-form[data-v-9e00d57f] {
    margin-bottom: 15px;
}
.yt-fp-oi-tag-small[data-v-9e00d57f] {
    font-size: 10px;
    line-height: 1.11;
}
.yt-fp-oi-tag-big[data-v-9e00d57f] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-fp-bg-banner[data-v-9e00d57f] {
    min-height: 180px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-fp-btn[data-v-9e00d57f] {
    font-size: 16px;
}
}

.login-wrapper[data-v-7798e2df] {
  margin: 60px auto;
  padding: 20px 30px;
  max-width: 570px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 3px 3px 30px #eee;
  background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-7798e2df] {
  border-bottom: 3px solid #8c9095;
  font-weight: bold;
  color: #000;
}
.login-wrapper .nav-item[data-v-7798e2df] {
  cursor: pointer;
  color: #989ca2;
}
.yt-forgot-pw-wrap[data-v-7798e2df] {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
}
.yt-fpp-mb-5[data-v-7798e2df] {
  margin-bottom: 50px;
}
.yt-fpp-mt-5[data-v-7798e2df] {
  margin-top: 50px;
}
.yt-pass-info[data-v-7798e2df] {
  font-size: 11px;
  line-height: 1.1;
  color: var(--color-black);
  text-align: center;
}
.yt-cp-btn[data-v-7798e2df] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
  transition: .5s ease;
}
.yt-cp-btn[data-v-7798e2df]:hover{
  background-color: black;
  color: white;
}
.yt-fp-top-back-tag-name[data-v-7798e2df] {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
}
.yt-fp-back-icn[data-v-7798e2df] {
  font-size: 40px;
  margin-left: -12px;
}
.yt-fp-bg-banner[data-v-7798e2df] {
  background-image: url(/static/media/login-image.82f445ed.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-fp-other-info[data-v-7798e2df] {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-fp-oi-tag-small[data-v-7798e2df] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-fp-oi-tag-big[data-v-7798e2df] {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-fp-bdr[data-v-7798e2df] {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-fp-sp-now[data-v-7798e2df] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}
.yt-fp-tag-line[data-v-7798e2df] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 40px;
  margin-top: 16px;
}
.yt-fp-tag-line-2[data-v-7798e2df] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 40px;
}
.yt-fp-form .form-group[data-v-7798e2df] {
  position: relative;
  margin-bottom: 30px;
}
.yt-fp-icn[data-v-7798e2df] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn1[data-v-7798e2df] {
  max-width: 24px;
  position: absolute;
  top: 25%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-7798e2df] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-form[data-v-7798e2df] {
  margin-bottom: 30px;
}
.yt-fp-form .form-group .form-control[data-v-7798e2df] {
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.yt-fp-btn[data-v-7798e2df] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  border: 1px solid black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
}
.yt-fp-btn[data-v-7798e2df]:hover {
  background-color: black;
  color: white;
}
.yt-fp-skip-btn[data-v-7798e2df] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.otp-timer[data-v-7798e2df] {
  font-size: 22px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}
.yt-resent-otp-btn[data-v-7798e2df] {
  font-size: 16px;
  line-height: 1.14;
  color: var(--color-black);
  box-shadow: none !important;
}
.yt-fp-form .error.form-group .form-control[data-v-7798e2df] {
  border: solid 1px var(--color-orange);
}
.yt-optp-message-show[data-v-7798e2df] {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -20px;
  left: 0;
}
.yt-fp-form .error.form-group .yt-optp-message-show[data-v-7798e2df] {
  color: var(--color-orange);
  display: block;
}
.yt-pas-eye-icn[data-v-7798e2df] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-pas-eye-icn2[data-v-7798e2df] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-fp-form .success.form-group .yt-optp-message-show[data-v-7798e2df] {
  color: var(--color-green);
  display: block;
}
.error.form-group[data-v-7798e2df],
.success.form-group[data-v-7798e2df] {
  margin-bottom: 43px !important;
}
.invalid[data-v-7798e2df] {
  color: var(--color-orange);
}
.invalid-input[data-v-7798e2df] {
  color: var(--color-orange);
  border: 1px solid var(--color-orange) !important;
}
.normal-input[data-v-7798e2df]:active {
  border: 1px solid var(--color-blue) !important;
}
.yt-forgot-pass-vie-icn[data-v-7798e2df] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-forgot-pass-vie-icn2[data-v-7798e2df] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.pass_success[data-v-7798e2df] {
  color: green;
  text-align: center;
}
.yt-fp-inner-content.yt-banner-col[data-v-7798e2df] {
  width: 45%;
}
.yt-fp-inner-content.yt-form-col[data-v-7798e2df] {
  padding-left: 40px;
  width: 55%;
}
.invalid[data-v-7798e2df] {
  color: var(--color-orange);
  text-align: center;
}
.otp-div-style[data-v-7798e2df] {
  text-align: center;
  margin-top: 20px;
}
.invalid-feedback[data-v-7798e2df] {
  text-align: center;
}
/**/
@media (min-width: 1400px) {
.yt-frgt-row .yt-banner-col[data-v-7798e2df],
  .yt-frgt-row .yt-form-col[data-v-7798e2df] {
    width: 50%;
}
.yt-forgot-pw-wrap[data-v-7798e2df] {
    max-width: 1340px;
}
.col-xxl-7[data-v-7798e2df] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xxl-5[data-v-7798e2df] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-forgot-pw-wrap[data-v-7798e2df] {
    max-width: 588px;
    padding: 0;
}
.yt-frgt-row .yt-fp-inner-content[data-v-7798e2df] {
    width: 100%;
}
.yt-frgt-row .yt-fp-bg-banner[data-v-7798e2df] {
    order: 2;
}
.yt-fp-inner-content.yt-form-col[data-v-7798e2df] {
    padding: 32px 30px 30px;
}
.yt-fp-top-back-tag-name[data-v-7798e2df] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-fp-tag-line[data-v-7798e2df] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 20px;
    margin-top: 23px;
}
.yt-fp-tag-line-2[data-v-7798e2df] {
    margin-bottom: 34px;
    margin-top: 0;
}
.yt-fp-form .form-group .form-control[data-v-7798e2df] {
    padding-top: 18px;
    padding-bottom: 17px;
    font-size: 14px;
    line-height: 1.14;
}
.yt-fp-form .form-group[data-v-7798e2df] {
    margin-bottom: 20px;
}
.yt-fp-btn[data-v-7798e2df] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
}
.yt-fp-bg-banner[data-v-7798e2df] {
    min-height: 211px;
    background-position: 100% 35%;
}
.yt-fp-oi-tag-small[data-v-7798e2df] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
}
.yt-fp-oi-tag-big[data-v-7798e2df] {
    font-size: 48px;
    line-height: 1.15;
    margin: 0;
}
.yt-fp-sp-now[data-v-7798e2df] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 30px;
}
.yt-fp-bdr[data-v-7798e2df] {
    bottom: 55px !important;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-fp-inner-content.yt-form-col[data-v-7798e2df] {
    padding: 15px;
}
.yt-forgot-pw-wrap[data-v-7798e2df] {
    max-width: 335px;
}
.yt-fp-tag-line[data-v-7798e2df] {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 10px;
}
.yt-fp-tag-line-2[data-v-7798e2df] {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.17;
}
.yt-fp-form .form-group .form-control[data-v-7798e2df] {
    padding-top: 13px;
    padding-bottom: 12px;
}
.yt-fp-form[data-v-7798e2df] {
    margin-bottom: 15px;
}
.yt-fp-oi-tag-small[data-v-7798e2df] {
    font-size: 10px;
    line-height: 1.11;
}
.yt-fp-oi-tag-big[data-v-7798e2df] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-fp-bg-banner[data-v-7798e2df] {
    min-height: 180px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-fp-btn[data-v-7798e2df] {
    font-size: 16px;
}
}

.yt-create-pw-wrap[data-v-51cb1e46] {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1090px;
}
.yt-cpp-mb-5[data-v-51cb1e46] {
  margin-bottom: 50px;
}
.yt-cpp-mt-5[data-v-51cb1e46] {
  margin-top: 50px;
}
.yt-cp-top-back-tag-name[data-v-51cb1e46] {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: var(--color-blue);
}
.yt-cp-back-icn[data-v-51cb1e46] {
  font-size: 40px;
}
.yt-cp-bg-banner[data-v-51cb1e46] {
  background-image: url(/static/media/login-image.82f445ed.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-cp-other-info[data-v-51cb1e46] {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-cp-oi-tag-small[data-v-51cb1e46] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-cp-oi-tag-big[data-v-51cb1e46] {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-cp-bdr[data-v-51cb1e46] {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-cp-sp-now[data-v-51cb1e46] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}
.yt-pas-eye-icn[data-v-51cb1e46] {
  position: absolute;
  top: 14px;
  right: 25px;
  color: var(--color-dark-grey);
}
.yt-cp-tag-line[data-v-51cb1e46] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 40px;
  margin-top: 30px;
}
.yt-cp-form .form-group[data-v-51cb1e46] {
  position: relative;
  margin-bottom: 30px;
}
.yt-cp-icn[data-v-51cb1e46] {
  max-width: 24px;
  position: absolute;
  top: 18px;
  left: 20px;
}
.yt-cp-form .form-group .form-control[data-v-51cb1e46] {
  padding-left: 65px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
}
.yt-pass-info[data-v-51cb1e46] {
  font-size: 11px;
  line-height: 1.1;
  color: var(--color-black);
}
.yt-reenter-pass[data-v-51cb1e46] {
  font-size: 13px;
  line-height: 1.17;
}
.yt-cp-btn[data-v-51cb1e46] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
}
.yt-cp-btn[data-v-51cb1e46]:hover{
  background-color: black;
  color: white;
}
.yt-cp-skip-btn[data-v-51cb1e46] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-cp-form .error.form-group .form-control[data-v-51cb1e46] {
  border: solid 1px var(--color-orange);
}
.yt-cp-form .error.form-group .yt-reenter-pass[data-v-51cb1e46] {
  color: var(--color-orange);
}
.yt-cp-form .success.form-group .yt-reenter-pass[data-v-51cb1e46] {
  color: var(--color-green);
}
.yt-cp-inner-content.yt-banner-col[data-v-51cb1e46] {
  width: 45%;
}
.yt-cp-inner-content.yt-form-col[data-v-51cb1e46] {
  padding-left: 40px;
  width: 55%;
}
@media (min-width: 1900px) {
.yt-cret-row .yt-banner-col[data-v-51cb1e46],
  .yt-cret-row .yt-form-col[data-v-51cb1e46] {
    width: 50%;
}
.yt-create-pw-wrap[data-v-51cb1e46] {
    max-width: 1340px;
}
.col-xxl-7[data-v-51cb1e46] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xxl-5[data-v-51cb1e46] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-create-pw-wrap[data-v-51cb1e46] {
    max-width: 588px;
    padding: 0;
    margin-top: 37px;
}
.yt-cret-row .yt-cp-inner-content[data-v-51cb1e46] {
    width: 100%;
}
.yt-cret-row .yt-cp-bg-banner[data-v-51cb1e46] {
    order: 2;
}
.yt-cp-inner-content.yt-form-col[data-v-51cb1e46] {
    padding: 32px 30px 20px;
}
.yt-cp-top-back-tag-name[data-v-51cb1e46] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-cp-tag-line[data-v-51cb1e46] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 20px;
    margin-top: 23px;
}
.yt-cp-tag-line-2[data-v-51cb1e46] {
    margin-bottom: 34px;
    margin-top: 0;
}
.yt-cp-form .form-group .form-control[data-v-51cb1e46] {
    padding-top: 18px;
    padding-bottom: 17px;
    font-size: 14px;
    line-height: 1.14;
}
.yt-cp-form .form-group[data-v-51cb1e46] {
    margin-bottom: 20px;
}
.yt-cp-btn[data-v-51cb1e46] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px;
    padding-bottom: 11px;
}
.yt-cp-bg-banner[data-v-51cb1e46] {
    min-height: 211px;
    background-position: 100% 35%;
}
.yt-cp-oi-tag-small[data-v-51cb1e46] {
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 11px;
}
.yt-cp-oi-tag-big[data-v-51cb1e46] {
    font-size: 48px;
    line-height: 1.15;
    margin: 0;
}
.yt-cp-sp-now[data-v-51cb1e46] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-top: 30px;
}
.yt-cp-bdr[data-v-51cb1e46] {
    bottom: 55px !important;
}
.yt-cp-form[data-v-51cb1e46] {
    margin-bottom: 0 !important;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-cp-inner-content.yt-form-col[data-v-51cb1e46] {
    padding: 15px 15px 10px;
}
.yt-create-pw-wrap[data-v-51cb1e46] {
    max-width: 335px;
    margin-top: 10px;
}
.yt-cp-tag-line[data-v-51cb1e46] {
    font-size: 12px;
    line-height: 1.17;
    margin-top: 10px;
}
.yt-cp-tag-line-2[data-v-51cb1e46] {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 1.17;
}
.yt-cp-form .form-group .form-control[data-v-51cb1e46] {
    padding-top: 13px;
    padding-bottom: 12px;
}
.yt-cp-form[data-v-51cb1e46] {
    margin-bottom: 15px;
}
.yt-cp-oi-tag-small[data-v-51cb1e46] {
    font-size: 10px;
    line-height: 1.11;
}
.yt-cp-oi-tag-big[data-v-51cb1e46] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-cp-bg-banner[data-v-51cb1e46] {
    min-height: 180px;
}
}

/*
* Global CSS of Help Center
*/
.p-40[data-v-80bfd5d6] {
  padding: 40px;
}
/*
* Breadcrumbs CSS
*/
.yt-mb-80[data-v-80bfd5d6] {
  margin-bottom: 80px;
}
.profile-pg-breadcrumbs[data-v-80bfd5d6] {
  color: var(--color-dark-grey);
  font-size: 15px;
  line-height: 1.17;
}
.profile-pg-current[data-v-80bfd5d6] {
  color: var(--color-black);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.17;
}
.profile-pg-beckfrom-ct-page[data-v-80bfd5d6] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.profile-pg-mb-30[data-v-80bfd5d6] {
  margin-bottom: 30px;
}
.profile-pg-mt-30[data-v-80bfd5d6] {
  margin-bottom: 30px;
}
.profile-pg-mb-50[data-v-80bfd5d6] {
  margin-bottom: 50px;
}
.radius-10[data-v-80bfd5d6] {
  border-radius: 10px;
}
.list-style-none[data-v-80bfd5d6] {
  list-style: none;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-80bfd5d6]:first-child {
  padding-top: 0;
}
/*
* Left Col CSS
*/
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-80bfd5d6] {
  padding: 15px 0;
  border-bottom: 2px solid #e6e6e6;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
  cursor: pointer;
  position: relative;
  font-weight: 500;
}
.profile-pg-inner-contnet .pg-profile-box li:first-child.active[data-v-80bfd5d6]::after {
  opacity: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-80bfd5d6]::after {
  content: "";
  height: 75%;
  width: 3px;
  background: black;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-80bfd5d6]:last-child {
  border: none;
  padding-bottom: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-80bfd5d6] {
  font-weight: bold;
  color: black;
}

/*
* Right Col CSS
*/
.profile-pg-tab-main-title[data-v-80bfd5d6] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.profile-pg-tab-sub-title[data-v-80bfd5d6] {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
}
.ht-tab-description[data-v-80bfd5d6] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}

/*
*/
.img-upload svg[data-v-80bfd5d6] {
  font-size: 34px;
  color: var(--color-dark-black);
}
.img-upload[data-v-80bfd5d6] {
  width: 109px;
  min-width: 109px;
  height: 109px;
  background-color: #f6f8fa;
  border-radius: 6px;
}
.profile-name[data-v-80bfd5d6] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  margin-right: 0;
}
.profile-email[data-v-80bfd5d6] {
  line-height: 1.13;
  font-size: 16px;
  color: var(--color-dark-grey);
  word-break: break-all;
}
.profile-item-wrap[data-v-80bfd5d6] {
  position: relative;
}
.profile-notiy[data-v-80bfd5d6] {
  position: absolute;
  background-color: var(--color-dark-black);
  font-size: 12px;
  line-height: 1.15;
  color: #ffffff;
  padding: 5px 3px;
  border-radius: 100%;
  top: -5px;
  left: 45px;
  width: 22px;
  height: 22px;
  text-align: center;
}
.profile-pg-title[data-v-80bfd5d6] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  padding: 0 20px;
}
.profile-data-tag[data-v-80bfd5d6] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-user-name[data-v-80bfd5d6] {
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
  border-bottom: 2px solid #dbdbdb;
}
.profile-edit-btn[data-v-80bfd5d6] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #fff;
  width: 100%;
  max-width: 180px;
}
.profile-edit-btn[data-v-80bfd5d6]:hover{
  background-color: white;
  color: black;
  border: solid 1px black
}
.profile-edit-pass[data-v-80bfd5d6] {
  color: var(--color-dark-black);
}
.profile-edit-pass[data-v-80bfd5d6],
.profile-edit-btn[data-v-80bfd5d6] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  padding: 15px 30px;
}
.yt-cm-lt-col[data-v-80bfd5d6] {
  padding-right: 7px;
  max-width: 480px;
}
.yt-cm-rt-col[data-v-80bfd5d6] {
  padding-left: 7px;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-80bfd5d6],
.profile-pg-inner-wrap.profile-inner-tab-content[data-v-80bfd5d6],
.profile-pg-inner-wrap[data-v-80bfd5d6] {
  border: solid 1px #dbdbdb;
}
@media (min-width: 768px) {
.yt-profile-mb-ttl[data-v-80bfd5d6] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-80bfd5d6] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-80bfd5d6] {
    padding-left: 10px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-80bfd5d6] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-80bfd5d6] {
    padding-left: 20px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.profile-name[data-v-80bfd5d6] {
    font-size: 21px;
    line-height: 1.14;
}
.profile-pg-title[data-v-80bfd5d6] {
    font-size: 26px;
    line-height: 1.12;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-80bfd5d6] {
    padding: 20px;
}
.profile-pg-inner-wrap.profile-inner-tab-content[data-v-80bfd5d6] {
    padding: 27px 30px;
}
.profile-notiy[data-v-80bfd5d6] {
    font-size: 12px;
    line-height: 1.17;
}
.profile-edit-pass[data-v-80bfd5d6],
  .profile-edit-btn[data-v-80bfd5d6] {
    font-size: 16px;
    line-height: 1.13;
}
.profile-pg-breadcrumbs[data-v-80bfd5d6] {
    margin-bottom: 30px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-item-wrap .profile-item-name[data-v-80bfd5d6] {
    font-size: 14px;
    line-height: 1.14;
}
.profile-item-wrap .profile-item-icn[data-v-80bfd5d6] {
    max-width: 46px;
    margin-right: 17px !important;
}
.profile-pg-inner-wrap[data-v-80bfd5d6],
  .tab-content .profile-pg-inner-wrap[data-v-80bfd5d6] {
    padding: 15px !important;
}
.profile-inner-tab-content[data-v-80bfd5d6] {
    padding: 18px 15px 15px !important;
}
.profile-notiy[data-v-80bfd5d6] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-cm-lt-col[data-v-80bfd5d6] {
    max-width: 250px;
    box-sizing: content-box;
}
.img-upload[data-v-80bfd5d6] {
    min-width: 36px;
    height: 43px;
}
.yt-profile-img-nm-wrap .img-upload[data-v-80bfd5d6] {
    width: 90px;
    height: 90px;
}
.yt-profile-img-nm-wrap[data-v-80bfd5d6] {
    margin-bottom: 20px !important;
}
.img-upload svg[data-v-80bfd5d6] {
    font-size: 16px;
}
.profile-data-wrap[data-v-80bfd5d6] {
    max-width: 330px;
}
.profile-data-tag[data-v-80bfd5d6] {
    font-size: 12px;
    line-height: 1.17;
}
.profile-edit-btn[data-v-80bfd5d6] {
    padding-top: 14px;
    padding-bottom: 14px;
}
.profile-edit-pass[data-v-80bfd5d6],
  .profile-edit-btn[data-v-80bfd5d6] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.profile-name[data-v-80bfd5d6] {
    font-size: 20px;
    line-height: 1.15;
}
.profile-email[data-v-80bfd5d6] {
    font-size: 12px;
    line-height: 1.17;
}
.user-profileinfo[data-v-80bfd5d6] {
    margin-left: 12px !important;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-80bfd5d6] {
    margin-bottom: 10px;
}
.profile-pg-title[data-v-80bfd5d6] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 14px !important;
}
.yt-cm-ptc-col[data-v-80bfd5d6] {
    flex: 0 0 100%;
    max-width: 100%;
}
.profile-pg-breadcrumbs[data-v-80bfd5d6] {
    margin-bottom: 58px;
}
.yt-profile-img-nm-wrap .img-upload svg[data-v-80bfd5d6] {
    font-size: 34px;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-80bfd5d6]::after {
    right: -15px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-title[data-v-80bfd5d6] {
    margin-bottom: 10px !important;
}
.img-upload svg[data-v-80bfd5d6] {
    font-size: 34px;
}
.profile-pg-title[data-v-80bfd5d6] {
    font-size: 18px;
    line-height: 1.11;
    padding-left: 8px;
}
.profile-pg-breadcrumbs[data-v-80bfd5d6] {
    margin-bottom: 10px;
}
.profile-pg-inner-wrap[data-v-80bfd5d6] {
    padding: 15px;
}
.yt-profile-mb-ttl.profile-pg-title[data-v-80bfd5d6] {
    display: block;
}
.yt-cm-row[data-v-80bfd5d6] {
    display: block;
}
.yt-cm-row .yt-cm-rt-col[data-v-80bfd5d6],
  .yt-cm-row .yt-cm-lt-col[data-v-80bfd5d6] {
    width: 100%;
    max-width: 100%;
}
.yt-cm-row.row .yt-cm-rt-col[data-v-80bfd5d6],
  .yt-cm-row.row .yt-cm-lt-col[data-v-80bfd5d6] {
    padding-right: 6px;
    padding-left: 6px;
}
.yt-cm-row.row .yt-cm-lt-col[data-v-80bfd5d6] {
    display: none;
}
.profile-edit-pass[data-v-80bfd5d6] {
    padding: 0;
    margin-right: auto !important;
}
.profile-edit-pass[data-v-80bfd5d6],
  .profile-edit-btn[data-v-80bfd5d6] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.profile-user-name[data-v-80bfd5d6] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 24px;
}
.profile-data-tag[data-v-80bfd5d6] {
    font-size: 13px;
    line-height: 1.15;
}
.yt-profile-img-nm-wrap[data-v-80bfd5d6] {
    margin-bottom: 24px !important;
}
}

/*
* Global CSS of Help Center
*/
.p-40[data-v-69356ca3] {
  padding: 40px;
}
/*
* Breadcrumbs CSS
*/
.yt-mb-80[data-v-69356ca3] {
  margin-bottom: 80px;
}
.profile-pg-breadcrumbs[data-v-69356ca3] {
  color: var(--color-dark-grey);
  font-size: 15px;
  line-height: 1.17;
}
.profile-pg-current[data-v-69356ca3] {
  color: var(--color-black);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.17;
}
.profile-pg-beckfrom-ct-page[data-v-69356ca3] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.profile-pg-mb-30[data-v-69356ca3] {
  margin-bottom: 30px;
}
.profile-pg-mt-30[data-v-69356ca3] {
  margin-bottom: 30px;
}
.profile-pg-mb-50[data-v-69356ca3] {
  margin-bottom: 50px;
}
.radius-10[data-v-69356ca3] {
  border-radius: 10px;
}
.list-style-none[data-v-69356ca3] {
  list-style: none;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-69356ca3]:first-child {
  padding-top: 0;
}
/*
* Left Col CSS
*/
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-69356ca3] {
  padding: 15px 0;
  border-bottom: 2px solid #e6e6e6;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
  cursor: pointer;
  position: relative;
  font-weight: 500;
}
.profile-pg-inner-contnet .pg-profile-box li:first-child.active[data-v-69356ca3]::after {
  opacity: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-69356ca3]::after {
  content: "";
  height: 75%;
  width: 3px;
  background: black;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-69356ca3]:last-child {
  border: none;
  padding-bottom: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-69356ca3] {
  font-weight: bold;
  color: black;
}

/*
* Right Col CSS
*/
.profile-pg-tab-main-title[data-v-69356ca3] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.profile-pg-tab-sub-title[data-v-69356ca3] {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
}
.ht-tab-description[data-v-69356ca3] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}

/*
*/
.img-upload svg[data-v-69356ca3] {
  font-size: 34px;
  color: var(--color-dark-black);
}
.img-upload[data-v-69356ca3] {
  width: 109px;
  min-width: 109px;
  height: 109px;
  background-color: #f6f8fa;
  border-radius: 6px;
}
.profile-name[data-v-69356ca3] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  margin-right: 0;
}
.profile-email[data-v-69356ca3] {
  line-height: 1.13;
  font-size: 16px;
  color: var(--color-dark-grey);
  word-break: break-all;
}
.profile-item-wrap[data-v-69356ca3] {
  position: relative;
}
.profile-notiy[data-v-69356ca3] {
  position: absolute;
  background-color: var(--color-dark-black);
  font-size: 12px;
  line-height: 1.15;
  color: #ffffff;
  padding: 5px 3px;
  border-radius: 100%;
  top: -5px;
  left: 45px;
  width: 22px;
  height: 22px;
  text-align: center;
}
.profile-pg-title[data-v-69356ca3] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  padding: 0 20px;
}
.profile-data-tag[data-v-69356ca3] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-user-name[data-v-69356ca3] {
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
  border-bottom: 2px solid #dbdbdb;
}
.profile-edit-btn[data-v-69356ca3] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #fff;
  width: 100%;
  max-width: 180px;
}
.profile-edit-btn[data-v-69356ca3]:hover{
  background-color: white;
  color: black;
  border: solid 1px black
}
.profile-edit-pass[data-v-69356ca3] {
  color: var(--color-dark-black);
}
.profile-edit-pass[data-v-69356ca3],
.profile-edit-btn[data-v-69356ca3] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  padding: 15px 30px;
}
.yt-cm-lt-col[data-v-69356ca3] {
  padding-right: 7px;
  max-width: 480px;
}
.yt-cm-rt-col[data-v-69356ca3] {
  padding-left: 7px;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-69356ca3],
.profile-pg-inner-wrap.profile-inner-tab-content[data-v-69356ca3],
.profile-pg-inner-wrap[data-v-69356ca3] {
  border: solid 1px #dbdbdb;
}
@media (min-width: 768px) {
.yt-profile-mb-ttl[data-v-69356ca3] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-69356ca3] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-69356ca3] {
    padding-left: 10px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-69356ca3] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-69356ca3] {
    padding-left: 20px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.profile-name[data-v-69356ca3] {
    font-size: 21px;
    line-height: 1.14;
}
.profile-pg-title[data-v-69356ca3] {
    font-size: 26px;
    line-height: 1.12;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-69356ca3] {
    padding: 20px;
}
.profile-pg-inner-wrap.profile-inner-tab-content[data-v-69356ca3] {
    padding: 27px 30px;
}
.profile-notiy[data-v-69356ca3] {
    font-size: 12px;
    line-height: 1.17;
}
.profile-edit-pass[data-v-69356ca3],
  .profile-edit-btn[data-v-69356ca3] {
    font-size: 16px;
    line-height: 1.13;
}
.profile-pg-breadcrumbs[data-v-69356ca3] {
    margin-bottom: 30px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-item-wrap .profile-item-name[data-v-69356ca3] {
    font-size: 14px;
    line-height: 1.14;
}
.profile-item-wrap .profile-item-icn[data-v-69356ca3] {
    max-width: 46px;
    margin-right: 17px !important;
}
.profile-pg-inner-wrap[data-v-69356ca3],
  .tab-content .profile-pg-inner-wrap[data-v-69356ca3] {
    padding: 15px !important;
}
.profile-inner-tab-content[data-v-69356ca3] {
    padding: 18px 15px 15px !important;
}
.profile-notiy[data-v-69356ca3] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-cm-lt-col[data-v-69356ca3] {
    max-width: 250px;
    box-sizing: content-box;
}
.img-upload[data-v-69356ca3] {
    min-width: 36px;
    height: 43px;
}
.yt-profile-img-nm-wrap .img-upload[data-v-69356ca3] {
    width: 90px;
    height: 90px;
}
.yt-profile-img-nm-wrap[data-v-69356ca3] {
    margin-bottom: 20px !important;
}
.img-upload svg[data-v-69356ca3] {
    font-size: 16px;
}
.profile-data-wrap[data-v-69356ca3] {
    max-width: 330px;
}
.profile-data-tag[data-v-69356ca3] {
    font-size: 12px;
    line-height: 1.17;
}
.profile-edit-btn[data-v-69356ca3] {
    padding-top: 14px;
    padding-bottom: 14px;
}
.profile-edit-pass[data-v-69356ca3],
  .profile-edit-btn[data-v-69356ca3] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.profile-name[data-v-69356ca3] {
    font-size: 20px;
    line-height: 1.15;
}
.profile-email[data-v-69356ca3] {
    font-size: 12px;
    line-height: 1.17;
}
.user-profileinfo[data-v-69356ca3] {
    margin-left: 12px !important;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-69356ca3] {
    margin-bottom: 10px;
}
.profile-pg-title[data-v-69356ca3] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 14px !important;
}
.yt-cm-ptc-col[data-v-69356ca3] {
    flex: 0 0 100%;
    max-width: 100%;
}
.profile-pg-breadcrumbs[data-v-69356ca3] {
    margin-bottom: 58px;
}
.yt-profile-img-nm-wrap .img-upload svg[data-v-69356ca3] {
    font-size: 34px;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-69356ca3]::after {
    right: -15px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-title[data-v-69356ca3] {
    margin-bottom: 10px !important;
}
.img-upload svg[data-v-69356ca3] {
    font-size: 34px;
}
.profile-pg-title[data-v-69356ca3] {
    font-size: 18px;
    line-height: 1.11;
    padding-left: 8px;
}
.profile-pg-breadcrumbs[data-v-69356ca3] {
    margin-bottom: 10px;
}
.profile-pg-inner-wrap[data-v-69356ca3] {
    padding: 15px;
}
.yt-profile-mb-ttl.profile-pg-title[data-v-69356ca3] {
    display: block;
}
.yt-cm-row[data-v-69356ca3] {
    display: block;
}
.yt-cm-row .yt-cm-rt-col[data-v-69356ca3],
  .yt-cm-row .yt-cm-lt-col[data-v-69356ca3] {
    width: 100%;
    max-width: 100%;
}
.yt-cm-row.row .yt-cm-rt-col[data-v-69356ca3],
  .yt-cm-row.row .yt-cm-lt-col[data-v-69356ca3] {
    padding-right: 6px;
    padding-left: 6px;
}
.yt-cm-row.row .yt-cm-lt-col[data-v-69356ca3] {
    display: none;
}
.profile-edit-pass[data-v-69356ca3] {
    padding: 0;
    margin-right: auto !important;
}
.profile-edit-pass[data-v-69356ca3],
  .profile-edit-btn[data-v-69356ca3] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.profile-user-name[data-v-69356ca3] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 24px;
}
.profile-data-tag[data-v-69356ca3] {
    font-size: 13px;
    line-height: 1.15;
}
.yt-profile-img-nm-wrap[data-v-69356ca3] {
    margin-bottom: 24px !important;
}
}

/*
* Global CSS of Help Center
*/
.p-40[data-v-9bfa0273] {
  padding: 40px;
}
/*
* Breadcrumbs CSS
*/
.yt-mb-80[data-v-9bfa0273] {
  margin-bottom: 80px;
}
.profile-pg-breadcrumbs[data-v-9bfa0273] {
  color: var(--color-dark-grey);
  font-size: 15px;
  line-height: 1.17;
}
.profile-pg-current[data-v-9bfa0273] {
  color: var(--color-black);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.17;
}
.profile-pg-beckfrom-ct-page[data-v-9bfa0273] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.profile-pg-mb-30[data-v-9bfa0273] {
  margin-bottom: 30px;
}
.profile-pg-mt-30[data-v-9bfa0273] {
  margin-bottom: 30px;
}
.profile-pg-mb-50[data-v-9bfa0273] {
  margin-bottom: 50px;
}
.radius-10[data-v-9bfa0273] {
  border-radius: 10px;
}
.list-style-none[data-v-9bfa0273] {
  list-style: none;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-9bfa0273]:first-child {
  padding-top: 0;
}
/*
* Left Col CSS
*/
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-9bfa0273] {
  padding: 15px 0;
  border-bottom: 2px solid #e6e6e6;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
  cursor: pointer;
  position: relative;
  font-weight: 500;
}
.profile-pg-inner-contnet .pg-profile-box li:first-child.active[data-v-9bfa0273]::after {
  opacity: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-9bfa0273]::after {
  content: "";
  height: 75%;
  width: 3px;
  background: black;
  font-weight: bold;
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
}
.profile-pg-inner-contnet .profile-pg-tabs-name li[data-v-9bfa0273]:last-child {
  border: none;
  padding-bottom: 0;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-9bfa0273] {
  font-weight: bold;
  color: black;
}

/*
* Right Col CSS
*/
.profile-pg-tab-main-title[data-v-9bfa0273] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.profile-pg-tab-sub-title[data-v-9bfa0273] {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
}
.ht-tab-description[data-v-9bfa0273] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}

/*
*/
.img-upload svg[data-v-9bfa0273] {
  font-size: 34px;
  color: var(--color-dark-black);
}
.img-upload[data-v-9bfa0273] {
  width: 109px;
  min-width: 109px;
  height: 109px;
  background-color: #f6f8fa;
  border-radius: 6px;
}
.profile-name[data-v-9bfa0273] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  margin-right: 0;
}
.profile-email[data-v-9bfa0273] {
  line-height: 1.13;
  font-size: 16px;
  color: var(--color-dark-grey);
  word-break: break-all;
}
.profile-item-wrap[data-v-9bfa0273] {
  position: relative;
}
.profile-notiy[data-v-9bfa0273] {
  position: absolute;
  background-color: var(--color-dark-black);
  font-size: 12px;
  line-height: 1.15;
  color: #ffffff;
  padding: 5px 3px;
  border-radius: 100%;
  top: -5px;
  left: 45px;
  width: 22px;
  height: 22px;
  text-align: center;
}
.profile-pg-title[data-v-9bfa0273] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  padding: 0 20px;
}
.profile-data-tag[data-v-9bfa0273] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-user-name[data-v-9bfa0273] {
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-black);
  border-bottom: 2px solid #dbdbdb;
}
.profile-edit-btn[data-v-9bfa0273] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #fff;
  width: 100%;
  max-width: 180px;
}
.profile-edit-btn[data-v-9bfa0273]:hover{
  background-color: white;
  color: black;
  border: solid 1px black
}
.profile-edit-pass[data-v-9bfa0273] {
  color: var(--color-dark-black);
}
.profile-edit-pass[data-v-9bfa0273],
.profile-edit-btn[data-v-9bfa0273] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  padding: 15px 30px;
}
.yt-cm-lt-col[data-v-9bfa0273] {
  padding-right: 7px;
  max-width: 480px;
}
.yt-cm-rt-col[data-v-9bfa0273] {
  padding-left: 7px;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-9bfa0273],
.profile-pg-inner-wrap.profile-inner-tab-content[data-v-9bfa0273],
.profile-pg-inner-wrap[data-v-9bfa0273] {
  border: solid 1px #dbdbdb;
}
@media (min-width: 768px) {
.yt-profile-mb-ttl[data-v-9bfa0273] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-9bfa0273] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-9bfa0273] {
    padding-left: 10px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-9bfa0273] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-9bfa0273] {
    padding-left: 20px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.profile-name[data-v-9bfa0273] {
    font-size: 21px;
    line-height: 1.14;
}
.profile-pg-title[data-v-9bfa0273] {
    font-size: 26px;
    line-height: 1.12;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-9bfa0273] {
    padding: 20px;
}
.profile-pg-inner-wrap.profile-inner-tab-content[data-v-9bfa0273] {
    padding: 27px 30px;
}
.profile-notiy[data-v-9bfa0273] {
    font-size: 12px;
    line-height: 1.17;
}
.profile-edit-pass[data-v-9bfa0273],
  .profile-edit-btn[data-v-9bfa0273] {
    font-size: 16px;
    line-height: 1.13;
}
.profile-pg-breadcrumbs[data-v-9bfa0273] {
    margin-bottom: 30px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-item-wrap .profile-item-name[data-v-9bfa0273] {
    font-size: 14px;
    line-height: 1.14;
}
.profile-item-wrap .profile-item-icn[data-v-9bfa0273] {
    max-width: 46px;
    margin-right: 17px !important;
}
.profile-pg-inner-wrap[data-v-9bfa0273],
  .tab-content .profile-pg-inner-wrap[data-v-9bfa0273] {
    padding: 15px !important;
}
.profile-inner-tab-content[data-v-9bfa0273] {
    padding: 18px 15px 15px !important;
}
.profile-notiy[data-v-9bfa0273] {
    font-size: 12px;
    line-height: 1.17;
}
.yt-cm-lt-col[data-v-9bfa0273] {
    max-width: 250px;
    box-sizing: content-box;
}
.img-upload[data-v-9bfa0273] {
    min-width: 36px;
    height: 43px;
}
.yt-profile-img-nm-wrap .img-upload[data-v-9bfa0273] {
    width: 90px;
    height: 90px;
}
.yt-profile-img-nm-wrap[data-v-9bfa0273] {
    margin-bottom: 20px !important;
}
.img-upload svg[data-v-9bfa0273] {
    font-size: 16px;
}
.profile-data-wrap[data-v-9bfa0273] {
    max-width: 330px;
}
.profile-data-tag[data-v-9bfa0273] {
    font-size: 12px;
    line-height: 1.17;
}
.profile-edit-btn[data-v-9bfa0273] {
    padding-top: 14px;
    padding-bottom: 14px;
}
.profile-edit-pass[data-v-9bfa0273],
  .profile-edit-btn[data-v-9bfa0273] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.profile-name[data-v-9bfa0273] {
    font-size: 20px;
    line-height: 1.15;
}
.profile-email[data-v-9bfa0273] {
    font-size: 12px;
    line-height: 1.17;
}
.user-profileinfo[data-v-9bfa0273] {
    margin-left: 12px !important;
}
.profile-pg-inner-wrap.profile-inner-tab-wrap[data-v-9bfa0273] {
    margin-bottom: 10px;
}
.profile-pg-title[data-v-9bfa0273] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 14px !important;
}
.yt-cm-ptc-col[data-v-9bfa0273] {
    flex: 0 0 100%;
    max-width: 100%;
}
.profile-pg-breadcrumbs[data-v-9bfa0273] {
    margin-bottom: 58px;
}
.yt-profile-img-nm-wrap .img-upload svg[data-v-9bfa0273] {
    font-size: 34px;
}
.profile-pg-inner-contnet .profile-pg-tabs-name li.active[data-v-9bfa0273]::after {
    right: -15px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-title[data-v-9bfa0273] {
    margin-bottom: 10px !important;
}
.img-upload svg[data-v-9bfa0273] {
    font-size: 34px;
}
.profile-pg-title[data-v-9bfa0273] {
    font-size: 18px;
    line-height: 1.11;
    padding-left: 8px;
}
.profile-pg-breadcrumbs[data-v-9bfa0273] {
    margin-bottom: 10px;
}
.profile-pg-inner-wrap[data-v-9bfa0273] {
    padding: 15px;
}
.yt-profile-mb-ttl.profile-pg-title[data-v-9bfa0273] {
    display: block;
}
.yt-cm-row[data-v-9bfa0273] {
    display: block;
}
.yt-cm-row .yt-cm-rt-col[data-v-9bfa0273],
  .yt-cm-row .yt-cm-lt-col[data-v-9bfa0273] {
    width: 100%;
    max-width: 100%;
}
.yt-cm-row.row .yt-cm-rt-col[data-v-9bfa0273],
  .yt-cm-row.row .yt-cm-lt-col[data-v-9bfa0273] {
    padding-right: 6px;
    padding-left: 6px;
}
.yt-cm-row.row .yt-cm-lt-col[data-v-9bfa0273] {
    display: none;
}
.profile-edit-pass[data-v-9bfa0273] {
    padding: 0;
    margin-right: auto !important;
}
.profile-edit-pass[data-v-9bfa0273],
  .profile-edit-btn[data-v-9bfa0273] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
.profile-user-name[data-v-9bfa0273] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 24px;
}
.profile-data-tag[data-v-9bfa0273] {
    font-size: 13px;
    line-height: 1.15;
}
.yt-profile-img-nm-wrap[data-v-9bfa0273] {
    margin-bottom: 24px !important;
}
}

/* -----Change password----- */
.custom-modal .close-btn[data-v-af497ca7] {
  cursor: pointer;
}
.custom-modal .custom-body[data-v-af497ca7] {
  padding: 20px;
}
.custom-modal .dark-btn[data-v-af497ca7] {
  display: block;
  color: #fff;
  background-color: #000;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 0;
}
.main-form .custom-label[data-v-af497ca7] {
  margin: 0;
  color: #92969b;
  font-size: 14px;
}
.main-form .custom-input[data-v-af497ca7] {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 0;
  color: #000;
  height: 30px;
  font-size: 20px;
}
.main-form .error[data-v-af497ca7] {
  margin: 8px 0 0 -3px;
  font-size: 12px;
  color: red;
}
.main-form .form-control.invalid[data-v-af497ca7] {
  color: red;
  border-color: red;
}

/* -----change password success--- */
.custom-modal[data-v-af497ca7] {
  max-width: 700px;
}
.custom-modal .custom-body[data-v-af497ca7] {
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.custom-body img[data-v-af497ca7] {
  height: 200px;
  width: 200px;
}
.custom-body h1[data-v-af497ca7] {
  font-size: 28px;
  font-weight: 500;
}
.custom-body p[data-v-af497ca7] {
  color: #8c9096;
  margin: 10px 0;
}
.custom-modal .dark-btn[data-v-af497ca7] {
  margin-top: 30px;
  color: #fff;
  background-color: #000;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 25px;
  border-radius: 5px;
}

/*
* YT Product Slider CSS
*/
.profile-pg-mb-30[data-v-2992ca32] {
  margin-bottom: 30px;
}
.profile-pg-mt-30[data-v-2992ca32] {
  margin-bottom: 30px;
}
.radius-10[data-v-2992ca32] {
  border-radius: 10px;
}
.profile-p-30[data-v-2992ca32] {
  padding: 30px;
}
.profile-p-20[data-v-2992ca32] {
  padding: 20px;
}
.profile-pg-wl-sgl-product-fav[data-v-2992ca32] {
  width: 24px;
}
.profile-pg-wl-sglproduct-fav[data-v-2992ca32] {
  color: var(--color-orange);
  cursor: pointer;
  font-size: 24px;
}
.pp-na-ttl[data-v-2992ca32] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.profile-pg-wl-sgl-product-off[data-v-2992ca32] {
  background-color: var(--color-primary);
  color: #ffffff;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.profile-pg-wl-sgl-product-cpnt[data-v-2992ca32] {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding-bottom: 46px;
  position: relative;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.45s;
}
.profile-pg-wl-sgl-component-wrapper[data-v-2992ca32] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-2992ca32] {
  outline: none !important;
  box-shadow: none;
}
.profile-pg-wl-sgl-product-add-btn.buttoncart[data-v-2992ca32] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  color: var(--color-dark-black);
  border: 1px solid var(--color-dark-black);
  background-color: transparent;
  box-shadow: none !important;
}
.pp-na-text[data-v-2992ca32] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.emptyWishlist[data-v-2992ca32] {
  margin-top: 149px;
    margin-left: 400px;
    font-size: 30px;
    font-weight: 500;
    color: black;
}
.profile-pg-wl-sgl-product-title[data-v-2992ca32] {
  font-size: 20px;
  line-height: 0.95;
  color: var(--color-dark-black);
  margin: 16px 0 14px;
}
.profile-pg-wl-sgl-price-wrap[data-v-2992ca32] {
  margin-bottom: 25px;
}
.profile-pg-wl-sgl-product-img[data-v-2992ca32] {
  width: 184px;
  height: 184px;
  background-size: cover;
  background-position: 100%;
  margin: 5px auto 0;
}
.profile-pg-wl-sgl-component-more[data-v-2992ca32] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.profile-pg-wl-sgl-comonent-top-title[data-v-2992ca32] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.profile-pg-sa-no-address-main-wrap[data-v-2992ca32] {
  padding: 35px 0;
}
.profile-pg-wl-cm-row-margin[data-v-2992ca32] {
  margin: 0 -0.5rem;
}
.profile-pg-mb-30[data-v-2992ca32] {
  margin-bottom: 30px;
}
.profile-pg-wl-sgl-product-off[data-v-2992ca32] {
  background-color: var(--color-primary);
  font-size: 18px !important;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.profile-yt-sgl-product-off[data-v-2992ca32] {
  background-color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.profile-pg-inner-no-add img[data-v-2992ca32] {
  max-height: 217px;
}
.profile-pg-inner-wrap[data-v-2992ca32] {
  padding: 20px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
.pp-no-addr-btn[data-v-2992ca32] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.13;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
}
.pp-no-addr-btn[data-v-2992ca32]:hover{
  border: 1px solid black;
  color: black;
  background-color: white;
}
.profile-pg-wl-sgl-product-add-btn.buttoncart[data-v-2992ca32] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
}
.profile-pg-wl-sgl-product-sale-price[data-v-2992ca32],
.profile-pg-wl-sgl-product-reg-price2[data-v-2992ca32] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.profile-pg-wl-sgl-product-sale-price[data-v-2992ca32] {
  color: var(--color-dark-black);
}
.profile-pg-wl-sgl-product-reg-price2[data-v-2992ca32] {
  padding-left: 8px;
  color: var(--color-dark-grey);
}
.yt-fav-circle-wrap[data-v-2992ca32] {
  box-shadow: 0 3px 6px 0 rgba(62, 69, 79, 0.1);
}
.yt-sp-top-fav-act[data-v-2992ca32] {
  position: absolute;
  top: 10px;
  right: 15px;
  left: 0;
}
.yt-product-bg-image[data-v-2992ca32] {
  width: 100%;
  height: 252px;
  background-size: cover;
  background-position: 100%;
  cursor: pointer;
  border-radius: 4px;
  border-radius: 4px;
  background-repeat: no-repeat;
}
.profile-pg-wl-sgl-product-cpnt[data-v-2992ca32]:hover {
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
}
.profile-pg-wl-sgl-product-cpnt:hover
  .profile-pg-wl-sgl-product-add-btn.buttoncart[data-v-2992ca32] {
  background: var(--color-dark-black);
  color: #fff;
}
@media (max-width: 1900px) and (min-width: 1366px) {
.profile-pg-wl-sgl-product-title[data-v-2992ca32] {
    font-size: 18px;
    line-height: 1.06;
}
.profile-pg-wl-sgl-product-add-btn.buttoncart[data-v-2992ca32] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-sa-no-address-main-wrap[data-v-2992ca32] {
    padding: 35px 0;
}
.profile-pg-wl-sgl-product-off[data-v-2992ca32] {
    font-size: 12px !important;
    line-height: 1.17;
    min-width: 35px;
}
.profile-pg-wl-sgl-product-title[data-v-2992ca32] {
    font-size: 14px;
    line-height: 0.71;
    margin: 15px 0 8px;
}
.pp-na-text[data-v-2992ca32] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-no-addr-btn[data-v-2992ca32] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.pp-na-ttl[data-v-2992ca32] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
}
.profile-pg-inner-no-add img[data-v-2992ca32] {
    margin-bottom: 39px !important;
}
.profile-pg-wl-sgl-product-sale-price[data-v-2992ca32],
  .profile-pg-wl-sgl-product-reg-price2[data-v-2992ca32] {
    font-size: 15px;
    line-height: 1.2;
}
.pp-sa-no-add-wrap[data-v-2992ca32] {
    margin-bottom: 40px !important;
}
.profile-pg-wl-sgl-product-add-btn[data-v-2992ca32] {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 12px;
    line-height: 1.17;
}
.profile-pg-wl-sgl-price-wrap[data-v-2992ca32] {
    margin-bottom: 16px;
}
.profile-pg-wl-sgl-product-cpnt[data-v-2992ca32] {
    margin-bottom: 15px !important;
}
.profile-pg-whish-lt-inner-wrap.profile-pg-inner-wrap[data-v-2992ca32] {
    padding: 20px 20px 5px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-whish-lt-inner-wrap.profile-pg-inner-wrap[data-v-2992ca32] {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
}
.profile-pg-sa-no-address-main-wrap[data-v-2992ca32] {
    padding: 18px 0 5px;
}
.profile-pg-wl-cm-row-margin[data-v-2992ca32] {
    margin: 0 -11px;
}
.profile-pg-wl-sgl-product-cpnt[data-v-2992ca32] {
    max-width: 100%;
    margin-bottom: 10px !important;
}
.pp-na-ttl[data-v-2992ca32] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
}
.pp-na-text[data-v-2992ca32] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}
.pp-no-addr-btn[data-v-2992ca32] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
}
.yt-cm-wl-col[data-v-2992ca32] {
    max-width: 50%;
}
.pp-sa-no-add-wrap[data-v-2992ca32] {
    margin-bottom: 30px !important;
}
.profile-pg-wl-cm-row-margin .yt-cm-wl-col[data-v-2992ca32]:nth-child(2n) {
    padding-left: 6.5px !important;
    padding-right: 15px !important;
}
.profile-pg-inner-no-add img[data-v-2992ca32] {
    margin-bottom: 34px !important;
}
.profile-pg-wl-cm-row-margin .yt-cm-wl-col[data-v-2992ca32]:nth-child(2n + 1) {
    padding-right: 6.5px !important;
    padding-left: 15px !important;
}
.profile-pg-wl-sgl-product-title[data-v-2992ca32] {
    font-size: 16px;
    line-height: 1.19;
    margin: 20px 0 23px;
}
.profile-pg-wl-sgl-product-sale-price[data-v-2992ca32],
  .profile-pg-wl-sgl-product-reg-price2[data-v-2992ca32] {
    font-size: 15px;
    line-height: 1.2;
}
.profile-pg-wl-sgl-product-add-btn[data-v-2992ca32] {
    font-size: 13px;
    line-height: 1.15;
}
.profile-pg-wl-sgl-price-wrap[data-v-2992ca32] {
    margin-bottom: 18px;
}
}

/*Order Detail css*/
.pp-order-product-ttl[data-v-ede3987b] {
  font-size: 24px;
  font-weight: 500;
  color: var(--color-dark-black);
  cursor: default;
  line-height: 1.67;
}
.order-ul-list-none[data-v-ede3987b] {
  list-style: none;
}
.profile-pg-order-main-wrap[data-v-ede3987b] {
  padding: 127px 0;
}
.radius-10[data-v-ede3987b] {
  border-radius: 10px;
}
.profile-mo-dt-wrap[data-v-ede3987b] {
  padding-left: 154px;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-ede3987b] {
  padding: 40px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
  position: relative;
}
/* .order-main-wrapper .profile-pg-inner-wrapper .btn.od-cancel-btn {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.69px;
  color: var(--color-orange);
} */
.btn-paddings[data-v-ede3987b]{
  padding-right: 0!important;
    padding-left: 0!important;
}
.span-style[data-v-ede3987b]{
  font-size: 18px;
    line-height: 1.11;
    letter-spacing: 0.69px;
}
.op-order-quantity[data-v-ede3987b] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.yt-pp-od-prdt-sub-ttl[data-v-ede3987b] {
  font-size: 16px;
  line-height: 1.25;
  color: var(--color-dark-grey);
  margin: 5px 0 12px;
}
.op-order-product-price[data-v-ede3987b] {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-dark-black);
}
.order-prodict-type th[data-v-ede3987b],
.order-prodict-type td[data-v-ede3987b] {
  padding: 3px;
  vertical-align: middle;
}
.order-prodict-type th[data-v-ede3987b]:first-child,
.order-prodict-type tr td[data-v-ede3987b]:first-child {
  padding-left: 0 !important;
}
.order-prodict-type th[data-v-ede3987b] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
  text-transform: uppercase;
}
.order-prodict-type td[data-v-ede3987b] {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-dark-black);
  text-transform: capitalize;
}
.od-product-img[data-v-ede3987b] {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  width: 133px;
  height: 133px;
  overflow: hidden;
}
.order-product-info[data-v-ede3987b] {
  padding-left: 20px;
}
.yt-order-wrapper-box .order-product-quanity[data-v-ede3987b] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.yt-pp-od-cancel-wrap[data-v-ede3987b] {
  position: absolute;
  bottom: 20px;
  right: 40px;
}
.yt-order-wrapper-box[data-v-ede3987b] {
  position: relative;
}
.order-number-wrap .order-tag[data-v-ede3987b],
.order-date-wrap .order-tag[data-v-ede3987b] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.order-number-wrap .order-tag-val[data-v-ede3987b],
.order-date-wrap .order-tag-val[data-v-ede3987b] {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: var(--color-dark-black);
}
.order-bdr-between[data-v-ede3987b] {
  width: 2px;
  background-color: #f6f8fa;
  margin: 0 15px;
  height: 18px;
}
.order-write-review[data-v-ede3987b] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #30458c;
}
.order-cancel-btn[data-v-ede3987b] {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.69px;
  text-align: left;
  color: var(--color-orange);
  position: absolute;
  bottom: -20px;
  right: 0;
}
.pp-od-no-ttl[data-v-ede3987b] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.pp-od-no-text.mb-0[data-v-ede3987b] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-order-btn[data-v-ede3987b] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
}
.profile-pg-inner-wrapper .yt-order-wrapper-box[data-v-ede3987b]:last-child {
  padding-bottom: 0 !important;
}
.order-product-info[data-v-ede3987b] {
  padding-left: 40px;
}
.profile-pg-order-main-wrap img[data-v-ede3987b] {
  max-height: 217px;
}
.profile-pg-inner-no-order[data-v-ede3987b] {
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
/**/
.yt-order-wrapper-box .order-product-quanity .yt-qt-prc li[data-v-ede3987b] {
  width: 100%;
  text-align: right;
}
.od-product-img img[data-v-ede3987b] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
/**/
@media (min-width: 1900px) {
.pp-order-product-ttl[data-v-ede3987b] {
    line-height: 1.67;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-ede3987b] {
    padding: 40px;
}
.order-main-wrapper .profile-mo-dt-wrap[data-v-ede3987b] {
    padding-left: 173px;
}
}
@media (max-width: 1899.98px) {
.pp-order-product-ttl[data-v-ede3987b] {
    max-width: 300px;
}
}
@media (max-width: 1900px) and (min-width: 1366px) {
.order-number-wrap .order-tag[data-v-ede3987b],
  .order-date-wrap .order-tag[data-v-ede3987b],
  .order-number-wrap .order-tag-val[data-v-ede3987b],
  .order-date-wrap .order-tag-val[data-v-ede3987b] {
    font-size: 14px;
    line-height: 1.36;
}
.order-write-review[data-v-ede3987b] {
    font-size: 16px;
    line-height: 1.19;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.order-prodict-type th[data-v-ede3987b] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.order-prodict-type td[data-v-ede3987b] {
    font-size: 16px;
    line-height: 1.31;
}
.od-product-img img[data-v-ede3987b] {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.order-product-info[data-v-ede3987b] {
    padding-left: 20px;
}
.od-cancel-btn[data-v-ede3987b] {
    
    padding-right: 0 !important;
    padding-left: 0 !important;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-order-main-wrap img[data-v-ede3987b] {
    margin-bottom: 40px !important;
}
.pp-sa-order-wrap[data-v-ede3987b] {
    margin-bottom: 40px !important;
}
.pp-od-no-ttl[data-v-ede3987b] {
    font-size: 24px;
    line-height: 1.17;
}
.pp-od-no-text[data-v-ede3987b] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-no-order-btn[data-v-ede3987b] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.profile-pg-order-main-wrap[data-v-ede3987b] {
    padding: 40px 0;
}
.od-product-img[data-v-ede3987b] {
    max-width: 70px;
    align-self: flex-start;
}
.order-product-info[data-v-ede3987b] {
    padding-left: 10px;
}
.pp-order-product-ttl[data-v-ede3987b] {
    font-size: 16px;
    line-height: 1.13;
    max-width: 200px;
}
.yt-my-order-wrap .order-prodict-type th[data-v-ede3987b] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.38px;
}
.yt-my-order-wrap .order-prodict-type td[data-v-ede3987b] {
    font-size: 16px;
    line-height: 1.31;
}
.yt-my-order-wrap .op-order-quantity[data-v-ede3987b] {
    font-size: 14px;
    line-height: 1.36;
}
.yt-my-order-wrap .op-order-product-price[data-v-ede3987b] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-order-wrapper-box .order-product-quanity[data-v-ede3987b] {
    top: 15px;
}
.yt-my-order-wrap .order-number-wrap .order-tag[data-v-ede3987b],
  .yt-my-order-wrap .order-date-wrap .order-tag[data-v-ede3987b] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .order-number-wrap .order-tag-val[data-v-ede3987b],
  .yt-my-order-wrap .order-date-wrap .order-tag-val[data-v-ede3987b] {
    font-size: 12px;
    line-height: 1.58;
}
.order-write-review[data-v-ede3987b] {
    font-size: 14px;
    line-height: 1.36;
    padding: 0;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-ede3987b] {
    padding: 18px 15px;
}
.profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord[data-v-ede3987b] {
    padding-bottom: 40px;
}
.order-bdr-between[data-v-ede3987b] {
    margin: 0 7.5px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-inner-wrap.profile-pg-inner-no-order[data-v-ede3987b] {
    padding: 20px 9px;
}
.profile-pg-order-main-wrap[data-v-ede3987b] {
    padding: 0;
}
.profile-pg-order-main-wrap img[data-v-ede3987b] {
    max-height: 165px;
    margin-bottom: 16px !important;
}
.pp-od-no-ttl[data-v-ede3987b] {
    font-size: 17px;
    line-height: 1.12;
}
.pp-od-no-text[data-v-ede3987b] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 233px;
    margin: 0 auto;
}
.pp-no-order-btn[data-v-ede3987b] {
    font-size: 14px;
    line-height: 1.14;
    width: 100%;
    max-width: 100%;
}
.pp-sa-order-wrap[data-v-ede3987b] {
    margin-bottom: 30px !important;
}
  /* yt-my-order-wrap */
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-ede3987b] {
    padding: 15px;
    position: relative;
    margin: 67px 0;
}
.yt-my-order-wrap .od-product-img[data-v-ede3987b] {
    max-width: 70px;
}
.yt-my-order-wrap .order-product-info[data-v-ede3987b] {
    padding-left: 16px;
}
.yt-my-order-wrap .pp-order-product-ttl[data-v-ede3987b] {
    font-size: 14px;
    line-height: 1.36;
    max-width: 160px;
}
.yt-my-order-wrap .order-prodict-type th[data-v-ede3987b] {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.38px;
}
.yt-my-order-wrap .order-prodict-type td[data-v-ede3987b] {
    font-size: 14px;
    line-height: 1.5;
}
.yt-my-order-wrap .op-order-quantity[data-v-ede3987b] {
    font-size: 10px;
    line-height: 1.9;
}
.yt-my-order-wrap .op-order-product-price[data-v-ede3987b] {
    font-size: 15px;
    line-height: 1.2;
}
.profile-pg-inner-wrap .yt-my-order-tdet-wrap[data-v-ede3987b] {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 10px 0px;
    align-items: flex-start !important;
}
.yt-my-order-wrap .order-number-wrap .order-tag[data-v-ede3987b],
  .yt-my-order-wrap .order-date-wrap .order-tag[data-v-ede3987b] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .order-number-wrap .order-tag-val[data-v-ede3987b],
  .yt-my-order-wrap .order-date-wrap .order-tag-val[data-v-ede3987b] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .order-bdr-between[data-v-ede3987b] {
    display: none !important;
}
.yt-my-order-wrap .profile-mo-dt-wrap[data-v-ede3987b] {
    max-width: 60%;
}
.yt-my-order-wrap .order-write-review[data-v-ede3987b] {
    padding: 0 !important;
    font-size: 14px;
    line-height: 1.36;
}
.yt-my-order-wrap .order-cancel-btn[data-v-ede3987b] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
    position: absolute;
    bottom: -25px;
    right: 0;
    padding: 0;
}
.yt-order-wrapper-box[data-v-ede3987b] {
    padding-top: 0 !important;
}
.yt-order-wrapper-box .order-product-quanity[data-v-ede3987b] {
    top: 0;
    bottom: 5px;
}
.profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord[data-v-ede3987b] {
    padding-bottom: 40px;
}
}

/*Order Detail css*/
.pp-order-product-ttl[data-v-7d92d546] {
  font-size: 24px;
  font-weight: 500;
  color: var(--color-dark-black);
  cursor: default;
  line-height: 1.67;
}
.order-ul-list-none[data-v-7d92d546] {
  list-style: none;
}
.profile-pg-order-main-wrap[data-v-7d92d546] {
  padding: 35px 0;
}
.radius-10[data-v-7d92d546] {
  border-radius: 10px;
}
.profile-mo-dt-wrap[data-v-7d92d546] {
  padding-left: 154px;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-7d92d546] {
  padding: 40px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
  position: relative;
}
/* .order-main-wrapper .profile-pg-inner-wrapper .btn.od-cancel-btn {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.69px;
  color: var(--color-orange);
} */
.btn-paddings[data-v-7d92d546]{
  padding-right: 0!important;
    padding-left: 0!important;
}
.span-style[data-v-7d92d546]{
  font-size: 18px;
    line-height: 1.11;
    letter-spacing: 0.69px;
}
.op-order-quantity[data-v-7d92d546] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.yt-pp-od-prdt-sub-ttl[data-v-7d92d546] {
  font-size: 16px;
  line-height: 1.25;
  color: var(--color-dark-grey);
  margin: 5px 0 12px;
}
.op-order-product-price[data-v-7d92d546] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-dark-black);
  cursor: pointer;
}
.op-order-product-price[data-v-7d92d546]:hover{
  text-decoration: underline;
  font-weight: bold;
}
.order-prodict-type th[data-v-7d92d546],
.order-prodict-type td[data-v-7d92d546] {
  padding: 3px;
  vertical-align: middle;
}
.order-prodict-type th[data-v-7d92d546]:first-child,
.order-prodict-type tr td[data-v-7d92d546]:first-child {
  padding-left: 0 !important;
}
.order-prodict-type th[data-v-7d92d546] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
  text-transform: uppercase;
}
.order-prodict-type td[data-v-7d92d546] {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-dark-black);
  text-transform: capitalize;
}
.od-product-img[data-v-7d92d546] {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  width: 133px;
  height: 133px;
  overflow: hidden;
}
.order-product-info[data-v-7d92d546] {
  padding-left: 20px;
}
.yt-order-wrapper-box .order-product-quanity[data-v-7d92d546] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.yt-pp-od-cancel-wrap[data-v-7d92d546] {
  position: absolute;
  bottom: 20px;
  right: 40px;
}
.yt-order-wrapper-box[data-v-7d92d546] {
  position: relative;
}
.order-number-wrap .order-tag[data-v-7d92d546],
.order-date-wrap .order-tag[data-v-7d92d546] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.order-number-wrap .order-tag-val[data-v-7d92d546],
.order-date-wrap .order-tag-val[data-v-7d92d546] {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: var(--color-dark-black);
}
.order-bdr-between[data-v-7d92d546] {
  width: 2px;
  background-color: #f6f8fa;
  margin: 0 15px;
  height: 18px;
}
.order-write-review[data-v-7d92d546] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: #30458c;
}
.order-cancel-btn[data-v-7d92d546] {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.69px;
  text-align: left;
  color: var(--color-orange);
  position: absolute;
  bottom: -20px;
  right: 0;
}
.pp-od-no-ttl[data-v-7d92d546] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.pp-od-no-text.mb-0[data-v-7d92d546] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-order-btn[data-v-7d92d546] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
}
.pp-no-order-btn[data-v-7d92d546]:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}
.profile-pg-inner-wrapper .yt-order-wrapper-box[data-v-7d92d546]:last-child {
  padding-bottom: 0 !important;
}
.order-product-info[data-v-7d92d546] {
  padding-left: 40px;
}
.profile-pg-order-main-wrap img[data-v-7d92d546] {
  max-height: 217px;
}
.profile-pg-inner-no-order[data-v-7d92d546] {
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
/**/
.yt-order-wrapper-box .order-product-quanity .yt-qt-prc li[data-v-7d92d546] {
  width: 100%;
  text-align: right;
}
.od-product-img img[data-v-7d92d546] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
/**/
@media (min-width: 1900px) {
.pp-order-product-ttl[data-v-7d92d546] {
    line-height: 1.67;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-7d92d546] {
    padding: 40px;
}
.order-main-wrapper .profile-mo-dt-wrap[data-v-7d92d546] {
    padding-left: 173px;
}
}
@media (max-width: 1899.98px) {
.pp-order-product-ttl[data-v-7d92d546] {
    max-width: 300px;
}
}
@media (max-width: 1900px) and (min-width: 1366px) {
.order-number-wrap .order-tag[data-v-7d92d546],
  .order-date-wrap .order-tag[data-v-7d92d546],
  .order-number-wrap .order-tag-val[data-v-7d92d546],
  .order-date-wrap .order-tag-val[data-v-7d92d546] {
    font-size: 14px;
    line-height: 1.36;
}
.order-write-review[data-v-7d92d546] {
    font-size: 16px;
    line-height: 1.19;
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.order-prodict-type th[data-v-7d92d546] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.order-prodict-type td[data-v-7d92d546] {
    font-size: 16px;
    line-height: 1.31;
}
.od-product-img img[data-v-7d92d546] {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.order-product-info[data-v-7d92d546] {
    padding-left: 20px;
}
.od-cancel-btn[data-v-7d92d546] {
    
    padding-right: 0 !important;
    padding-left: 0 !important;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-order-main-wrap img[data-v-7d92d546] {
    margin-bottom: 40px !important;
}
.pp-sa-order-wrap[data-v-7d92d546] {
    margin-bottom: 40px !important;
}
.pp-od-no-ttl[data-v-7d92d546] {
    font-size: 24px;
    line-height: 1.17;
}
.pp-od-no-text[data-v-7d92d546] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-no-order-btn[data-v-7d92d546] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.profile-pg-order-main-wrap[data-v-7d92d546] {
    padding: 35px 0;
}
.od-product-img[data-v-7d92d546] {
    max-width: 70px;
    align-self: flex-start;
}
.order-product-info[data-v-7d92d546] {
    padding-left: 10px;
}
.pp-order-product-ttl[data-v-7d92d546] {
    font-size: 16px;
    line-height: 1.13;
    max-width: 200px;
}
.yt-my-order-wrap .order-prodict-type th[data-v-7d92d546] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.38px;
}
.yt-my-order-wrap .order-prodict-type td[data-v-7d92d546] {
    font-size: 16px;
    line-height: 1.31;
}
.yt-my-order-wrap .op-order-quantity[data-v-7d92d546] {
    font-size: 14px;
    line-height: 1.36;
}
.yt-my-order-wrap .op-order-product-price[data-v-7d92d546] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-order-wrapper-box .order-product-quanity[data-v-7d92d546] {
    top: 15px;
}
.yt-my-order-wrap .order-number-wrap .order-tag[data-v-7d92d546],
  .yt-my-order-wrap .order-date-wrap .order-tag[data-v-7d92d546] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .order-number-wrap .order-tag-val[data-v-7d92d546],
  .yt-my-order-wrap .order-date-wrap .order-tag-val[data-v-7d92d546] {
    font-size: 12px;
    line-height: 1.58;
}
.order-write-review[data-v-7d92d546] {
    font-size: 14px;
    line-height: 1.36;
    padding: 0;
}
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-7d92d546] {
    padding: 18px 15px;
}
.profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord[data-v-7d92d546] {
    padding-bottom: 40px;
}
.order-bdr-between[data-v-7d92d546] {
    margin: 0 7.5px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-inner-wrap.profile-pg-inner-no-order[data-v-7d92d546] {
    padding: 20px 9px;
}
.profile-pg-order-main-wrap[data-v-7d92d546] {
    padding: 0;
}
.profile-pg-order-main-wrap img[data-v-7d92d546] {
    max-height: 165px;
    margin-bottom: 16px !important;
}
.pp-od-no-ttl[data-v-7d92d546] {
    font-size: 17px;
    line-height: 1.12;
}
.pp-od-no-text[data-v-7d92d546] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 233px;
    margin: 0 auto;
}
.pp-no-order-btn[data-v-7d92d546] {
    font-size: 14px;
    line-height: 1.14;
    width: 100%;
    max-width: 100%;
}
.pp-sa-order-wrap[data-v-7d92d546] {
    margin-bottom: 30px !important;
}
  /* yt-my-order-wrap */
.profile-pg-inner-wrap.yt-my-order-wrap[data-v-7d92d546] {
    padding: 15px;
    position: relative;
    margin: 67px 0;
}
.yt-my-order-wrap .od-product-img[data-v-7d92d546] {
    min-width: 120px;
}
.yt-my-order-wrap .order-product-info[data-v-7d92d546] {
    padding-left: 16px;
}
.yt-my-order-wrap .pp-order-product-ttl[data-v-7d92d546] {
    font-size: 14px;
    line-height: 1.36;
    max-width: 160px;
}
.yt-my-order-wrap .order-prodict-type th[data-v-7d92d546] {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.38px;
}
.yt-my-order-wrap .order-prodict-type td[data-v-7d92d546] {
    font-size: 14px;
    line-height: 1.5;
    word-break: break-all;
}
.yt-my-order-wrap .op-order-quantity[data-v-7d92d546] {
    font-size: 10px;
    line-height: 1.9;
}
.yt-my-order-wrap .op-order-product-price[data-v-7d92d546] {
    font-size: 15px;
    line-height: 1.2;
}
.profile-pg-inner-wrap .yt-my-order-tdet-wrap[data-v-7d92d546] {
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 10px 0px;
    align-items: flex-start !important;
}
.yt-my-order-wrap .order-number-wrap .order-tag[data-v-7d92d546],
  .yt-my-order-wrap .order-date-wrap .order-tag[data-v-7d92d546] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .order-number-wrap .order-tag-val[data-v-7d92d546],
  .yt-my-order-wrap .order-date-wrap .order-tag-val[data-v-7d92d546] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-my-order-wrap .profile-mo-dt-wrap[data-v-7d92d546] {
    max-width: 60%;
    padding-left: 0;
}
.yt-my-order-wrap .order-write-review[data-v-7d92d546] {
    padding: 0 !important;
    font-size: 14px;
    line-height: 1.36;
}
.yt-my-order-wrap .order-cancel-btn[data-v-7d92d546] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
    position: absolute;
    bottom: -25px;
    right: 0;
    padding: 0;
}
.yt-order-wrapper-box[data-v-7d92d546] {
    padding-top: 0 !important;
}
.yt-order-wrapper-box .order-product-quanity[data-v-7d92d546] {
    top: 0;
}
.profile-pg-inner-wrap.yt-my-order-wrap.yt-cc-ord[data-v-7d92d546] {
    padding-bottom: 40px;
}
}

/*Order Detail css*/
.hc-breadcrumbs[data-v-cd0257b0] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.order-details-main-wrapper[data-v-cd0257b0] {
  padding: 40px 40px 42px;
  border: solid 1px #dbdbdb;
}
.hc-current[data-v-cd0257b0] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.hc-mb-30[data-v-cd0257b0] {
  margin-bottom: 30px;
}
.hc-mb-80[data-v-cd0257b0] {
  margin-bottom: 80px;
}
.radius-10[data-v-cd0257b0] {
  border-radius: 10px;
}
.hc-beckfrom-ct-page[data-v-cd0257b0] {
  color: black;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.hcp-back-arrow[data-v-cd0257b0] {
  cursor: pointer;
}
.order-tracing-details[data-v-cd0257b0] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.order-details-status[data-v-cd0257b0] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-black);
  position: absolute;
  bottom: 23px;
  text-align: right;
  right: 0;
}
.order-details-sub-title[data-v-cd0257b0] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-dark-black);
}
.order-details-status-bar[data-v-cd0257b0] {
  border-top: 2px solid #dbdbdb;
  border-bottom: 2px solid #dbdbdb;
}
.order-step-1 .order-status-date[data-v-cd0257b0] {
  color: var(--color-dark-grey);
  padding-left: 10px;
}
.order-step-1 h4[data-v-cd0257b0],
.order-details-address-type[data-v-cd0257b0] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.19;
  color: var(--color-dark-black);
}
.order-details-address-details[data-v-cd0257b0] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
}
.order-details-status-bar .order-details-status-wrap[data-v-cd0257b0] {
  border-left: 2px solid #dbdbdb;
}
.order-details-status-bar .order-details-status-wrap li[data-v-cd0257b0] {
  position: relative;
  padding-left: 10px;
}
.order-details-status-icn[data-v-cd0257b0] {
  position: absolute;
  left: -17px;
  top: 0;
}
.order-details-contact[data-v-cd0257b0] {
  font-size: 15px;
  line-height: 1.2;
  color: var(--color-dark-black);
}
.order-details-message[data-v-cd0257b0] {
  font-size: 16px;
  line-height: 1.19;
  color: var(--color-dark-grey);
}
.order-details-status.on-the-way[data-v-cd0257b0]::after {
  content: "";
  width: 7px;
  height: 7px;
  background: var(--color-green);
  border-radius: 100%;
  display: block;
  position: absolute;
  bottom: 3px;
  left: -15px;
}
.pp-order-product-ttl[data-v-cd0257b0] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.67;
  color: var(--color-black);
}
.order-ul-list-none[data-v-cd0257b0] {
  list-style: none;
}
.op-order-quantity[data-v-cd0257b0] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.op-order-product-price[data-v-cd0257b0] {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-dark-black);
}
.order-prodict-type[data-v-cd0257b0] {
  width: auto;
}
.order-prodict-type th[data-v-cd0257b0],
.order-prodict-type td[data-v-cd0257b0] {
  padding: 3px;
  vertical-align: middle;
}
.order-prodict-type th[data-v-cd0257b0] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
  color: var(--color-dark-grey);
}
.order-prodict-type td[data-v-cd0257b0] {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-dark-black);
}
.od-product-img[data-v-cd0257b0] {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  width: 133px;
  height: 133px;
}
.order-number-wrap .order-tag[data-v-cd0257b0],
.order-date-wrap .order-tag[data-v-cd0257b0] {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.order-number-wrap .order-tag-val[data-v-cd0257b0],
.order-date-wrap .order-tag-val[data-v-cd0257b0] {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: var(--color-dark-black);
}
.yt-pp-od-prdt-sub-ttl[data-v-cd0257b0] {
  line-height: 1.25;
  font-size: 16px;
  color: var(--color-dark-grey);
  margin: 5px 0 10px;
}
.order-bdr-between[data-v-cd0257b0] {
  width: 2px;
  background-color: #f6f8fa;
  margin: 0 15px;
  height: 18px;
}
.order-write-review[data-v-cd0257b0] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-dark-black);
  text-decoration: underline;
}
.order-cancel-btn[data-v-cd0257b0] {
  font-size: 20px;
  line-height: 1.13;
  letter-spacing: 0.69px;
  text-align: left;
  color: var(--color-orange);
}
.pp-od-no-ttl[data-v-cd0257b0] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.pp-od-no-text.mb-0[data-v-cd0257b0] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.pp-no-order-btn[data-v-cd0257b0] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.order-product-info[data-v-cd0257b0] {
  padding-left: 40px;
}
.od-single-prd-details-wrap[data-v-cd0257b0] {
  position: relative;
}
.order-product-quanity[data-v-cd0257b0] {
  position: absolute;
  right: 0;
  top: 30px;
}
.order-details-main-wrapper .sinlge-mo-dt-wrap[data-v-cd0257b0] {
  padding-left: 173px;
}
.od-product-img img[data-v-cd0257b0] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
@media (min-width: 1280px) {
.od-cm-col-pad[data-v-cd0257b0] {
    padding: 0 40px;
}
}
@media (min-width: 1900px) {
.od-cm-col-pad[data-v-cd0257b0] {
    padding: 0 20px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.order-number-wrap .order-tag[data-v-cd0257b0],
  .order-date-wrap .order-tag[data-v-cd0257b0],
  .order-number-wrap .order-tag-val[data-v-cd0257b0],
  .order-date-wrap .order-tag-val[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.36;
}
.order-write-review[data-v-cd0257b0] {
    font-size: 16px;
    line-height: 1.19;
}
.order-prodict-type th[data-v-cd0257b0] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.order-prodict-type td[data-v-cd0257b0] {
    font-size: 16px;
    line-height: 1.31;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) and (min-width: 768px) {
.od-single-prd-details-wrap[data-v-cd0257b0] {
    margin-bottom: 0 !important;
    padding-bottom: 5px !important;
    padding-top: 28px !important;
}
}
@media (max-width: 1279.92px) {
.hc-beckfrom-ct-page[data-v-cd0257b0] {
    margin-bottom: 10px;
}
.order-tracing-details[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.14;
}
.order-details-sub-title[data-v-cd0257b0] {
    font-size: 18px;
    line-height: 1.11;
    margin: 5px 0 17px !important;
}
.order-details-address-details[data-v-cd0257b0] {
    color: var(--color-dark-grey);
    font-size: 14px;
    line-height: 1.36;
}
.order-details-main-wrapper[data-v-cd0257b0] {
    padding: 23px 20px 20px;
}
.order-details-main-wrapper .sinlge-mo-dt-wrap[data-v-cd0257b0] {
    padding-left: 80px;
}
.od-product-img[data-v-cd0257b0] {
    max-width: 70px;
    align-self: flex-start;
}
.order-product-info[data-v-cd0257b0] {
    padding-left: 10px;
}
.order-number-wrap .order-tag[data-v-cd0257b0],
  .order-date-wrap .order-tag[data-v-cd0257b0],
  .order-number-wrap .order-tag-val[data-v-cd0257b0],
  .order-date-wrap .order-tag-val[data-v-cd0257b0] {
    font-size: 12px;
    line-height: 1.58;
}
.order-write-review[data-v-cd0257b0] {
    padding: 0;
    font-size: 14px;
    line-height: 1.36;
}
.pp-order-product-ttl[data-v-cd0257b0] {
    font-size: 16px;
    line-height: 1.13;
}
.order-prodict-type th[data-v-cd0257b0] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.order-prodict-type td[data-v-cd0257b0] {
    font-size: 16px;
    line-height: 1.31;
}
.order-product-price[data-v-cd0257b0] {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
}
.order-details-contact[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.14;
}
.order-cancel-btn[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.order-details-main-wrapper[data-v-cd0257b0] {
    padding: 15px;
    position: relative;
    margin: 67px 0 !important;
    padding-bottom: 55px;
}
.order-details-main-wrapper .sinlge-mo-dt-wrap[data-v-cd0257b0] {
    padding: 0;
    max-width: 60%;
}
.order-bdr-between[data-v-cd0257b0] {
    display: none;
}
.order-write-review[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.36;
    padding: 0;
    text-decoration: none;
}
.order-number-wrap .order-tag[data-v-cd0257b0],
  .order-date-wrap .order-tag[data-v-cd0257b0] {
    font-size: 12px;
    line-height: 1.58;
}
.order-number-wrap .order-tag-val[data-v-cd0257b0],
  .order-date-wrap .order-tag-val[data-v-cd0257b0] {
    font-size: 12px;
    line-height: 1.58;
}
.yt-sp-my-order-tdet-wrap[data-v-cd0257b0] {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: 10px 0px;
}
.od-product-img[data-v-cd0257b0] {
    max-width: 70px;
    align-self: flex-start;
}
.order-product-info[data-v-cd0257b0] {
    padding-left: 14px;
}
.pp-order-product-ttl[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.36;
    max-width: 160px;
}
.order-product-quanity[data-v-cd0257b0] {
    top: 15px;
}
.order-prodict-type th[data-v-cd0257b0] {
    font-size: 10px;
    line-height: 1.1;
}
.order-prodict-type td[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.5;
}
.op-order-product-price[data-v-cd0257b0] {
    width: 100%;
    padding-right: 0 !important;
    text-align: right;
}
.order-tracing-details[data-v-cd0257b0] {
    font-size: 12px;
    line-height: 1.17;
}
.order-details-status[data-v-cd0257b0] {
    left: 100px;
    right: auto;
    bottom: 40px;
}
.order-details-status-bar[data-v-cd0257b0] {
    margin: 0 !important;
}
.order-details-sub-title[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.36;
    margin-bottom: 26px;
    margin-top: 0;
}
.order-details-shipping-barmy-3[data-v-cd0257b0] {
    padding-top: 15px;
}
.order-details-shipping-barmy-3 .order-details-address-type[data-v-cd0257b0],
  .order-details-shipping-barmy-3 .order-details-address-details[data-v-cd0257b0] {
    font-size: 15px;
    line-height: 1.2;
}
.order-cancel-wrap[data-v-cd0257b0] {
    width: 100%;
    position: absolute;
    left: 15px;
    right: 0;
    bottom: -40px;
    text-align: left !important;
    padding: 0 !important;
}
.order-cancel-wrap .order-cancel-btn[data-v-cd0257b0] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.54px;
}
.op-order-product-price[data-v-cd0257b0] {
    font-size: 15px;
    line-height: 1.2;
}
.op-order-product-price[data-v-cd0257b0] {
    position: absolute;
    right: 0;
    bottom: 40px;
}
.order-product-info .order-ul-list-none[data-v-cd0257b0] {
    padding-top: 25px !important;
}
.order-product-quanity .order-ul-list-none[data-v-cd0257b0],
  .order-details-status-bar .order-ul-list-none[data-v-cd0257b0] {
    padding-top: 0 !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.hc-beckfrom-ct-page[data-v-cd0257b0] {
    font-size: 26px;
}
}

.pp-na-ttl[data-v-93f663fb] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.profile-pg-sa-address-main-wrap[data-v-93f663fb] {
  margin: 0 20px;
}
.pp-na-text[data-v-93f663fb] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.profile-pg-inner-wrap[data-v-93f663fb] {
  padding: 20px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
.pp-no-addr-btn[data-v-93f663fb] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.13;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
}
.pp-no-addr-btn[data-v-93f663fb]:hover{
  border: 1px solid black;
  color: black;
  background-color: white;
}
.pp-sa-list-none[data-v-93f663fb] {
  list-style: none;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-93f663fb] {
  padding-left: 32px;
}
.profile-pg-inner-no-add img[data-v-93f663fb] {
  max-height: 217px;
}
.pp-sa-action-wrap[data-v-93f663fb] {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
.profile-pg-address-list[data-v-93f663fb] {
  padding: 40px;
  position: relative;
  border: solid 1px #dbdbdb;
}
.profile-pg-sa-no-address-main-wrap[data-v-93f663fb] {
  padding: 35px 0;
}
.pp-sa-type[data-v-93f663fb] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-dark-black);
  text-transform: capitalize;
}
.pp-sa-address[data-v-93f663fb] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-grey);
}
.pp-sa-action-wrap .btn[data-v-93f663fb] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  text-decoration: underline;
}
.pp-sa-action-wrap .pp-add-delete-btn[data-v-93f663fb] {
  color: var(--color-orange);
}
.radius-10[data-v-93f663fb] {
  border-radius: 10px;
}
.profile-pg-mb-30[data-v-93f663fb] {
  margin-bottom: 30px;
}
.pp-sa-all-addres-list li[data-v-93f663fb] {
  cursor: pointer;
}
@media (max-width: 1400px) {
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-93f663fb] {
    max-width: 500px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.pp-sa-address[data-v-93f663fb] {
    font-size: 16px;
    line-height: 1.13;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-sa-no-address-main-wrap[data-v-93f663fb] {
    padding: 35px 0;
}
.profile-pg-inner-no-add img[data-v-93f663fb] {
    margin-bottom: 39px !important;
}
.pp-na-ttl[data-v-93f663fb] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
}
.pp-na-text[data-v-93f663fb] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-sa-no-add-wrap[data-v-93f663fb] {
    margin-bottom: 40px !important;
}
.pp-no-addr-btn[data-v-93f663fb] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.profile-pg-address-list[data-v-93f663fb] {
    padding: 20px;
    margin-bottom: 10px;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-93f663fb] {
    padding-left: 14px;
    margin-top: 7px;
    padding-right: 125px;
}
.pp-sa-type[data-v-93f663fb] {
    font-size: 16px;
    line-height: 1.19;
}
.pp-sa-address[data-v-93f663fb] {
    line-height: 1.36;
    font-size: 14px;
}
.pp-sa-img-wrap[data-v-93f663fb] {
    max-width: 80%;
}
.pp-sa-action-wrap .btn[data-v-93f663fb] {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 0;
    padding-right: 0;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.98px) {
.pp-sa-info-wrap[data-v-93f663fb] {
    margin: 20px 0;
}
.profile-pg-sa-no-address-main-wrap[data-v-93f663fb] {
    padding: 18px 0 5px;
}
.pp-na-ttl[data-v-93f663fb] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
}
.pp-na-text[data-v-93f663fb] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}
.profile-pg-inner-no-add img[data-v-93f663fb] {
    margin-bottom: 34px !important;
}
.pp-sa-no-add-wrap[data-v-93f663fb] {
    margin-bottom: 30px !important;
}
.pp-no-addr-btn[data-v-93f663fb] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
}
.profile-pg-address-list[data-v-93f663fb] {
    padding: 20px;
    margin-bottom: 14px;
}
.pp-sa-action-wrap[data-v-93f663fb] {
    position: static !important;
    position: initial !important;
    transform: none !important;
    width: 100%;
}
.pp-sa-action-wrap .pp-sa-delet img[data-v-93f663fb] {
    position: absolute;
    top: 20px;
    right: 20px;
}
.pp-sa-action-wrap .pp-sa-edit[data-v-93f663fb],
  .pp-sa-action-wrap .pp-sa-delet[data-v-93f663fb] {
    width: 50%;
    text-align: center !important;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-93f663fb] {
    margin: 0 0 16px !important;
    padding: 0 20px 0 15px;
}
.pp-sa-action-wrap[data-v-93f663fb] {
    padding-top: 10px;
    border-top: solid 1px #f2f6f8;
    margin-top: 16px;
}
.pp-sa-type[data-v-93f663fb] {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1.2;
}
.pp-sa-address[data-v-93f663fb] {
    font-size: 15px;
    line-height: 1.2;
}
.pp-sa-action-wrap .btn[data-v-93f663fb] {
    font-size: 15px;
    line-height: 1.2;
}
}

.pp-na-ttl[data-v-7422c6d5] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.profile-pg-sa-address-main-wrap[data-v-7422c6d5] {
  margin: 0 20px;
}
.pp-na-text[data-v-7422c6d5] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.profile-pg-inner-wrap[data-v-7422c6d5] {
  padding: 20px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
.pp-no-addr-btn[data-v-7422c6d5] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.13;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
}
.pp-no-addr-btn[data-v-7422c6d5]:hover{
  border: 1px solid black;
  color: black;
  background-color: white;
}
.pp-sa-list-none[data-v-7422c6d5] {
  list-style: none;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-7422c6d5] {
  padding-left: 32px;
}
.profile-pg-inner-no-add img[data-v-7422c6d5] {
  max-height: 217px;
}
.pp-sa-action-wrap[data-v-7422c6d5] {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
.profile-pg-address-list[data-v-7422c6d5] {
  padding: 40px;
  position: relative;
  border: solid 1px #dbdbdb;
}
.profile-pg-sa-no-address-main-wrap[data-v-7422c6d5] {
  padding: 35px 0;
}
.pp-sa-type[data-v-7422c6d5] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-dark-black);
  text-transform: capitalize;
}
.pp-sa-address[data-v-7422c6d5] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-grey);
}
.pp-sa-action-wrap .btn[data-v-7422c6d5] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  text-decoration: underline;
}
.pp-sa-action-wrap .pp-add-delete-btn[data-v-7422c6d5] {
  color: var(--color-orange);
}
.radius-10[data-v-7422c6d5] {
  border-radius: 10px;
}
.profile-pg-mb-30[data-v-7422c6d5] {
  margin-bottom: 30px;
}
.pp-sa-all-addres-list li[data-v-7422c6d5] {
  cursor: pointer;
}
@media (max-width: 1400px) {
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-7422c6d5] {
    max-width: 500px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.pp-sa-address[data-v-7422c6d5] {
    font-size: 16px;
    line-height: 1.13;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-sa-no-address-main-wrap[data-v-7422c6d5] {
    padding: 35px 0;
}
.profile-pg-inner-no-add img[data-v-7422c6d5] {
    margin-bottom: 39px !important;
}
.pp-na-ttl[data-v-7422c6d5] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
}
.pp-na-text[data-v-7422c6d5] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-sa-no-add-wrap[data-v-7422c6d5] {
    margin-bottom: 40px !important;
}
.pp-no-addr-btn[data-v-7422c6d5] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.profile-pg-address-list[data-v-7422c6d5] {
    padding: 20px;
    margin-bottom: 10px;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-7422c6d5] {
    padding-left: 14px;
    margin-top: 7px;
    padding-right: 125px;
}
.pp-sa-type[data-v-7422c6d5] {
    font-size: 16px;
    line-height: 1.19;
}
.pp-sa-address[data-v-7422c6d5] {
    line-height: 1.36;
    font-size: 14px;
}
.pp-sa-img-wrap[data-v-7422c6d5] {
    max-width: 80%;
}
.pp-sa-action-wrap .btn[data-v-7422c6d5] {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 0;
    padding-right: 0;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.98px) {
.pp-sa-info-wrap[data-v-7422c6d5] {
    margin: 20px 0;
}
.profile-pg-sa-no-address-main-wrap[data-v-7422c6d5] {
    padding: 18px 0 5px;
}
.pp-na-ttl[data-v-7422c6d5] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
}
.pp-na-text[data-v-7422c6d5] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}
.profile-pg-inner-no-add img[data-v-7422c6d5] {
    margin-bottom: 34px !important;
}
.pp-sa-no-add-wrap[data-v-7422c6d5] {
    margin-bottom: 30px !important;
}
.pp-no-addr-btn[data-v-7422c6d5] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
}
.profile-pg-address-list[data-v-7422c6d5] {
    padding: 20px;
    margin-bottom: 14px;
}
.pp-sa-action-wrap[data-v-7422c6d5] {
    position: static !important;
    position: initial !important;
    transform: none !important;
    width: 100%;
}
.pp-sa-action-wrap .pp-sa-delet img[data-v-7422c6d5] {
    position: absolute;
    top: 20px;
    right: 20px;
}
.pp-sa-action-wrap .pp-sa-edit[data-v-7422c6d5],
  .pp-sa-action-wrap .pp-sa-delet[data-v-7422c6d5] {
    width: 50%;
    text-align: center !important;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-7422c6d5] {
    margin: 0 0 16px !important;
    padding: 0 20px 0 15px;
}
.pp-sa-action-wrap[data-v-7422c6d5] {
    padding-top: 10px;
    border-top: solid 1px #f2f6f8;
    margin-top: 16px;
}
.pp-sa-type[data-v-7422c6d5] {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1.2;
}
.pp-sa-address[data-v-7422c6d5] {
    font-size: 15px;
    line-height: 1.2;
}
.pp-sa-action-wrap .btn[data-v-7422c6d5] {
    font-size: 15px;
    line-height: 1.2;
}
}

.pp-na-ttl[data-v-4d6b1f12] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.profile-pg-sa-address-main-wrap[data-v-4d6b1f12] {
  margin: 0 20px;
}
.pp-na-text[data-v-4d6b1f12] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.profile-pg-inner-wrap[data-v-4d6b1f12] {
  padding: 20px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
.pp-no-addr-btn[data-v-4d6b1f12] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.13;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
}
.pp-no-addr-btn[data-v-4d6b1f12]:hover{
  border: 1px solid black;
  color: black;
  background-color: white;
}
.pp-sa-list-none[data-v-4d6b1f12] {
  list-style: none;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-4d6b1f12] {
  padding-left: 32px;
}
.profile-pg-inner-no-add img[data-v-4d6b1f12] {
  max-height: 217px;
}
.pp-sa-action-wrap[data-v-4d6b1f12] {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}
.profile-pg-address-list[data-v-4d6b1f12] {
  padding: 40px;
  position: relative;
  border: solid 1px #dbdbdb;
}
.profile-pg-sa-no-address-main-wrap[data-v-4d6b1f12] {
  padding: 35px 0;
}
.pp-sa-type[data-v-4d6b1f12] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-dark-black);
  text-transform: capitalize;
}
.pp-sa-address[data-v-4d6b1f12] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-grey);
}
.pp-sa-action-wrap .btn[data-v-4d6b1f12] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
  text-decoration: underline;
}
.pp-sa-action-wrap .pp-add-delete-btn[data-v-4d6b1f12] {
  color: var(--color-orange);
}
.radius-10[data-v-4d6b1f12] {
  border-radius: 10px;
}
.profile-pg-mb-30[data-v-4d6b1f12] {
  margin-bottom: 30px;
}
.pp-sa-all-addres-list li[data-v-4d6b1f12] {
  cursor: pointer;
}
@media (max-width: 1400px) {
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-4d6b1f12] {
    max-width: 500px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.pp-sa-address[data-v-4d6b1f12] {
    font-size: 16px;
    line-height: 1.13;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-sa-no-address-main-wrap[data-v-4d6b1f12] {
    padding: 35px 0;
}
.profile-pg-inner-no-add img[data-v-4d6b1f12] {
    margin-bottom: 39px !important;
}
.pp-na-ttl[data-v-4d6b1f12] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
}
.pp-na-text[data-v-4d6b1f12] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-sa-no-add-wrap[data-v-4d6b1f12] {
    margin-bottom: 40px !important;
}
.pp-no-addr-btn[data-v-4d6b1f12] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.profile-pg-address-list[data-v-4d6b1f12] {
    padding: 20px;
    margin-bottom: 10px;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-4d6b1f12] {
    padding-left: 14px;
    margin-top: 7px;
    padding-right: 125px;
}
.pp-sa-type[data-v-4d6b1f12] {
    font-size: 16px;
    line-height: 1.19;
}
.pp-sa-address[data-v-4d6b1f12] {
    line-height: 1.36;
    font-size: 14px;
}
.pp-sa-img-wrap[data-v-4d6b1f12] {
    max-width: 80%;
}
.pp-sa-action-wrap .btn[data-v-4d6b1f12] {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 0;
    padding-right: 0;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.98px) {
.pp-sa-info-wrap[data-v-4d6b1f12] {
    margin: 20px 0;
}
.profile-pg-sa-no-address-main-wrap[data-v-4d6b1f12] {
    padding: 18px 0 5px;
}
.pp-na-ttl[data-v-4d6b1f12] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
}
.pp-na-text[data-v-4d6b1f12] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}
.profile-pg-inner-no-add img[data-v-4d6b1f12] {
    margin-bottom: 34px !important;
}
.pp-sa-no-add-wrap[data-v-4d6b1f12] {
    margin-bottom: 30px !important;
}
.pp-no-addr-btn[data-v-4d6b1f12] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
}
.profile-pg-address-list[data-v-4d6b1f12] {
    padding: 20px;
    margin-bottom: 14px;
}
.pp-sa-action-wrap[data-v-4d6b1f12] {
    position: static !important;
    position: initial !important;
    transform: none !important;
    width: 100%;
}
.pp-sa-action-wrap .pp-sa-delet img[data-v-4d6b1f12] {
    position: absolute;
    top: 20px;
    right: 20px;
}
.pp-sa-action-wrap .pp-sa-edit[data-v-4d6b1f12],
  .pp-sa-action-wrap .pp-sa-delet[data-v-4d6b1f12] {
    width: 50%;
    text-align: center !important;
}
.pp-sa-all-addres-list li .pp-sa-info-wrap[data-v-4d6b1f12] {
    margin: 0 0 16px !important;
    padding: 0 20px 0 15px;
}
.pp-sa-action-wrap[data-v-4d6b1f12] {
    padding-top: 10px;
    border-top: solid 1px #f2f6f8;
    margin-top: 16px;
}
.pp-sa-type[data-v-4d6b1f12] {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 1.2;
}
.pp-sa-address[data-v-4d6b1f12] {
    font-size: 15px;
    line-height: 1.2;
}
.pp-sa-action-wrap .btn[data-v-4d6b1f12] {
    font-size: 15px;
    line-height: 1.2;
}
}

.pp-ca-list-none[data-v-97b6581b] {
  list-style: none;
}
.pp-ca-tag-name[data-v-97b6581b] {
  font-size: 15px;
  line-height: 1.2;
  text-align: left;
  color: var(--color-dark-grey);
}
.pp-ca-user-name[data-v-97b6581b] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-dark-black);
}
.pp-ca-listing li.active .pp-ca-type img[data-v-97b6581b] {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.pp-ca-listing li[data-v-97b6581b] {
  padding: 30px 0;
  border-bottom: 2px solid #e6e6e6;
}
.pp-ca-listing li[data-v-97b6581b]:last-child {
  border: none;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-97b6581b] {
  padding: 40px 30px 30px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
.pp-ca-name[data-v-97b6581b] {
  min-height: 40px;
}
.radius-10[data-v-97b6581b] {
  border-radius: 10px;
}
.cursordefault[data-v-97b6581b]{
  cursor:default
}
.profile-pg-mb-30[data-v-97b6581b] {
  margin-bottom: 30px;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap .pp-ca-listing li[data-v-97b6581b]:first-child {
  padding-top: 0;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap .pp-ca-listing li[data-v-97b6581b]:last-child {
  padding-bottom: 0;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.pp-ca-type img[data-v-97b6581b] {
    width: 38px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-97b6581b] {
    padding: 13px 15px 26px;
}
.pp-ca-tag-name[data-v-97b6581b] {
    font-size: 12px;
    line-height: 1.17;
    margin-bottom: 5px !important;
}
.pp-ca-user-name[data-v-97b6581b] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-ca-listing li[data-v-97b6581b] {
    padding: 20px 0;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-97b6581b] {
    padding: 22px 20px;
}
.pp-ca-tag-name[data-v-97b6581b] {
    font-size: 12px;
    line-height: 1.17;
}
.pp-ca-user-name[data-v-97b6581b] {
    font-size: 17px;
    line-height: 1.12;
}
.pp-ca-listing li[data-v-97b6581b] {
    padding: 15px 0;
}
.pp-ca-remove[data-v-97b6581b] {
    opacity: 0.5;
}
}

.radius-10[data-v-7f7e3126] {
  border-radius: 10px;
}
.cart-pg-mb-30[data-v-7f7e3126] {
  margin-bottom: 30px;
}
.cart-pg-breadcrumbs[data-v-7f7e3126] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 30px;
}
.cartno-wrap[data-v-7f7e3126] {
  margin-top: 52px;
  margin-bottom: 40px;
}
.cart-pg-current[data-v-7f7e3126] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.empty-cartn-ttl[data-v-7f7e3126] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 17px;
}
.empty-cart-text[data-v-7f7e3126] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.empty-cart-btn[data-v-7f7e3126] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.cart-page-main-title[data-v-7f7e3126] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 30px;
}
.cart-product-image[data-v-7f7e3126] {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}
.yt-cart-pg-middle-sep[data-v-7f7e3126] {
  height: 23px;
  width: 1px;
  background: #707070;
  margin: 0 33px;
}
.cart-product-title[data-v-7f7e3126] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
  margin-bottom: 12px;
  max-width: 310px;
}
.cart-prodict-type th[data-v-7f7e3126] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
}
.cart-product-delete[data-v-7f7e3126],
.yt-cart-mv-list[data-v-7f7e3126],
.cart-prodict-type th[data-v-7f7e3126] {
  color: var(--color-dark-grey);
}
.cart-product-delete[data-v-7f7e3126]{
  cursor: pointer;
}
.cart-prodict-type th[data-v-7f7e3126],
.cart-prodict-type td[data-v-7f7e3126] {
  padding: 3px;
  vertical-align: middle;
}
.card-product-delete[data-v-7f7e3126],
.yt-cart-mv-list[data-v-7f7e3126] {
  cursor: pointer;
}
.cart-product-price[data-v-7f7e3126] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-dark-black);
}
.cart-subttl-box[data-v-7f7e3126] {
  font-size: 16px;
  line-height: 1.25;
  color: var(--color-dark-grey);
  max-width: 80%;
}
.cart-product-delete svg[data-v-7f7e3126] {
  font-size: 22px;
  color: #8d9096;
  cursor: pointer;
}
.cart-prodict-amount th[data-v-7f7e3126] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-dark-black);
}
.cart-product-amount-ttl[data-v-7f7e3126] {
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-dark-grey);
}
.cart-product-amount-price[data-v-7f7e3126] {
  font-size: 18px;
  line-height: 1.11;
  text-align: right;
  color: var(--color-dark-black);
  display: block;
}
.cart-prodict-amount thead th[data-v-7f7e3126] {
  padding-top: 0;
  padding-bottom: 18px;
  padding-left: 0;
  padding-right: 0;
}
.cart-price-column[data-v-7f7e3126]{
  min-width: 140px;
}
.cart-prodict-amount tbody td[data-v-7f7e3126],
.cart-prodict-total-amount tbody td[data-v-7f7e3126],
.ccart-prodict-sub-total-amount tbody td[data-v-7f7e3126],
.cart-prodict-sub-total-amount tbody td[data-v-7f7e3126] {
  padding: 10px 0;
}
.cart-divider[data-v-7f7e3126] {
  border-bottom: solid 1px #cae2fe !important;
  display: block;
  margin: 15px 0 20px;
}
.cart-coupon .form-group[data-v-7f7e3126] {
  position: relative;
}
.cart-coupon .form-control[data-v-7f7e3126] {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}
.cart-coupon-btn[data-v-7f7e3126] {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: #4cb122;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
  border-color: #4cb122;
}
.cart-remove-coupon-btn[data-v-7f7e3126] {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: #e0502f;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
  border-color: #e0502f;
}
.cart-proceed-to-checkput[data-v-7f7e3126] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-dark-black);
}
.cart-checkout-tile[data-v-7f7e3126] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-dark-black);
}
.cart-select-aar-btn[data-v-7f7e3126] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.checkout-form-wrap .form-group .form-control[data-v-7f7e3126] {
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb !important;
  border-radius: 0;
}
.checkout-form-label[data-v-7f7e3126] {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-dark-grey);
}
.checkout-checkbox .form-group[data-v-7f7e3126],
.yt-chekout-radio .form-group[data-v-7f7e3126] {
  padding-left: 25px;
  position: relative;
  line-height: 25px;
}
.yt-empty-cart-wrap[data-v-7f7e3126] {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input[data-v-7f7e3126] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  margin: 0;
}
.cart-pg-inner-wrap .yt-checkout-form-chk-box[data-v-7f7e3126],
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-7f7e3126] {
  position: absolute;
  cursor: pointer;
  margin: 0;
  left: 0;
  top: 0;
}
.cart-pg-inner-wrap .checkout-checkbox .yt-checkout-form-chk-box[data-v-7f7e3126]:before,
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-7f7e3126]:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-7f7e3126]:before {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 5px;
}
.yt-checkout-chekbox-label[data-v-7f7e3126] {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-black);
}
.cart-pg-inner-wrap
  .checkout-checkbox
  .form-check-input:checked
  + label[data-v-7f7e3126]:before {
  background-color: var(--color-dark-black);
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input:checked + label[data-v-7f7e3126]:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label[data-v-7f7e3126]:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  width: 7px;
  height: 7px;
  z-index: 5;
  background: var(--color-blue);
  border-radius: 25px;
  left: 4px;
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label[data-v-7f7e3126]:before {
  border-color: var(--color-blue);
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input[data-v-7f7e3126] {
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 5;
  opacity: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
  bottom: 0;
  height: 100%;
}
.checkout-form-wrap .form-check-label[data-v-7f7e3126] {
  line-height: 1.5;
}
.cart-coupon .error .cart-coupon-code-message[data-v-7f7e3126] {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-orange);
}
.cart-coupon .success .cart-coupon-code-message[data-v-7f7e3126] {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}
.cart-coupon-change-btn[data-v-7f7e3126] {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}
.cart-coupon .cart-coupon-bottom-wrapper[data-v-7f7e3126] {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
}
.cart-coupon .error .cart-coupon-bottom-wrapper[data-v-7f7e3126],
.cart-coupon .success .cart-coupon-bottom-wrapper[data-v-7f7e3126] {
  opacity: 1;
}
.cart-coupon .success .cart-coupon-bottom-wrapper .error-message[data-v-7f7e3126],
.cart-coupon .error .cart-coupon-bottom-wrapper .success-message[data-v-7f7e3126],
.login-on-cart-page .lp-bottom-links[data-v-7f7e3126] {
  display: none;
}
.yt-login-wrap[data-v-7f7e3126] {
  margin-top: 0 !important;
}
.cart-pg-list-prdt-info .cart-prodict-info[data-v-7f7e3126] {
  padding-left: 30px;
}
.cart-action-btm-wrap[data-v-7f7e3126] {
  position: absolute;
  top: 100%;
  left: 30px;
  right: 0;
  border-top: 1px solid #dbdbdb;
  padding: 20px 0;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:last-child {
  text-align: right;
}
.cart-prodict-type tr td[data-v-7f7e3126] {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-dark-black);
  text-transform: capitalize;
}
.cart-pg-list-image[data-v-7f7e3126] {
  width: 20%;
}
.cart-pg-list-prdt-info[data-v-7f7e3126] {
  width: 80%;
  position: relative;
}
.cart-list-other-act[data-v-7f7e3126] {
  position: relative;
  max-height: 100px;
  min-width: 140px;
  text-align: right;
}
.cart-quantity-box[data-v-7f7e3126] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 115px;
}
.checkout-form-wrap .yt-remove-arrow[data-v-7f7e3126]::-webkit-outer-spin-button,
.checkout-form-wrap .yt-remove-arrow[data-v-7f7e3126]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.checkout-form-wrap .yt-remove-arrow[type="number"][data-v-7f7e3126] {
  -moz-appearance: textfield;
}
.cart-produt-list-wrap[data-v-7f7e3126] {
  padding: 40px 40px 65px;
}
.cart-produt-list-wrap[data-v-7f7e3126],
.yt-cart-price-lister[data-v-7f7e3126],
.checkout-form-wrap .cart-pg-inner-wrap[data-v-7f7e3126] {
  border: solid 1px #dbdbdb;
}
.yt-cart-price-lister[data-v-7f7e3126] {
  padding: 40px 30px 56px;
  margin-bottom: 30px;
}
.checkout-form-wrap .cart-pg-inner-wrap[data-v-7f7e3126] {
  padding: 40px 30px 30px;
}
.yt-cm-lt-col[data-v-7f7e3126] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-7f7e3126] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.cart-proceed-to-checkput.cart-btn-bill-pg[data-v-7f7e3126] {
    display: none;
}
.cart-pg-list-image .cart-product-image img[data-v-7f7e3126] {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.cart-pg-list-image .cart-product-image[data-v-7f7e3126] {
    width: 133px;
    height: 133px;
    overflow: hidden;
}
}
@media (min-width: 1280px) {
.empty-main-ttl[data-v-7f7e3126] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-7f7e3126] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-7f7e3126] {
    padding-left: 10px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-7f7e3126] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-7f7e3126] {
    padding-left: 20px;
}
.cart-product-title[data-v-7f7e3126] {
    line-height: 1.67;
    max-width: 100%;
}
.cart-produt-list-wrap[data-v-7f7e3126] {
    padding: 40px 40px 37px;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.empty-cartn-ttl[data-v-7f7e3126] {
    font-size: 24px;
    line-height: 1.17;
}
.empty-cart-text[data-v-7f7e3126] {
    font-size: 14px;
    line-height: 1.14;
}
.empty-cart-btn[data-v-7f7e3126] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.cart-action-btm-wrap[data-v-7f7e3126] {
    position: absolute;
    top: 100%;
    left: 30px;
    right: 0;
    border-top: 1px solid #dbdbdb;
    padding: 3px 0;
}
.yt-empty-cart-wrap.cart-pg-inner-wrap[data-v-7f7e3126] {
    margin-top: 10px !important;
}
.cart-page-main-title[data-v-7f7e3126] {
    margin-bottom: 10px !important;
    font-size: 24px;
    line-height: 1.17;
}
.cart-produt-list-wrap[data-v-7f7e3126] {
    padding: 28px;
    margin-bottom: 15px;
}
.yt-cart-price-lister[data-v-7f7e3126] {
    padding: 20px 15px;
}
.cart-product-title[data-v-7f7e3126] {
    font-size: 16px;
    line-height: 1.13;
}
.cart-prodict-type th[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.cart-prodict-type tr td[data-v-7f7e3126] {
    font-size: 16px;
    line-height: 1.31;
}
.cart-product-price[data-v-7f7e3126] {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:first-child {
    font-size: 18px;
    line-height: 1.11;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:last-child {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
}
.cart-product-amount-ttl[data-v-7f7e3126] {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
}
.cart-product-amount-price[data-v-7f7e3126] {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
}
.cart-prodict-amount tbody td[data-v-7f7e3126],
  .cart-prodict-total-amount tbody td[data-v-7f7e3126],
  .cart-prodict-sub-total-amount tbody td[data-v-7f7e3126],
  .cart-prodict-sub-total-amount tbody td[data-v-7f7e3126] {
    padding: 7.5px 0;
}
.cart-divider[data-v-7f7e3126] {
    margin: 15px 0;
}
.cart-proceed-to-checkput[data-v-7f7e3126] {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.cart-coupon .form-control[data-v-7f7e3126] {
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
}
.cart-coupon .error .cart-coupon-code-message[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.58;
}
.cart-coupon-change-btn[data-v-7f7e3126] {
    font-size: 14px;
    line-height: 1.14;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.cart-pg-list-image .cart-product-image img[data-v-7f7e3126] {
    width: 57px;
    height: 57px;
    object-fit: cover;
    object-position: center;
}
.cart-pg-list-image .cart-product-image[data-v-7f7e3126] {
    width: 70px;
    height: 70 px;
}
.cart-checkout-wrap .cart-proceed-to-checkput[data-v-7f7e3126] {
    display: none;
}
.yt-cm-row[data-v-7f7e3126] {
    margin-left: -20px;
    margin-right: -20px;
}
.yt-cm-lt-col[data-v-7f7e3126] {
    padding-right: 11px;
    padding-left: 11px;
}
.yt-cm-rt-col[data-v-7f7e3126] {
    padding: 0;
}
.empty-cartn-ttl[data-v-7f7e3126] {
    font-size: 17px;
    line-height: 1.12;
}
.empty-cart-text[data-v-7f7e3126] {
    font-size: 15px;
    line-height: 1.2;
}
.empty-cart-btn[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
}
.cart-page-main-title[data-v-7f7e3126] {
    font-size: 18px;
    line-height: 1.11;
}
.cart-produt-list-wrap[data-v-7f7e3126] {
    padding: 15px;
    margin-bottom: 10px;
}
.cart-product-title[data-v-7f7e3126] {
    font-size: 14px;
    line-height: 1.36;
}
.cart-prodict-type th[data-v-7f7e3126] {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.38px;
}
.cart-prodict-type tr td[data-v-7f7e3126] {
    font-size: 14px;
    line-height: 1.5;
}
.cart-product-price[data-v-7f7e3126] {
    font-size: 15px;
    line-height: 1.2;
}
.cart-product-delete svg[data-v-7f7e3126] {
    font-size: 15px;
}
.cart-page-main-title[data-v-7f7e3126] {
    margin-bottom: 10px !important;
}
.yt-cart-price-lister[data-v-7f7e3126] {
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:first-child {
    font-size: 16px;
    line-height: 1.19;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:last-child {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
}
.cart-product-amount-ttl[data-v-7f7e3126] {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
}
.cart-product-amount-price[data-v-7f7e3126] {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
}
.cart-prodict-amount tbody td[data-v-7f7e3126],
  .cart-prodict-total-amount tbody td[data-v-7f7e3126],
  .cart-prodict-sub-total-amount tbody td[data-v-7f7e3126],
  .cart-prodict-sub-total-amount tbody td[data-v-7f7e3126] {
    padding: 8px 0;
}
.cart-divider[data-v-7f7e3126] {
    margin: 3px 0 7px;
}
.cart-coupon .form-control[data-v-7f7e3126] {
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
}
.cart-proceed-to-checkput[data-v-7f7e3126] {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.checkout-form-wrap .cart-pg-inner-wrap[data-v-7f7e3126] {
    padding: 20px;
    margin-bottom: 10px;
}
.cart-checkout-tile[data-v-7f7e3126] {
    font-size: 15px;
    line-height: 1.2;
}
.cart-select-aar-btn[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
}
.checkout-form-label[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input[data-v-7f7e3126] {
    font-size: 10px;
    line-height: 1.1;
}
.checkout-form-wrap .yt-chekout-radio .form-check-label[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
}
.checkout-form-wrap .yt-chekout-radio .form-check-label .form-check-input[data-v-7f7e3126] {
    margin-top: 0px;
}
.checkout-form-wrap .yt-chekout-radio > div[data-v-7f7e3126] {
    width: 100%;
    margin-bottom: 10px;
}
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-7f7e3126] {
    top: 6px;
}
}
@media (max-width: 575.98px) {
.empty-cart-text[data-v-7f7e3126] {
    max-width: 233px;
    margin-left: auto;
    margin-right: auto;
}
.empty-cart-btn[data-v-7f7e3126] {
    width: 100%;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.empty-cart-btn[data-v-7f7e3126] {
    font-size: 16px;
}
.cart-page-main-title[data-v-7f7e3126] {
    font-size: 26px;
}
.cart-proceed-to-checkput[data-v-7f7e3126] {
    font-size: 16px;
}
}
@media (min-width: 1900px) {
.cart-action-btm-wrap[data-v-7f7e3126] {
    position: absolute;
    top: 100%;
    left: 30px;
    right: 0;
    border-top: 1px solid #dbdbdb;
    padding: 7px 0;
}
}
.spinner[data-v-7f7e3126] {
  margin: 20px auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 10px;
}
.spinner > div[data-v-7f7e3126] {
  background-color: #707070;
  height: 100%;
  border-radius: 8px;
  width: 5px;
  margin: 0 2px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay-data-v-7f7e3126 1.2s infinite ease-in-out;
  animation: sk-stretchdelay-data-v-7f7e3126 1.2s infinite ease-in-out;
}
.spinner .rect2[data-v-7f7e3126] {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3[data-v-7f7e3126] {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
.spinner .rect4[data-v-7f7e3126] {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5[data-v-7f7e3126] {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay-data-v-7f7e3126 {
0%, 40%, 100% { -webkit-transform: scaleY(0.4)
}
20% { -webkit-transform: scaleY(1.0)
}
}
@keyframes sk-stretchdelay-data-v-7f7e3126 {
0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
}
20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
}
}
.payment-alert[data-v-7f7e3126] {
  background-color: #f4f4f4;
  text-align: center;
  border: none;
}
.payment-alert p[data-v-7f7e3126] {
  color: #707070;
  font-size: 18px;
}



/*Quantity CSS*/
.cart-quantity-field .form-group[data-v-40bc51b8] {
  position: relative;
  border-radius: 2px;
  border: solid 1px #cae2fe;
}
.cart-quantity-icn[data-v-40bc51b8] {
  position: absolute;
  top: 1px;
  bottom: 1px;
  background: #f6f8fa;
  cursor: pointer;
}
.quantity-icn-left[data-v-40bc51b8] {
  left: 1px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.quantity-icn-right[data-v-40bc51b8] {
  right: 1px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.cart-quantity-field .form-control[data-v-40bc51b8] {
  border-radius: 0;
  box-shadow: none !important;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}
.cart-quantity-field input[data-v-40bc51b8]::-webkit-outer-spin-button,
.cart-quantity-field input[data-v-40bc51b8]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.cart-quantity-field input[type="number"][data-v-40bc51b8] {
  -moz-appearance: textfield;
}
.cart-quantity-field[data-v-40bc51b8] {
  max-width: 120px;
}
/*Quantity CSS END*/

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.cart-quantity-field[data-v-40bc51b8] {
    max-width: 100px;
    margin-left: auto;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.cart-quantity-field[data-v-40bc51b8] {
    max-width: 88px;
    margin-left: auto;
}
}

.radius-10[data-v-6fe59481] {
  border-radius: 10px;
}
.orderplc-pg-mb-30[data-v-6fe59481] {
  margin-bottom: 30px;
}
.order-placed[data-v-6fe59481] {
  width: 100%;
  height: 500px;
  padding: 400px 69px 394px;
  border-radius: 10px;
  background-color: #43b7a7
}
.yt-order-placed-icn[data-v-6fe59481] {
  max-height: 135px;
  width: auto;
}
.orderplc-ttl[data-v-6fe59481] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-white);
}
.orderplc-text[data-v-6fe59481] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-white);
}
.orderplc-btn[data-v-6fe59481] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  width: 100%;
  max-width: 180px;
}
.orderplc-btn[data-v-6fe59481]:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}
.orderplc-pg-inner-wrap[data-v-6fe59481] {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1900px) {
.orderplc-pg-mb-80[data-v-6fe59481] {
    margin-bottom: 80px;
}
}
@media (min-width: 1280px) {
.orderplc-pg-inner-wrap[data-v-6fe59481] {
    margin-bottom: 60px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.orderplc-pg-inner-wrap[data-v-6fe59481] {
    margin-bottom: 60px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.orderplc-pg-inner-wrap[data-v-6fe59481] {
    margin-bottom: 15px;
    padding: 20px !important;
}
.orderplc-pg-success-main-wrap[data-v-6fe59481] {
    padding-bottom: 0 !important;
}
.yt-order-placed-icn[data-v-6fe59481] {
    max-height: 85px;
}
.orderplc-ttl[data-v-6fe59481] {
    font-size: 16px;
    line-height: 1.13;
}
.orderplc-text[data-v-6fe59481] {
    line-height: 1.14;
    font-size: 14px;
}
.orderplc-btn[data-v-6fe59481] {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
}
}

.radius-10[data-v-c8833587] {
  border-radius: 10px;
}
.trans-fl-pg-mb-30[data-v-c8833587] {
  margin-bottom: 30px;
}
.trans-fl-pg-mb-80[data-v-c8833587] {
  margin-bottom: 80px;
}
.trans-fl-ttl[data-v-c8833587] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-orange);
}
.trans-fl-text[data-v-c8833587] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.trans-fl-btn[data-v-c8833587] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
  margin: 40px 54px 11.3px;
}
.trans-fl-cancel-btn[data-v-c8833587] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
  box-shadow: none !important;
}
.yt-transaction-cl-icn[data-v-c8833587] {
  max-height: 135px;
  width: auto;
}
.trans-fl-pg-inner-wrap[data-v-c8833587] {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1280px) {
.trans-fl-pg-inner-wrap[data-v-c8833587] {
    margin-bottom: 60px;
}
}
@media (min-width: 1900px) {
.trans-fl-pg-inner-wrap[data-v-c8833587] {
    margin-bottom: 80px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.trans-fl-pg-inner-wrap[data-v-c8833587] {
    margin-bottom: 20px;
}
.trans-fl-wrap[data-v-c8833587] {
    margin-top: 75px;
    margin-bottom: 40px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.trans-fl-pg-inner-wrap[data-v-c8833587] {
    margin-bottom: 15px;
    padding: 11px 20px 20px !important;
}
.trans-fl-btn[data-v-c8833587] {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
}
.trans-fl-btn[data-v-c8833587]{
    border: 1px solid black;
    background-color: white;
    color: black;
}
.cart-pg-empty-main-wrap[data-v-c8833587] {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.trans-fl-cancel-btn[data-v-c8833587] {
    font-size: 12px;
    line-height: 1.17;
}
.trans-fl-cancel-btn[data-v-c8833587]:hover{
    border: 1px solid black;
    background-color: white;
    color: black;
}
.trans-fl-ttl[data-v-c8833587] {
    font-size: 16px;
    line-height: 1.13;
}
.trans-fl-text[data-v-c8833587] {
    font-size: 14px;
    line-height: 1.14;
}
.trans-fl-wrap[data-v-c8833587] {
    margin-bottom: 22px !important;
    margin-top: 20px !important;
}
}

.radius-10[data-v-cb5e327c] {
  border-radius: 10px;
}
.list-style-none[data-v-cb5e327c] {
  list-style: none;
}
.border-top-grey[data-v-cb5e327c] {
  border-top: 2px solid #dbdbdb;
}
.sp-current[data-v-cb5e327c] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.sp-image-vert-slider[data-v-cb5e327c] {
  position: relative;
  max-height: 550px;
}
.yt-product-img-inner-row .sp-inner-content-wrap.sp-spp-inner-image-wrap[data-v-cb5e327c] {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid silver;
  border-radius: 10px;
  min-height: 450px;
}
.yt-slider-down[data-v-cb5e327c],
.yt-slider-up[data-v-cb5e327c] {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}
.yt-slider-up[data-v-cb5e327c] {
  top: 0;
}
.yt-slider-down[data-v-cb5e327c] {
  bottom: 0;
}
.rec[data-v-cb5e327c] {
  background: red;
}
div[data-v-cb5e327c] {
  box-shadow: none;
  outline: none !important;
}
.yt-product-img-inner-row
  .sp-inner-content-wrap.sp-spp-inner-image-wrap
  .sp-spimg-item[data-v-cb5e327c] {
  min-height: 450px;
  object-fit: contain;
  object-position: center;
  padding: 20px;
  max-height: 100%;
}
.sp-inner-wrap[data-v-cb5e327c]{
  padding-top: 35px;
}
@media (min-width: 1900px) {
.sp-inner-wrap[data-v-cb5e327c] {
    padding: 40px 40px 30px;
}
}
@media (min-width: 1280px) {
.sp-image-vert-slider[data-v-cb5e327c] {
    overflow: hidden;
}
}
@media (min-width: 768px) {
.vert-slider-item img[data-v-cb5e327c] {
    height: 110px;
    width: 110px;
    object-fit: cover;
}
.vert-slider-item[data-v-cb5e327c] {
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    height: 133px;
    width: 133px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-inner-col[data-v-cb5e327c] {
    flex: 0 0 100%;
    max-width: 100%;
}
.yt-slider-down[data-v-cb5e327c],
  .yt-slider-up[data-v-cb5e327c] {
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    z-index: 50;
}
.yt-slider-up[data-v-cb5e327c] {
    left: -10px;
}
.yt-slider-down[data-v-cb5e327c] {
    right: -10px;
    left: auto;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.vert-slider-item[data-v-cb5e327c] {
    height: 70px;
    width: 70px;
    border-radius: 9px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
}
.vert-slider-item img[data-v-cb5e327c] {
    height: 57px;
    width: 57px;
    object-fit: cover;
}
}

.yt-sglproduct-fav[data-v-07325c3d] {
  font-size: 24px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-sglproduct-fav.active[data-v-07325c3d] {
  color: var(--color-orange);
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-sglproduct-fav[data-v-07325c3d] {
    font-size: 16px;
}
}

.cls-1[data-v-8b051588] {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  stroke-width: 0.5px;
}

.cls-1[data-v-da9d598f] {
  fill: var(--color-primary);
  stroke: var(--color-primary);
  stroke-width: 0.5px;
}

.radius-10[data-v-035a47a1] {
  border-radius: 10px;
}
.trans-fl-pg-mb-30[data-v-035a47a1] {
  margin-bottom: 30px;
}
.trans-fl-pg-mb-80[data-v-035a47a1] {
  margin-bottom: 80px;
}
.trans-fl-ttl[data-v-035a47a1] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-orange);
}
.trans-fl-text[data-v-035a47a1] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.trans-fl-btn[data-v-035a47a1] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.trans-fl-btn[data-v-035a47a1]:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}
.trans-fl-cancel-btn[data-v-035a47a1] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
  box-shadow: none !important;
}
.trans-fl-cancel-btn[data-v-035a47a1]:hover{
  background-color: white;
  color: black;
  border: 1px solid black;
}
.yt-transaction-cl-icn[data-v-035a47a1] {
  max-height: 135px;
  width: auto;
}
.trans-fl-pg-inner-wrap[data-v-035a47a1] {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1280px) {
.trans-fl-pg-inner-wrap[data-v-035a47a1] {
    margin-bottom: 60px;
}
}
@media (min-width: 1900px) {
.trans-fl-pg-inner-wrap[data-v-035a47a1] {
    margin-bottom: 80px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.trans-fl-pg-inner-wrap[data-v-035a47a1] {
    margin-bottom: 20px;
}
.trans-fl-wrap[data-v-035a47a1] {
    margin-top: 75px;
    margin-bottom: 40px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.trans-fl-pg-inner-wrap[data-v-035a47a1] {
    margin-bottom: 15px;
    padding: 11px 20px 20px !important;
}
.trans-fl-btn[data-v-035a47a1] {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
}
.cart-pg-empty-main-wrap[data-v-035a47a1] {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.trans-fl-cancel-btn[data-v-035a47a1] {
    font-size: 12px;
    line-height: 1.17;
}
.trans-fl-ttl[data-v-035a47a1] {
    font-size: 16px;
    line-height: 1.13;
}
.trans-fl-text[data-v-035a47a1] {
    font-size: 14px;
    line-height: 1.14;
}
.trans-fl-wrap[data-v-035a47a1] {
    margin-bottom: 22px !important;
    margin-top: 20px !important;
}
}

.radius-10[data-v-bcac7626] {
  border-radius: 10px;
}
.trans-fl-pg-mb-30[data-v-bcac7626] {
  margin-bottom: 30px;
}
.trans-fl-pg-mb-80[data-v-bcac7626] {
  margin-bottom: 80px;
}
.trans-fl-ttl[data-v-bcac7626] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-orange);
}
.trans-fl-text[data-v-bcac7626] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.trans-fl-btn[data-v-bcac7626] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.trans-fl-btn[data-v-bcac7626]{
  border: 1px solid black;
  background-color: white;
  color: black;
}
.trans-fl-cancel-btn[data-v-bcac7626] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
  box-shadow: none !important;
}
.trans-fl-cancel-btn[data-v-bcac7626]:hover{
  border: 1px solid black;
  background-color: white;
  color: black;
}
.yt-transaction-cl-icn[data-v-bcac7626] {
  max-height: 135px;
  width: auto;
}
.trans-fl-pg-inner-wrap[data-v-bcac7626] {
  max-width: 1150px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1280px) {
.trans-fl-pg-inner-wrap[data-v-bcac7626] {
    margin-bottom: 60px;
}
}
@media (min-width: 1900px) {
.trans-fl-pg-inner-wrap[data-v-bcac7626] {
    margin-bottom: 80px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.trans-fl-pg-inner-wrap[data-v-bcac7626] {
    margin-bottom: 20px;
}
.trans-fl-wrap[data-v-bcac7626] {
    margin-top: 75px;
    margin-bottom: 40px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.trans-fl-pg-inner-wrap[data-v-bcac7626] {
    margin-bottom: 15px;
    padding: 11px 20px 20px !important;
}
.trans-fl-btn[data-v-bcac7626] {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    line-height: 1.14;
    font-size: 14px;
    letter-spacing: 0.4px;
    max-width: 100%;
}
.cart-pg-empty-main-wrap[data-v-bcac7626] {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}
.trans-fl-cancel-btn[data-v-bcac7626] {
    font-size: 12px;
    line-height: 1.17;
}
.trans-fl-ttl[data-v-bcac7626] {
    font-size: 16px;
    line-height: 1.13;
}
.trans-fl-text[data-v-bcac7626] {
    font-size: 14px;
    line-height: 1.14;
}
.trans-fl-wrap[data-v-bcac7626] {
    margin-bottom: 22px !important;
    margin-top: 20px !important;
}
}

/*
* Home Page CSS
*/
/*
* Global CSS
*/
.yt-component-wrapper[data-v-7c293060]{
    position: relative;
}


/*End*/
.deals[data-v-7c293060] {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 500;
}
.products[data-v-7c293060] {
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
}
.more[data-v-7c293060] {
    float: right;
    font-size: 13px;
    cursor: pointer;
}
.fullscreen[data-v-7c293060] {
    width: 100%;
}
.oneintwo[data-v-7c293060] {
    margin-top: 20px;
    margin-bottom: 20px;
}
.shopnowbutton[data-v-7c293060] {
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
}
.savetext[data-v-7c293060] {
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.textblock1[data-v-7c293060] {
    margin-top: -150px;
    margin-left: 80px;
}
.welcometext[data-v-7c293060] {
    color: black;
    font-weight: 600;
    font-size: 40px;
}
.textblock2[data-v-7c293060] {
    margin-top: -350px;
    margin-left: 80px;
}
.headtop[data-v-7c293060] {
    color: white;
    font-weight: 200px;
}
.uptooff[data-v-7c293060] {
    color: white;
    font-weight: 600;
    font-size: 60px;
}
.textblock3[data-v-7c293060] {
    margin-top: -300px;
    margin-left: 350px;
}
.logohead1[data-v-7c293060] {
    margin-left: 30px;
    font-weight: 500px;
    margin-top: -60px;
    font-size: 20px;
    color: white;
}
.shopnowgoldenbutton[data-v-7c293060] {
    width: 100px;
    background-color: var(--color-primary);
    color: white;
    font-weight: 400px;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: -70px;
    float: right;
    margin-right: 10px;
}
.shopnowgoldenbutton1[data-v-7c293060] {
    width: 100px;
    background-color: var(--color-primary);
    color: white;
    font-weight: 400px;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    float: left;
    margin-top: 20px;
}
.dontmiss[data-v-7c293060] {
    font-size: 30px;
}
.offthirty[data-v-7c293060] {
    font-size: 70px;
    font-weight: 600;
}
.textbox4[data-v-7c293060] {
    margin-left: 120px;
    margin-top: -500px;
}
.hurryup[data-v-7c293060] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.dailydeal[data-v-7c293060] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.lowprice[data-v-7c293060] {
    color: white;
}
.textbox5[data-v-7c293060] {
    margin-left: 50px;
    margin-top: -200px;
}
.happytimes[data-v-7c293060] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.flat[data-v-7c293060] {
    font-size: 40px;
    color: white;
    font-weight: 700px;
}
.textbox7[data-v-7c293060] {
    margin-top: -230px;
    margin-left: auto;
    margin-right: auto;
}
.stores[data-v-7c293060] {
    width: 30%;
    margin-top: 70px;
    cursor: pointer;
}
.playbuttons[data-v-7c293060] {
    margin-left: 190px;
    margin-right: auto;
}
.download[data-v-7c293060] {
    font-size: 40px;
    font-weight: 400;
    color: black;
    margin-left: 100px;
}
.imglast[data-v-7c293060] {
    width: 100%;
    height: 100%;
}
.home-banner[data-v-7c293060], .banner-container[data-v-7c293060] {
    position: relative;
    min-height: 357px;
}
.home-banner .content[data-v-7c293060] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.banner-container .content[data-v-7c293060] {
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.home-banner .content .scheme[data-v-7c293060] {
    font-size: 80px;
    font-weight: 500;
}
.mytextbanner2[data-v-7c293060]{
    margin: 0 0 67px;
    font-size: 50px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
    font-style: italic;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-banner .content .info[data-v-7c293060] {
    font-size: 24px;
    color: #000;
}
.banner-container .content .scheme[data-v-7c293060] {
    font-size: 102px;
    font-weight: bold;
}
.banner-container .content .info[data-v-7c293060] {
    font-size: 24px;
    color: #8B8F95;
}
.myVideo[data-v-7c293060]{
    max-width: 100%;
    height: auto;
}
.my-btn[data-v-7c293060]{
    opacity: .99;
    background-color: transparent;
    border-color: white;
    font-weight: 500;
    line-height: 1.11;
    color: #fff;
    max-width: 200px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 90px;
    width: 200px;
    height: 55px;
    font-size: 18px;
    letter-spacing: 3.8px;
    text-align: center;
}
.my-btn[data-v-7c293060]:hover{
    background-color: white;
    color: black;
}
.content .btn-shop[data-v-7c293060] {
    color: #fff;
    background-color: #000;
    padding: 8px 40px;
    border: 1px solid #000;
    border-radius: 6px;
    margin-top: 270px;
    font-weight: bold;
}
@media (max-width: 1471px){
.my-btn[data-v-7c293060]{
        bottom: 100px !important;
}
}
@media (max-width: 1399px) {
.home-banner[data-v-7c293060], .banner-container[data-v-7c293060]{
        min-height: auto;
}
}
@media (max-width: 1199px) {
.home-banner .content .scheme[data-v-7c293060]{
        font-size: 36px;
        margin-top: 0;
}
.home-banner .content .info[data-v-7c293060]{
        font-size: 18px;
}
.banner-container .content .scheme[data-v-7c293060] {
        font-size: 36px;
        margin-top: 0;
}
.banner-container .content .info[data-v-7c293060]{
        font-size: 18px;
}
}
@media (max-width: 1198px){
.mytextbanner2[data-v-7c293060]{
        font-size: 40px;
}
}
/* for mobile */
@media only screen and (max-width: 992px) {
.shopnowbutton[data-v-7c293060] {
        width: 100px;
        padding: 5px;
        height: 30px;
}
.shopnowgoldenbutton1[data-v-7c293060] {
        width: 100px;
        padding: 5px;
        height: 30px;
}
.savetext[data-v-7c293060] {
        font-size: 15px;
}
.textblock1[data-v-7c293060] {
        margin-top: -90px;
        margin-left: 50px;
}
.welcometext[data-v-7c293060] {
        color: black;
        font-weight: 600;
        font-size: 20px;
}
.textblock2[data-v-7c293060] {
        margin-top: -200px;
        margin-left: 40px;
        width: 50%;
}
.headtop[data-v-7c293060] {
        font-weight: 200px;
}
.uptooff[data-v-7c293060] {
        color: white;
        font-weight: 600;
        font-size: 30px;
}
.textblock3[data-v-7c293060] {
        margin-top: -250px;
        margin-left: 200px;
        width: 50%;
}
.three[data-v-7c293060] {
        padding-bottom: 10px;
}
.logohead1[data-v-7c293060] {
        width: 100%;
}
.hurryup[data-v-7c293060] {
        font-size: 20px;
        color: white;
        font-weight: 600px;
}
.dailydeal[data-v-7c293060] {
        font-size: 15px;
        color: white;
        font-weight: 600px;
}
.lowprice[data-v-7c293060] {
        color: white;
        font-size: 10px;
}
.textbox5[data-v-7c293060] {
        margin-left: 30px;
        margin-top: -160px;
        width: 50%;
}
.happytimes[data-v-7c293060] {
        font-size: 20px;
        color: white;
        font-weight: 600px;
}
.flat[data-v-7c293060] {
        font-size: 25px;
        color: white;
        font-weight: 700px;
}
.stores[data-v-7c293060] {
        width: 30%;
        margin-top: 0px;
}
.playbuttons[data-v-7c293060] {
        margin-left: 120px;
        margin-right: auto;
}
.download[data-v-7c293060] {
        font-size: 15px;
        font-weight: 400;
        color: black;
        margin-left: 120px;
}
.textbox7[data-v-7c293060] {
        margin-top: -60px;
        margin-left: auto;
        margin-right: auto;
}
.imglast[data-v-7c293060] {
        width: 100%;
        height: 100%;
}
.dontmiss[data-v-7c293060] {
        font-size: 15px;
}
.offthirty[data-v-7c293060] {
        font-size: 25px;
        font-weight: 600;
}
.textbox4[data-v-7c293060] {
        margin-left: 40px;
        margin-top: -150px;
        width: 50%;
}
}

/* for desktop */
@media (max-width: 991px) {
.home-banner >img[data-v-7c293060], .banner-container>img[data-v-7c293060]{
        height: 250px;
        object-fit: cover;
        width: 100%;
}
.mytextbanner2[data-v-7c293060]{
        font-size: 30px;
        max-width: 250px;
}
.my-btn[data-v-7c293060]{
        bottom: 20px !important;
        width: 150px;
    height: 40px;
}
}
@media (max-width: 767px) {
.home-banner .content .scheme[data-v-7c293060]{
        font-size: 22px;
        margin-bottom: 15px;
}
.home-banner .content .info[data-v-7c293060]{
        font-size: 14px;
}
.home-banner >img[data-v-7c293060], .banner-container>img[data-v-7c293060]{
        height: 160px;
}
.banner-container .content .scheme[data-v-7c293060] {
        font-size: 22px;
        margin-top: 0;
}
.banner-container .content .info[data-v-7c293060]{
        font-size: 14px;
}
.content .btn-shop[data-v-7c293060]{
        margin-top: 0px;
        font-size: 13px;
        padding: 6px 20px;
}
.mytextbanner2[data-v-7c293060]{
        font-size: 20px;
    max-width: 200px;
}
.my-btn[data-v-7c293060]{
        bottom: 15px !important;
        width: 150px;
        height: 30px;
}
}
.featurebarcontainer[data-v-7c293060]{
    margin:40px 0px;
}
.formcontainer[data-v-a6691a93] {
  max-width: 600px;
  width: 100%;
  display: inline-block;
  margin-bottom: 30px;
}

.about-first .title[data-v-52b9eb18] {
  font-weight: bold;
  font-size: 35px;
  line-height: 1.4;
}
.about-first .para[data-v-52b9eb18] {
  font-weight: 400;
  color: #8e9298;
  text-align: justify;
  line-height: 1.6;
  font-size: 18px;
}

.deals[data-v-52b9eb18] {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
}
.products[data-v-52b9eb18] {
  background-color: white;
  padding: 30px;
  margin-bottom: 20px;
}
.more[data-v-52b9eb18] {
  float: right;
  font-size: 13px;
  cursor: pointer;
}
.one[data-v-52b9eb18] {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.two[data-v-52b9eb18] {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.three[data-v-52b9eb18] {
  width: 100%;
  height: 100%;
  margin-left: 0;
  float: left;
  margin-right: 0;
  padding-right: 0;
}
.fullscreen[data-v-52b9eb18] {
  width: 100%;
}
.oneintwo[data-v-52b9eb18] {
  margin-top: 20px;
  margin-bottom: 20px;
}
.shopnowbutton[data-v-52b9eb18] {
  width: 140px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.savetext[data-v-52b9eb18] {
  color: black;
  font-weight: 600;
  font-size: 20px;
}
.textblock1[data-v-52b9eb18] {
  margin-top: -150px;
  margin-left: 80px;
}
.image-container[data-v-52b9eb18] {
  position: relative;
}
.image-container .after[data-v-52b9eb18] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.banner-title[data-v-52b9eb18] {
  font-size: 82px;
  color: #ffffff;
  padding-top: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.banner-subtitle[data-v-52b9eb18] {
  width: 800px;
  font-size: 20px;
  line-height: 1.8;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.welcometext[data-v-52b9eb18] {
  color: black;
  font-weight: 600;
  font-size: 40px;
}
.textblock2[data-v-52b9eb18] {
  margin-top: -350px;
  margin-left: 80px;
}
.headtop[data-v-52b9eb18] {
  color: white;
  font-weight: 200px;
}
.uptooff[data-v-52b9eb18] {
  color: white;
  font-weight: 600;
  font-size: 60px;
}
.textblock3[data-v-52b9eb18] {
  margin-top: -300px;
  margin-left: 350px;
}
.logohead1[data-v-52b9eb18] {
  margin-left: 30px;
  font-weight: 500px;
  margin-top: -60px;
  font-size: 20px;
  color: white;
}
.shopnowgoldenbutton[data-v-52b9eb18] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: -70px;
  float: right;
  margin-right: 10px;
}
.shopnowgoldenbutton1[data-v-52b9eb18] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-top: 20px;
}
.dontmiss[data-v-52b9eb18] {
  font-size: 30px;
}
.offthirty[data-v-52b9eb18] {
  font-size: 70px;
  font-weight: 600;
}
.textbox4[data-v-52b9eb18] {
  margin-left: 120px;
  margin-top: -500px;
}
.hurryup[data-v-52b9eb18] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.dailydeal[data-v-52b9eb18] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.lowprice[data-v-52b9eb18] {
  color: white;
}
.textbox5[data-v-52b9eb18] {
  margin-left: 50px;
  margin-top: -200px;
}
.happytimes[data-v-52b9eb18] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.flat[data-v-52b9eb18] {
  font-size: 40px;
  color: white;
  font-weight: 700px;
}
.textbox7[data-v-52b9eb18] {
  margin-top: -230px;
  margin-left: auto;
  margin-right: auto;
}
.stores[data-v-52b9eb18] {
  width: 30%;
  cursor: pointer;
  margin-top: 70px;
}
.playbuttons[data-v-52b9eb18] {
  margin-left: 190px;
  margin-right: auto;
}
.download[data-v-52b9eb18] {
  font-size: 40px;
  font-weight: 400;
  color: black;
  margin-left: 100px;
}
.imglast[data-v-52b9eb18] {
  width: 100%;
  height: 100%;
}
.prodcluster[data-v-52b9eb18] {
  margin-bottom: 10px;
  margin-top: 10px;
}
.filterpage[data-v-52b9eb18] {
  margin-bottom: 100px;
  margin-top: 30px;
}
.filterpanel[data-v-52b9eb18] {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
}
.abayas[data-v-52b9eb18] {
  font-size: 20px;
  font-weight: 500;
}
.yt-shop-category-name[data-v-52b9eb18] {
  font-size: 33px;
  font-weight: 500;
  line-height: 1.13;
  text-transform: uppercase;
  color: var(--color-dark-black);
}
.filter_abayas[data-v-52b9eb18] {
  margin-top: 35px;
}
.recommeded[data-v-52b9eb18] {
  float: right;
  margin-right: -23px;
}
.recomdrop[data-v-52b9eb18] {
  margin-right: 20px;
  margin-left: 10px;
}
.selectedopt[data-v-52b9eb18] {
  /* width: 90px; */
  /* height: 40px; */
  border-radius: 24px;
  border: solid 1px #cae2fe;
  background-color: #ffffff;
  padding: 5px;
  margin-left: 5px;
  font-size: 13px;
}
.showingnum[data-v-52b9eb18] {
  float: right;
}
.yt-product-showing-total-item[data-v-52b9eb18] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-first[data-v-52b9eb18],
.yt-second[data-v-52b9eb18] {
  color: var(--color-black);
}
.yt-selected-filter-wrap[data-v-52b9eb18] {
  margin-top: 25px;
  margin-bottom: 20px;
}
.pageroute[data-v-52b9eb18] {
  color: gray;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 1.17;
}
.currpage[data-v-52b9eb18] {
  color: black;
}
.filter_view[data-v-52b9eb18] {
  margin-bottom: 5px;
  color: grey;
}
.cat_name[data-v-52b9eb18] {
  margin-bottom: 10px;
  padding-left: 20px;
}
.cat_name_top[data-v-52b9eb18] {
  padding-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
}
.listing[data-v-52b9eb18] {
  margin-left: 0;
  list-style-type: none;
  padding-left: 20px;
}
.checkbox_div[data-v-52b9eb18] {
  margin-right: 20px;
  padding-top: 5px;
}
.cat_item[data-v-52b9eb18] {
  color: gray;
  margin-top: 2px;
  margin-bottom: 2px;
}
.color_opt[data-v-52b9eb18] {
  height: 10px;
  width: 10px;
  background-color: pink;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.searchcontainer[data-v-52b9eb18] {
  display: flex;
  align-items: center;
  height: 100%;
}
.searchinput[data-v-52b9eb18] {
  border-radius: 6px;
  border: solid 1px #cae2fe;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  width: 90%;
  padding-left: 48px;
}
.searchicon[data-v-52b9eb18] {
  height: auto;
  width: 14px;
  position: absolute;
  left: 50px;
}
.cat_color[data-v-52b9eb18] {
  color: gray;
}
.slider[data-v-52b9eb18] {
  width: 90%;
  height: 20px;
  background: var(--color-primary);
  opacity: 0.7;
  transition: opacity 0.2s;
}

/* for mobile */
@media only screen and (max-width: 992px) {
.shopnowbutton[data-v-52b9eb18] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.shopnowgoldenbutton1[data-v-52b9eb18] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.savetext[data-v-52b9eb18] {
    font-size: 15px;
}
.textblock1[data-v-52b9eb18] {
    margin-top: -90px;
    margin-left: 50px;
}
.welcometext[data-v-52b9eb18] {
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.textblock2[data-v-52b9eb18] {
    margin-top: -200px;
    margin-left: 40px;
    width: 50%;
}
.headtop[data-v-52b9eb18] {
    font-weight: 200px;
}
.uptooff[data-v-52b9eb18] {
    color: white;
    font-weight: 600;
    font-size: 30px;
}
.textblock3[data-v-52b9eb18] {
    margin-top: -250px;
    margin-left: 200px;
    width: 50%;
}
.three[data-v-52b9eb18] {
    padding-bottom: 10px;
}
.logohead1[data-v-52b9eb18] {
    width: 100%;
}
.hurryup[data-v-52b9eb18] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.dailydeal[data-v-52b9eb18] {
    font-size: 15px;
    color: white;
    font-weight: 600px;
}
.lowprice[data-v-52b9eb18] {
    color: white;
    font-size: 10px;
}
.textbox5[data-v-52b9eb18] {
    margin-left: 30px;
    margin-top: -160px;
    width: 50%;
}
.happytimes[data-v-52b9eb18] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.flat[data-v-52b9eb18] {
    font-size: 25px;
    color: white;
    font-weight: 700px;
}
.stores[data-v-52b9eb18] {
    width: 30%;
    margin-top: 0px;
}
.playbuttons[data-v-52b9eb18] {
    margin-left: 120px;
    margin-right: auto;
}
.download[data-v-52b9eb18] {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 120px;
}
.textbox7[data-v-52b9eb18] {
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
}
.imglast[data-v-52b9eb18] {
    width: 100%;
    height: 100%;
}
.dontmiss[data-v-52b9eb18] {
    font-size: 15px;
}
.offthirty[data-v-52b9eb18] {
    font-size: 25px;
    font-weight: 600;
}
.textbox4[data-v-52b9eb18] {
    margin-left: 40px;
    margin-top: -150px;
    width: 50%;
}
}

/* for mobile */
@media only screen and (max-width: 992px) {
.filtertop[data-v-52b9eb18] {
    margin-top: 60px;
}
.selectedprops[data-v-52b9eb18] {
    margin-bottom: 10px;
}
.selectedopt[data-v-52b9eb18] {
    margin-top: 5px;
}
.banner-title[data-v-52b9eb18] {
    font-size: 50px;
    padding-top: 0;
}
}
@media (max-width: 800px) {
.banner-title[data-v-52b9eb18] {
    padding-top: 0px !important;
    font: 40px !important;
}
.banner-subtitle[data-v-52b9eb18] {
    width: auto;
}
}
.dropcustom[data-v-52b9eb18] {
  background-color: skyblue;
}
.removefilter[data-v-52b9eb18] {
  cursor: pointer;
}
.clearall[data-v-52b9eb18] {
  padding-top: 5px;
  margin-left: 5px;
  color: red;
  font-size: 13px;
  cursor: pointer;
}
.all-prod-sort-tag-name[data-v-52b9eb18] {
  line-height: 2.4em;
  font-size: 18px;
  color: var(--color-dark-grey);
}
.yt-recommend-wrapper[data-v-52b9eb18] {
  width: 100%;
  box-shadow: none;
  border: none;
  margin: 0;
  border-radius: 0;
  border: solid 1px #cae2fe;
  border-top: none !important;
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-52b9eb18] {
  box-shadow: 0px -20px 20px 0 rgba(0, 0, 0, 0.05);
}
.yt-recommend-inner .show .yt-recommend-wrapper[data-v-52b9eb18] {
  box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/**/
.yt-close-icn[data-v-52b9eb18] {
  cursor: pointer;
  margin-left: 13px;
}
.yt-product-page-recommend-btn[data-v-52b9eb18] {
  border-radius: 10px !important;
  border: solid 1px #f0f0f0 !important;
  background-color: #fff !important;
  min-width: 228px;
  text-align: left;
  box-shadow: none !important;
  position: relative;
  color: var(--color-black);
  font-size: 18px !important;
  font-weight: 500;
  line-height: 1.11 !important;
  padding: 14px !important;
}
.yt-product-page-recommend-btn[data-v-52b9eb18]::after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-black);
}
.current-active-filter[data-v-52b9eb18] {
  position: relative;
}
.current-active-filter[data-v-52b9eb18]::after {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAABHNCSVQICAgIfAhkiAAAAYNJREFUOE+tlEFuglAQhv+BJsKqHkFPUI4ANmmiK11qF7UnaD2BegNvULtQl7jSxKSKN/AIvUF1BRuY5r0IQXkgacpuwrwv8///vEf4x4/yWPbOrZLvPxCRLXqY6MCVyt5zOse8MxmYgGi+PySid9UhBqaRYQxU0AuYvZlZWqi5BNSK1DPzMbpjx3t6PqT7EpiYSPf9HYisMjZKoGnW0xMmsMZqPiGitzKgVM/nV7Pbj2sJk1MFwc8tEAPjswUvcW8I1L1m91vUEva4mrdB5Bb6BLxGhrG8toKZB9tWb5LAGuvFiIDhGXZiYJKqwTkguTLAeNvsjtQw5kNomo4WBG0CPopASlhG5hmIIKjCMI5FKWdkKgMQQKKOzuwWrUsmABnCejEFkKR0K1kpkXm/bfXkdUs8S62HiPi+DAjAKQSseC0uYBK4mVl6qHklgKdQj+zc6xRPY68XNR0QUSslC2kRUT89UUbmtTQB1ZjFMlflP3EXiZYqyE1YSd8u2nIfx7/AfgE25rsU8uIr8gAAAABJRU5ErkJggg==);
  display: block;
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
.yt-filter-selected .yt-flt-tag[data-v-52b9eb18] {
  border-radius: 5px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  padding: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.13;
  font-size: 16px;
  color: var(--color-dark-black);
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-52b9eb18] {
  background: #fff !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: inherit !important;
  border: solid 1px #cae2fe !important;
  border-bottom: none !important;
}
.yt-recommend-wrapper .dropdown-item[data-v-52b9eb18] {
  padding: 10px 14px;
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
}
.yt-recommend-wrapper .dropdown-item.active[data-v-52b9eb18] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAABHNCSVQICAgIfAhkiAAAAYNJREFUOE+tlEFuglAQhv+BJsKqHkFPUI4ANmmiK11qF7UnaD2BegNvULtQl7jSxKSKN/AIvUF1BRuY5r0IQXkgacpuwrwv8///vEf4x4/yWPbOrZLvPxCRLXqY6MCVyt5zOse8MxmYgGi+PySid9UhBqaRYQxU0AuYvZlZWqi5BNSK1DPzMbpjx3t6PqT7EpiYSPf9HYisMjZKoGnW0xMmsMZqPiGitzKgVM/nV7Pbj2sJk1MFwc8tEAPjswUvcW8I1L1m91vUEva4mrdB5Bb6BLxGhrG8toKZB9tWb5LAGuvFiIDhGXZiYJKqwTkguTLAeNvsjtQw5kNomo4WBG0CPopASlhG5hmIIKjCMI5FKWdkKgMQQKKOzuwWrUsmABnCejEFkKR0K1kpkXm/bfXkdUs8S62HiPi+DAjAKQSseC0uYBK4mVl6qHklgKdQj+zc6xRPY68XNR0QUSslC2kRUT89UUbmtTQB1ZjFMlflP3EXiZYqyE1YSd8u2nIfx7/AfgE25rsU8uIr8gAAAABJRU5ErkJggg==);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-color: #fff;
  color: #000;
  background-position: 95% center;
}
.yt-clear-all[data-v-52b9eb18] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
  text-decoration: underline;
}
.yt-product-page-recommend-btn[data-v-52b9eb18]:hover {
  color: var(--color-dark-black) !important;
}
.yt-shop-pg-cm-lft-col-pd[data-v-52b9eb18] {
  padding-right: 10px;
}
.yt-shop-pg-cm-rgt-col-pd[data-v-52b9eb18] {
  padding-left: 10px;
}
.yt-cm-lt-col[data-v-52b9eb18] {
  padding-right: 10px;
}
.yt-cm-rt-col[data-v-52b9eb18] {
  padding-left: 10px;
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-52b9eb18] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-52b9eb18] {
    padding-left: 20px;
}
.yt-shop-pg-cm-lft-col-pd[data-v-52b9eb18] {
    padding-right: 20px;
}
.yt-shop-pg-cm-rgt-col-pd[data-v-52b9eb18] {
    padding-left: 20px;
}
.yt-filter-selected .yt-flt-tag[data-v-52b9eb18],
  .yt-product-showing-total-item[data-v-52b9eb18] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-shop-category-name[data-v-52b9eb18] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-filter-selected .yt-flt-tag[data-v-52b9eb18] {
    line-height: 1.11;
    font-size: 18px;
}
}
@media (min-width: 1280px) {
.yt-sp-mb-recomment-wrap[data-v-52b9eb18],
  .yt-sp-mb-filter-wrapper[data-v-52b9eb18] {
    display: none;
}
}
@media (max-width: 1279.92px) {
.yt-cm-lt-col.ytMbfilteropen[data-v-52b9eb18] {
    display: block;
    position: absolute;
    top: 270px;
    left: 0;
    right: 0;
    z-index: 10;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px 47px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.all-prod-sort-tag-name[data-v-52b9eb18],
  .yt-product-page-recommend-btn span[data-v-52b9eb18],
  .yt-product-page-recommend-btn[data-v-52b9eb18]::after,
  .yt-cm-lt-col[data-v-52b9eb18] {
    display: none;
}
.yt-cm-rt-col[data-v-52b9eb18] {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    background-color: #fff;
    margin-top: 40px;
}
.yt-product-page-recommend-btn[data-v-52b9eb18] {
    background-color: transparent !important;
    border: none !important;
    min-width: auto;
}
.yt-product-page-recommend-btn[data-v-52b9eb18]:active,
  .yt-product-page-recommend-btn[data-v-52b9eb18]:focus,
  .yt-product-page-recommend-btn[data-v-52b9eb18]:hover {
    color: var(--color-dark-black) !important;
}
.yt-recommend-wrapper .dropdown-item[data-v-52b9eb18] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-product-page-recommend-btn .yt-recmnd-mb-txt[data-v-52b9eb18],
  .yt-sp-mb-filter-wrapper .yt-recmnd-mb-txt[data-v-52b9eb18] {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 5px;
}
.filter_abayas[data-v-52b9eb18] {
    margin-top: 0;
}
.filterpage .filtertop[data-v-52b9eb18] {
    margin-top: 0;
    position: absolute;
    top: -50px;
    left: 20px;
    right: 20px;
}
.yt-recommend-wrapper[data-v-52b9eb18] {
    left: auto !important;
    right: 0 !important;
    min-width: 200px;
    transform: translate3d(-0px, 48px, 0px) !important;
    border-radius: 10px;
    border: solid 1px var(--color-dark-black) !important;
    padding: 0;
    overflow: hidden;
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-52b9eb18] {
    background: transparent !important;
    border: none !important;
}
.filterpage[data-v-52b9eb18] {
    margin-top: 0;
}
.yt-shop-category-name[data-v-52b9eb18] {
    font-size: 20px;
    line-height: 1.15;
}
}
@media (max-width: 1365px) {
.banner-title[data-v-52b9eb18] {
    padding-top: 50px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 991.92px) {
.yt-cm-lt-col.ytMbfilteropen[data-v-52b9eb18] {
    padding: 0px 40px;
    top: 320px;
}
}
@media (max-width: 767.92px) {
.yt-cm-lt-col.ytMbfilteropen[data-v-52b9eb18] {
    padding: 0px 15px;
    top: 160px;
}
.yt-recommend-wrapper .dropdown-item[data-v-52b9eb18] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-recommend-wrapper[data-v-52b9eb18] {
    min-width: 191px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-product-page-recommend-btn[data-v-52b9eb18] {
    font-size: 16px !important;
}
.yt-filter-selected .yt-flt-tag[data-v-52b9eb18] {
    font-size: 14px;
}
.yt-clear-all[data-v-52b9eb18] {
    font-size: 14px;
}
.yt-recommend-wrapper .dropdown-item[data-v-52b9eb18] {
    font-size: 16px;
}
.banner-title[data-v-52b9eb18] {
    padding-top: 110px;
}
}
@media only screen and (max-device-width: 480px) {
.myImage[data-v-52b9eb18] {
    height: 250px;
    object-fit: cover;
}
.banner-title[data-v-52b9eb18] {
    font-size: 35px;
    padding-top: 0;
}
.banner-subtitle[data-v-52b9eb18] {
    width: 350px;
    font-size: 17px;
    line-height: 1.6;
}
.yt-cm-rt-col[data-v-52b9eb18] {
    margin-top: 0;
}
}

.about-first[data-v-73b66c1a] {
  padding-bottom: 70px;
}
.page_title[data-v-73b66c1a] {
  font-size: 55px;
  font-weight: bold;
  color: #000000;
}
.subtitle-content[data-v-73b66c1a] {
  font-size: 18px;
  line-height: 1.8;
  color: black;
  padding-bottom: 20px;
  font-style: italic;
}
/* .table >thead >tr >th{
  width: 50px !important;
} */
.table[data-v-73b66c1a] {
  font-size: 20px;
}
.borderless td[data-v-73b66c1a],
.borderless th[data-v-73b66c1a] {
  border: none;
}
.table-striped > tbody > tr:nth-child(odd) > td[data-v-73b66c1a],
.table-striped > tbody > tr:nth-child(odd) > th[data-v-73b66c1a] {
  background-color: #f8fbfe;
}
.table-striped > tbody > tr:nth-child(even) > td[data-v-73b66c1a],
.table-striped > tbody > tr:nth-child(even) > th[data-v-73b66c1a] {
  background-color: #ffff;
}
.section_1[data-v-73b66c1a] {
  padding-top: 15px;
}
.section_common[data-v-73b66c1a] {
  padding-top: 70px;
}
.about-first .title[data-v-73b66c1a] {
  font-weight: bold;
  font-size: 35px;
  line-height: 1.4;
  color: #000000;
}
.about-first .para[data-v-73b66c1a] {
  font-weight: 400;
  color: #8e9298;
  text-align: justify;
  line-height: 1.6;
  font-size: 18px;
}
.size_chart[data-v-73b66c1a] {
  text-align: center;
}
/* iPad [portrait + landscape] */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
.container[data-v-73b66c1a]{
    width: 600px  !important;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.sizeTable[data-v-73b66c1a]{
    overflow-x: scroll;
}
th[data-v-73b66c1a],td[data-v-73b66c1a]{
    min-width: 100px
}
.container[data-v-73b66c1a]{
    width: 330px  !important;
}
.page_title[data-v-73b66c1a]{
    font-size: 40px;
}
.about-first .title[data-v-73b66c1a]{
    font-size: 30px;
}
.section_1[data-v-73b66c1a]{
    padding-top: 0;
}
.table[data-v-73b66c1a]{
    font-size: 16px;
}
}
.deals[data-v-8f169bae] {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
}
.products[data-v-8f169bae] {
  background-color: white;
  padding: 30px;
  margin-bottom: 20px;
}
.more[data-v-8f169bae] {
  float: right;
  font-size: 13px;
  cursor: pointer;
}
.one[data-v-8f169bae] {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.two[data-v-8f169bae] {
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.three[data-v-8f169bae] {
  width: 100%;
  height: 100%;
  margin-left: 0;
  float: left;
  margin-right: 0;
  padding-right: 0;
}
.fullscreen[data-v-8f169bae] {
  width: 100%;
}
.oneintwo[data-v-8f169bae] {
  margin-top: 20px;
  margin-bottom: 20px;
}
.shopnowbutton[data-v-8f169bae] {
  width: 140px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.savetext[data-v-8f169bae] {
  color: black;
  font-weight: 600;
  font-size: 20px;
}
.textblock1[data-v-8f169bae] {
  margin-top: -150px;
  margin-left: 80px;
}
.image-container[data-v-8f169bae] {
  position: relative;
}
.image-container .after[data-v-8f169bae] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.banner-title[data-v-8f169bae] {
  font-size: 82px;
  color: #ffffff;
  padding-top: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.banner-subtitle[data-v-8f169bae] {
  width: 800px;
  font-size: 20px;
  line-height: 1.8;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.welcometext[data-v-8f169bae] {
  color: black;
  font-weight: 600;
  font-size: 40px;
}
.textblock2[data-v-8f169bae] {
  margin-top: -350px;
  margin-left: 80px;
}
.headtop[data-v-8f169bae] {
  color: white;
  font-weight: 200px;
}
.uptooff[data-v-8f169bae] {
  color: white;
  font-weight: 600;
  font-size: 60px;
}
.textblock3[data-v-8f169bae] {
  margin-top: -300px;
  margin-left: 350px;
}
.logohead1[data-v-8f169bae] {
  margin-left: 30px;
  font-weight: 500px;
  margin-top: -60px;
  font-size: 20px;
  color: white;
}
.shopnowgoldenbutton[data-v-8f169bae] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: -70px;
  float: right;
  margin-right: 10px;
}
.shopnowgoldenbutton1[data-v-8f169bae] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-top: 20px;
}
.dontmiss[data-v-8f169bae] {
  font-size: 30px;
}
.offthirty[data-v-8f169bae] {
  font-size: 70px;
  font-weight: 600;
}
.textbox4[data-v-8f169bae] {
  margin-left: 120px;
  margin-top: -500px;
}
.hurryup[data-v-8f169bae] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.dailydeal[data-v-8f169bae] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.lowprice[data-v-8f169bae] {
  color: white;
}
.textbox5[data-v-8f169bae] {
  margin-left: 50px;
  margin-top: -200px;
}
.happytimes[data-v-8f169bae] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.flat[data-v-8f169bae] {
  font-size: 40px;
  color: white;
  font-weight: 700px;
}
.textbox7[data-v-8f169bae] {
  margin-top: -230px;
  margin-left: auto;
  margin-right: auto;
}
.stores[data-v-8f169bae] {
  width: 30%;
  cursor: pointer;
  margin-top: 70px;
}
.playbuttons[data-v-8f169bae] {
  margin-left: 190px;
  margin-right: auto;
}
.download[data-v-8f169bae] {
  font-size: 40px;
  font-weight: 400;
  color: black;
  margin-left: 100px;
}
.imglast[data-v-8f169bae] {
  width: 100%;
  height: 100%;
}
.prodcluster[data-v-8f169bae] {
  margin-bottom: 10px;
  margin-top: 10px;
}
.filterpage[data-v-8f169bae] {
  margin-bottom: 100px;
  margin-top: 30px;
}
.filterpanel[data-v-8f169bae] {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
}
.abayas[data-v-8f169bae] {
  font-size: 20px;
  font-weight: 500;
}
.yt-shop-category-name[data-v-8f169bae] {
  font-size: 33px;
  font-weight: 500;
  line-height: 1.13;
  text-transform: uppercase;
  color: var(--color-dark-black);
}
.filter_abayas[data-v-8f169bae] {
  margin-top: 35px;
}
.recommeded[data-v-8f169bae] {
  float: right;
  margin-right: -23px;
}
.recomdrop[data-v-8f169bae] {
  margin-right: 20px;
  margin-left: 10px;
}
.selectedopt[data-v-8f169bae] {
  /* width: 90px; */
  /* height: 40px; */
  border-radius: 24px;
  border: solid 1px #cae2fe;
  background-color: #ffffff;
  padding: 5px;
  margin-left: 5px;
  font-size: 13px;
}
.showingnum[data-v-8f169bae] {
  float: right;
}
.yt-product-showing-total-item[data-v-8f169bae] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-first[data-v-8f169bae],
.yt-second[data-v-8f169bae] {
  color: var(--color-black);
}
.yt-selected-filter-wrap[data-v-8f169bae] {
  margin-top: 25px;
  margin-bottom: 20px;
}
.pageroute[data-v-8f169bae] {
  color: gray;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  line-height: 1.17;
}
.currpage[data-v-8f169bae] {
  color: black;
}
.filter_view[data-v-8f169bae] {
  margin-bottom: 5px;
  color: grey;
}
.cat_name[data-v-8f169bae] {
  margin-bottom: 10px;
  padding-left: 20px;
}
.cat_name_top[data-v-8f169bae] {
  padding-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
}
.listing[data-v-8f169bae] {
  margin-left: 0;
  list-style-type: none;
  padding-left: 20px;
}
.checkbox_div[data-v-8f169bae] {
  margin-right: 20px;
  padding-top: 5px;
}
.cat_item[data-v-8f169bae] {
  color: gray;
  margin-top: 2px;
  margin-bottom: 2px;
}
.color_opt[data-v-8f169bae] {
  height: 10px;
  width: 10px;
  background-color: pink;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.searchcontainer[data-v-8f169bae] {
  display: flex;
  align-items: center;
  height: 100%;
}
.searchinput[data-v-8f169bae] {
  border-radius: 6px;
  border: solid 1px #cae2fe;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  width: 90%;
  padding-left: 48px;
}
.searchicon[data-v-8f169bae] {
  height: auto;
  width: 14px;
  position: absolute;
  left: 50px;
}
.cat_color[data-v-8f169bae] {
  color: gray;
}
.slider[data-v-8f169bae] {
  width: 90%;
  height: 20px;
  background: var(--color-primary);
  opacity: 0.7;
  transition: opacity 0.2s;
}

/* for mobile */
@media only screen and (max-width: 992px) {
.shopnowbutton[data-v-8f169bae] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.shopnowgoldenbutton1[data-v-8f169bae] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.savetext[data-v-8f169bae] {
    font-size: 15px;
}
.textblock1[data-v-8f169bae] {
    margin-top: -90px;
    margin-left: 50px;
}
.welcometext[data-v-8f169bae] {
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.textblock2[data-v-8f169bae] {
    margin-top: -200px;
    margin-left: 40px;
    width: 50%;
}
.headtop[data-v-8f169bae] {
    font-weight: 200px;
}
.uptooff[data-v-8f169bae] {
    color: white;
    font-weight: 600;
    font-size: 30px;
}
.textblock3[data-v-8f169bae] {
    margin-top: -250px;
    margin-left: 200px;
    width: 50%;
}
.three[data-v-8f169bae] {
    padding-bottom: 10px;
}
.logohead1[data-v-8f169bae] {
    width: 100%;
}
.hurryup[data-v-8f169bae] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.dailydeal[data-v-8f169bae] {
    font-size: 15px;
    color: white;
    font-weight: 600px;
}
.lowprice[data-v-8f169bae] {
    color: white;
    font-size: 10px;
}
.textbox5[data-v-8f169bae] {
    margin-left: 30px;
    margin-top: -160px;
    width: 50%;
}
.happytimes[data-v-8f169bae] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.flat[data-v-8f169bae] {
    font-size: 25px;
    color: white;
    font-weight: 700px;
}
.stores[data-v-8f169bae] {
    width: 30%;
    margin-top: 0px;
}
.playbuttons[data-v-8f169bae] {
    margin-left: 120px;
    margin-right: auto;
}
.download[data-v-8f169bae] {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 120px;
}
.textbox7[data-v-8f169bae] {
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
}
.imglast[data-v-8f169bae] {
    width: 100%;
    height: 100%;
}
.dontmiss[data-v-8f169bae] {
    font-size: 15px;
}
.offthirty[data-v-8f169bae] {
    font-size: 25px;
    font-weight: 600;
}
.textbox4[data-v-8f169bae] {
    margin-left: 40px;
    margin-top: -150px;
    width: 50%;
}
}

/* for mobile */
@media only screen and (max-width: 992px) {
.filtertop[data-v-8f169bae] {
    margin-top: 60px;
}
.selectedprops[data-v-8f169bae] {
    margin-bottom: 10px;
}
.selectedopt[data-v-8f169bae] {
    margin-top: 5px;
}
.banner-title[data-v-8f169bae] {
    font-size: 50px;
    padding-top: 0;
}
}
@media (max-width: 800px) {
.banner-title[data-v-8f169bae] {
    padding-top: 0px !important;
    font: 40px !important;
}
.banner-subtitle[data-v-8f169bae] {
    width: auto;
}
}
.dropcustom[data-v-8f169bae] {
  background-color: skyblue;
}
.removefilter[data-v-8f169bae] {
  cursor: pointer;
}
.clearall[data-v-8f169bae] {
  padding-top: 5px;
  margin-left: 5px;
  color: red;
  font-size: 13px;
  cursor: pointer;
}
.all-prod-sort-tag-name[data-v-8f169bae] {
  line-height: 2.4em;
  font-size: 18px;
  color: var(--color-dark-grey);
}
.yt-recommend-wrapper[data-v-8f169bae] {
  width: 100%;
  box-shadow: none;
  border: none;
  margin: 0;
  border-radius: 0;
  border: solid 1px #cae2fe;
  border-top: none !important;
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-8f169bae] {
  box-shadow: 0px -20px 20px 0 rgba(0, 0, 0, 0.05);
}
.yt-recommend-inner .show .yt-recommend-wrapper[data-v-8f169bae] {
  box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/**/
.yt-close-icn[data-v-8f169bae] {
  cursor: pointer;
  margin-left: 13px;
}
.yt-product-page-recommend-btn[data-v-8f169bae] {
  border-radius: 10px !important;
  border: solid 1px #f0f0f0 !important;
  background-color: #fff !important;
  min-width: 228px;
  text-align: left;
  box-shadow: none !important;
  position: relative;
  color: var(--color-black);
  font-size: 18px !important;
  font-weight: 500;
  line-height: 1.11 !important;
  padding: 14px !important;
}
.yt-product-page-recommend-btn[data-v-8f169bae]::after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-black);
}
.current-active-filter[data-v-8f169bae] {
  position: relative;
}
.current-active-filter[data-v-8f169bae]::after {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAABHNCSVQICAgIfAhkiAAAAYNJREFUOE+tlEFuglAQhv+BJsKqHkFPUI4ANmmiK11qF7UnaD2BegNvULtQl7jSxKSKN/AIvUF1BRuY5r0IQXkgacpuwrwv8///vEf4x4/yWPbOrZLvPxCRLXqY6MCVyt5zOse8MxmYgGi+PySid9UhBqaRYQxU0AuYvZlZWqi5BNSK1DPzMbpjx3t6PqT7EpiYSPf9HYisMjZKoGnW0xMmsMZqPiGitzKgVM/nV7Pbj2sJk1MFwc8tEAPjswUvcW8I1L1m91vUEva4mrdB5Bb6BLxGhrG8toKZB9tWb5LAGuvFiIDhGXZiYJKqwTkguTLAeNvsjtQw5kNomo4WBG0CPopASlhG5hmIIKjCMI5FKWdkKgMQQKKOzuwWrUsmABnCejEFkKR0K1kpkXm/bfXkdUs8S62HiPi+DAjAKQSseC0uYBK4mVl6qHklgKdQj+zc6xRPY68XNR0QUSslC2kRUT89UUbmtTQB1ZjFMlflP3EXiZYqyE1YSd8u2nIfx7/AfgE25rsU8uIr8gAAAABJRU5ErkJggg==);
  display: block;
  background-size: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
.yt-filter-selected .yt-flt-tag[data-v-8f169bae] {
  border-radius: 5px;
  border: solid 1px #e6e6e6;
  background-color: #ffffff;
  padding: 14px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.13;
  font-size: 16px;
  color: var(--color-dark-black);
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-8f169bae] {
  background: #fff !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  color: inherit !important;
  border: solid 1px #cae2fe !important;
  border-bottom: none !important;
}
.yt-recommend-wrapper .dropdown-item[data-v-8f169bae] {
  padding: 10px 14px;
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
}
.yt-recommend-wrapper .dropdown-item.active[data-v-8f169bae] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAABHNCSVQICAgIfAhkiAAAAYNJREFUOE+tlEFuglAQhv+BJsKqHkFPUI4ANmmiK11qF7UnaD2BegNvULtQl7jSxKSKN/AIvUF1BRuY5r0IQXkgacpuwrwv8///vEf4x4/yWPbOrZLvPxCRLXqY6MCVyt5zOse8MxmYgGi+PySid9UhBqaRYQxU0AuYvZlZWqi5BNSK1DPzMbpjx3t6PqT7EpiYSPf9HYisMjZKoGnW0xMmsMZqPiGitzKgVM/nV7Pbj2sJk1MFwc8tEAPjswUvcW8I1L1m91vUEva4mrdB5Bb6BLxGhrG8toKZB9tWb5LAGuvFiIDhGXZiYJKqwTkguTLAeNvsjtQw5kNomo4WBG0CPopASlhG5hmIIKjCMI5FKWdkKgMQQKKOzuwWrUsmABnCejEFkKR0K1kpkXm/bfXkdUs8S62HiPi+DAjAKQSseC0uYBK4mVl6qHklgKdQj+zc6xRPY68XNR0QUSslC2kRUT89UUbmtTQB1ZjFMlflP3EXiZYqyE1YSd8u2nIfx7/AfgE25rsU8uIr8gAAAABJRU5ErkJggg==);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-color: #fff;
  color: #000;
  background-position: 95% center;
}
.yt-clear-all[data-v-8f169bae] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
  text-decoration: underline;
}
.yt-product-page-recommend-btn[data-v-8f169bae]:hover {
  color: var(--color-dark-black) !important;
}
.yt-shop-pg-cm-lft-col-pd[data-v-8f169bae] {
  padding-right: 10px;
}
.yt-shop-pg-cm-rgt-col-pd[data-v-8f169bae] {
  padding-left: 10px;
}
.yt-cm-lt-col[data-v-8f169bae] {
  padding-right: 10px;
}
.yt-cm-rt-col[data-v-8f169bae] {
  padding-left: 10px;
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-8f169bae] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-8f169bae] {
    padding-left: 20px;
}
.yt-shop-pg-cm-lft-col-pd[data-v-8f169bae] {
    padding-right: 20px;
}
.yt-shop-pg-cm-rgt-col-pd[data-v-8f169bae] {
    padding-left: 20px;
}
.yt-filter-selected .yt-flt-tag[data-v-8f169bae],
  .yt-product-showing-total-item[data-v-8f169bae] {
    font-size: 18px;
    line-height: 1.11;
}
.yt-shop-category-name[data-v-8f169bae] {
    font-size: 30px;
    line-height: 1.13;
}
.yt-filter-selected .yt-flt-tag[data-v-8f169bae] {
    line-height: 1.11;
    font-size: 18px;
}
}
@media (min-width: 1280px) {
.yt-sp-mb-recomment-wrap[data-v-8f169bae],
  .yt-sp-mb-filter-wrapper[data-v-8f169bae] {
    display: none;
}
}
@media (max-width: 1279.92px) {
.yt-cm-lt-col.ytMbfilteropen[data-v-8f169bae] {
    display: block;
    position: absolute;
    top: 270px;
    left: 0;
    right: 0;
    z-index: 10;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px 47px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.all-prod-sort-tag-name[data-v-8f169bae],
  .yt-product-page-recommend-btn span[data-v-8f169bae],
  .yt-product-page-recommend-btn[data-v-8f169bae]::after,
  .yt-cm-lt-col[data-v-8f169bae] {
    display: none;
}
.yt-cm-rt-col[data-v-8f169bae] {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    background-color: #fff;
    margin-top: 40px;
}
.yt-product-page-recommend-btn[data-v-8f169bae] {
    background-color: transparent !important;
    border: none !important;
    min-width: auto;
}
.yt-product-page-recommend-btn[data-v-8f169bae]:active,
  .yt-product-page-recommend-btn[data-v-8f169bae]:focus,
  .yt-product-page-recommend-btn[data-v-8f169bae]:hover {
    color: var(--color-dark-black) !important;
}
.yt-recommend-wrapper .dropdown-item[data-v-8f169bae] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-product-page-recommend-btn .yt-recmnd-mb-txt[data-v-8f169bae],
  .yt-sp-mb-filter-wrapper .yt-recmnd-mb-txt[data-v-8f169bae] {
    font-size: 14px;
    line-height: 1.14;
    padding-left: 5px;
}
.filter_abayas[data-v-8f169bae] {
    margin-top: 0;
}
.filterpage .filtertop[data-v-8f169bae] {
    margin-top: 0;
    position: absolute;
    top: -50px;
    left: 20px;
    right: 20px;
}
.yt-recommend-wrapper[data-v-8f169bae] {
    left: auto !important;
    right: 0 !important;
    min-width: 200px;
    transform: translate3d(-0px, 48px, 0px) !important;
    border-radius: 10px;
    border: solid 1px var(--color-dark-black) !important;
    padding: 0;
    overflow: hidden;
}
.yt-recommend-inner .show .yt-product-page-recommend-btn[data-v-8f169bae] {
    background: transparent !important;
    border: none !important;
}
.filterpage[data-v-8f169bae] {
    margin-top: 0;
}
.yt-shop-category-name[data-v-8f169bae] {
    font-size: 20px;
    line-height: 1.15;
}
}
@media (max-width: 1365px) {
.banner-title[data-v-8f169bae] {
    padding-top: 50px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 991.92px) {
.yt-cm-lt-col.ytMbfilteropen[data-v-8f169bae] {
    padding: 0px 40px;
    top: 320px;
}
}
@media (max-width: 767.92px) {
.yt-cm-lt-col.ytMbfilteropen[data-v-8f169bae] {
    padding: 0px 15px;
    top: 160px;
}
.yt-recommend-wrapper .dropdown-item[data-v-8f169bae] {
    font-size: 14px;
    line-height: 1.14;
}
.yt-recommend-wrapper[data-v-8f169bae] {
    min-width: 191px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-product-page-recommend-btn[data-v-8f169bae] {
    font-size: 16px !important;
}
.yt-filter-selected .yt-flt-tag[data-v-8f169bae] {
    font-size: 14px;
}
.yt-clear-all[data-v-8f169bae] {
    font-size: 14px;
}
.yt-recommend-wrapper .dropdown-item[data-v-8f169bae] {
    font-size: 16px;
}
.banner-title[data-v-8f169bae] {
    padding-top: 110px;
}
}
@media only screen and (max-device-width: 480px) {
.myImage[data-v-8f169bae] {
    height: 250px;
    object-fit: cover;
}
.banner-title[data-v-8f169bae] {
    font-size: 35px;
    padding-top: 0;
}
.banner-subtitle[data-v-8f169bae] {
    width: 350px;
    font-size: 17px;
    line-height: 1.6;
}
.yt-cm-rt-col[data-v-8f169bae] {
    margin-top: 0;
}
}

#pagination > .pagination {
  justify-content: center;
  background-color: white;
  padding: 15px 0px;
  color: black;
  /* border: 1px solid black; */
  transition: 0.7s ease;
}

#pagination > .pagination:hover {
  /* background-color: black; */
  /* color: white; */
  /* border: 1px solid white; */
}
#pagination > .pagination:hover .active {
  border-bottom: 1px solid white;
}
#pagination > .pagination > li {
  padding: 0px 10px;
}
#pagination > .pagination > .active {
  border-bottom: 1px solid black;
  font-weight: bold;
  padding: 0px 10px;
}

/*
* CSS
*/


/*
* Global CSS Classes
*/
.radius-10[data-v-23e03094] {
    border-radius: 10px;
}
.list-style-none[data-v-23e03094] {
    list-style: none;
}
.border-top-grey[data-v-23e03094] {
    border-top: 2px solid #dbdbdb;
}
.sp-breadcrumbs[data-v-23e03094] {
    margin-bottom: 46px;
}
.pageroute.sp-breadcrumbs span[data-v-23e03094] {
    color: var(--color-dark-grey);
    font-size: 15px;
    line-height: 1.17;
}
.pageroute.sp-breadcrumbs .sp-current[data-v-23e03094] {
    color: var(--color-black);
    font-weight: 500;
}
.yt-feature-point li[data-v-23e03094] {
    line-height: 1.81;
    color: #8b8f95;
}
@media (min-width: 1900px) {
.sp-breadcrumbs[data-v-23e03094] {
        margin-bottom: 40px;
}
}
@media (min-width: 768px) {
.ml-md-0[data-v-23e03094] {
        margin-left: 0;
}
}


/*
* Single Product Left Section CSS
*/
.sp-image-slide img[data-v-23e03094] {
    border: 1px solid #dbdbdb;
}
.sp-description-title[data-v-23e03094] {
    font-size: 20px;
    line-height: 1.15;
    color: var(--color-black);
    font-weight: 500;
    /* margin-bottom: 27px; */
}
.sp-description-text[data-v-23e03094] {
    font-size: 16px;
    color: var(--color-dark-grey);
    line-height: 2.13;
}
.yt-based-on[data-v-23e03094] {
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-dark-grey);
}
.sp-rating-content[data-v-23e03094] {
    font-size: 38px;
    line-height: 1.13;
    color: var(--color-black);
}
.sp-rating-view span[data-v-23e03094]:first-child {
    font-size: 20px;
    color: var(--color-black);
    line-height: 1.75;
}
span.sp-rating-area[data-v-23e03094] {
    min-width: 100px;
    height: 2px;
    background-color: #f6f8fa;
}
.sp-rating-wrap .sp-rating-inner-wrap[data-v-23e03094] {
    width: 50%;
}
.sp-give-review-btn[data-v-23e03094] {
    min-width: 145px;
    border-radius: 5px;
    /* background-color: var(--color-primary); */
    background-color: black;
    opacity: 0.99;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.11;
}
.sp-rating-area[data-v-23e03094] {
    position: relative;
}
.sp-rating-area[data-v-23e03094]::after {
    content: "";
    height: 2px;
    position: absolute;
}
.sp-width-80[data-v-23e03094]::after {
    width: 80%;
    background-color: var(--color-dark-black);
}
.sp-width-60[data-v-23e03094]::after {
    width: 60%;
    background-color: var(--color-dark-black);
}
.sp-width-40[data-v-23e03094]::after {
    width: 40%;
    background-color: var(--color-blue);
}
.sp-width-20[data-v-23e03094]::after {
    width: 20%;
    background-color: var(--color-blue);
}
.sp-width-10[data-v-23e03094]::after {
    width: 10%;
    background-color: var(--color-orange);
}
.sp-rating-date[data-v-23e03094] {
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-dark-grey);
}
.sp-user-name[data-v-23e03094] {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
    color: var(--color-black);
}
.sp-usermessage[data-v-23e03094] {
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-dark-grey);
}
.sp-user-rated-meta img[data-v-23e03094] {
    max-width: 11px;
}
.yt-rating-single-prdt[data-v-23e03094],
.yt-rating-val-wrapper[data-v-23e03094] {
    pointer-events: none;
}
.yt-rating-val-wrapper[data-v-23e03094] {
    margin: 15px 0 30px;
}


/*
* Single Product Right Section CSS
*/
.added.sp-favorite-wrap .sp-fav-icn[data-v-23e03094] {
    margin: 41px 25px 30px 40px;
    padding: 20px 20px 25px 40px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
}
.sp-fav-icn[data-v-23e03094] {
    width: 19px;
    height: 19px;
}
.product-title[data-v-23e03094] {
    color: var(--color-black);
    font-size: 50px;
    font-weight: 500;
    line-height: 1.67;
    max-width: 550px;
}
.sp-favorite-wrap[data-v-23e03094] {
    cursor: pointer;
    box-shadow: 0 1px 4px 0 rgba(199, 214, 227, 0.5);
    border-radius: 100%;
}
.sp-price-tag-name[data-v-23e03094] {
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
}
.sp-price-tag-value[data-v-23e03094] {
    font-size: 28px;
    font-weight: 500;
    color: #000000;
    line-height: 1.14;
    letter-spacing: 1.08px;
}
.sp-small-tag-value[data-v-23e03094] {
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: 1.08px;
    color: var(--color-green);
}
.sp-price-wrap[data-v-23e03094],
.sp-size-wrap[data-v-23e03094] {
    padding: 12px 0;
}
.sp-quantity-tag-name[data-v-23e03094] {
    font-size: 16px;
    color: var(--color-dark-grey);
    line-height: 1.13;
    letter-spacing: normal;
}
.sp-verify-icn-wrap[data-v-23e03094] {
    line-height: 14px;
    margin-right: 10px;
}
.sp-verify-icn-wrap img[data-v-23e03094] {
    max-height: 15px;
    width: auto;
}
.product-stock-message[data-v-23e03094] {
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
}
.sp-small-tag-name[data-v-23e03094] {
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
}
.sp-out-quantity-tag-name[data-v-23e03094] {
    font-size: 16px;
    color: var(--color-orange);
    line-height: 1.13;
    letter-spacing: normal;
}
.sp-ccolor-box[data-v-23e03094] {
    width: 33px;
    height: 33px;
    border-radius: 27px;
    position: relative;
    cursor: pointer;
    border: 1px solid #dee2e6;
}
.active.sp-ccolor-box[data-v-23e03094]::after {
    content: "";
    height: 11px;
    width: 11px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAABHNCSVQICAgIfAhkiAAAAYNJREFUOE+tlEFuglAQhv+BJsKqHkFPUI4ANmmiK11qF7UnaD2BegNvULtQl7jSxKSKN/AIvUF1BRuY5r0IQXkgacpuwrwv8///vEf4x4/yWPbOrZLvPxCRLXqY6MCVyt5zOse8MxmYgGi+PySid9UhBqaRYQxU0AuYvZlZWqi5BNSK1DPzMbpjx3t6PqT7EpiYSPf9HYisMjZKoGnW0xMmsMZqPiGitzKgVM/nV7Pbj2sJk1MFwc8tEAPjswUvcW8I1L1m91vUEva4mrdB5Bb6BLxGhrG8toKZB9tWb5LAGuvFiIDhGXZiYJKqwTkguTLAeNvsjtQw5kNomo4WBG0CPopASlhG5hmIIKjCMI5FKWdkKgMQQKKOzuwWrUsmABnCejEFkKR0K1kpkXm/bfXkdUs8S62HiPi+DAjAKQSseC0uYBK4mVl6qHklgKdQj+zc6xRPY68XNR0QUSslC2kRUT89UUbmtTQB1ZjFMlflP3EXiZYqyE1YSd8u2nIfx7/AfgE25rsU8uIr8gAAAABJRU5ErkJggg==);
    position: absolute;
    background-size: cover;
    background-color: #fff;
    right: -5px;
    top: -5px;
    border-radius: 100%;
}
.sp-color-1[data-v-23e03094] {
    background-color: var(--color-black);
}
.sp-color-2[data-v-23e03094] {
    background-color: #f6f8fa;
}
.sp-color-3[data-v-23e03094] {
    background-color: var(--color-green);
}
.sp-color-4[data-v-23e03094] {
    background-color: var(--color-blue);
}
.sp-color-5[data-v-23e03094] {
    background-color: var(--color-dark-grey);
}
.sp-border-top-bottom[data-v-23e03094] {
    border-top: 2px solid #cae2fe;
    border-bottom: 2px solid #cae2fe;
}
.sp-size-details[data-v-23e03094] {
    width: 50px;
    height: 37px;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    font-size: 17px;
    color: var(--color-black);
    line-height: 1.24;
    cursor: pointer;
}
.sp-variant-details[data-v-23e03094] {
    width: auto;
    height: auto;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    font-size: 17px;
    color: var(--color-black);
    line-height: 1.24;
    cursor: pointer;
}
.active.sp-variant-details[data-v-23e03094] {
    background-color: #000000;
    color: #f6f8fa;
}
.active.sp-size-details[data-v-23e03094] {
    background-color: #000000;
    color: #f6f8fa;
}
.sp-addto-cart-btn[data-v-23e03094],
.sp-buy-now-btn[data-v-23e03094] {
    min-width: 125px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #000;
}
.sp-buy-now-btn[data-v-23e03094] {
    background-color: #ffff;
    color: #000;
}
.sp-buy-now-btn[data-v-23e03094]:hover{
    background-color: #000;
    color: #ffffff;
}
.sp-addto-cart-btn[data-v-23e03094], .sp-addto-cart-btn[data-v-23e03094]:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}



/* .yt-cm-lt-col {
    padding-right: 7px;
}

.yt-cm-rt-col {
    padding-left: 7px;
} */
.sp-product-add-action-wrap[data-v-23e03094] {
    margin-top: 30px;
}
.sp-product-notification-wrap[data-v-23e03094] {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.sp-inner-content-wrap[data-v-23e03094] {
    padding: 20px 30px 0px 30px;
    /* margin-top: 20px; */
}
@media (min-width: 1280px) {
.yt-tab-mob[data-v-23e03094] {
        display: none;
}
}

/* @media (min-width: 1280.88px) {
    .yt-cm-lt-col {
        padding-right: 10px;
    }
    .yt-cm-rt-col {
        padding-left: 10px;
    }
}

@media (min-width: 1900px) {
    .yt-cm-lt-col {
        padding-right: 20px;
    }
    .yt-cm-rt-col {
        padding-left: 20px;
    }
} */
@media (min-width: 769px) {
.sp-price-left-content[data-v-23e03094],
    .sp-price-right-content[data-v-23e03094] {
        width: 100%;
}
}
@media (min-width: 768px) {
.yt-read-more-des[data-v-23e03094] {
        display: none;
}
}
@media (min-width: 576px) {
.sp-addto-cart-btn[data-v-23e03094],
    .sp-buy-now-btn[data-v-23e03094] {
        min-width: 300px;
}
.sp-image-slide li.navigation-up[data-v-23e03094] {
        transform: rotate(180deg);
}
}
@media (min-width: 1280px) {
.sp-border-md-right[data-v-23e03094] {
        border-right: 2px solid #f6f8fa;
        padding-right: 15px;
}
}


/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.sp-breadcrumbs[data-v-23e03094] {
        margin-bottom: 20px;
}
.sp-inner-wrap[data-v-23e03094],
    .sp-inner-content-wrap[data-v-23e03094] {
        padding: 20px 15px;
}
.product-title[data-v-23e03094] {
        font-size: 18px;
        line-height: 1.33;
        margin-bottom: 7px;
}
    /* .sp-price-wrap,
        .sp-size-wrap {
            padding: 20px 0;
        } */
.sp-small-tag-name[data-v-23e03094] {
        font-size: 12px;
        line-height: 1.17;
        letter-spacing: 0.46px;
}
.sp-price-tag-value[data-v-23e03094] {
        font-size: 20px;
        line-height: 1.15;
        letter-spacing: 0.77px;
}
.sp-quantity-tag-name[data-v-23e03094] {
        font-size: 12px;
        line-height: 2.33;
}
.sp-price-right-content .sp-quantity-tag-name[data-v-23e03094] {
        font-size: 11px;
        line-height: 1.18;
}
.sp-addto-cart-btn[data-v-23e03094],
    .sp-buy-now-btn[data-v-23e03094] {
        padding-top: 10px !important;
        padding-bottom: 12px !important;
        min-width: 150px;
        font-size: 16px;
        line-height: 1.13;
        letter-spacing: 0.32px;
}
.sp-description-title[data-v-23e03094] {
        font-size: 16px;
        line-height: 1.13;
        margin-bottom: 9px;
}
.sp-description-text[data-v-23e03094] {
        font-size: 14px;
        line-height: 1.71;
}
.sp-rating-content[data-v-23e03094] {
        font-size: 20px;
        line-height: 1.15;
}
.yt-rating-val-wrapper[data-v-23e03094] {
        margin: 8px 0;
}
.sp-rating-view span[data-v-23e03094]:first-child {
        font-size: 12px;
        line-height: 1.75;
}
.yt-rating-col[data-v-23e03094] {
        flex: 0 0 100%;
        max-width: 100%;
}
.sp-size-details[data-v-23e03094] {
        width: 41px;
        height: 30px;
        font-size: 12px;
}
.sp-inner-write-review[data-v-23e03094] {
        border-bottom: 2px solid #cae2fe;
        border-top: 2px solid #cae2fe;
        padding: 15px 0;
        margin-bottom: 5px;
}
.sp-user-name[data-v-23e03094] {
        font-size: 16px;
        line-height: 1.13;
}
.sp-rating-wrap[data-v-23e03094] {
        border: none;
        padding: 0 !important;
}
.sp-inner-write-review .sp-description-title[data-v-23e03094] {
        font-size: 14px;
        line-height: 1.14;
}
.sp-user-rated-meta ul[data-v-23e03094] {
        display: none !important;
}
.sp-user-info .yt-tab-mob ul li img[data-v-23e03094] {
        max-width: 12px;
}
.sp-rating-date[data-v-23e03094] {
        padding-top: 10px;
}
span.sp-rating-area[data-v-23e03094] {
        width: 80%;
}
.yt-rating-inner-row[data-v-23e03094] {
        flex-wrap: nowrap !important;
}
.yt-rating-inner-row .sp-rating-inner-wrap[data-v-23e03094]:last-child {
        width: 100%;
        padding-left: 15px;
}
.yt-rating-inner-row .sp-rating-inner-wrap[data-v-23e03094]:first-child {
        width: 255px !important;
}
.yt-rating-inner-row .sp-rating-inner-wrap:last-child ul li span[data-v-23e03094]:nth-child(2) {
        padding: 0 7px;
}
.yt-rating-inner-row .sp-rating-inner-wrap:last-child ul li span[data-v-23e03094]:first-child {
        width: 8px;
}
.sp-inner-write-review .sp-give-review-btn[data-v-23e03094] {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 1.13;
        letter-spacing: 0.32px;
        padding-top: 11px !important;
        padding-bottom: 11px !important;
}
.sp-rating-wrap .sp-user-info[data-v-23e03094] {
        padding-left: 8px !important;
}
.sp-user-rated-meta[data-v-23e03094] {
        width: 70px;
}
#sp-size-data li[data-v-23e03094] {
        margin-right: 4px !important;
}
.sp-product-add-action-wrap[data-v-23e03094] {
        margin-top: 20px;
}
.sp-out-quantity-tag-name[data-v-23e03094] {
        font-size: 12px;
        line-height: 2.33;
}
.yt-product-img-inner-row .sp-inner-content-wrap[data-v-23e03094] {
        text-align: center;
}
}


/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-cm-lt-col[data-v-23e03094],
    .yt-cm-rt-col[data-v-23e03094],
    .yt-sp-description-col[data-v-23e03094] {
        flex: 0 0 100%;
        max-width: 100%;
}
.yt-product-img-inner-row .sp-inner-content-wrap[data-v-23e03094] {
        text-align: center;
}
    /* .yt-cm-lt-col {
        padding-right: 15px;
    }
    .yt-cm-rt-col {
        padding-left: 15px;
    } */
.right-inner-content-wrap[data-v-23e03094] {
        margin-top: 10px;
}
.right-inner-content-wrap[data-v-23e03094],
    .yt-lt-inner-bottom-content[data-v-23e03094] {
        margin-left: -20px;
        margin-right: -20px;
        overflow: hidden;
}
.right-inner-content-wrap .sp-inner-wrap[data-v-23e03094] {
        border-radius: 0 !important;
}
.right-inner-content-wrap .sp-size-wrap[data-v-23e03094] {
        display: none;
}
.right-inner-content-wrap .sp-price-wrap[data-v-23e03094] {
        border: none;
}
.product-title[data-v-23e03094] {
        max-width: 100%;
        width: 100%;
}
.sp-price-wrap.yt-mb-space-border[data-v-23e03094] {
        padding-bottom: 0;
}
.yt-mb-space-border[data-v-23e03094] {
        position: relative;
}
.yt-mb-space-border[data-v-23e03094]::after {
        content: "";
        width: 150%;
        height: 4px;
        background: #f2f6f8;
        position: absolute;
        left: -20px;
        top: 0;
}
.yt-lt-inner-bottom-content .sp-inner-content-wrap[data-v-23e03094] {
        border-radius: 0;
}
.sp-description-text[data-v-23e03094] {
        font-size: 17px;
        color: var(--color-black);
        line-height: 1.24;
}
.sp-small-tag-name[data-v-23e03094],
    .sp-description-title[data-v-23e03094] {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.15;
        letter-spacing: 0.5px;
        color: var(--color-dark-grey);
}
.sp-product-add-action-wrap[data-v-23e03094] {
        width: 100%;
}
.sp-product-add-action-wrap li[data-v-23e03094] {
        width: 50%;
}
.sp-description-text[data-v-23e03094] {
        max-height: 44px;
        overflow: hidden;
}
.sp-description-wrap.active-desc .sp-description-text[data-v-23e03094] {
        max-height: 100%;
}
.yt-read-more-des[data-v-23e03094] {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        color: #3bc490;
}
.sp-inner-write-review[data-v-23e03094] {
        border: none;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-23e03094]:last-child {
        border-bottom: none;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-23e03094]:first-child {
        border-top: solid 2px #f2f6f8;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-23e03094] {
        border-bottom: solid 2px #f2f6f8;
}
.yt-sp-title-wrapper[data-v-23e03094] {
        flex-wrap: wrap-reverse;
}
.sp-favorite-wrap[data-v-23e03094] {
        margin-left: auto;
        margin-bottom: 10px;
}
.sp-price-right-content .sp-quantity-tag-name[data-v-23e03094] {
        display: none;
}
.sp-addto-cart-btn[data-v-23e03094],
    .sp-buy-now-btn[data-v-23e03094] {
        width: 100%;
}
.sp-out-quantity-tag-name[data-v-23e03094] {
        line-height: 1.75;
}
.yt-lt-inner-bottom-content .sp-inner-content-wrap[data-v-23e03094] {
        margin-top: 0;
}
}
@media (max-width: 575.98px) {
.sp-rating-wrap .sp-rating-inner-wrap[data-v-23e03094] {
        width: 100%;
}
.sp-image-slide li.navigation-down[data-v-23e03094] {
        transform: rotate(270deg);
}
.sp-image-slide li.navigation-up[data-v-23e03094] {
        transform: rotate(90deg);
}
.sp-image-slide li[data-v-23e03094] {
        margin: 0 10px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.sp-price-tag-value[data-v-23e03094] {
        font-size: 24px;
}
.sp-quantity-tag-name[data-v-23e03094] {
        font-size: 14px;
}
.sp-addto-cart-btn[data-v-23e03094],
    .sp-buy-now-btn[data-v-23e03094] {
        font-size: 16px;
}
.sp-give-review-btn[data-v-23e03094] {
        font-size: 16px;
}
}
@media only screen and (max-device-width: 480px) {
.product-title[data-v-23e03094]{
      font-size: 30px;
}
}
/*
* Global CSS of Help Center
*/

/*
* Breadcrumbs CSS
*/
.hc-breadcrumbs[data-v-21683468] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.hc-inner-wrap[data-v-21683468] {
  padding: 40px 40px 36px;
  border: solid 1px #dbdbdb;
}
.hc-inner-contnet .hc-tabs-name li[data-v-21683468]:first-child {
  padding-top: 0;
}
.hc-inner-contnet .hc-tabs-name li[data-v-21683468]:last-child {
  padding-bottom: 0;
}
.hc-current[data-v-21683468] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.hc-beckfrom-ct-page[data-v-21683468] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.hc-mb-30[data-v-21683468] {
  margin-bottom: 30px;
}
.hc-mt-30[data-v-21683468] {
  margin-bottom: 30px;
}
.hc-mb-50[data-v-21683468] {
  margin-bottom: 50px;
}
.radius-10[data-v-21683468] {
  border-radius: 10px;
}
.list-style-none[data-v-21683468] {
  list-style: none;
}
.hcp-back-arrow[data-v-21683468] {
  cursor: pointer;
}
/*
* Left Col CSS
*/
.hc-inner-contnet .hc-tabs-name li[data-v-21683468] {
  padding: 23.5px 0;
  border-bottom: 2px solid #e6e6e6;
  font-size: 18px;
  line-height: 1.15;
  color: var(--color-black);
  cursor: pointer;
}
.hc-inner-contnet .hc-tabs-name li[data-v-21683468]:last-child {
  border: none;
  padding-bottom: 0;
}
.hc-inner-contnet .hc-tabs-name li.active[data-v-21683468] {
  font-weight: bold;
  color: var(--color-dark-black);
}

/*
* Right Col CSS
*/
.hc-tab-main-title[data-v-21683468] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 40px;
}
.hc-tab-sub-title[data-v-21683468] {
  font-size: 20px;
  font-weight: bolder;
  color: var(--color-black);
  line-height: 1.15;
  margin-bottom: 10px;
}
.ht-tab-description[data-v-21683468] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}
.hc-tab-content[data-v-21683468] {
  padding: 40px 40px 23px 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.yt-cm-lt-col[data-v-21683468] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-21683468] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.hc-inner-contnet.yt-tab-movile-hc[data-v-21683468],
  .yt-mb-pg-ttl[data-v-21683468] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-21683468] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-21683468] {
    padding-left: 10px;
}
.hc-tab-content[data-v-21683468] {
    margin-top: 115px;
}
}
@media (min-width: 1400px) {
.hc-tab-content[data-v-21683468] {
    padding: 40px 40px 23px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-21683468] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-21683468] {
    padding-left: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-21683468] {
    font-size: 20px;
}
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-cm-lt-col[data-v-21683468] {
    max-width: 30%;
}
.yt-cm-rt-col[data-v-21683468] {
    flex: 0 0 70%;
    max-width: 70%;
}
.yt-cm-lt-col .hc-inner-wrap[data-v-21683468] {
    padding: 33px 15px;
}
.hc-inner-wrap.hc-tab-content[data-v-21683468] {
    margin-top: 48px;
    padding: 15px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.hc-beckfrom-ct-page[data-v-21683468] {
    margin: 0 0 20px !important;
}
.hc-tab-main-title[data-v-21683468] {
    font-size: 20px;
    line-height: 1.15;
    margin-bottom: 17px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-21683468] {
    font-size: 14px;
    line-height: 1.14;
    padding: 20px 0;
}
.hc-tab-sub-title[data-v-21683468] {
    font-size: 16px;
    line-height: 1.13;
}
.ht-tab-description[data-v-21683468] {
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.14px;
}
.hc-beckfrom-ct-page[data-v-21683468] {
    font-size: 24px;
    line-height: 1.17;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.hc-inner-contnet.yt-desk-hc[data-v-21683468] {
    display: none;
}
.yt-cm-lt-col[data-v-21683468] {
    padding-right: 6px;
    padding-left: 6px;
}
.hc-beckfrom-ct-page[data-v-21683468] {
    margin: 10px 0 !important ;
    font-size: 18px;
    line-height: 1.11;
}
  /* .hc-beckfrom-ct-page svg {
    display: none;
  } */
.hc-inner-wrap[data-v-21683468] {
    padding: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-21683468] {
    line-height: 1.2;
    font-size: 15px;
    padding: 10px 0;
}
  
  /* .yt-desktop-datapg .yt-cm-rt-col,
  .yt-mobile-datapg .yt-cm-lt-col {
    display: none;
  } */
  /* .yt-mobile-datapg .yt-cm-rt-col {
    display: block;
  } */
  /* .yt-mb-pg-ttl .hc-beckfrom-ct-page svg {
    display: block;
  } */
.hc-tab-main-title[data-v-21683468] {
    font-size: 18px;
    line-height: 1.11;
    position: absolute;
    top: 9px;
    margin: 0 !important;
    left: 35px;
}
.hc-tab-sub-title[data-v-21683468] {
    line-height: 1.13;
    font-size: 16px;
}
.ht-tab-description[data-v-21683468] {
    font-size: 12px;
    line-height: 1.92;
    letter-spacing: 0.12px;
}
}

/*
* Global CSS of Help Center
*/

/*
* Breadcrumbs CSS
*/
.hc-breadcrumbs[data-v-20da2f85] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.hc-inner-wrap[data-v-20da2f85] {
  padding: 40px 40px 36px;
  border: solid 1px #dbdbdb;
}
.hc-inner-contnet .hc-tabs-name li[data-v-20da2f85]:first-child {
  padding-top: 0;
}
.hc-inner-contnet .hc-tabs-name li[data-v-20da2f85]:last-child {
  padding-bottom: 0;
}
.hc-current[data-v-20da2f85] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.hc-beckfrom-ct-page[data-v-20da2f85] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.hc-mb-30[data-v-20da2f85] {
  margin-bottom: 30px;
}
.hc-mt-30[data-v-20da2f85] {
  margin-bottom: 30px;
}
.hc-mb-50[data-v-20da2f85] {
  margin-bottom: 50px;
}
.radius-10[data-v-20da2f85] {
  border-radius: 10px;
}
.list-style-none[data-v-20da2f85] {
  list-style: none;
}
.hcp-back-arrow[data-v-20da2f85] {
  cursor: pointer;
}
/*
* Left Col CSS
*/
.hc-inner-contnet .hc-tabs-name li[data-v-20da2f85] {
  padding: 23.5px 0;
  border-bottom: 2px solid #e6e6e6;
  font-size: 18px;
  line-height: 1.15;
  color: var(--color-black);
  cursor: pointer;
}
.hc-inner-contnet .hc-tabs-name li[data-v-20da2f85]:last-child {
  border: none;
  padding-bottom: 0;
}
.hc-inner-contnet .hc-tabs-name li.active[data-v-20da2f85] {
  font-weight: 500;
  color: var(--color-dark-black);
}
/*
* Right Col CSS
*/
.hc-tab-main-title[data-v-20da2f85] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 40px;
}
.hc-tab-sub-title[data-v-20da2f85] {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
  margin-bottom: 10px;
}
.ht-tab-description[data-v-20da2f85] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}
.hc-tab-content[data-v-20da2f85] {
  padding: 40px 40px 23px 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.yt-cm-lt-col[data-v-20da2f85] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-20da2f85] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.hc-inner-contnet.yt-tab-movile-hc[data-v-20da2f85],
  .yt-mb-pg-ttl[data-v-20da2f85] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-20da2f85] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-20da2f85] {
    padding-left: 10px;
}
.hc-tab-content[data-v-20da2f85] {
    margin-top: 115px;
}
}
@media (min-width: 1400px) {
.hc-tab-content[data-v-20da2f85] {
    padding: 40px 40px 23px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-20da2f85] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-20da2f85] {
    padding-left: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-20da2f85] {
    font-size: 20px;
}
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-cm-lt-col[data-v-20da2f85] {
    max-width: 30%;
}
.yt-cm-rt-col[data-v-20da2f85] {
    flex: 0 0 70%;
    max-width: 70%;
}
.yt-cm-lt-col .hc-inner-wrap[data-v-20da2f85] {
    padding: 33px 15px;
}
.hc-inner-wrap.hc-tab-content[data-v-20da2f85] {
    margin-top: 48px;
    padding: 15px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.hc-beckfrom-ct-page[data-v-20da2f85] {
    margin: 0 0 20px !important;
}
.hc-tab-main-title[data-v-20da2f85] {
    font-size: 20px;
    line-height: 1.15;
    margin-bottom: 17px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-20da2f85] {
    font-size: 14px;
    line-height: 1.14;
    padding: 20px 0;
}
.hc-tab-sub-title[data-v-20da2f85] {
    font-size: 16px;
    line-height: 1.13;
}
.ht-tab-description[data-v-20da2f85] {
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.14px;
}
.hc-beckfrom-ct-page[data-v-20da2f85] {
    font-size: 24px;
    line-height: 1.17;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.hc-inner-contnet.yt-desk-hc[data-v-20da2f85] {
    display: none;
}
.yt-cm-lt-col[data-v-20da2f85] {
    padding-right: 6px;
    padding-left: 6px;
}
.hc-beckfrom-ct-page[data-v-20da2f85] {
    margin: 10px 0 !important ;
    font-size: 18px;
    line-height: 1.11;
}
.hc-beckfrom-ct-page svg[data-v-20da2f85] {
    display: none;
}
.hc-inner-wrap[data-v-20da2f85] {
    padding: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-20da2f85] {
    line-height: 1.2;
    font-size: 15px;
    padding: 10px 0;
}
.yt-desktop-datapg .yt-cm-rt-col[data-v-20da2f85],
  .yt-mobile-datapg .yt-cm-lt-col[data-v-20da2f85] {
    display: none;
}
.yt-mobile-datapg .yt-cm-rt-col[data-v-20da2f85] {
    display: block;
}
.yt-mb-pg-ttl .hc-beckfrom-ct-page svg[data-v-20da2f85] {
    display: block;
}
.hc-tab-main-title[data-v-20da2f85] {
    font-size: 18px;
    line-height: 1.11;
    position: absolute;
    top: 9px;
    margin: 0 !important;
    left: 35px;
}
.hc-tab-sub-title[data-v-20da2f85] {
    line-height: 1.13;
    font-size: 16px;
}
.ht-tab-description[data-v-20da2f85] {
    font-size: 12px;
    line-height: 1.92;
    letter-spacing: 0.12px;
}
}

/*
* Global CSS of Help Center
*/

/*
* Breadcrumbs CSS
*/
.hc-breadcrumbs[data-v-6ed4d09e] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.hc-inner-wrap[data-v-6ed4d09e] {
  padding: 40px 40px 36px;
  border: solid 1px #dbdbdb;
}
.hc-inner-contnet .hc-tabs-name li[data-v-6ed4d09e]:first-child {
  padding-top: 0;
}
.hc-inner-contnet .hc-tabs-name li[data-v-6ed4d09e]:last-child {
  padding-bottom: 0;
}
.hc-current[data-v-6ed4d09e] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.hc-beckfrom-ct-page[data-v-6ed4d09e] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.hc-mb-30[data-v-6ed4d09e] {
  margin-bottom: 30px;
}
.hc-mt-30[data-v-6ed4d09e] {
  margin-bottom: 30px;
}
.hc-mb-50[data-v-6ed4d09e] {
  margin-bottom: 50px;
}
.radius-10[data-v-6ed4d09e] {
  border-radius: 10px;
}
.list-style-none[data-v-6ed4d09e] {
  list-style: none;
}
.hcp-back-arrow[data-v-6ed4d09e] {
  cursor: pointer;
}
/*
* Left Col CSS
*/
.hc-inner-contnet .hc-tabs-name li[data-v-6ed4d09e] {
  padding: 23.5px 0;
  border-bottom: 2px solid #e6e6e6;
  font-size: 18px;
  line-height: 1.15;
  color: var(--color-black);
  cursor: pointer;
}
.hc-inner-contnet .hc-tabs-name li[data-v-6ed4d09e]:last-child {
  border: none;
  padding-bottom: 0;
}
.hc-inner-contnet .hc-tabs-name li.active[data-v-6ed4d09e] {
  font-weight: 500;
  color: var(--color-dark-black);
}
/*
* Right Col CSS
*/
.hc-tab-main-title[data-v-6ed4d09e] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 40px;
}
.hc-tab-sub-title[data-v-6ed4d09e] {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
  margin-bottom: 10px;
}
.ht-tab-description[data-v-6ed4d09e] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}
.hc-tab-content[data-v-6ed4d09e] {
  padding: 40px 40px 23px 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.yt-cm-lt-col[data-v-6ed4d09e] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-6ed4d09e] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.hc-inner-contnet.yt-tab-movile-hc[data-v-6ed4d09e],
  .yt-mb-pg-ttl[data-v-6ed4d09e] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-6ed4d09e] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-6ed4d09e] {
    padding-left: 10px;
}
.hc-tab-content[data-v-6ed4d09e] {
    margin-top: 115px;
}
}
@media (min-width: 1400px) {
.hc-tab-content[data-v-6ed4d09e] {
    padding: 40px 40px 23px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-6ed4d09e] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-6ed4d09e] {
    padding-left: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-6ed4d09e] {
    font-size: 20px;
}
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-cm-lt-col[data-v-6ed4d09e] {
    max-width: 30%;
}
.yt-cm-rt-col[data-v-6ed4d09e] {
    flex: 0 0 70%;
    max-width: 70%;
}
.yt-cm-lt-col .hc-inner-wrap[data-v-6ed4d09e] {
    padding: 33px 15px;
}
.hc-inner-wrap.hc-tab-content[data-v-6ed4d09e] {
    margin-top: 48px;
    padding: 15px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.hc-beckfrom-ct-page[data-v-6ed4d09e] {
    margin: 0 0 20px !important;
}
.hc-tab-main-title[data-v-6ed4d09e] {
    font-size: 20px;
    line-height: 1.15;
    margin-bottom: 17px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-6ed4d09e] {
    font-size: 14px;
    line-height: 1.14;
    padding: 20px 0;
}
.hc-tab-sub-title[data-v-6ed4d09e] {
    font-size: 16px;
    line-height: 1.13;
}
.ht-tab-description[data-v-6ed4d09e] {
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.14px;
}
.hc-beckfrom-ct-page[data-v-6ed4d09e] {
    font-size: 24px;
    line-height: 1.17;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.hc-inner-contnet.yt-desk-hc[data-v-6ed4d09e] {
    display: none;
}
.yt-cm-lt-col[data-v-6ed4d09e] {
    padding-right: 6px;
    padding-left: 6px;
}
.hc-beckfrom-ct-page[data-v-6ed4d09e] {
    margin: 10px 0 !important ;
    font-size: 18px;
    line-height: 1.11;
}
.hc-inner-wrap[data-v-6ed4d09e] {
    padding: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-6ed4d09e] {
    line-height: 1.2;
    font-size: 15px;
    padding: 10px 0;
}
.yt-mobile-datapg .yt-cm-rt-col[data-v-6ed4d09e] {
    display: block;
}
.yt-mb-pg-ttl .hc-beckfrom-ct-page svg[data-v-6ed4d09e] {
    display: block;
}
.hc-tab-main-title[data-v-6ed4d09e] {
    font-size: 18px;
    line-height: 1.11;
    position: absolute;
    top: 9px;
    margin: 0 !important;
    left: 35px;
}
.hc-tab-sub-title[data-v-6ed4d09e] {
    line-height: 1.13;
    font-size: 16px;
}
.ht-tab-description[data-v-6ed4d09e] {
    font-size: 12px;
    line-height: 1.92;
    letter-spacing: 0.12px;
}
}

/*
* Global CSS of Help Center
*/

/*
* Breadcrumbs CSS
*/
.hc-breadcrumbs[data-v-5ac54a0c] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
}
.hc-inner-wrap[data-v-5ac54a0c] {
  padding: 40px 40px 36px;
  border: solid 1px #dbdbdb;
}
.hc-inner-contnet .hc-tabs-name li[data-v-5ac54a0c]:first-child {
  padding-top: 0;
}
.hc-inner-contnet .hc-tabs-name li[data-v-5ac54a0c]:last-child {
  padding-bottom: 0;
}
.hc-current[data-v-5ac54a0c] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.hc-beckfrom-ct-page[data-v-5ac54a0c] {
  margin-top: 50px;
  color: var(--color-black);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
}
.hc-mb-30[data-v-5ac54a0c] {
  margin-bottom: 30px;
}
.hc-mt-30[data-v-5ac54a0c] {
  margin-bottom: 30px;
}
.hc-mb-50[data-v-5ac54a0c] {
  margin-bottom: 50px;
}
.radius-10[data-v-5ac54a0c] {
  border-radius: 10px;
}
.list-style-none[data-v-5ac54a0c] {
  list-style: none;
}
.hcp-back-arrow[data-v-5ac54a0c] {
  cursor: pointer;
}
/*
* Left Col CSS
*/
.hc-inner-contnet .hc-tabs-name li[data-v-5ac54a0c] {
  padding: 23.5px 0;
  border-bottom: 2px solid #e6e6e6;
  font-size: 18px;
  line-height: 1.15;
  color: var(--color-black);
  cursor: pointer;
}
.hc-inner-contnet .hc-tabs-name li[data-v-5ac54a0c]:last-child {
  border: none;
  padding-bottom: 0;
}
.hc-inner-contnet .hc-tabs-name li.active[data-v-5ac54a0c] {
  font-weight: 500;
  color: var(--color-dark-black);
}

/*
* Right Col CSS
*/
.hc-tab-main-title[data-v-5ac54a0c] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 40px;
}
.hc-tab-sub-title[data-v-5ac54a0c] {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1.15;
  margin-bottom: 10px;
}
.ht-tab-description[data-v-5ac54a0c] {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.18px;
  color: var(--color-dark-grey);
}
.hc-tab-content[data-v-5ac54a0c] {
  padding: 40px 40px 23px 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.yt-cm-lt-col[data-v-5ac54a0c] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-5ac54a0c] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.hc-inner-contnet.yt-tab-movile-hc[data-v-5ac54a0c],
  .yt-mb-pg-ttl[data-v-5ac54a0c] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-5ac54a0c] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-5ac54a0c] {
    padding-left: 10px;
}
.hc-tab-content[data-v-5ac54a0c] {
    margin-top: 115px;
}
}
@media (min-width: 1400px) {
.hc-tab-content[data-v-5ac54a0c] {
    padding: 40px 40px 23px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-5ac54a0c] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-5ac54a0c] {
    padding-left: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-5ac54a0c] {
    font-size: 20px;
}
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-cm-lt-col[data-v-5ac54a0c] {
    max-width: 30%;
}
.yt-cm-rt-col[data-v-5ac54a0c] {
    flex: 0 0 70%;
    max-width: 70%;
}
.yt-cm-lt-col .hc-inner-wrap[data-v-5ac54a0c] {
    padding: 33px 15px;
}
.hc-inner-wrap.hc-tab-content[data-v-5ac54a0c] {
    margin-top: 48px;
    padding: 15px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.hc-beckfrom-ct-page[data-v-5ac54a0c] {
    margin: 0 0 20px !important;
}
.hc-tab-main-title[data-v-5ac54a0c] {
    font-size: 20px;
    line-height: 1.15;
    margin-bottom: 17px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-5ac54a0c] {
    font-size: 14px;
    line-height: 1.14;
    padding: 20px 0;
}
.hc-tab-sub-title[data-v-5ac54a0c] {
    font-size: 16px;
    line-height: 1.13;
}
.ht-tab-description[data-v-5ac54a0c] {
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: 0.14px;
}
.hc-beckfrom-ct-page[data-v-5ac54a0c] {
    font-size: 24px;
    line-height: 1.17;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.hc-inner-contnet.yt-desk-hc[data-v-5ac54a0c] {
    display: none;
}
.yt-cm-lt-col[data-v-5ac54a0c] {
    padding-right: 6px;
    padding-left: 6px;
}
.hc-beckfrom-ct-page[data-v-5ac54a0c] {
    margin: 10px 0 !important ;
    font-size: 18px;
    line-height: 1.11;
}
.hc-beckfrom-ct-page svg[data-v-5ac54a0c] {
    display: none;
}
.hc-inner-wrap[data-v-5ac54a0c] {
    padding: 20px;
}
.hc-inner-contnet .hc-tabs-name li[data-v-5ac54a0c] {
    line-height: 1.2;
    font-size: 15px;
    padding: 10px 0;
}
.yt-desktop-datapg .yt-cm-rt-col[data-v-5ac54a0c],
  .yt-mobile-datapg .yt-cm-lt-col[data-v-5ac54a0c] {
    display: none;
}
.yt-mobile-datapg .yt-cm-rt-col[data-v-5ac54a0c] {
    display: block;
}
.yt-mb-pg-ttl .hc-beckfrom-ct-page svg[data-v-5ac54a0c] {
    display: block;
}
.hc-tab-main-title[data-v-5ac54a0c] {
    font-size: 18px;
    line-height: 1.11;
    position: absolute;
    top: 9px;
    margin: 0 !important;
    left: 35px;
}
.hc-tab-sub-title[data-v-5ac54a0c] {
    line-height: 1.13;
    font-size: 16px;
}
.ht-tab-description[data-v-5ac54a0c] {
    font-size: 12px;
    line-height: 1.92;
    letter-spacing: 0.12px;
}
}


/*
* Global CSS of Help Center
*/

/*
* Breadcrumbs CSS
*/


.radius-10[data-v-65a95d71] {
  border-radius: 10px;
}
.cart-pg-mb-30[data-v-65a95d71] {
  margin-bottom: 30px;
}
.empty-cartn-ttl[data-v-65a95d71] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.empty-cart-text[data-v-65a95d71] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.empty-cart-btn[data-v-65a95d71] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}

.radius-10[data-v-697092bb] {
  border-radius: 10px;
}
.cart-pg-mb-30[data-v-697092bb] {
  margin-bottom: 30px;
}
.empty-cartn-ttl[data-v-697092bb] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.empty-cart-text[data-v-697092bb] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.empty-cart-btn[data-v-697092bb] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}



.login-wrapper[data-v-c1e63a44] {
  margin: 60px auto;
  padding: 20px 30px;
  max-width: 570px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 3px 3px 30px #eee;
  background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-c1e63a44] {
  border-bottom: 3px solid #8C9095;
  font-weight: bold;
  color: #000;
}
.login-wrapper .nav-item[data-v-c1e63a44] {
  cursor: pointer;
  color: #989CA2;
}
.yt-forgot-pw-wrap[data-v-c1e63a44] {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
}
.yt-fpp-mb-5[data-v-c1e63a44] {
  margin-bottom: 50px;
}
.yt-fpp-mt-5[data-v-c1e63a44] {
  margin-top: 50px;
}
.yt-fp-top-back-tag-name[data-v-c1e63a44] {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: #000;
}
.yt-fp-back-icn[data-v-c1e63a44] {
  font-size: 40px;
  margin-left: -12px;
}
.yt-fp-bg-banner[data-v-c1e63a44] {
  background-image: url(/static/media/login-image.82f445ed.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-fp-other-info[data-v-c1e63a44] {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-fp-oi-tag-small[data-v-c1e63a44] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-fp-oi-tag-big[data-v-c1e63a44] {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-fp-bdr[data-v-c1e63a44] {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-fp-sp-now[data-v-c1e63a44] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}
.yt-fp-tag-line[data-v-c1e63a44] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 30px;
  margin-top: 16px;
}
.yt-fp-tag-line-2[data-v-c1e63a44] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 10px;
}
.navigate[data-v-c1e63a44]{
  cursor: pointer;
}
.yt-fp-form .form-group[data-v-c1e63a44] {
  position: relative;
  margin-bottom: 30px;
}
.yt-fp-icn[data-v-c1e63a44] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-c1e63a44] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-form[data-v-c1e63a44] {
  margin-bottom: 30px;
}
.yt-fp-form .form-group .form-control[data-v-c1e63a44] {
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.yt-fp-btn[data-v-c1e63a44] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
}
.yt-fp-btn[data-v-c1e63a44]:hover{
  background-color: black;
  color: white;
}
.yt-fp-skip-btn[data-v-c1e63a44] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.otp-timer[data-v-c1e63a44] {
  font-size: 18px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}
.yt-resent-otp-btn[data-v-c1e63a44] {
  font-size: 18px;
  line-height: 1.14;
  color: var(--color-orange);
  text-decoration: underline;
  box-shadow: none !important;
}
.yt-fp-form .error.form-group .form-control[data-v-c1e63a44] {
  border: solid 1px var(--color-orange);
}
.yt-optp-message-show[data-v-c1e63a44] {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -18px;
  left: 0;
}
.yt-fp-form .error.form-group .yt-optp-message-show[data-v-c1e63a44] {
  color: var(--color-orange);
  display: block;
}
.yt-pas-eye-icn[data-v-c1e63a44] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-pas-eye-icn2[data-v-c1e63a44] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-fp-form .success.form-group .yt-optp-message-show[data-v-c1e63a44] {
  color: var(--color-green);
  display: block;
}
.error.form-group[data-v-c1e63a44],
.success.form-group[data-v-c1e63a44] {
  margin-bottom: 43px !important;
}
.invalid[data-v-c1e63a44]{
 color: var(--color-orange);
}
.invalid-input[data-v-c1e63a44]{
  color: var(--color-orange); 
  border:1px solid var(--color-orange) !important;
}
.normal-input[data-v-c1e63a44]:active{
  border:1px solid var(--color-blue) !important;
}
.yt-forgot-pass-vie-icn[data-v-c1e63a44] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-forgot-pass-vie-icn2[data-v-c1e63a44] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.pass_success[data-v-c1e63a44]{
  color: green;
}
.yt-fp-icn[data-v-c1e63a44] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-c1e63a44] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
/**/
@media (min-width: 1400px) {
.yt-forgot-pw-wrap[data-v-c1e63a44] {
    max-width: 1340px;
}
.col-xxl-7[data-v-c1e63a44] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xxl-5[data-v-c1e63a44] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
}

.login-wrapper[data-v-5983587d] {
  margin: 60px auto;
  padding: 20px 30px;
  max-width: 570px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 3px 3px 30px #eee;
  background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-5983587d] {
  border-bottom: 3px solid #8C9095;
  font-weight: bold;
  color: #000;
}
.login-wrapper .nav-item[data-v-5983587d] {
  cursor: pointer;
  color: #989CA2;
}
.yt-forgot-pw-wrap[data-v-5983587d] {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
}
.yt-fpp-mb-5[data-v-5983587d] {
  margin-bottom: 50px;
}
.yt-fpp-mt-5[data-v-5983587d] {
  margin-top: 50px;
}
.yt-fp-top-back-tag-name[data-v-5983587d] {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.11;
  color: #000;
}
.yt-fp-back-icn[data-v-5983587d] {
  font-size: 40px;
  margin-left: -12px;
}
.yt-fp-bg-banner[data-v-5983587d] {
  background-image: url(/static/media/login-image.82f445ed.jpg);
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  min-height: 636px;
}
.yt-fp-other-info[data-v-5983587d] {
  position: absolute;
  right: 40px;
  bottom: 10%;
}
.yt-fp-oi-tag-small[data-v-5983587d] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-fp-oi-tag-big[data-v-5983587d] {
  font-size: 57px;
  font-weight: bold;
  line-height: 1.14;
  color: #ffffff;
}
.yt-fp-bdr[data-v-5983587d] {
  height: 2px;
  background: #ced6dc;
  width: 80%;
  display: block;
  margin-left: auto;
  position: absolute;
  bottom: 75px;
  right: -40px;
}
.yt-fp-sp-now[data-v-5983587d] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #ffffff;
  padding: 15px 25px;
  margin-top: 50px;
}
.yt-fp-tag-line[data-v-5983587d] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  margin-bottom: 30px;
  margin-top: 16px;
}
.yt-fp-tag-line-2[data-v-5983587d] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  margin-bottom: 10px;
}
.navigate[data-v-5983587d]{
  cursor: pointer;
}
.yt-fp-form .form-group[data-v-5983587d] {
  position: relative;
  margin-bottom: 30px;
}
.yt-fp-icn[data-v-5983587d] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-5983587d] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-form[data-v-5983587d] {
  margin-bottom: 30px;
}
.yt-fp-form .form-group .form-control[data-v-5983587d] {
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  margin-bottom: 9px;
  box-shadow: none;
  border: solid 1px #cae2fe;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.yt-fp-btn[data-v-5983587d] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: black;
  padding: 15px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: white;
  border: 1px solid black;
}
.yt-fp-btn[data-v-5983587d]:hover{
  background-color: black;
  color: white;
}
.yt-fp-skip-btn[data-v-5983587d] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.otp-timer[data-v-5983587d] {
  font-size: 18px;
  line-height: 1.14;
  text-align: center;
  color: var(--color-orange);
}
.yt-resent-otp-btn[data-v-5983587d] {
  font-size: 18px;
  line-height: 1.14;
  color: var(--color-orange);
  text-decoration: underline;
  box-shadow: none !important;
}
.yt-fp-form .error.form-group .form-control[data-v-5983587d] {
  border: solid 1px var(--color-orange);
}
.yt-optp-message-show[data-v-5983587d] {
  display: none;
  font-size: 12px;
  line-height: 1.17;
  position: absolute;
  bottom: -18px;
  left: 0;
}
.yt-fp-form .error.form-group .yt-optp-message-show[data-v-5983587d] {
  color: var(--color-orange);
  display: block;
}
.yt-pas-eye-icn[data-v-5983587d] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-pas-eye-icn2[data-v-5983587d] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-fp-form .success.form-group .yt-optp-message-show[data-v-5983587d] {
  color: var(--color-green);
  display: block;
}
.error.form-group[data-v-5983587d],
.success.form-group[data-v-5983587d] {
  margin-bottom: 43px !important;
}
.invalid[data-v-5983587d]{
 color: var(--color-orange);
}
.invalid-input[data-v-5983587d]{
  color: var(--color-orange); 
  border:1px solid var(--color-orange) !important;
}
.normal-input[data-v-5983587d]:active{
  border:1px solid var(--color-blue) !important;
}
.yt-forgot-pass-vie-icn[data-v-5983587d] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-forgot-pass-vie-icn2[data-v-5983587d] {
  position: absolute;
  top: 33%;
  transform: translateY(-50%);
  right: 20px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.pass_success[data-v-5983587d]{
  color: green;
}
.yt-fp-icn[data-v-5983587d] {
  max-width: 24px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.yt-fp-icn2[data-v-5983587d] {
  max-width: 24px;
  position: absolute;
  top: 33%;
  left: 20px;
  transform: translateY(-50%);
}
/**/
@media (min-width: 1400px) {
.yt-forgot-pw-wrap[data-v-5983587d] {
    max-width: 1340px;
}
.col-xxl-7[data-v-5983587d] {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-xxl-5[data-v-5983587d] {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
}

.login-wrapper[data-v-5983587d] {
    /* margin: 25px auto; */
    padding: 20px 30px;
    max-width: 570px;
    /* border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 3px 3px 30px #eee; */
    background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-5983587d] {
    border-bottom: solid 1px #212529;
    font-weight: bold;
    color: #000;
}
.tagline-title[data-v-5983587d]{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
}
.tagline[data-v-5983587d]{
    padding-bottom: 30px;
    text-align: center;
    font-size: 18px;
    color: #8b8f95;
    letter-spacing: 1px;
}
.login-wrapper .nav-item[data-v-5983587d] {
    cursor: pointer;
    color: #989CA2;
    width: 50%;
    text-align: center;
    font-size: 24px;
    letter-spacing: 1.5px;
    border-bottom: solid 1px #dbdbdb;
}
.sign-up-form .form-group[data-v-5983587d] {
    position: relative;
}
.sign-up-form .form-group img[data-v-5983587d]{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 11px;
    margin: 0 20px;
}
.sign-up-form .form-group .eye-icon[data-v-5983587d] {
    position: absolute;
    top: 14px;
    right: 25px;
}
.sign-up-form .form-group .form-control[data-v-5983587d] {
    padding: 20px 60px 20px 30px;
    font-size: 16px;
    color: #000;
}
.sign-up-form .form-group .form-control[data-v-5983587d]:active,
.sign-up-form .form-group .form-control[data-v-5983587d]:focus {
    border-color: #000;
}
.sign-up-form .password-hint[data-v-5983587d] {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}
.sign-up-form .form-group .error[data-v-5983587d] {
    color: red;
    margin-top: 4px;
    font-size: 12px;
}
.sign-up-form .btn-sign-up[data-v-5983587d] {
    color: black;
    background-color: #ffffff;
    border: 1px solid black;
    letter-spacing: 2px;
    margin-top: 20px;
    padding: 10px 0;
    font-size: 18px;
    transition: .5s ease;
}
.sign-up-form .btn-sign-up[data-v-5983587d]:hover{
    background-color: #000;
    color: white;
}
.other-actions[data-v-5983587d] {
    text-align: center;
}
.other-actions .skip-line-1[data-v-5983587d]{
    color: #8C9095;
    font-size: 16px;
    text-decoration: none;
}
.other-actions .skip-line-2[data-v-5983587d]{
    color: #8C9095;
    margin-top: 40px;
    font-size: 16px;
    margin-bottom: 0;
}
.other-actions .fb-button[data-v-5983587d] {
    background: transparent;
    color: #3b5998;
    border: none;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}
.other-actions .login-action[data-v-5983587d] {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 40px;
}
.other-actions .google-button[data-v-5983587d] {
    background: transparent;
    color: #EA4335;
    border: none;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.other-actions .terms-tagline[data-v-5983587d] {
    color: #8C9095;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
}
.forgot-password[data-v-5983587d] {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    float: right;
    padding-bottom: 15px;
}
.other-actions .terms a[data-v-5983587d]{
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0 5px;
}
.radius-10[data-v-434b707f] {
  border-radius: 10px;
}
.cart-pg-mb-30[data-v-434b707f] {
  margin-bottom: 30px;
}
.empty-cartn-ttl[data-v-434b707f] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
}
.empty-cart-text[data-v-434b707f] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.empty-cart-btn[data-v-434b707f] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}


.about-first .title[data-v-a9a9c259] {
  font-weight: bold;
  font-size: 22px;
  line-height: 1.4;
}
.about-first .para[data-v-a9a9c259] {
  font-weight: 400;
  color: #8E9298;
  text-align: justify;
  line-height: 1.6;
  font-size: 18px;
}
.yt-review-border[data-v-a9a9c259] {
  border: solid 1px #e6e6e6;
}
.yt-quote-icon[data-v-a9a9c259] {
  fill: #e6e6e6;
  font-size: 30px;
}
.yt-card-text[data-v-a9a9c259] {
  line-height: 1.5;
  min-height: 80px;
}
.yt-card-box-shadow[data-v-a9a9c259] {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  box-shadow: 5px 5px 21px 0px rgba(240, 240, 240, 1) !important;
}

:root {
  --color-primary: #d4c96d;
  --color-white: #ffffff;
  --color-black: black;
  --color-dark-grey: #8b8f95;
  --color-dark-black: #000000;
  --color-green: #43b7a7;
  --color-orange: #e65e52;
  --color-blue: #30458c;
}

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.btn:focus, .form-control:focus, .btn:active, .form-control:active, 
textarea, textarea:focus, textarea:active,
input, input:focus, input:active,
select, select:focus, select:active,
button, button:focus, button:active {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}
/* W3.CSS 4.13 June 2019 by Jan Egil and Borge Refsnes */
html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html{-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section{display:block}summary{display:list-item}
audio,canvas,progress,video{display:inline-block}progress{vertical-align:baseline}
audio:not([controls]){display:none;height:0}[hidden],template{display:none}
a{background-color:transparent}a:active,a:hover{outline-width:0}
abbr[title]{border-bottom:none;text-decoration:underline;-webkit-text-decoration:underline dotted;text-decoration:underline dotted}
b,strong{font-weight:bolder}dfn{font-style:italic}mark{background:#ff0;color:#000}
small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-0.25em}sup{top:-0.5em}figure{margin:1em 40px}img{border-style:none}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}hr{box-sizing:content-box;height:0;overflow:visible}
button,input,select,textarea,optgroup{font:inherit;margin:0}optgroup{font-weight:bold}
button,input{overflow:visible}button,select{text-transform:none}
button,[type=button],[type=reset],[type=submit]{-webkit-appearance:button}
button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{border-style:none;padding:0}
button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring{outline:1px dotted ButtonText}
fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:.35em .625em .75em}
legend{color:inherit;display:table;max-width:100%;padding:0;white-space:normal}textarea{overflow:auto}
[type=checkbox],[type=radio]{padding:0}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px}
[type=search]::-webkit-search-decoration{-webkit-appearance:none}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
/* End extract */
html,body{font-family:Verdana,sans-serif;font-size:15px;line-height:1.5}html{overflow-x:hidden}
h1{font-size:36px}h2{font-size:30px}h3{font-size:24px}h4{font-size:20px}h5{font-size:18px}h6{font-size:16px}.w3-serif{font-family:serif}
h1,h2,h3,h4,h5,h6{font-family:"Segoe UI",Arial,sans-serif;font-weight:400;margin:10px 0}.w3-wide{letter-spacing:4px}
hr{border:0;border-top:1px solid #eee;margin:20px 0}
.w3-image{max-width:100%;height:auto}img{vertical-align:middle}a{color:inherit}
.w3-table,.w3-table-all{border-collapse:collapse;border-spacing:0;width:100%;display:table}.w3-table-all{border:1px solid #ccc}
.w3-bordered tr,.w3-table-all tr{border-bottom:1px solid #ddd}.w3-striped tbody tr:nth-child(even){background-color:#f1f1f1}
.w3-table-all tr:nth-child(odd){background-color:#fff}.w3-table-all tr:nth-child(even){background-color:#f1f1f1}
.w3-hoverable tbody tr:hover,.w3-ul.w3-hoverable li:hover{background-color:#ccc}.w3-centered tr th,.w3-centered tr td{text-align:center}
.w3-table td,.w3-table th,.w3-table-all td,.w3-table-all th{padding:8px 8px;display:table-cell;text-align:left;vertical-align:top}
.w3-table th:first-child,.w3-table td:first-child,.w3-table-all th:first-child,.w3-table-all td:first-child{padding-left:16px}
.w3-btn,.w3-button{border:none;display:inline-block;padding:8px 16px;vertical-align:middle;overflow:hidden;text-decoration:none;color:inherit;background-color:inherit;text-align:center;cursor:pointer;white-space:nowrap}
.w3-btn:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)}
.w3-btn,.w3-button{-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none}   
.w3-disabled,.w3-btn:disabled,.w3-button:disabled{cursor:not-allowed;opacity:0.3}.w3-disabled *,:disabled *{pointer-events:none}
.w3-btn.w3-disabled:hover,.w3-btn:disabled:hover{box-shadow:none}
.w3-badge,.w3-tag{background-color:#000;color:#fff;display:inline-block;padding-left:8px;padding-right:8px;text-align:center}.w3-badge{border-radius:50%}
.w3-ul{list-style-type:none;padding:0;margin:0}.w3-ul li{padding:8px 16px;border-bottom:1px solid #ddd}.w3-ul li:last-child{border-bottom:none}
.w3-tooltip,.w3-display-container{position:relative}.w3-tooltip .w3-text{display:none}.w3-tooltip:hover .w3-text{display:inline-block}
.w3-ripple:active{opacity:0.5}.w3-ripple{transition:opacity 0s}
.w3-input{padding:8px;display:block;border:none;border-bottom:1px solid #ccc;width:100%}
.w3-select{padding:9px 0;width:100%;border:none;border-bottom:1px solid #ccc}
.w3-dropdown-click,.w3-dropdown-hover{position:relative;display:inline-block;cursor:pointer}
.w3-dropdown-hover:hover .w3-dropdown-content{display:block}
.w3-dropdown-hover:first-child,.w3-dropdown-click:hover{background-color:#ccc;color:#000}
.w3-dropdown-hover:hover > .w3-button:first-child,.w3-dropdown-click:hover > .w3-button:first-child{background-color:#ccc;color:#000}
.w3-dropdown-content{cursor:auto;color:#000;background-color:#fff;display:none;position:absolute;min-width:160px;margin:0;padding:0;z-index:1}
.w3-check,.w3-radio{width:24px;height:24px;position:relative;top:6px}
.w3-sidebar{height:100%;width:200px;background-color:#fff;position:fixed!important;z-index:1;overflow:auto}
.w3-bar-block .w3-dropdown-hover,.w3-bar-block .w3-dropdown-click{width:100%}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,.w3-bar-block .w3-dropdown-click .w3-dropdown-content{min-width:100%}
.w3-bar-block .w3-dropdown-hover .w3-button,.w3-bar-block .w3-dropdown-click .w3-button{width:100%;text-align:left;padding:8px 16px}
.w3-main,#main{transition:margin-left .4s}
.w3-modal{z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.w3-modal-content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;width:600px}
.w3-bar{width:100%;overflow:hidden}.w3-center .w3-bar{display:inline-block;width:auto}
.w3-bar .w3-bar-item{padding:8px 16px;float:left;width:auto;border:none;display:block;outline:0}
.w3-bar .w3-dropdown-hover,.w3-bar .w3-dropdown-click{position:static;float:left}
.w3-bar .w3-button{white-space:normal}
.w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
.w3-responsive{display:block;overflow-x:auto}
.w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
.w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
.w3-col,.w3-half,.w3-third,.w3-twothird,.w3-threequarter,.w3-quarter{float:left;width:100%}
.w3-col.s1{width:8.33333%}.w3-col.s2{width:16.66666%}.w3-col.s3{width:24.99999%}.w3-col.s4{width:33.33333%}
.w3-col.s5{width:41.66666%}.w3-col.s6{width:49.99999%}.w3-col.s7{width:58.33333%}.w3-col.s8{width:66.66666%}
.w3-col.s9{width:74.99999%}.w3-col.s10{width:83.33333%}.w3-col.s11{width:91.66666%}.w3-col.s12{width:99.99999%}
@media (min-width:601px){.w3-col.m1{width:8.33333%}.w3-col.m2{width:16.66666%}.w3-col.m3,.w3-quarter{width:24.99999%}.w3-col.m4,.w3-third{width:33.33333%}
.w3-col.m5{width:41.66666%}.w3-col.m6,.w3-half{width:49.99999%}.w3-col.m7{width:58.33333%}.w3-col.m8,.w3-twothird{width:66.66666%}
.w3-col.m9,.w3-threequarter{width:74.99999%}.w3-col.m10{width:83.33333%}.w3-col.m11{width:91.66666%}.w3-col.m12{width:99.99999%}}
@media (min-width:993px){.w3-col.l1{width:8.33333%}.w3-col.l2{width:16.66666%}.w3-col.l3{width:24.99999%}.w3-col.l4{width:33.33333%}
.w3-col.l5{width:41.66666%}.w3-col.l6{width:49.99999%}.w3-col.l7{width:58.33333%}.w3-col.l8{width:66.66666%}
.w3-col.l9{width:74.99999%}.w3-col.l10{width:83.33333%}.w3-col.l11{width:91.66666%}.w3-col.l12{width:99.99999%}}
.w3-rest{overflow:hidden}.w3-stretch{margin-left:-16px;margin-right:-16px}
.w3-content,.w3-auto{margin-left:auto;margin-right:auto}.w3-content{max-width:980px}.w3-auto{max-width:1140px}
.w3-cell-row{display:table;width:100%}.w3-cell{display:table-cell}
.w3-cell-top{vertical-align:top}.w3-cell-middle{vertical-align:middle}.w3-cell-bottom{vertical-align:bottom}
.w3-hide{display:none!important}.w3-show-block,.w3-show{display:block!important}.w3-show-inline-block{display:inline-block!important}
@media (max-width:1205px){.w3-auto{max-width:95%}}
@media (max-width:600px){.w3-modal-content{margin:0 10px;width:auto!important}.w3-modal{padding-top:30px}
.w3-dropdown-hover.w3-mobile .w3-dropdown-content,.w3-dropdown-click.w3-mobile .w3-dropdown-content{position:relative}	
.w3-hide-small{display:none!important}.w3-mobile{display:block;width:100%!important}.w3-bar-item.w3-mobile,.w3-dropdown-hover.w3-mobile,.w3-dropdown-click.w3-mobile{text-align:center}
.w3-dropdown-hover.w3-mobile,.w3-dropdown-hover.w3-mobile .w3-btn,.w3-dropdown-hover.w3-mobile .w3-button,.w3-dropdown-click.w3-mobile,.w3-dropdown-click.w3-mobile .w3-btn,.w3-dropdown-click.w3-mobile .w3-button{width:100%}}
@media (max-width:768px){.w3-modal-content{width:500px}.w3-modal{padding-top:50px}}
@media (min-width:993px){.w3-modal-content{width:900px}.w3-hide-large{display:none!important}.w3-sidebar.w3-collapse{display:block!important}}
@media (max-width:992px) and (min-width:601px){.w3-hide-medium{display:none!important}}
@media (max-width:992px){.w3-sidebar.w3-collapse{display:none}.w3-main{margin-left:0!important;margin-right:0!important}.w3-auto{max-width:100%}}
.w3-top,.w3-bottom{position:fixed;width:100%;z-index:1}.w3-top{top:0}.w3-bottom{bottom:0}
.w3-overlay{position:fixed;display:none;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);z-index:2}
.w3-display-topleft{position:absolute;left:0;top:0}.w3-display-topright{position:absolute;right:0;top:0}
.w3-display-bottomleft{position:absolute;left:0;bottom:0}.w3-display-bottomright{position:absolute;right:0;bottom:0}
.w3-display-middle{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%)}
.w3-display-left{position:absolute;top:50%;left:0%;transform:translate(0%,-50%);-ms-transform:translate(-0%,-50%)}
.w3-display-right{position:absolute;top:50%;right:0%;transform:translate(0%,-50%);-ms-transform:translate(0%,-50%)}
.w3-display-topmiddle{position:absolute;left:50%;top:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-bottommiddle{position:absolute;left:50%;bottom:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-container:hover .w3-display-hover{display:block}.w3-display-container:hover span.w3-display-hover{display:inline-block}.w3-display-hover{display:none}
.w3-display-position{position:absolute}
.w3-circle{border-radius:50%}
.w3-round-small{border-radius:2px}.w3-round,.w3-round-medium{border-radius:4px}.w3-round-large{border-radius:8px}.w3-round-xlarge{border-radius:16px}.w3-round-xxlarge{border-radius:32px}
.w3-row-padding,.w3-row-padding>.w3-half,.w3-row-padding>.w3-third,.w3-row-padding>.w3-twothird,.w3-row-padding>.w3-threequarter,.w3-row-padding>.w3-quarter,.w3-row-padding>.w3-col{padding:0 8px}
.w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
.w3-code,.w3-codespan{font-family:Consolas,"courier new";font-size:16px}
.w3-code{width:auto;background-color:#fff;padding:8px 12px;border-left:4px solid #4CAF50;word-wrap:break-word}
.w3-codespan{color:crimson;background-color:#f1f1f1;padding-left:4px;padding-right:4px;font-size:110%}
.w3-card,.w3-card-2{box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)}
.w3-card-4,.w3-hover-shadow:hover{box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)}
.w3-spin{-webkit-animation:w3-spin 2s infinite linear;animation:w3-spin 2s infinite linear}@-webkit-keyframes w3-spin{0%{transform:rotate(0deg)}100%{transform:rotate(359deg)}}@keyframes w3-spin{0%{transform:rotate(0deg)}100%{transform:rotate(359deg)}}
.w3-animate-fading{-webkit-animation:fading 10s infinite;animation:fading 10s infinite}@-webkit-keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{-webkit-animation:opac 0.8s;animation:opac 0.8s}@-webkit-keyframes opac{from{opacity:0} to{opacity:1}}@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;-webkit-animation:animatetop 0.4s;animation:animatetop 0.4s}@-webkit-keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.w3-animate-left{position:relative;-webkit-animation:animateleft 0.4s;animation:animateleft 0.4s}@-webkit-keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;-webkit-animation:animateright 0.4s;animation:animateright 0.4s}@-webkit-keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;-webkit-animation:animatebottom 0.4s;animation:animatebottom 0.4s}@-webkit-keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {-webkit-animation:animatezoom 0.6s;animation:animatezoom 0.6s}@-webkit-keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
.w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.w3-opacity,.w3-hover-opacity:hover{opacity:0.60}.w3-opacity-off,.w3-hover-opacity-off:hover{opacity:1}
.w3-opacity-max{opacity:0.25}.w3-opacity-min{opacity:0.75}
.w3-greyscale-max,.w3-grayscale-max,.w3-hover-greyscale:hover,.w3-hover-grayscale:hover{-webkit-filter:grayscale(100%);filter:grayscale(100%)}
.w3-greyscale,.w3-grayscale{-webkit-filter:grayscale(75%);filter:grayscale(75%)}.w3-greyscale-min,.w3-grayscale-min{-webkit-filter:grayscale(50%);filter:grayscale(50%)}
.w3-sepia{-webkit-filter:sepia(75%);filter:sepia(75%)}.w3-sepia-max,.w3-hover-sepia:hover{-webkit-filter:sepia(100%);filter:sepia(100%)}.w3-sepia-min{-webkit-filter:sepia(50%);filter:sepia(50%)}
.w3-tiny{font-size:10px!important}.w3-small{font-size:12px!important}.w3-medium{font-size:15px!important}.w3-large{font-size:18px!important}
.w3-xlarge{font-size:24px!important}.w3-xxlarge{font-size:36px!important}.w3-xxxlarge{font-size:48px!important}.w3-jumbo{font-size:64px!important}
.w3-left-align{text-align:left!important}.w3-right-align{text-align:right!important}.w3-justify{text-align:justify!important}.w3-center{text-align:center!important}
.w3-border-0{border:0!important}.w3-border{border:1px solid #ccc!important}
.w3-border-top{border-top:1px solid #ccc!important}.w3-border-bottom{border-bottom:1px solid #ccc!important}
.w3-border-left{border-left:1px solid #ccc!important}.w3-border-right{border-right:1px solid #ccc!important}
.w3-topbar{border-top:6px solid #ccc!important}.w3-bottombar{border-bottom:6px solid #ccc!important}
.w3-leftbar{border-left:6px solid #ccc!important}.w3-rightbar{border-right:6px solid #ccc!important}
.w3-section,.w3-code{margin-top:16px!important;margin-bottom:16px!important}
.w3-margin{margin:16px!important}.w3-margin-top{margin-top:16px!important}.w3-margin-bottom{margin-bottom:16px!important}
.w3-margin-left{margin-left:16px!important}.w3-margin-right{margin-right:16px!important}
.w3-padding-small{padding:4px 8px!important}.w3-padding{padding:8px 16px!important}.w3-padding-large{padding:12px 24px!important}
.w3-padding-16{padding-top:16px!important;padding-bottom:16px!important}.w3-padding-24{padding-top:24px!important;padding-bottom:24px!important}
.w3-padding-32{padding-top:32px!important;padding-bottom:32px!important}.w3-padding-48{padding-top:48px!important;padding-bottom:48px!important}
.w3-padding-64{padding-top:64px!important;padding-bottom:64px!important}
.w3-left{float:left!important}.w3-right{float:right!important}
.w3-button:hover{color:#000!important;background-color:#ccc!important}
.w3-transparent,.w3-hover-none:hover{background-color:transparent!important}
.w3-hover-none:hover{box-shadow:none!important}
/* Colors */
.w3-amber,.w3-hover-amber:hover{color:#000!important;background-color:#ffc107!important}
.w3-aqua,.w3-hover-aqua:hover{color:#000!important;background-color:#00ffff!important}
.w3-blue,.w3-hover-blue:hover{color:#fff!important;background-color:#2196F3!important}
.w3-light-blue,.w3-hover-light-blue:hover{color:#000!important;background-color:#87CEEB!important}
.w3-brown,.w3-hover-brown:hover{color:#fff!important;background-color:#795548!important}
.w3-cyan,.w3-hover-cyan:hover{color:#000!important;background-color:#00bcd4!important}
.w3-blue-grey,.w3-hover-blue-grey:hover,.w3-blue-gray,.w3-hover-blue-gray:hover{color:#fff!important;background-color:#607d8b!important}
.w3-green,.w3-hover-green:hover{color:#fff!important;background-color:#4CAF50!important}
.w3-light-green,.w3-hover-light-green:hover{color:#000!important;background-color:#8bc34a!important}
.w3-indigo,.w3-hover-indigo:hover{color:#fff!important;background-color:#3f51b5!important}
.w3-khaki,.w3-hover-khaki:hover{color:#000!important;background-color:#f0e68c!important}
.w3-lime,.w3-hover-lime:hover{color:#000!important;background-color:#cddc39!important}
.w3-orange,.w3-hover-orange:hover{color:#000!important;background-color:#ff9800!important}
.w3-deep-orange,.w3-hover-deep-orange:hover{color:#fff!important;background-color:#ff5722!important}
.w3-pink,.w3-hover-pink:hover{color:#fff!important;background-color:#e91e63!important}
.w3-purple,.w3-hover-purple:hover{color:#fff!important;background-color:#9c27b0!important}
.w3-deep-purple,.w3-hover-deep-purple:hover{color:#fff!important;background-color:#673ab7!important}
.w3-red,.w3-hover-red:hover{color:#fff!important;background-color:#f44336!important}
.w3-sand,.w3-hover-sand:hover{color:#000!important;background-color:#fdf5e6!important}
.w3-teal,.w3-hover-teal:hover{color:#fff!important;background-color:#009688!important}
.w3-yellow,.w3-hover-yellow:hover{color:#000!important;background-color:#ffeb3b!important}
.w3-white,.w3-hover-white:hover{color:#000!important;background-color:#fff!important}
.w3-black,.w3-hover-black:hover{color:#fff!important;background-color:#000!important}
.w3-grey,.w3-hover-grey:hover,.w3-gray,.w3-hover-gray:hover{color:#000!important;background-color:#9e9e9e!important}
.w3-light-grey,.w3-hover-light-grey:hover,.w3-light-gray,.w3-hover-light-gray:hover{color:#000!important;background-color:#f1f1f1!important}
.w3-dark-grey,.w3-hover-dark-grey:hover,.w3-dark-gray,.w3-hover-dark-gray:hover{color:#fff!important;background-color:#616161!important}
.w3-pale-red,.w3-hover-pale-red:hover{color:#000!important;background-color:#ffdddd!important}
.w3-pale-green,.w3-hover-pale-green:hover{color:#000!important;background-color:#ddffdd!important}
.w3-pale-yellow,.w3-hover-pale-yellow:hover{color:#000!important;background-color:#ffffcc!important}
.w3-pale-blue,.w3-hover-pale-blue:hover{color:#000!important;background-color:#ddffff!important}
.w3-text-amber,.w3-hover-text-amber:hover{color:#ffc107!important}
.w3-text-aqua,.w3-hover-text-aqua:hover{color:#00ffff!important}
.w3-text-blue,.w3-hover-text-blue:hover{color:#2196F3!important}
.w3-text-light-blue,.w3-hover-text-light-blue:hover{color:#87CEEB!important}
.w3-text-brown,.w3-hover-text-brown:hover{color:#795548!important}
.w3-text-cyan,.w3-hover-text-cyan:hover{color:#00bcd4!important}
.w3-text-blue-grey,.w3-hover-text-blue-grey:hover,.w3-text-blue-gray,.w3-hover-text-blue-gray:hover{color:#607d8b!important}
.w3-text-green,.w3-hover-text-green:hover{color:#4CAF50!important}
.w3-text-light-green,.w3-hover-text-light-green:hover{color:#8bc34a!important}
.w3-text-indigo,.w3-hover-text-indigo:hover{color:#3f51b5!important}
.w3-text-khaki,.w3-hover-text-khaki:hover{color:#b4aa50!important}
.w3-text-lime,.w3-hover-text-lime:hover{color:#cddc39!important}
.w3-text-orange,.w3-hover-text-orange:hover{color:#ff9800!important}
.w3-text-deep-orange,.w3-hover-text-deep-orange:hover{color:#ff5722!important}
.w3-text-pink,.w3-hover-text-pink:hover{color:#e91e63!important}
.w3-text-purple,.w3-hover-text-purple:hover{color:#9c27b0!important}
.w3-text-deep-purple,.w3-hover-text-deep-purple:hover{color:#673ab7!important}
.w3-text-red,.w3-hover-text-red:hover{color:#f44336!important}
.w3-text-sand,.w3-hover-text-sand:hover{color:#fdf5e6!important}
.w3-text-teal,.w3-hover-text-teal:hover{color:#009688!important}
.w3-text-yellow,.w3-hover-text-yellow:hover{color:#d2be0e!important}
.w3-text-white,.w3-hover-text-white:hover{color:#fff!important}
.w3-text-black,.w3-hover-text-black:hover{color:#000!important}
.w3-text-grey,.w3-hover-text-grey:hover,.w3-text-gray,.w3-hover-text-gray:hover{color:#757575!important}
.w3-text-light-grey,.w3-hover-text-light-grey:hover,.w3-text-light-gray,.w3-hover-text-light-gray:hover{color:#f1f1f1!important}
.w3-text-dark-grey,.w3-hover-text-dark-grey:hover,.w3-text-dark-gray,.w3-hover-text-dark-gray:hover{color:#3a3a3a!important}
.w3-border-amber,.w3-hover-border-amber:hover{border-color:#ffc107!important}
.w3-border-aqua,.w3-hover-border-aqua:hover{border-color:#00ffff!important}
.w3-border-blue,.w3-hover-border-blue:hover{border-color:#2196F3!important}
.w3-border-light-blue,.w3-hover-border-light-blue:hover{border-color:#87CEEB!important}
.w3-border-brown,.w3-hover-border-brown:hover{border-color:#795548!important}
.w3-border-cyan,.w3-hover-border-cyan:hover{border-color:#00bcd4!important}
.w3-border-blue-grey,.w3-hover-border-blue-grey:hover,.w3-border-blue-gray,.w3-hover-border-blue-gray:hover{border-color:#607d8b!important}
.w3-border-green,.w3-hover-border-green:hover{border-color:#4CAF50!important}
.w3-border-light-green,.w3-hover-border-light-green:hover{border-color:#8bc34a!important}
.w3-border-indigo,.w3-hover-border-indigo:hover{border-color:#3f51b5!important}
.w3-border-khaki,.w3-hover-border-khaki:hover{border-color:#f0e68c!important}
.w3-border-lime,.w3-hover-border-lime:hover{border-color:#cddc39!important}
.w3-border-orange,.w3-hover-border-orange:hover{border-color:#ff9800!important}
.w3-border-deep-orange,.w3-hover-border-deep-orange:hover{border-color:#ff5722!important}
.w3-border-pink,.w3-hover-border-pink:hover{border-color:#e91e63!important}
.w3-border-purple,.w3-hover-border-purple:hover{border-color:#9c27b0!important}
.w3-border-deep-purple,.w3-hover-border-deep-purple:hover{border-color:#673ab7!important}
.w3-border-red,.w3-hover-border-red:hover{border-color:#f44336!important}
.w3-border-sand,.w3-hover-border-sand:hover{border-color:#fdf5e6!important}
.w3-border-teal,.w3-hover-border-teal:hover{border-color:#009688!important}
.w3-border-yellow,.w3-hover-border-yellow:hover{border-color:#ffeb3b!important}
.w3-border-white,.w3-hover-border-white:hover{border-color:#fff!important}
.w3-border-black,.w3-hover-border-black:hover{border-color:#000!important}
.w3-border-grey,.w3-hover-border-grey:hover,.w3-border-gray,.w3-hover-border-gray:hover{border-color:#9e9e9e!important}
.w3-border-light-grey,.w3-hover-border-light-grey:hover,.w3-border-light-gray,.w3-hover-border-light-gray:hover{border-color:#f1f1f1!important}
.w3-border-dark-grey,.w3-hover-border-dark-grey:hover,.w3-border-dark-gray,.w3-hover-border-dark-gray:hover{border-color:#616161!important}
.w3-border-pale-red,.w3-hover-border-pale-red:hover{border-color:#ffe7e7!important}.w3-border-pale-green,.w3-hover-border-pale-green:hover{border-color:#e7ffe7!important}
.w3-border-pale-yellow,.w3-hover-border-pale-yellow:hover{border-color:#ffffcc!important}.w3-border-pale-blue,.w3-hover-border-pale-blue:hover{border-color:#e7ffff!important}
body {
  background-color: #fff;
  color: #000;
  font: 15px "AbhayaLibre";
  font-family: "Abhaya Libre", serif;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font: 15px "Abhaya Libre", "serif";
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
  border: 3px solid black;
}
.modal-backdrop.show {
  opacity: 0.8;
  background-color: var(--color-black);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
div:focus {
  outline: none !important;
}

#root {
  max-width: 1920px;
  margin: 0 auto;
}
.modal-content {
  border-radius: 10px;
}
.styles_scroll-to-top__2A70v:hover {
  color: white !important;
  background-color: var(--color-dark-black) !important;
}
.styles_scroll-to-top__2A70v:hover > svg {
  fill: #fff;
}
/*
* Global Remove Number field arrow
*/
.yt-fp-form
  .yt-number-filed.form-group
  .form-control::-webkit-outer-spin-button,
.yt-fp-form
  .yt-number-filed.form-group
  .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.yt-fp-form .yt-number-filed.form-group .form-control[type="number"] {
  -moz-appearance: textfield;
}

/*END*/
@media (min-width: 576px) {
  .cm-small-modal {
    max-width: 350px;
  }
}
@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}
@media (min-width: 768px) {
  .cm-small-modal-4 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-4 {
    max-width: 286px;
    margin-left: auto;
    margin-right: auto;
  }
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1281px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 88%;
  }
}
@media (min-width: 1900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 88.9%;
  }
}
/* @media (max-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
} */

