    /* div {
    background-color: green;
} */
.bottombar[data-v-42c881c7] {
  /* height: 40px; */
  color: var(--color-black);
  background-color: #f6f8fa;
  font-size: 14px;
  margin: 6px 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.footercontainer[data-v-42c881c7] {
  padding-top: 50px;
  background-color: #000;
  padding-bottom: 50px;
}
.footer-area[data-v-42c881c7]{
  padding-top: 20px;
  padding-bottom: 20px;
}
.logobox[data-v-42c881c7] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoimage[data-v-42c881c7] {
    width: 139px;
    height: auto;
}
.data_underlogo[data-v-42c881c7] {
  padding-top: 30px;
  height: 95px;
  width: 330px;
}
.navtitle[data-v-42c881c7] {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-black);
  padding-top: 10px;
}
.navlist[data-v-42c881c7] {
  font-weight: normal;
  text-decoration: none;
  color: var(--color-dark-grey);
  padding-top: 20px;
}
.navitem[data-v-42c881c7] {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.promotionsimage[data-v-42c881c7] {
  padding-top: 20px;
  padding-bottom: 10px;
  width: 270px;
  height: 150px;
}
.social_all[data-v-42c881c7] {
  color: var(--color-primary);
  justify-content: flex-end;
    margin-left: auto;
}
.social_all a[data-v-42c881c7]{
  display: inline-block;
}
.social_all a[data-v-42c881c7]:not(:last-child){
  border-right: 1px solid #8b8f95;
}
.social_all a[data-v-42c881c7]:last-child{
  padding-left: 5px;
}
.social_all a .social_logos[data-v-42c881c7] {
  max-width: 25px;
}
.yt-footer-widget-title[data-v-42c881c7] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: #fff;
  margin-bottom: 34px;
}
.yt-footler-menu-links[data-v-42c881c7] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7] {
  font-size: 20px;
  line-height: 2;
  text-align: left;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
.footer-divider[data-v-42c881c7] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  margin: 45px 0 45px;
  opacity: 0.3;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child) {
  margin-right: 125px;
}
.yt-ftr-banner[data-v-42c881c7] {
  padding-top: 6px;
}
.yt-text-about[data-v-42c881c7] {
  font-size: 16px;
  line-height: 1.38;
  text-align: left;
  color: #fff;
  padding-top: 36px;
  padding-bottom: 31px;
}
.yt-copyright-text[data-v-42c881c7] {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.13;
  color: #000;
}
@media (max-width: 1280px) {
.container[data-v-42c881c7],
  .container-lg[data-v-42c881c7],
  .container-md[data-v-42c881c7],
  .container-sm[data-v-42c881c7],
  .container-xl[data-v-42c881c7] {
    max-width: 93.3%;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-col.yt-first-col[data-v-42c881c7] {
            flex: 0 0 100%;
            max-width: 100%;
}
.yt-foote-link-col[data-v-42c881c7],
        .yt-last-col[data-v-42c881c7] {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
}
.yt-footer-widget-title[data-v-42c881c7] {
            font-size: 16px;
            line-height: 1.13;
            margin-bottom: 17px;
}
.yt-text-about[data-v-42c881c7] {
            padding-top: 16px;
            padding-bottom: 21px;
            font-size: 14px;
            line-height: 1.57;
}
        /* .social_all {
            padding: 0;
            margin-bottom: 17px;
        } */
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7] {
            font-size: 14px;
            line-height: 1.9;
}
.footer-divider[data-v-42c881c7] {
          margin: 15px 0;
}
}
    /*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-last-col[data-v-42c881c7] {
            flex: 0 0 100%;
            max-width: 100%;
}
.yt-foote-link-col[data-v-42c881c7] {
            flex: 0 0 50%;
            max-width: 50%;
}
.yt-footer-widget-title[data-v-42c881c7],
        .yt-footler-menu-links[data-v-42c881c7] {
            margin-bottom: 20px;
}
.yt-text-about[data-v-42c881c7] {
            padding-top: 12px;
            padding-bottom: 20px;
            font-size: 12px;
            line-height: 1.83;
}
        /* .social_all {
            padding: 0;
            margin-bottom: 20px;
        } */
.yt-main-footer[data-v-42c881c7] {
            padding-top: 20px;
            padding-bottom: 20px;
}
.yt-footer-widget-title[data-v-42c881c7] {
            font-size: 14px;
            line-height: 1.14;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7] {
            font-size: 13px;
            line-height: 1.97;
}
.footer-divider[data-v-42c881c7] {
          margin: 3px 0 7px;
}
}
@media (max-width: 1534px ) and (min-width: 1366px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 100px !important;
}
}
@media( max-width : 1365px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 90px !important;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]{
        font-size: 15px !important;
}
}
@media (max-width: 1184px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 70px !important;
}
}
@media (max-width: 1055px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 50px !important;
}
}
@media (max-width: 927px){
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
        margin-right: 25px !important;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-footer-widget-title[data-v-42c881c7] {
            font-size: 18px;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7] {
            font-size: 20px;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]{
    font-size: 8px !important;
}
.yt-footler-menu-links .yt-ftr-link[data-v-42c881c7]:not(:last-child){
    margin-right: 11px !important;
}
.yt-copyright-text[data-v-42c881c7]{
    font-size: 10px !important;
}
.social_all a .social_logos[data-v-42c881c7]{
    max-width: 15px;
}
.logoimage[data-v-42c881c7]{
    width: 90px;
}
}