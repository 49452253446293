/*
* Hero Banner CSS
*/
.looks_good_sec[data-v-346450e3] {
  padding-bottom: 40px;
  position: relative;
}
.looks_good_sec[data-v-346450e3]:after {
  content: "";
  position: absolute;
  min-width: 1155px;
  min-height: 556px;
  background-color: #f8fbfe;
  right: 0;
  top: 0;
  z-index: -1;
}
.looks_good_sec .item[data-v-346450e3] {
  margin-top: 137px;
}
.looks_good_sec .content[data-v-346450e3] {
  display: flex;
  flex-direction: column;
  padding-top: 163px;
  padding-left: 30px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.wrap-my-text[data-v-346450e3] {
  overflow: hidden;
  text-overflow: ellipsis;
}
.myImagebanner[data-v-346450e3] {
  object-fit: cover;
}
.looks_good_sec .content h2[data-v-346450e3] {
  font-size: 42px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 18px;
}
.looks_good_sec .content p[data-v-346450e3] {
  font-size: 24px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 4px;
}
.my-btn[data-v-346450e3] {
  background-color: transparent;
  border-color: black;
  font-weight: 500;
  color: black;
  max-width: 200px;
  min-height: 55px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
}
.my-btn[data-v-346450e3]:hover {
  background-color: black;
  color: white;
}
@media only screen and (max-width: 992px) {
}
@media only screen and (min-width: 992px) {
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
}
/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
}
@media only screen and (max-device-width: 480px) {
.looks_good_sec .content h2[data-v-346450e3] {
    font-size: 30px;
}
.looks_good_sec .content p[data-v-346450e3] {
    font-size: 18px;
}
.my-btn[data-v-346450e3] {
    max-width: 150px;
    min-height: 45px;
    font-size: 15px;
}
}
