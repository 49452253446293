/*
* YT Product Slider CSS
*/
.yt-sgl-product-fav[data-v-de0838de] {
  width: 24px;
}
.yt-sgl-product-off[data-v-de0838de] {
  background-color: #e65e52;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.noProducts[data-v-de0838de]{
  font-size: 25px;
}
.yt-sgl-product-cpnt[data-v-de0838de] {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  position: relative;
  transition: 0.5s ease;
  margin-bottom: 20px;
}
.yt-sgl-component-wrapper[data-v-de0838de] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-de0838de] {
  outline: none !important;
  box-shadow: none;
}
.yt-sgl-product-add-btn.buttoncart[data-v-de0838de] {
  position: relative;
  bottom: 0;
  width: 30%;
  border-radius: 1px;
  border: 1px solid var(--color-dark-black);
  background-color: transparent;
  font-size: 18px;
  letter-spacing: 2px;
}
.yt-sgl-product-cpnt:hover .yt-sgl-product-add-btn.buttoncart[data-v-de0838de] {
  background-color: var(--color-dark-black);
  border-radius: 0 0 4px 4px;
  color: var(--color-white);
}
.yt-sgl-product-cpnt[data-v-de0838de]:hover {
  border-radius: 10px;
}
.yt-ld-more-prdt[data-v-de0838de] {
  font-size: 30px;
  line-height: 1.13;
  color: var(--color-dark-black);
  border: none;
  box-shadow: none !important;
}
.price.yt-sgl-price-wrap[data-v-de0838de] {
  margin-bottom: 25px;
}
.yt-sgl-product-title[data-v-de0838de] {
  font-size: 25px;
  color: var(--color-dark-black);
  margin: 12px auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  float: left;
}
.yt-product-bg-image[data-v-de0838de] {
  width: 100%;
  height: 252px;
  background-size: cover;
  background-position: 100%;
  cursor: pointer;
  border-radius: 4px;
  border-radius: 4px;
  background-repeat: no-repeat;
}
.yt-fav-circle-wrap[data-v-de0838de] {
  box-shadow: 0 3px 6px 0 rgba(62, 69, 79, 0.1);
}
.yt-sgl-product-img[data-v-de0838de] {
  max-height: 150px;
}
.yt-sgl-component-more[data-v-de0838de] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.yt-sgl-comonent-top-title[data-v-de0838de] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.yt-sgl-product-off[data-v-de0838de] {
  background-color: #e65e52;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.yt-sgl-product-sale-price[data-v-de0838de] {
  color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.11;
}
.yt-sgl-product-reg-price2[data-v-de0838de] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.yt-sgl-product-add-btn[data-v-de0838de] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.trans-fl-ttl[data-v-de0838de]{
  font-size: 20px;
}
/* .yt-cm-row-margin {
  margin: 0 -5px;
} */
.yt-sp-top-fav-act[data-v-de0838de] {
  position: absolute;
  top: 10px;
  right: 0;
  left: 8px;
}
@media (min-width: 1400px) {
  /* .yt-cm-row-margin {
    margin: 0 -10px;
  } */
.cm-xxl-3[data-v-de0838de] {
    flex: 0 0 25%;
    max-width: 25%;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-cm-row-margin .cm-col-prdt-col[data-v-de0838de] {
    flex: 0 0 25%;
    max-width: 25%;
}
.yt-sgl-product-off[data-v-de0838de] {
    font-size: 12px;
    line-height: 1.17;
    min-width: 35px;
}
.yt-sgl-product-title[data-v-de0838de] {
    font-size: 16px;
}
.price.yt-sgl-price-wrap .yt-sgl-product-sale-price[data-v-de0838de],
  .price.yt-sgl-price-wrap .yt-sgl-product-reg-price2[data-v-de0838de] {
    font-size: 12px;
    line-height: 1.17;
    font-weight: 500;
    margin-bottom: 16px;
}
.yt-sgl-product-add-btn.buttoncart[data-v-de0838de] {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    width: 50%;
}
.price.yt-sgl-price-wrap[data-v-de0838de] {
    margin-bottom: 16px;
}
.yt-allproduct-main-wrap[data-v-de0838de] {
    margin-top: 0px !important;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 1199px) {
.yt-sgl-product-title[data-v-de0838de] {
    max-width: 150px;
}
}
@media (max-width: 767.92px) {
.yt-cm-row-margin .cm-col-prdt-col[data-v-de0838de] {
    flex: 0 0 50%;
    max-width: 50%;
}
.yt-sgl-product-title[data-v-de0838de] {
    font-size: 14px;
}
.price.yt-sgl-price-wrap .yt-sgl-product-sale-price[data-v-de0838de],
  .price.yt-sgl-price-wrap .yt-sgl-product-reg-price2[data-v-de0838de] {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 18px;
}
.yt-sgl-product-add-btn.buttoncart[data-v-de0838de] {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: normal;
}
.price.yt-sgl-price-wrap[data-v-de0838de] {
    margin-bottom: 18px;
}
.yt-allproduct-main-wrap[data-v-de0838de] {
    margin-top: 60px !important;
}
  /* .yt-cm-row-margin {
    margin: 0 -5px;
  } */
}
@media (max-width: 576px) {
.yt-cm-row-margin .cm-col-prdt-col[data-v-de0838de] {
    flex: 0 0 100%;
    max-width: 100%;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-sgl-product-add-btn[data-v-de0838de] {
    font-size: 16px;
}
}
@media only screen and (max-device-width: 480px) {
.yt-sgl-product-title[data-v-de0838de] {
    font-size: 20px;
}
}
