/*
* Add Address Modal CSs
*/
.edit-profile-title-bar span[data-v-ddf5d07a] {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  top: 27px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-dark-black);
}
.edit-profile-title-bar[data-v-ddf5d07a] {
  position: relative;
}
.myImage[data-v-ddf5d07a]{
  max-height: 107px;
}
.edit-profile-body-wrap .form-control[data-v-ddf5d07a] {
  height: auto;
  border: none;
  border-bottom: 2px solid #f6f8fa;
  box-shadow: none;
  padding-left: 0;
}
.edit-profile-btn[data-v-ddf5d07a] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
/* Chrome, Safari, Edge, Opera */
.edit-profile-body-wrap input[data-v-ddf5d07a]::-webkit-outer-spin-button,
.edit-profile-body-wrap input[data-v-ddf5d07a]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.edit-profile-body-wrap .form-control input[type="number"][data-v-ddf5d07a] {
  -moz-appearance: textfield;
}
.edit-profile-body-wrap .profile-form-tag[data-v-ddf5d07a] {
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-form-field[data-v-ddf5d07a] {
  position: relative;
  margin-bottom: 20px;
}
.form-control[data-v-ddf5d07a]:disabled{
  background-color: transparent !important;
  cursor: not-allowed;
}
.profile-form-field .form-control[data-v-ddf5d07a] {
  border: none;
  border-bottom: 2px solid #dbdbdb;
  padding-left: 0;
  box-shadow: none;
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-dark-black);
  height: auto;
}
.profile-form-field .profile-form-tag[data-v-ddf5d07a] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  font-size: 14px;
  line-height: 1.14;
  color: var(--color-dark-grey);
}
.profile-form-field.fields-active .profile-form-tag[data-v-ddf5d07a] {
  top: 0;
}
.img-upload svg[data-v-ddf5d07a] {
  font-size: 26px;
  color: var(--color-dark-black) !important;
  position: absolute;
  top: -15px;
  right: -25px;
  padding: 7px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-sizing: content-box;
  border-radius: 32px;
  border: 6px solid #fff;
  width: 40px;
}
.yt-remove-pic-wrap[data-v-ddf5d07a] {
  cursor: pointer;
  color: #f67054;
}
.img-upload[data-v-ddf5d07a] {
  width: 109px;
  height: 109px;
  border-radius: 6px;
  position: relative;
}
.profile-edit-profile-btn[data-v-ddf5d07a] {
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.yt-edit-prfl-body[data-v-ddf5d07a] {
  padding: 40px 40px 30px;
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-remove-pic-wrap[data-v-ddf5d07a] {
    font-size: 13px;
    line-height: 1.15;
}
.yt-remove-pic-wrap img[data-v-ddf5d07a] {
    max-width: 16px;
}
.profile-form-field .form-control[data-v-ddf5d07a] {
    padding: 10px 0 !important;
}
.profile-edit-profile-btn[data-v-ddf5d07a] {
    font-size: 16px;
    line-height: 1.13;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
}
.yt-edit-prfl-body[data-v-ddf5d07a] {
    padding: 40px 40px 20px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.yt-remove-pic-wrap[data-v-ddf5d07a] {
    display: none;
}
.edit-profile-body-wrap .img-upload[data-v-ddf5d07a] {
    margin: 0 auto;
    width: 122px;
    height: 122px;
}
.profile-form-field .profile-form-tag[data-v-ddf5d07a] {
    font-size: 13px;
    line-height: 1.15;
}
.profile-form-field .form-control[data-v-ddf5d07a] {
    font-size: 17px;
    line-height: 1.12;
    padding-top: 11px !important;
    padding-bottom: 6px !important;
}
.yt-edit-prfl-body[data-v-ddf5d07a] {
    padding: 20px 15px;
}
.profile-edit-profile-btn[data-v-ddf5d07a] {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.profile-edit-pass[data-v-ddf5d07a],
  .profile-edit-btn[data-v-ddf5d07a] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
}
}
