.login-wrapper[data-v-5983587d] {
    /* margin: 25px auto; */
    padding: 20px 30px;
    max-width: 570px;
    /* border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 3px 3px 30px #eee; */
    background-color: #fff;
}
.login-wrapper .nav-link.active[data-v-5983587d] {
    border-bottom: solid 1px #212529;
    font-weight: bold;
    color: #000;
}
.tagline-title[data-v-5983587d]{
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 30px;
}
.tagline[data-v-5983587d]{
    padding-bottom: 30px;
    text-align: center;
    font-size: 18px;
    color: #8b8f95;
    letter-spacing: 1px;
}
.login-wrapper .nav-item[data-v-5983587d] {
    cursor: pointer;
    color: #989CA2;
    width: 50%;
    text-align: center;
    font-size: 24px;
    letter-spacing: 1.5px;
    border-bottom: solid 1px #dbdbdb;
}
.sign-up-form .form-group[data-v-5983587d] {
    position: relative;
}
.sign-up-form .form-group img[data-v-5983587d]{
    position: absolute;
    height: 20px;
    width: 20px;
    top: 11px;
    margin: 0 20px;
}
.sign-up-form .form-group .eye-icon[data-v-5983587d] {
    position: absolute;
    top: 14px;
    right: 25px;
}
.sign-up-form .form-group .form-control[data-v-5983587d] {
    padding: 20px 60px 20px 30px;
    font-size: 16px;
    color: #000;
}
.sign-up-form .form-group .form-control[data-v-5983587d]:active,
.sign-up-form .form-group .form-control[data-v-5983587d]:focus {
    border-color: #000;
}
.sign-up-form .password-hint[data-v-5983587d] {
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
}
.sign-up-form .form-group .error[data-v-5983587d] {
    color: red;
    margin-top: 4px;
    font-size: 12px;
}
.sign-up-form .btn-sign-up[data-v-5983587d] {
    color: black;
    background-color: #ffffff;
    border: 1px solid black;
    letter-spacing: 2px;
    margin-top: 20px;
    padding: 10px 0;
    font-size: 18px;
    transition: .5s ease;
}
.sign-up-form .btn-sign-up[data-v-5983587d]:hover{
    background-color: #000;
    color: white;
}
.other-actions[data-v-5983587d] {
    text-align: center;
}
.other-actions .skip-line-1[data-v-5983587d]{
    color: #8C9095;
    font-size: 16px;
    text-decoration: none;
}
.other-actions .skip-line-2[data-v-5983587d]{
    color: #8C9095;
    margin-top: 40px;
    font-size: 16px;
    margin-bottom: 0;
}
.other-actions .fb-button[data-v-5983587d] {
    background: transparent;
    color: #3b5998;
    border: none;
    display: flex;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    justify-content: center;
}
.other-actions .login-action[data-v-5983587d] {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 40px;
}
.other-actions .google-button[data-v-5983587d] {
    background: transparent;
    color: #EA4335;
    border: none;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.other-actions .terms-tagline[data-v-5983587d] {
    color: #8C9095;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
}
.forgot-password[data-v-5983587d] {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    float: right;
    padding-bottom: 15px;
}
.other-actions .terms a[data-v-5983587d]{
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0 5px;
}