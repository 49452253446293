#pagination > .pagination {
  justify-content: center;
  background-color: white;
  padding: 15px 0px;
  color: black;
  /* border: 1px solid black; */
  transition: 0.7s ease;
}

#pagination > .pagination:hover {
  /* background-color: black; */
  /* color: white; */
  /* border: 1px solid white; */
}
#pagination > .pagination:hover .active {
  border-bottom: 1px solid white;
}
#pagination > .pagination > li {
  padding: 0px 10px;
}
#pagination > .pagination > .active {
  border-bottom: 1px solid black;
  font-weight: bold;
  padding: 0px 10px;
}
