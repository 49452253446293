.yt-main-contact-us-pg .contactform[data-v-089c501c] {
  padding: 25px 20px;
}
.yt-main-contact-us-pg .title[data-v-089c501c] {
  font-size: 35px;
  font-weight: bold;
  padding-top: 25px;
  line-height: 1.13;
  margin-bottom: 30px;
  color: #000;
}
.yt-main-contact-us-pg .form[data-v-089c501c] {
  background-color: white;
  padding: 50px 40px;
}
.yt-main-contact-us-pg textarea[data-v-089c501c] {
  border-radius: 8px;
  padding: 12px;
  margin: 20px 15px 0 15px;
  width: 100%;
  border-color: #c0bebe;
  color: #8b8f95;
  font-size: 15px;
  height: 100px;
}
.yt-main-contact-us-pg textarea[data-v-089c501c]::-webkit-input-placeholder {
  color: #8b8f95;
}
.yt-main-contact-us-pg textarea[data-v-089c501c]:-ms-input-placeholder {
  color: #8b8f95;
}
.yt-main-contact-us-pg textarea[data-v-089c501c]::-ms-input-placeholder {
  color: #8b8f95;
}
.yt-main-contact-us-pg textarea[data-v-089c501c]::placeholder {
  color: #8b8f95;
}

/* form starting stylings ------------------------------- */
.yt-main-contact-us-pg .group[data-v-089c501c] {
  position: relative;
  margin-bottom: 30px;
}
.yt-main-contact-us-pg .group.phone[data-v-089c501c]{
  margin-bottom: 0;
}
.yt-main-contact-us-pg input[data-v-089c501c], .yt-main-contact-us-pg select[data-v-089c501c] {
  font-size: 16px;
  padding: 10px 10px 10px 0px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #c0bebe;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-main-contact-us-pg .select-arrow[data-v-089c501c] {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.yt-main-wrapper .form[data-v-089c501c] {
  border: 1px solid #c0bebe;
  border-radius: 10px;
}
.yt-main-contact-us-pg input[data-v-089c501c]:focus {
  outline: none;
  border-bottom: 1px solid black;
}

/* LABEL ======================================= */
.yt-main-contact-us-pg label[data-v-089c501c] {
  color: #999;
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.yt-main-contact-us-pg input:focus ~ label[data-v-089c501c], .yt-main-contact-us-pg select:focus ~ label.select-label[data-v-089c501c],
.yt-main-contact-us-pg input:valid ~ label[data-v-089c501c], .yt-main-contact-us-pg select:valid ~ label.select-label[data-v-089c501c] {
  top: -15px;
  font-size: 14px;
  color: black;
}
.yt-main-wrapper[data-v-089c501c] {
  max-width: 770px;
  margin: 0 auto;
  position: relative;
}
.yt-main-contact-us-pg[data-v-089c501c] {
  /* margin-top: 60px; */
  margin-bottom: 100px;
}
.yt-contact-send-btn[data-v-089c501c] {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin: 20px 0 20px auto;  
  color: white;
  padding: 15px 70px;
  border-radius: 6px;
  background-color: black;
}
.yt-contact-send-btn[data-v-089c501c]:hover{
  border: 1px solid black;
  background-color: #fff;
  color: black;
}
@media (min-width: 1900px) {
}
@media (min-width: 768px) {
.yt-cm-mobile-bread[data-v-089c501c] {
    display: none;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-main-contact-us-pg[data-v-089c501c] {
            margin-bottom: 110px;
}
.yt-main-contact-us-pg .title[data-v-089c501c] {
            font-size: 24px;
            line-height: 1.17;
            margin-bottom: 20px;
}
.yt-main-contact-us-pg .form[data-v-089c501c] {
            padding: 20px 15px;
}
.yt-main-contact-us-pg label[data-v-089c501c] {
            font-size: 12px;
            line-height: 1.17;
}
.yt-contact-send-btn[data-v-089c501c] {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.13;
            letter-spacing: 0.32px;
}
.yt-main-contact-us-pg input[data-v-089c501c] {
            padding: 10px 10px 16px 6px;
}
}
    /*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.pageroute[data-v-089c501c] {
    color: gray;
    font-size: 15px;
    margin-bottom: 10px;
    line-height: 1.17;
}
.yt-profile-mb-ttl.profile-pg-title[data-v-089c501c] {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.11;
}
.yt-cm-mobile-bread[data-v-089c501c] {
    margin-top: 8px;
    margin-bottom: 10px;
}
.yt-main-contact-us-pg[data-v-089c501c] {
    margin-bottom: 20px;
}
.yt-main-contact-us-pg .title[data-v-089c501c] {
    font-size: 18px;
    line-height: 1.11;
    margin-bottom: 10px;
}
.yt-main-contact-us-pg .group[data-v-089c501c] {
    padding: 30px 20px;
}
.yt-main-contact-us-pg label[data-v-089c501c], .yt-main-contact-us-pg label.select-label[data-v-089c501c] {
    font-size: 13px;
    line-height: 1.15;
    padding-left: 20px;
    top: 35px;
}
.yt-main-contact-us-pg input:focus ~ label[data-v-089c501c], .yt-main-contact-us-pg select:focus ~ label.select-label[data-v-089c501c],
  .yt-main-contact-us-pg input:valid ~ label[data-v-089c501c], .yt-main-contact-us-pg select:valid ~ label.select-label[data-v-089c501c] {
    top: -5px;
    font-size: 14px;
    color: black;
}
.yt-main-contact-us-pg input[data-v-089c501c] {
    padding: 7px 10px 7px 0px;
}
.yt-contact-send-btn[data-v-089c501c] {
    position: static;
    position: initial;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    margin-top: 20px;
}
.yt-main-contact-us-pg .group[data-v-089c501c]:last-child {
    margin-bottom: 0;
}
}
