/*
* Home Page CSS
*/
/*
* Global CSS
*/
.yt-component-wrapper[data-v-9862d71b] {
  position: relative;
}

/*End*/
.deals[data-v-9862d71b] {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
}
.products[data-v-9862d71b] {
  background-color: white;
  padding: 30px;
  margin-bottom: 20px;
}
.more[data-v-9862d71b] {
  float: right;
  font-size: 13px;
  cursor: pointer;
}
.fullscreen[data-v-9862d71b] {
  width: 100%;
}
.oneintwo[data-v-9862d71b] {
  margin-top: 20px;
  margin-bottom: 20px;
}
.shopnowbutton[data-v-9862d71b] {
  width: 140px;
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.savetext[data-v-9862d71b] {
  color: black;
  font-weight: 600;
  font-size: 20px;
}
.textblock1[data-v-9862d71b] {
  margin-top: -150px;
  margin-left: 80px;
}
.welcometext[data-v-9862d71b] {
  color: black;
  font-weight: 600;
  font-size: 40px;
}
.textblock2[data-v-9862d71b] {
  margin-top: -350px;
  margin-left: 80px;
}
.headtop[data-v-9862d71b] {
  color: white;
  font-weight: 200px;
}
.uptooff[data-v-9862d71b] {
  color: white;
  font-weight: 600;
  font-size: 60px;
}
.textblock3[data-v-9862d71b] {
  margin-top: -300px;
  margin-left: 350px;
}
.logohead1[data-v-9862d71b] {
  margin-left: 30px;
  font-weight: 500px;
  margin-top: -60px;
  font-size: 20px;
  color: white;
}
.shopnowgoldenbutton[data-v-9862d71b] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: -70px;
  float: right;
  margin-right: 10px;
}
.shopnowgoldenbutton1[data-v-9862d71b] {
  width: 100px;
  background-color: var(--color-primary);
  color: white;
  font-weight: 400px;
  padding: 10px;
  border-radius: 5px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  float: left;
  margin-top: 20px;
}
.dontmiss[data-v-9862d71b] {
  font-size: 30px;
}
.offthirty[data-v-9862d71b] {
  font-size: 70px;
  font-weight: 600;
}
.textbox4[data-v-9862d71b] {
  margin-left: 120px;
  margin-top: -500px;
}
.hurryup[data-v-9862d71b] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.dailydeal[data-v-9862d71b] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.lowprice[data-v-9862d71b] {
  color: white;
}
.textbox5[data-v-9862d71b] {
  margin-left: 50px;
  margin-top: -200px;
}
.happytimes[data-v-9862d71b] {
  font-size: 30px;
  color: white;
  font-weight: 600px;
}
.flat[data-v-9862d71b] {
  font-size: 40px;
  color: white;
  font-weight: 700px;
}
.textbox7[data-v-9862d71b] {
  margin-top: -230px;
  margin-left: auto;
  margin-right: auto;
}
.stores[data-v-9862d71b] {
  width: 30%;
  margin-top: 70px;
  cursor: pointer;
}
.playbuttons[data-v-9862d71b] {
  margin-left: 190px;
  margin-right: auto;
}
.download[data-v-9862d71b] {
  font-size: 40px;
  font-weight: 400;
  color: black;
  margin-left: 100px;
}
.imglast[data-v-9862d71b] {
  width: 100%;
  height: 100%;
}
.home-banner[data-v-9862d71b],
.banner-container[data-v-9862d71b] {
  position: relative;
  min-height: 357px;
}
.home-banner .content[data-v-9862d71b] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.banner-container .content[data-v-9862d71b] {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
}
.home-banner .content .scheme[data-v-9862d71b] {
  font-size: 80px;
  font-weight: 500;
}
.home-banner .content .info[data-v-9862d71b] {
  font-size: 24px;
  color: #000;
}
.banner-container .content .scheme[data-v-9862d71b] {
  font-size: 102px;
  font-weight: bold;
}
.banner-container .content .info[data-v-9862d71b] {
  font-size: 24px;
  color: #8b8f95;
}
.content .btn-shop[data-v-9862d71b] {
  color: white;
  background-color: black;
  padding: 8px 40px;
  border-radius: 6px;
  margin-top: 270px;
  transition: 0.5s ease;
  letter-spacing: 1px;
}
.content .btn-shop[data-v-9862d71b]:hover {
  border: 1px solid black;
  color: black;
  background-color: white;
}
@media (max-width: 1399px) {
.home-banner[data-v-9862d71b],
  .banner-container[data-v-9862d71b] {
    min-height: auto;
}
}
@media (max-width: 1200px) {
.banner-container .content[data-v-9862d71b] {
    top: 37%;
}
}
@media (max-width: 1199px) {
.home-banner .content .scheme[data-v-9862d71b] {
    font-size: 36px;
    margin-top: 0;
}
.home-banner .content .info[data-v-9862d71b] {
    font-size: 18px;
}
.banner-container .content .scheme[data-v-9862d71b] {
    font-size: 36px;
    margin-top: 0;
}
.banner-container .content .info[data-v-9862d71b] {
    font-size: 18px;
}
}
/* for mobile */
@media only screen and (max-width: 992px) {
.shopnowbutton[data-v-9862d71b] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.shopnowgoldenbutton1[data-v-9862d71b] {
    width: 100px;
    padding: 5px;
    height: 30px;
}
.savetext[data-v-9862d71b] {
    font-size: 15px;
}
.textblock1[data-v-9862d71b] {
    margin-top: -90px;
    margin-left: 50px;
}
.welcometext[data-v-9862d71b] {
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.textblock2[data-v-9862d71b] {
    margin-top: -200px;
    margin-left: 40px;
    width: 50%;
}
.headtop[data-v-9862d71b] {
    font-weight: 200px;
}
.uptooff[data-v-9862d71b] {
    color: white;
    font-weight: 600;
    font-size: 30px;
}
.textblock3[data-v-9862d71b] {
    margin-top: -250px;
    margin-left: 200px;
    width: 50%;
}
.three[data-v-9862d71b] {
    padding-bottom: 10px;
}
.logohead1[data-v-9862d71b] {
    width: 100%;
}
.hurryup[data-v-9862d71b] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.dailydeal[data-v-9862d71b] {
    font-size: 15px;
    color: white;
    font-weight: 600px;
}
.lowprice[data-v-9862d71b] {
    color: white;
    font-size: 10px;
}
.textbox5[data-v-9862d71b] {
    margin-left: 30px;
    margin-top: -160px;
    width: 50%;
}
.happytimes[data-v-9862d71b] {
    font-size: 20px;
    color: white;
    font-weight: 600px;
}
.flat[data-v-9862d71b] {
    font-size: 25px;
    color: white;
    font-weight: 700px;
}
.stores[data-v-9862d71b] {
    width: 30%;
    margin-top: 0px;
}
.playbuttons[data-v-9862d71b] {
    margin-left: 120px;
    margin-right: auto;
}
.download[data-v-9862d71b] {
    font-size: 15px;
    font-weight: 400;
    color: black;
    margin-left: 120px;
}
.textbox7[data-v-9862d71b] {
    margin-top: -60px;
    margin-left: auto;
    margin-right: auto;
}
.imglast[data-v-9862d71b] {
    width: 100%;
    height: 100%;
}
.dontmiss[data-v-9862d71b] {
    font-size: 15px;
}
.offthirty[data-v-9862d71b] {
    font-size: 25px;
    font-weight: 600;
}
.textbox4[data-v-9862d71b] {
    margin-left: 40px;
    margin-top: -150px;
    width: 50%;
}
.banner-container .content[data-v-9862d71b] {
    top: 17%;
}
}

/* for desktop */
@media (max-width: 991px) {
.home-banner > img[data-v-9862d71b],
  .banner-container > img[data-v-9862d71b] {
    height: auto;
    object-fit: cover;
    width: 100%;
}
}
@media (max-width: 767px) {
.home-banner .content .scheme[data-v-9862d71b] {
    font-size: 22px;
    margin-bottom: 15px;
}
.home-banner .content .info[data-v-9862d71b] {
    font-size: 14px;
}
.home-banner > img[data-v-9862d71b],
  .banner-container > img[data-v-9862d71b] {
    height: auto;
}
.banner-container .content[data-v-9862d71b] {
    top: 62%;
}
.banner-container .content .scheme[data-v-9862d71b] {
    font-size: 22px;
    margin-top: 0;
}
.banner-container .content .info[data-v-9862d71b] {
    font-size: 14px;
}
.content .btn-shop[data-v-9862d71b] {
    margin-top: 67px;
    font-size: 8px;
    padding: 4px 16px;
}
}
@media (max-width: 445px) {
.banner-container .content[data-v-9862d71b] {
    top: 47%;
}
}
.featurebarcontainer[data-v-9862d71b] {
  margin: 40px 0px;
}
