.pp-ca-list-none[data-v-97b6581b] {
  list-style: none;
}
.pp-ca-tag-name[data-v-97b6581b] {
  font-size: 15px;
  line-height: 1.2;
  text-align: left;
  color: var(--color-dark-grey);
}
.pp-ca-user-name[data-v-97b6581b] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  color: var(--color-dark-black);
}
.pp-ca-listing li.active .pp-ca-type img[data-v-97b6581b] {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.pp-ca-listing li[data-v-97b6581b] {
  padding: 30px 0;
  border-bottom: 2px solid #e6e6e6;
}
.pp-ca-listing li[data-v-97b6581b]:last-child {
  border: none;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-97b6581b] {
  padding: 40px 30px 30px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
.pp-ca-name[data-v-97b6581b] {
  min-height: 40px;
}
.radius-10[data-v-97b6581b] {
  border-radius: 10px;
}
.cursordefault[data-v-97b6581b]{
  cursor:default
}
.profile-pg-mb-30[data-v-97b6581b] {
  margin-bottom: 30px;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap .pp-ca-listing li[data-v-97b6581b]:first-child {
  padding-top: 0;
}
.profile-pg-inner-wrapper .pp-ca-main-wrap .pp-ca-listing li[data-v-97b6581b]:last-child {
  padding-bottom: 0;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.pp-ca-type img[data-v-97b6581b] {
    width: 38px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-97b6581b] {
    padding: 13px 15px 26px;
}
.pp-ca-tag-name[data-v-97b6581b] {
    font-size: 12px;
    line-height: 1.17;
    margin-bottom: 5px !important;
}
.pp-ca-user-name[data-v-97b6581b] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-ca-listing li[data-v-97b6581b] {
    padding: 20px 0;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-inner-wrapper .pp-ca-main-wrap[data-v-97b6581b] {
    padding: 22px 20px;
}
.pp-ca-tag-name[data-v-97b6581b] {
    font-size: 12px;
    line-height: 1.17;
}
.pp-ca-user-name[data-v-97b6581b] {
    font-size: 17px;
    line-height: 1.12;
}
.pp-ca-listing li[data-v-97b6581b] {
    padding: 15px 0;
}
.pp-ca-remove[data-v-97b6581b] {
    opacity: 0.5;
}
}
