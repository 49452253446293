.custom-modal[data-v-909ca115] {
    max-width: 700px;
}
.custom-modal .custom-body[data-v-909ca115] {
    display: flex;
    padding: 50px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.custom-body img[data-v-909ca115] {
    height: 200px;
    width: 200px;
}
.custom-body h1[data-v-909ca115] {
    font-size: 28px;
    font-weight: 500;
}
.custom-body p[data-v-909ca115] {
    color: #8C9096;
    margin: 10px 0;
}
.custom-modal .dark-btn[data-v-909ca115] {
    margin-top: 30px;
    color: #fff;
    background-color: #000;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 25px;
    border-radius: 5px;
}