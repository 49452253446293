.about-first[data-v-73b66c1a] {
  padding-bottom: 70px;
}
.page_title[data-v-73b66c1a] {
  font-size: 55px;
  font-weight: bold;
  color: #000000;
}
.subtitle-content[data-v-73b66c1a] {
  font-size: 18px;
  line-height: 1.8;
  color: black;
  padding-bottom: 20px;
  font-style: italic;
}
/* .table >thead >tr >th{
  width: 50px !important;
} */
.table[data-v-73b66c1a] {
  font-size: 20px;
}
.borderless td[data-v-73b66c1a],
.borderless th[data-v-73b66c1a] {
  border: none;
}
.table-striped > tbody > tr:nth-child(odd) > td[data-v-73b66c1a],
.table-striped > tbody > tr:nth-child(odd) > th[data-v-73b66c1a] {
  background-color: #f8fbfe;
}
.table-striped > tbody > tr:nth-child(even) > td[data-v-73b66c1a],
.table-striped > tbody > tr:nth-child(even) > th[data-v-73b66c1a] {
  background-color: #ffff;
}
.section_1[data-v-73b66c1a] {
  padding-top: 15px;
}
.section_common[data-v-73b66c1a] {
  padding-top: 70px;
}
.about-first .title[data-v-73b66c1a] {
  font-weight: bold;
  font-size: 35px;
  line-height: 1.4;
  color: #000000;
}
.about-first .para[data-v-73b66c1a] {
  font-weight: 400;
  color: #8e9298;
  text-align: justify;
  line-height: 1.6;
  font-size: 18px;
}
.size_chart[data-v-73b66c1a] {
  text-align: center;
}
/* iPad [portrait + landscape] */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
.container[data-v-73b66c1a]{
    width: 600px  !important;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.sizeTable[data-v-73b66c1a]{
    overflow-x: scroll;
}
th[data-v-73b66c1a],td[data-v-73b66c1a]{
    min-width: 100px
}
.container[data-v-73b66c1a]{
    width: 330px  !important;
}
.page_title[data-v-73b66c1a]{
    font-size: 40px;
}
.about-first .title[data-v-73b66c1a]{
    font-size: 30px;
}
.section_1[data-v-73b66c1a]{
    padding-top: 0;
}
.table[data-v-73b66c1a]{
    font-size: 16px;
}
}