.banner-container[data-v-3368272b]  {
  position: relative;
}
.banner-container img[data-v-3368272b] {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
.banner-container .content[data-v-3368272b] {
  position: absolute;
  margin-left: 40px;
  top: 50%;
  transform: translateY(-40%);
}
.content .scheme[data-v-3368272b] {
  font-size: 102px;
  font-weight: bold;
}
.content .info[data-v-3368272b] {
  font-size: 24px;
  color: #8B8F95;
}
.content .btn-shop[data-v-3368272b] {
  color: #fff;
  background-color: #000;
  padding: 8px 30px;
  border: 1px solid #000;
  border-radius: 6px;
  margin-top: 30px;
  font-weight: bold;
}