.notify-me-title-bar .modal-title[data-v-fe2c1095] {
    margin: 0;
    text-align: center;
    flex: 1 1;
    color: var(--color-dark-grey);
}
.notify-me-body-text[data-v-fe2c1095] {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    color: var(--color-dark-grey);
}
.notify-me-title-bar span[data-v-fe2c1095] {
    position: absolute;
    top: 27px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 !important;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.11;
    color: var(--color-black);
}
.pp-notify-me-btn-modal[data-v-fe2c1095] {
    width: 100%;
    margin: 0 !important;
    padding: 0;
    border-radius: 0;
    background: transparent;
    border-color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--color-black);
}
.pp-notify-me-btn-modal[data-v-fe2c1095]:hover {
    border-color: #fff;
}
.pp-notify-me-btn-light-grey[data-v-fe2c1095] {
    color: var(--color-dark-grey);
}
.pp-notify-me-btn-dark-grey[data-v-fe2c1095] {
    color: var(--color-black);
}
  /*
  * Screen for under 768 Resolution 
  */
@media (max-width: 767.92px) {
.notify-me-body-text[data-v-fe2c1095] {
      text-align: center !important;
}
}
  