/*
* CSS
*/


/*
* Global CSS Classes
*/
.radius-10[data-v-23e03094] {
    border-radius: 10px;
}
.list-style-none[data-v-23e03094] {
    list-style: none;
}
.border-top-grey[data-v-23e03094] {
    border-top: 2px solid #dbdbdb;
}
.sp-breadcrumbs[data-v-23e03094] {
    margin-bottom: 46px;
}
.pageroute.sp-breadcrumbs span[data-v-23e03094] {
    color: var(--color-dark-grey);
    font-size: 15px;
    line-height: 1.17;
}
.pageroute.sp-breadcrumbs .sp-current[data-v-23e03094] {
    color: var(--color-black);
    font-weight: 500;
}
.yt-feature-point li[data-v-23e03094] {
    line-height: 1.81;
    color: #8b8f95;
}
@media (min-width: 1900px) {
.sp-breadcrumbs[data-v-23e03094] {
        margin-bottom: 40px;
}
}
@media (min-width: 768px) {
.ml-md-0[data-v-23e03094] {
        margin-left: 0;
}
}


/*
* Single Product Left Section CSS
*/
.sp-image-slide img[data-v-23e03094] {
    border: 1px solid #dbdbdb;
}
.sp-description-title[data-v-23e03094] {
    font-size: 20px;
    line-height: 1.15;
    color: var(--color-black);
    font-weight: 500;
    /* margin-bottom: 27px; */
}
.sp-description-text[data-v-23e03094] {
    font-size: 16px;
    color: var(--color-dark-grey);
    line-height: 2.13;
}
.yt-based-on[data-v-23e03094] {
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-dark-grey);
}
.sp-rating-content[data-v-23e03094] {
    font-size: 38px;
    line-height: 1.13;
    color: var(--color-black);
}
.sp-rating-view span[data-v-23e03094]:first-child {
    font-size: 20px;
    color: var(--color-black);
    line-height: 1.75;
}
span.sp-rating-area[data-v-23e03094] {
    min-width: 100px;
    height: 2px;
    background-color: #f6f8fa;
}
.sp-rating-wrap .sp-rating-inner-wrap[data-v-23e03094] {
    width: 50%;
}
.sp-give-review-btn[data-v-23e03094] {
    min-width: 145px;
    border-radius: 5px;
    /* background-color: var(--color-primary); */
    background-color: black;
    opacity: 0.99;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.11;
}
.sp-rating-area[data-v-23e03094] {
    position: relative;
}
.sp-rating-area[data-v-23e03094]::after {
    content: "";
    height: 2px;
    position: absolute;
}
.sp-width-80[data-v-23e03094]::after {
    width: 80%;
    background-color: var(--color-dark-black);
}
.sp-width-60[data-v-23e03094]::after {
    width: 60%;
    background-color: var(--color-dark-black);
}
.sp-width-40[data-v-23e03094]::after {
    width: 40%;
    background-color: var(--color-blue);
}
.sp-width-20[data-v-23e03094]::after {
    width: 20%;
    background-color: var(--color-blue);
}
.sp-width-10[data-v-23e03094]::after {
    width: 10%;
    background-color: var(--color-orange);
}
.sp-rating-date[data-v-23e03094] {
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-dark-grey);
}
.sp-user-name[data-v-23e03094] {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.15;
    color: var(--color-black);
}
.sp-usermessage[data-v-23e03094] {
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-dark-grey);
}
.sp-user-rated-meta img[data-v-23e03094] {
    max-width: 11px;
}
.yt-rating-single-prdt[data-v-23e03094],
.yt-rating-val-wrapper[data-v-23e03094] {
    pointer-events: none;
}
.yt-rating-val-wrapper[data-v-23e03094] {
    margin: 15px 0 30px;
}


/*
* Single Product Right Section CSS
*/
.added.sp-favorite-wrap .sp-fav-icn[data-v-23e03094] {
    margin: 41px 25px 30px 40px;
    padding: 20px 20px 25px 40px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
}
.sp-fav-icn[data-v-23e03094] {
    width: 19px;
    height: 19px;
}
.product-title[data-v-23e03094] {
    color: var(--color-black);
    font-size: 50px;
    font-weight: 500;
    line-height: 1.67;
    max-width: 550px;
}
.sp-favorite-wrap[data-v-23e03094] {
    cursor: pointer;
    box-shadow: 0 1px 4px 0 rgba(199, 214, 227, 0.5);
    border-radius: 100%;
}
.sp-price-tag-name[data-v-23e03094] {
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
}
.sp-price-tag-value[data-v-23e03094] {
    font-size: 28px;
    font-weight: 500;
    color: #000000;
    line-height: 1.14;
    letter-spacing: 1.08px;
}
.sp-small-tag-value[data-v-23e03094] {
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: 1.08px;
    color: var(--color-green);
}
.sp-price-wrap[data-v-23e03094],
.sp-size-wrap[data-v-23e03094] {
    padding: 12px 0;
}
.sp-quantity-tag-name[data-v-23e03094] {
    font-size: 16px;
    color: var(--color-dark-grey);
    line-height: 1.13;
    letter-spacing: normal;
}
.sp-verify-icn-wrap[data-v-23e03094] {
    line-height: 14px;
    margin-right: 10px;
}
.sp-verify-icn-wrap img[data-v-23e03094] {
    max-height: 15px;
    width: auto;
}
.product-stock-message[data-v-23e03094] {
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
}
.sp-small-tag-name[data-v-23e03094] {
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: 0.5px;
    color: var(--color-dark-grey);
}
.sp-out-quantity-tag-name[data-v-23e03094] {
    font-size: 16px;
    color: var(--color-orange);
    line-height: 1.13;
    letter-spacing: normal;
}
.sp-ccolor-box[data-v-23e03094] {
    width: 33px;
    height: 33px;
    border-radius: 27px;
    position: relative;
    cursor: pointer;
    border: 1px solid #dee2e6;
}
.active.sp-ccolor-box[data-v-23e03094]::after {
    content: "";
    height: 11px;
    width: 11px;
    background-image: url("../images/verify-icn.png");
    position: absolute;
    background-size: cover;
    background-color: #fff;
    right: -5px;
    top: -5px;
    border-radius: 100%;
}
.sp-color-1[data-v-23e03094] {
    background-color: var(--color-black);
}
.sp-color-2[data-v-23e03094] {
    background-color: #f6f8fa;
}
.sp-color-3[data-v-23e03094] {
    background-color: var(--color-green);
}
.sp-color-4[data-v-23e03094] {
    background-color: var(--color-blue);
}
.sp-color-5[data-v-23e03094] {
    background-color: var(--color-dark-grey);
}
.sp-border-top-bottom[data-v-23e03094] {
    border-top: 2px solid #cae2fe;
    border-bottom: 2px solid #cae2fe;
}
.sp-size-details[data-v-23e03094] {
    width: 50px;
    height: 37px;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    font-size: 17px;
    color: var(--color-black);
    line-height: 1.24;
    cursor: pointer;
}
.sp-variant-details[data-v-23e03094] {
    width: auto;
    height: auto;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
    background-color: #ffffff;
    font-size: 17px;
    color: var(--color-black);
    line-height: 1.24;
    cursor: pointer;
}
.active.sp-variant-details[data-v-23e03094] {
    background-color: #000000;
    color: #f6f8fa;
}
.active.sp-size-details[data-v-23e03094] {
    background-color: #000000;
    color: #f6f8fa;
}
.sp-addto-cart-btn[data-v-23e03094],
.sp-buy-now-btn[data-v-23e03094] {
    min-width: 125px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #000;
}
.sp-buy-now-btn[data-v-23e03094] {
    background-color: #ffff;
    color: #000;
}
.sp-buy-now-btn[data-v-23e03094]:hover{
    background-color: #000;
    color: #ffffff;
}
.sp-addto-cart-btn[data-v-23e03094], .sp-addto-cart-btn[data-v-23e03094]:hover {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}



/* .yt-cm-lt-col {
    padding-right: 7px;
}

.yt-cm-rt-col {
    padding-left: 7px;
} */
.sp-product-add-action-wrap[data-v-23e03094] {
    margin-top: 30px;
}
.sp-product-notification-wrap[data-v-23e03094] {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.sp-inner-content-wrap[data-v-23e03094] {
    padding: 20px 30px 0px 30px;
    /* margin-top: 20px; */
}
@media (min-width: 1280px) {
.yt-tab-mob[data-v-23e03094] {
        display: none;
}
}

/* @media (min-width: 1280.88px) {
    .yt-cm-lt-col {
        padding-right: 10px;
    }
    .yt-cm-rt-col {
        padding-left: 10px;
    }
}

@media (min-width: 1900px) {
    .yt-cm-lt-col {
        padding-right: 20px;
    }
    .yt-cm-rt-col {
        padding-left: 20px;
    }
} */
@media (min-width: 769px) {
.sp-price-left-content[data-v-23e03094],
    .sp-price-right-content[data-v-23e03094] {
        width: 100%;
}
}
@media (min-width: 768px) {
.yt-read-more-des[data-v-23e03094] {
        display: none;
}
}
@media (min-width: 576px) {
.sp-addto-cart-btn[data-v-23e03094],
    .sp-buy-now-btn[data-v-23e03094] {
        min-width: 300px;
}
.sp-image-slide li.navigation-up[data-v-23e03094] {
        transform: rotate(180deg);
}
}
@media (min-width: 1280px) {
.sp-border-md-right[data-v-23e03094] {
        border-right: 2px solid #f6f8fa;
        padding-right: 15px;
}
}


/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.sp-breadcrumbs[data-v-23e03094] {
        margin-bottom: 20px;
}
.sp-inner-wrap[data-v-23e03094],
    .sp-inner-content-wrap[data-v-23e03094] {
        padding: 20px 15px;
}
.product-title[data-v-23e03094] {
        font-size: 18px;
        line-height: 1.33;
        margin-bottom: 7px;
}
    /* .sp-price-wrap,
        .sp-size-wrap {
            padding: 20px 0;
        } */
.sp-small-tag-name[data-v-23e03094] {
        font-size: 12px;
        line-height: 1.17;
        letter-spacing: 0.46px;
}
.sp-price-tag-value[data-v-23e03094] {
        font-size: 20px;
        line-height: 1.15;
        letter-spacing: 0.77px;
}
.sp-quantity-tag-name[data-v-23e03094] {
        font-size: 12px;
        line-height: 2.33;
}
.sp-price-right-content .sp-quantity-tag-name[data-v-23e03094] {
        font-size: 11px;
        line-height: 1.18;
}
.sp-addto-cart-btn[data-v-23e03094],
    .sp-buy-now-btn[data-v-23e03094] {
        padding-top: 10px !important;
        padding-bottom: 12px !important;
        min-width: 150px;
        font-size: 16px;
        line-height: 1.13;
        letter-spacing: 0.32px;
}
.sp-description-title[data-v-23e03094] {
        font-size: 16px;
        line-height: 1.13;
        margin-bottom: 9px;
}
.sp-description-text[data-v-23e03094] {
        font-size: 14px;
        line-height: 1.71;
}
.sp-rating-content[data-v-23e03094] {
        font-size: 20px;
        line-height: 1.15;
}
.yt-rating-val-wrapper[data-v-23e03094] {
        margin: 8px 0;
}
.sp-rating-view span[data-v-23e03094]:first-child {
        font-size: 12px;
        line-height: 1.75;
}
.yt-rating-col[data-v-23e03094] {
        flex: 0 0 100%;
        max-width: 100%;
}
.sp-size-details[data-v-23e03094] {
        width: 41px;
        height: 30px;
        font-size: 12px;
}
.sp-inner-write-review[data-v-23e03094] {
        border-bottom: 2px solid #cae2fe;
        border-top: 2px solid #cae2fe;
        padding: 15px 0;
        margin-bottom: 5px;
}
.sp-user-name[data-v-23e03094] {
        font-size: 16px;
        line-height: 1.13;
}
.sp-rating-wrap[data-v-23e03094] {
        border: none;
        padding: 0 !important;
}
.sp-inner-write-review .sp-description-title[data-v-23e03094] {
        font-size: 14px;
        line-height: 1.14;
}
.sp-user-rated-meta ul[data-v-23e03094] {
        display: none !important;
}
.sp-user-info .yt-tab-mob ul li img[data-v-23e03094] {
        max-width: 12px;
}
.sp-rating-date[data-v-23e03094] {
        padding-top: 10px;
}
span.sp-rating-area[data-v-23e03094] {
        width: 80%;
}
.yt-rating-inner-row[data-v-23e03094] {
        flex-wrap: nowrap !important;
}
.yt-rating-inner-row .sp-rating-inner-wrap[data-v-23e03094]:last-child {
        width: 100%;
        padding-left: 15px;
}
.yt-rating-inner-row .sp-rating-inner-wrap[data-v-23e03094]:first-child {
        width: 255px !important;
}
.yt-rating-inner-row .sp-rating-inner-wrap:last-child ul li span[data-v-23e03094]:nth-child(2) {
        padding: 0 7px;
}
.yt-rating-inner-row .sp-rating-inner-wrap:last-child ul li span[data-v-23e03094]:first-child {
        width: 8px;
}
.sp-inner-write-review .sp-give-review-btn[data-v-23e03094] {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        font-size: 16px;
        line-height: 1.13;
        letter-spacing: 0.32px;
        padding-top: 11px !important;
        padding-bottom: 11px !important;
}
.sp-rating-wrap .sp-user-info[data-v-23e03094] {
        padding-left: 8px !important;
}
.sp-user-rated-meta[data-v-23e03094] {
        width: 70px;
}
#sp-size-data li[data-v-23e03094] {
        margin-right: 4px !important;
}
.sp-product-add-action-wrap[data-v-23e03094] {
        margin-top: 20px;
}
.sp-out-quantity-tag-name[data-v-23e03094] {
        font-size: 12px;
        line-height: 2.33;
}
.yt-product-img-inner-row .sp-inner-content-wrap[data-v-23e03094] {
        text-align: center;
}
}


/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
.yt-cm-lt-col[data-v-23e03094],
    .yt-cm-rt-col[data-v-23e03094],
    .yt-sp-description-col[data-v-23e03094] {
        flex: 0 0 100%;
        max-width: 100%;
}
.yt-product-img-inner-row .sp-inner-content-wrap[data-v-23e03094] {
        text-align: center;
}
    /* .yt-cm-lt-col {
        padding-right: 15px;
    }
    .yt-cm-rt-col {
        padding-left: 15px;
    } */
.right-inner-content-wrap[data-v-23e03094] {
        margin-top: 10px;
}
.right-inner-content-wrap[data-v-23e03094],
    .yt-lt-inner-bottom-content[data-v-23e03094] {
        margin-left: -20px;
        margin-right: -20px;
        overflow: hidden;
}
.right-inner-content-wrap .sp-inner-wrap[data-v-23e03094] {
        border-radius: 0 !important;
}
.right-inner-content-wrap .sp-size-wrap[data-v-23e03094] {
        display: none;
}
.right-inner-content-wrap .sp-price-wrap[data-v-23e03094] {
        border: none;
}
.product-title[data-v-23e03094] {
        max-width: 100%;
        width: 100%;
}
.sp-price-wrap.yt-mb-space-border[data-v-23e03094] {
        padding-bottom: 0;
}
.yt-mb-space-border[data-v-23e03094] {
        position: relative;
}
.yt-mb-space-border[data-v-23e03094]::after {
        content: "";
        width: 150%;
        height: 4px;
        background: #f2f6f8;
        position: absolute;
        left: -20px;
        top: 0;
}
.yt-lt-inner-bottom-content .sp-inner-content-wrap[data-v-23e03094] {
        border-radius: 0;
}
.sp-description-text[data-v-23e03094] {
        font-size: 17px;
        color: var(--color-black);
        line-height: 1.24;
}
.sp-small-tag-name[data-v-23e03094],
    .sp-description-title[data-v-23e03094] {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.15;
        letter-spacing: 0.5px;
        color: var(--color-dark-grey);
}
.sp-product-add-action-wrap[data-v-23e03094] {
        width: 100%;
}
.sp-product-add-action-wrap li[data-v-23e03094] {
        width: 50%;
}
.sp-description-text[data-v-23e03094] {
        max-height: 44px;
        overflow: hidden;
}
.sp-description-wrap.active-desc .sp-description-text[data-v-23e03094] {
        max-height: 100%;
}
.yt-read-more-des[data-v-23e03094] {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        color: #3bc490;
}
.sp-inner-write-review[data-v-23e03094] {
        border: none;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-23e03094]:last-child {
        border-bottom: none;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-23e03094]:first-child {
        border-top: solid 2px #f2f6f8;
}
.sp-rating-wrap .yt-sp-user-rate-box[data-v-23e03094] {
        border-bottom: solid 2px #f2f6f8;
}
.yt-sp-title-wrapper[data-v-23e03094] {
        flex-wrap: wrap-reverse;
}
.sp-favorite-wrap[data-v-23e03094] {
        margin-left: auto;
        margin-bottom: 10px;
}
.sp-price-right-content .sp-quantity-tag-name[data-v-23e03094] {
        display: none;
}
.sp-addto-cart-btn[data-v-23e03094],
    .sp-buy-now-btn[data-v-23e03094] {
        width: 100%;
}
.sp-out-quantity-tag-name[data-v-23e03094] {
        line-height: 1.75;
}
.yt-lt-inner-bottom-content .sp-inner-content-wrap[data-v-23e03094] {
        margin-top: 0;
}
}
@media (max-width: 575.98px) {
.sp-rating-wrap .sp-rating-inner-wrap[data-v-23e03094] {
        width: 100%;
}
.sp-image-slide li.navigation-down[data-v-23e03094] {
        transform: rotate(270deg);
}
.sp-image-slide li.navigation-up[data-v-23e03094] {
        transform: rotate(90deg);
}
.sp-image-slide li[data-v-23e03094] {
        margin: 0 10px;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.sp-price-tag-value[data-v-23e03094] {
        font-size: 24px;
}
.sp-quantity-tag-name[data-v-23e03094] {
        font-size: 14px;
}
.sp-addto-cart-btn[data-v-23e03094],
    .sp-buy-now-btn[data-v-23e03094] {
        font-size: 16px;
}
.sp-give-review-btn[data-v-23e03094] {
        font-size: 16px;
}
}
@media only screen and (max-device-width: 480px) {
.product-title[data-v-23e03094]{
      font-size: 30px;
}
}