.navbar[data-v-b04a5803]{
    margin-top: 3px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}
.item[data-v-b04a5803]{
    margin-left: 35px;
    margin-right: 35px;
}
@media only screen and (max-width: 992px) {
.navimage[data-v-b04a5803]{
        width: 100%;
}
.shopnow[data-v-b04a5803]{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        margin-top: -50px;
        width:100px;
        background-color: black;
        color: white;
        padding: 5px;
        border-radius: 5px;
        height: 30px;
        text-align: center;
        cursor: pointer;
}
}
@media only screen and (min-width: 992px) {
.navimage[data-v-b04a5803]{
        width: 100%;
}
.shopnow[data-v-b04a5803]{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        margin-top: -100px;
        width:140px;
        background-color: black;
        color: white;
        padding: 10px;
        border-radius: 5px;
        height: 40px;
        text-align: center;
        cursor: pointer;
}
}