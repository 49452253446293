/*
* YT Product Slider CSS
*/
.profile-pg-mb-30[data-v-2992ca32] {
  margin-bottom: 30px;
}
.profile-pg-mt-30[data-v-2992ca32] {
  margin-bottom: 30px;
}
.radius-10[data-v-2992ca32] {
  border-radius: 10px;
}
.profile-p-30[data-v-2992ca32] {
  padding: 30px;
}
.profile-p-20[data-v-2992ca32] {
  padding: 20px;
}
.profile-pg-wl-sgl-product-fav[data-v-2992ca32] {
  width: 24px;
}
.profile-pg-wl-sglproduct-fav[data-v-2992ca32] {
  color: var(--color-orange);
  cursor: pointer;
  font-size: 24px;
}
.pp-na-ttl[data-v-2992ca32] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.profile-pg-wl-sgl-product-off[data-v-2992ca32] {
  background-color: var(--color-primary);
  color: #ffffff;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.profile-pg-wl-sgl-product-cpnt[data-v-2992ca32] {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding-bottom: 46px;
  position: relative;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.45s;
}
.profile-pg-wl-sgl-component-wrapper[data-v-2992ca32] {
  border-radius: 10px;
  position: relative;
}
.rec-item-wrapper[data-v-2992ca32] {
  outline: none !important;
  box-shadow: none;
}
.profile-pg-wl-sgl-product-add-btn.buttoncart[data-v-2992ca32] {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  width: 100%;
  color: var(--color-dark-black);
  border: 1px solid var(--color-dark-black);
  background-color: transparent;
  box-shadow: none !important;
}
.pp-na-text[data-v-2992ca32] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.emptyWishlist[data-v-2992ca32] {
  margin-top: 149px;
    margin-left: 400px;
    font-size: 30px;
    font-weight: 500;
    color: black;
}
.profile-pg-wl-sgl-product-title[data-v-2992ca32] {
  font-size: 20px;
  line-height: 0.95;
  color: var(--color-dark-black);
  margin: 16px 0 14px;
}
.profile-pg-wl-sgl-price-wrap[data-v-2992ca32] {
  margin-bottom: 25px;
}
.profile-pg-wl-sgl-product-img[data-v-2992ca32] {
  width: 184px;
  height: 184px;
  background-size: cover;
  background-position: 100%;
  margin: 5px auto 0;
}
.profile-pg-wl-sgl-component-more[data-v-2992ca32] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  text-align: right;
  border-radius: 0;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-dark-grey);
  text-decoration: none !important;
}
.profile-pg-wl-sgl-comonent-top-title[data-v-2992ca32] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
}
.profile-pg-sa-no-address-main-wrap[data-v-2992ca32] {
  padding: 35px 0;
}
.profile-pg-wl-cm-row-margin[data-v-2992ca32] {
  margin: 0 -0.5rem;
}
.profile-pg-mb-30[data-v-2992ca32] {
  margin-bottom: 30px;
}
.profile-pg-wl-sgl-product-off[data-v-2992ca32] {
  background-color: var(--color-primary);
  font-size: 18px !important;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.profile-yt-sgl-product-off[data-v-2992ca32] {
  background-color: var(--color-primary);
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.11;
  min-width: 66px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-left: -1px;
}
.profile-pg-inner-no-add img[data-v-2992ca32] {
  max-height: 217px;
}
.profile-pg-inner-wrap[data-v-2992ca32] {
  padding: 20px;
  border: solid 1px #dbdbdb;
  margin: 0 20px;
}
.pp-no-addr-btn[data-v-2992ca32] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.13;
  color: #ffffff;
  width: 100%;
  max-width: 200px;
}
.pp-no-addr-btn[data-v-2992ca32]:hover{
  border: 1px solid black;
  color: black;
  background-color: white;
}
.profile-pg-wl-sgl-product-add-btn.buttoncart[data-v-2992ca32] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
}
.profile-pg-wl-sgl-product-sale-price[data-v-2992ca32],
.profile-pg-wl-sgl-product-reg-price2[data-v-2992ca32] {
  padding-left: 8px;
  color: var(--color-dark-grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
}
.profile-pg-wl-sgl-product-sale-price[data-v-2992ca32] {
  color: var(--color-dark-black);
}
.profile-pg-wl-sgl-product-reg-price2[data-v-2992ca32] {
  padding-left: 8px;
  color: var(--color-dark-grey);
}
.yt-fav-circle-wrap[data-v-2992ca32] {
  box-shadow: 0 3px 6px 0 rgba(62, 69, 79, 0.1);
}
.yt-sp-top-fav-act[data-v-2992ca32] {
  position: absolute;
  top: 10px;
  right: 15px;
  left: 0;
}
.yt-product-bg-image[data-v-2992ca32] {
  width: 100%;
  height: 252px;
  background-size: cover;
  background-position: 100%;
  cursor: pointer;
  border-radius: 4px;
  border-radius: 4px;
  background-repeat: no-repeat;
}
.profile-pg-wl-sgl-product-cpnt[data-v-2992ca32]:hover {
  border-radius: 10px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
}
.profile-pg-wl-sgl-product-cpnt:hover
  .profile-pg-wl-sgl-product-add-btn.buttoncart[data-v-2992ca32] {
  background: var(--color-dark-black);
  color: #fff;
}
@media (max-width: 1900px) and (min-width: 1366px) {
.profile-pg-wl-sgl-product-title[data-v-2992ca32] {
    font-size: 18px;
    line-height: 1.06;
}
.profile-pg-wl-sgl-product-add-btn.buttoncart[data-v-2992ca32] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
}
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.profile-pg-sa-no-address-main-wrap[data-v-2992ca32] {
    padding: 35px 0;
}
.profile-pg-wl-sgl-product-off[data-v-2992ca32] {
    font-size: 12px !important;
    line-height: 1.17;
    min-width: 35px;
}
.profile-pg-wl-sgl-product-title[data-v-2992ca32] {
    font-size: 14px;
    line-height: 0.71;
    margin: 15px 0 8px;
}
.pp-na-text[data-v-2992ca32] {
    font-size: 14px;
    line-height: 1.14;
}
.pp-no-addr-btn[data-v-2992ca32] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.pp-na-ttl[data-v-2992ca32] {
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 9px !important;
}
.profile-pg-inner-no-add img[data-v-2992ca32] {
    margin-bottom: 39px !important;
}
.profile-pg-wl-sgl-product-sale-price[data-v-2992ca32],
  .profile-pg-wl-sgl-product-reg-price2[data-v-2992ca32] {
    font-size: 15px;
    line-height: 1.2;
}
.pp-sa-no-add-wrap[data-v-2992ca32] {
    margin-bottom: 40px !important;
}
.profile-pg-wl-sgl-product-add-btn[data-v-2992ca32] {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    font-size: 12px;
    line-height: 1.17;
}
.profile-pg-wl-sgl-price-wrap[data-v-2992ca32] {
    margin-bottom: 16px;
}
.profile-pg-wl-sgl-product-cpnt[data-v-2992ca32] {
    margin-bottom: 15px !important;
}
.profile-pg-whish-lt-inner-wrap.profile-pg-inner-wrap[data-v-2992ca32] {
    padding: 20px 20px 5px;
}
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.profile-pg-whish-lt-inner-wrap.profile-pg-inner-wrap[data-v-2992ca32] {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
}
.profile-pg-sa-no-address-main-wrap[data-v-2992ca32] {
    padding: 18px 0 5px;
}
.profile-pg-wl-cm-row-margin[data-v-2992ca32] {
    margin: 0 -11px;
}
.profile-pg-wl-sgl-product-cpnt[data-v-2992ca32] {
    max-width: 100%;
    margin-bottom: 10px !important;
}
.pp-na-ttl[data-v-2992ca32] {
    font-size: 17px;
    line-height: 1.12;
    margin-bottom: 9px !important;
}
.pp-na-text[data-v-2992ca32] {
    font-size: 15px;
    line-height: 1.2;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}
.pp-no-addr-btn[data-v-2992ca32] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: normal;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    width: 100%;
    max-width: 100%;
}
.yt-cm-wl-col[data-v-2992ca32] {
    max-width: 50%;
}
.pp-sa-no-add-wrap[data-v-2992ca32] {
    margin-bottom: 30px !important;
}
.profile-pg-wl-cm-row-margin .yt-cm-wl-col[data-v-2992ca32]:nth-child(2n) {
    padding-left: 6.5px !important;
    padding-right: 15px !important;
}
.profile-pg-inner-no-add img[data-v-2992ca32] {
    margin-bottom: 34px !important;
}
.profile-pg-wl-cm-row-margin .yt-cm-wl-col[data-v-2992ca32]:nth-child(2n + 1) {
    padding-right: 6.5px !important;
    padding-left: 15px !important;
}
.profile-pg-wl-sgl-product-title[data-v-2992ca32] {
    font-size: 16px;
    line-height: 1.19;
    margin: 20px 0 23px;
}
.profile-pg-wl-sgl-product-sale-price[data-v-2992ca32],
  .profile-pg-wl-sgl-product-reg-price2[data-v-2992ca32] {
    font-size: 15px;
    line-height: 1.2;
}
.profile-pg-wl-sgl-product-add-btn[data-v-2992ca32] {
    font-size: 13px;
    line-height: 1.15;
}
.profile-pg-wl-sgl-price-wrap[data-v-2992ca32] {
    margin-bottom: 18px;
}
}
