.yt-filter-title[data-v-eef5380b] {
  font-size: 18px;
  color: var(--color-dark-black);
  line-height: 1.11;
  margin-bottom: 10px;
}
.radius-10[data-v-eef5380b] {
  border-radius: 10px;
}
.yt-box-title[data-v-eef5380b] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-dark-black);
  margin-bottom: 25px;
}
.yt-ul-list-none[data-v-eef5380b] {
  list-style: none;
}
.yt-cat-name[data-v-eef5380b],
.yt-color-name[data-v-eef5380b],
.yt-size-name[data-v-eef5380b] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.yt-main-filter-box ul li[data-v-eef5380b]:last-child {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.yt-filter-divider[data-v-eef5380b] {
  display: block;
  height: 2px;
  background: #f6f8fa;
  margin: 7.5px 0;
}
.yt-filter-search-wrap[data-v-eef5380b] {
  position: relative;
}
.yt-search-icn[data-v-eef5380b] {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 22px;
  padding: 11px;
  box-sizing: content-box;
  background: #fff;
  bottom: 2px;
  border-radius: 7px;
  color: #a1a4a9;
}
.yt-color-search-bar[data-v-eef5380b] {
  border-radius: 7px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
  width: 100%;
  padding-left: 54px;
}
.yt-filter-checkbox-wrap .yt-filter-label[data-v-eef5380b] {
  position: relative;
  cursor: pointer;
  margin: 0;
}
.yt-filter-checkbox-wrap .yt-filter-label[data-v-eef5380b]:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}
.yt-filter-checkbox-wrap input:checked + label[data-v-eef5380b]:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}
.yt-filter-checkbox-wrap input:checked + label[data-v-eef5380b]:before {
  background-color: #000;
}
.yt-filter-checkbox-wrap input[data-v-eef5380b] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
}
.yt-filter-checkbox-wrap[data-v-eef5380b] {
  position: relative;
  text-align: right;
}
.yt-filter-search-filed[data-v-eef5380b] {
  position: relative;
  margin-bottom: 20px;
}
.yt-filter-search-filed .yt-searchicon-img[data-v-eef5380b] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 17px;
}
.yt-filter-search-filed #yt-filter-brands-flt[data-v-eef5380b],
.yt-filter-search-filed #yt-filter-tags-flt[data-v-eef5380b] {
  padding-left: 56px;
  font-size: 18px;
  line-height: 1.11;
  color: var(--color-dark-grey);
}
.yt-main-filter-box[data-v-eef5380b] {
  border: solid 1px #dbdbdb;
}
.yt-color-name[data-v-eef5380b] {
  position: relative;
}
.yt-color-name[data-v-eef5380b]::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  display: block;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.yt-color-name.Baby.Pink[data-v-eef5380b]::after {
  background-color: #f4c2c2;
}
.yt-color-name.Beig[data-v-eef5380b]::after {
  background-color: #f5f5dd;
}
.yt-color-name.Black[data-v-eef5380b]::after {
  background-color: #000000;
}
.yt-color-name.Blue[data-v-eef5380b]::after {
  background-color: #0000fe;
}
.yt-color-name.Bottle.Green[data-v-eef5380b]::after {
  background-color: #006a4e;
}
.yt-color-name.Brown[data-v-eef5380b]::after {
  background-color: #d3691f;
}
.yt-color-name.Green[data-v-eef5380b]::after {
  background-color: #008001;
}
.yt-color-name.Indigo[data-v-eef5380b]::after {
  background-color: #4c0182;
}
.yt-color-black[data-v-eef5380b] {
  color: var(--color-black) !important;
}
.yt-main-filter-box .yt-filter-inner-wrap .yt-ul-list-none[data-v-eef5380b] {
  overflow: auto;
}
/* width */
.yt-main-filter-box .yt-filter-inner-wrap .yt-ul-list-none[data-v-eef5380b]::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.yt-main-filter-box
  .yt-filter-inner-wrap
  .yt-ul-list-none[data-v-eef5380b]::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.yt-main-filter-box
  .yt-filter-inner-wrap
  .yt-ul-list-none[data-v-eef5380b]::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
.yt-main-filter-box
  .yt-filter-inner-wrap
  .yt-ul-list-none[data-v-eef5380b]::-webkit-scrollbar-thumb:hover {
  background: #000;
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-filter-inner-wrap.yt-price-filter-wrap[data-v-eef5380b] {
            width: 33.3333333333%;
            float: left;
}
}
    /*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-filter-title[data-v-eef5380b] {
            display: none;
}
        /* .yt-filter-inner-wrap .yt-ul-list-none {
            list-style: none;
            margin: 0 -15px !important;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
        .yt-filter-inner-wrap .yt-ul-list-none li {
            width: 33.3333333333%;
            -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%;
            padding: 0 15px 0 !important;
        } */
.yt-main-filter-box[data-v-eef5380b] {
            border-radius: 10px;
            box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.19);
            border: solid 1px var(--color-dark-black);
}
.yt-filter-divider[data-v-eef5380b] {
            display: none;
}
.yt-product-filter-wrap .yt-main-filter-box[data-v-eef5380b]:after {
            display: block;
            clear: both;
            content: "";
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-box-title[data-v-eef5380b] {
            font-size: 18px;
}
}
@media (max-width: 767px) {
.yt-box-title[data-v-eef5380b]{
        margin-bottom: 15px;
}
}