/*
* Hero Banner CSS
*/
.silver_sec[data-v-5db2c221] {
  position: relative;
  z-index: 0;
  min-height: 760px;
  padding-bottom: 40px;
}
.silver_sec[data-v-5db2c221]:after {
  content:'';
  position: absolute;
  min-width: 1525px;
  min-height: 556px;
  background-color: #f8fbfe;
  right: 0;
  top: 85px;
  z-index: -1;
}
.silver_sec .img_view[data-v-5db2c221]{
  display: flex;
}
.silver_sec .img_view .item[data-v-5db2c221]:first-child{
  margin-bottom: auto;
  margin-right: 52px;
}
.silver_sec .img_view .item[data-v-5db2c221]:last-child{
  margin-top: 228px;
}
.silver_sec .img_view .item img[data-v-5db2c221]{
  max-height: 528px;
}
.silver_sec .content[data-v-5db2c221]{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: center;
}
.silver_sec .content h2[data-v-5db2c221]{
  font-size: 29px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 38px;
}
.my-btn[data-v-5db2c221]{
    background-color: transparent;
    border-color: black;
    font-weight: 500;
    color: black;
    max-width: 200px;
    min-height: 55px;
    font-size: 18px;
    letter-spacing: 3.8px;
    text-align: center;
}
.my-btn[data-v-5db2c221]:hover{
  background-color: black;
  color: white;
}
@media only screen and (max-width: 992px) {
}
@media only screen and (min-width: 992px) {
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.88px) {
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
}

/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
}
