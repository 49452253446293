/*
* Hero Banner CSS
*/
.hc-hero-bg-img[data-v-c84661aa] {
  /* background-image: url("../images/heroimage.png"); */
  background-size: cover;
  background-position: 100%;
  min-height: 300px;
  position: relative;
}
.hc-hero-btn[data-v-c84661aa] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  color: #fff;
  max-width: 180px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 60px;
  width: 100%;
}
.home-banner1[data-v-c84661aa] {
  padding-top: 40px;
  padding-bottom: 40px;
}
.banner-title[data-v-c84661aa] {
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 6px;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.banner-subtitle[data-v-c84661aa] {
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 4px;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myBannerimg[data-v-c84661aa] {
  height: 720px;
  width: 100%;
  object-fit: cover;
}
.video-container[data-v-c84661aa] {
  height: 720px;
  width: 100%;
  object-fit: cover;
}
.myBannercontent[data-v-c84661aa] {
  color: black;
  position: absolute;
  left: 0;
  right: 0;
  margin: -40px auto;
  bottom: 145px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
  width: 300px;
  line-height: 1.17;
  overflow: hidden;
  text-overflow: ellipsis;
}
.my-btn[data-v-c84661aa] {
  opacity: 0.99;
  background-color: transparent;
  border-color: black;
  font-weight: 500;
  line-height: 1.11;
  color: black;
  max-width: 200px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 40px;
  width: 200px;
  height: 55px;
  font-size: 18px;
  letter-spacing: 3.8px;
  text-align: center;
}
.display-product-container:hover .my-btn[data-v-c84661aa] {
  background-color: black;
  color: white;
}
.my-btn[data-v-c84661aa]:hover {
  background-color: black;
  color: white;
}
.button[data-v-c84661aa] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.hc-hero-banner[data-v-c84661aa] {
  position: relative;
}
@media only screen and (max-width: 992px) {
.heroimage[data-v-c84661aa] {
    width: 100%;
}
.shopnow[data-v-c84661aa] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -50px;
    width: 100px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
}
}
@media only screen and (min-width: 992px) {
.heroimage[data-v-c84661aa] {
    width: 100%;
}
.shopnow[data-v-c84661aa] {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: -100px;
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}
}
.herocontainer[data-v-c84661aa] {
  display: flex;
  justify-content: center;
}
.hero[data-v-c84661aa] {
  max-width: 1366px;
}
.hc-hero-banner[data-v-c84661aa] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.hc-hero-banner[data-v-c84661aa] {
    min-height: 200px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 768px) {
.hc-hero-btn[data-v-c84661aa] {
    font-size: 14px;
    line-height: 1.14;
    max-width: 120px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.hc-hero-banner[data-v-c84661aa] {
    min-height: 200pt;
}
.banner-title[data-v-c84661aa] {
    font-size: 40px;
}
.banner-subtitle[data-v-c84661aa] {
    font-size: 20px;
}
.myBannercontent[data-v-c84661aa] {
    width: 150px !important;
    font-size: 15px;
}
.my-btn[data-v-c84661aa] {
    max-width: 130px !important;
    font-size: 12px !important;
}
}
/*
* Screen for 1280 Resolution 
*/
@media (min-width: 1280px) {
.hc-hero-banner[data-v-c84661aa] {
    min-height: 300px;
}
}
@media (max-width: 1457px) {
.myBannercontent[data-v-c84661aa] {
    width: 250px;
}
.my-btn[data-v-c84661aa] {
    max-width: 180px;
    font-size: 15px;
}
}
/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
.hc-hero-btn[data-v-c84661aa] {
    bottom: 95px;
}
.hc-hero-banner[data-v-c84661aa] {
    min-height: 422px;
}
}
@media only screen and (max-device-width: 480px) {
.mobilePadding[data-v-c84661aa] {
    margin-top: 30px;
}
.banner-title[data-v-c84661aa] {
    font-size: 30px;
}
.banner-subtitle[data-v-c84661aa] {
    font-size: 18px;
}
}
