.yt-sglproduct-fav[data-v-07325c3d] {
  font-size: 24px;
  color: var(--color-dark-grey);
  cursor: pointer;
}
.yt-sglproduct-fav.active[data-v-07325c3d] {
  color: var(--color-orange);
}
/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.yt-sglproduct-fav[data-v-07325c3d] {
    font-size: 16px;
}
}
