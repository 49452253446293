.title-bar[data-v-11ebf0a1] {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title-bar h4[data-v-11ebf0a1] {
    color: #000;
    font-weight: 500;
    font-size: 33px;
}
.title-bar a[data-v-11ebf0a1] {
    color: #000;
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
}
.carousal-arrow[data-v-11ebf0a1] {
	border: 2px solid #94979D;
	padding: 0;
	border-radius: 50%;
	height: 30px;
	width: 30px;
	align-self: center;
}
.product-card[data-v-11ebf0a1] {
    border: none;
    position: relative;
    border-radius: 8px;
}
.product-card .card-img-top[data-v-11ebf0a1] {
    height: 230px;
    object-fit: contain;
}
.product-card .product-name[data-v-11ebf0a1] {
    font-size: 18px;
    font-weight: 400;
    margin: 8px auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    max-width: 230px;
    text-align: center;
}
.product-card .product-price-now[data-v-11ebf0a1]{
    margin: 0 5px;
    font-weight: bold;
}
.product-card .product-price-original[data-v-11ebf0a1] {
    color: #909399;
    margin: 0 5px;
    text-decoration: line-through;
}
.product-card .btn-heart[data-v-11ebf0a1] {
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: center;
    padding: 6px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}
.product-card .discount-tag[data-v-11ebf0a1] {
    position: absolute;
    width: 90px;
    padding: 5px 10px;
    background: #E65E52;
    color: #fff;
    font-weight: bold;
    top: 15px;
    text-align: center;
    border-radius: 0 25px 25px 0;
}
.btn-add-to-cart[data-v-11ebf0a1] {
    display: block;
    width: 100%;
    padding: 10px 0;
    border: 1px solid #000;
    color: #000;
}
.product-card[data-v-11ebf0a1]:hover {
    box-shadow: 1px 3px 20px 0 rgba(0, 0, 0, 0.1);
}
.product-card:hover .btn-add-to-cart[data-v-11ebf0a1] {
    color: #fff;
    background-color: #000;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
@media (max-width:1680px){
.product-card .product-name[data-v-11ebf0a1] {
        max-width: 180px;
}
}
@media (max-width:1399px){
.product-card .product-name[data-v-11ebf0a1] {
        max-width: 160px;
        font-size: 16px;
}
}
@media (max-width:1199px){
.product-card .product-price-now[data-v-11ebf0a1], .product-price-original[data-v-11ebf0a1]{
        font-size: 14px;
}
}
@media (max-width:991px){
.product-card .product-name[data-v-11ebf0a1] {
        font-size: 14px;
}
}