/* -----Change password----- */
.custom-modal .close-btn[data-v-af497ca7] {
  cursor: pointer;
}
.custom-modal .custom-body[data-v-af497ca7] {
  padding: 20px;
}
.custom-modal .dark-btn[data-v-af497ca7] {
  display: block;
  color: #fff;
  background-color: #000;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 0;
}
.main-form .custom-label[data-v-af497ca7] {
  margin: 0;
  color: #92969b;
  font-size: 14px;
}
.main-form .custom-input[data-v-af497ca7] {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 0;
  color: #000;
  height: 30px;
  font-size: 20px;
}
.main-form .error[data-v-af497ca7] {
  margin: 8px 0 0 -3px;
  font-size: 12px;
  color: red;
}
.main-form .form-control.invalid[data-v-af497ca7] {
  color: red;
  border-color: red;
}

/* -----change password success--- */
.custom-modal[data-v-af497ca7] {
  max-width: 700px;
}
.custom-modal .custom-body[data-v-af497ca7] {
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.custom-body img[data-v-af497ca7] {
  height: 200px;
  width: 200px;
}
.custom-body h1[data-v-af497ca7] {
  font-size: 28px;
  font-weight: 500;
}
.custom-body p[data-v-af497ca7] {
  color: #8c9096;
  margin: 10px 0;
}
.custom-modal .dark-btn[data-v-af497ca7] {
  margin-top: 30px;
  color: #fff;
  background-color: #000;
  font-weight: bold;
  font-size: 16px;
  padding: 10px 25px;
  border-radius: 5px;
}
