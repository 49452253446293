.yt-main-header .topbar[data-v-2969037b] {
  color: var(--color-black);
  background-color: #f6f8fa;
  padding: 21px 0 17px;
}
.yt-main-header .logocontainer[data-v-2969037b] {
  background-color: #000;
  padding: 30px 0;
}
.yt-main-header .logobox[data-v-2969037b] {
  cursor: pointer;
  background-color: var(--color-dark-black);
}
.yt-header-top-message[data-v-2969037b] {
  font-size: 20px;
  line-height: 1.15;
  color: var(--color-black);
}
.yt-main-header .supportbox[data-v-2969037b] {
  /* height: 95px; */
  width: 220px;
  /* padding: 50px 0; */
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .support[data-v-2969037b] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.yt-main-header .supportimage[data-v-2969037b] {
  height: auto;
  width: 30px;
  margin-right: 10px;
}
.yt-main-header .supportnumber[data-v-2969037b] {
  display: flex;
  flex-direction: column;
}
.yt-main-header .supportnumber .title[data-v-2969037b] {
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  padding-bottom: 5px;
}
.yt-main-header .supportnumber .number[data-v-2969037b] {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}
.yt-main-header .searchcontainer[data-v-2969037b] {
  display: flex;
  align-items: center;
  height: 100%;
}
.yt-main-header .searchinput[data-v-2969037b] {
  border-radius: 6px;
  border: solid 1px #37393b;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0 10px 50px;
  line-height: 1.15;
  color: #757575;
  background-color: #37393b;
  font-size: 20px;
  font-weight: 500;
}
.yt-main-header .searchicon[data-v-2969037b] {
  height: auto;
  width: 40px;
  padding: 10px;
  position: absolute;
  left: 18px;
}
.yt-main-header .cartcontainer[data-v-2969037b] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.yt-main-header .carticon[data-v-2969037b] {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.yt-main-header .loginbutton[data-v-2969037b] {
  width: 140px;
  height: 36px;
  border-radius: 6px;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.yt-main-header .menucontainer[data-v-2969037b] {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-top: 1px solid #37393b;
  background-color: #000;
}
.main_header .menuicon[data-v-2969037b] {
  height: auto;
  width: 16px;
  position: absolute;
  left: 20px;
  color: white;
}
.yt-main-header nav.navbar[data-v-2969037b] {
  padding: 0px;
}
.yt-main-header ul.navbar-nav[data-v-2969037b] {
  width: 100%;
  justify-content: space-around;
  background-color: #000;
}
.yt-main-header .nav-link[data-v-2969037b] {
  font-size: 18px;
  padding: 20px;
  color: #fff !important;
  font-weight: 100 !important;
}
.yt-main-header .nav-link.active[data-v-2969037b] {
  color: #fff !important;
  border-bottom: 3px solid #8b8f95;
  font-weight: bold !important;
}
.yt-main-header .cm-drop-down-wrap[data-v-2969037b] {
  position: static;
}
.yt-main-header .cm-big-drop-menu[data-v-2969037b] {
  width: 100%;
  padding: 52px 60px 37px;
  max-width: 1180px;
  margin-left: auto;
  left: 0;
  right: 0;
  margin-right: auto;
}
.yt-main-header .cm_big_dropmenu[data-v-2969037b] {
  min-width: 250px;
  margin: 0;
  padding: 0;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  top: 150% !important;
}
.yt-main-header .cm_big_dropmenu[data-v-2969037b]::-webkit-scrollbar {
  width: 5px;
}
.yt-main-header .cm_big_dropmenu[data-v-2969037b]::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #ccc;
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.yt-main-header .cm_big_dropmenu[data-v-2969037b]::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
.yt-main-header .cm_big_dropmenu .category_name[data-v-2969037b] {
  font-size: 20px;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  font-weight: 500;
  color: black;
}
.yt-main-header .cm_big_dropmenu .category_name[data-v-2969037b]:hover,
.yt-main-header .cm_big_dropmenu .category_name[data-v-2969037b]:focus {
  background-color: transparent;
}
.cart-divider[data-v-2969037b] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  width: 87%;
  margin: auto;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b] {
  font-size: 18px;
  padding: 15px 20px;
  color: black;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b]:hover,
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b]:focus {
  background-color: transparent;
}
.yt-main-header .cm-big-menu-head .dropdown-divider[data-v-2969037b] {
  border-top: 1px solid var(--color-dark-grey);
}
.main_header .cm_big_dropmenu[data-v-2969037b] {
  position: absolute;
  border-radius: 0 !important;
  top: 150% !important;
}
.main_header .cm-big-menu-inner .dropdown-item[data-v-2969037b] {
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-dark-grey);
  padding: 8px 10px;
  margin-bottom: 5px;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-2969037b] {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.14;
  color: var(--color-black);
}
.yt-main-header .head-dropdown-arrow-icn[data-v-2969037b] {
  font-size: 12px;
  margin-left: 4px;
}
.yt-main-header li.cm-drop-down-wrap.dropdown.show > a[data-v-2969037b] {
  color: #fff !important;
  font-weight: 600;
  border-bottom: 3px solid #8b8f95;
}
.yt-main-header .header-search-field-wrap[data-v-2969037b] {
  position: relative;
}
.yt-recent-search-wrap[data-v-2969037b] {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 50;
  width: 250px;
}
.yt-main-header .recent-search-tag-name[data-v-2969037b] {
  font-size: 15px;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-dark-grey);
}
.yt-main-header .recent-search-list-wrap .active[data-v-2969037b] {
  background: transparent;
  color: inherit;
}
.yt-recent-search-wrap[data-v-2969037b] {
  border-radius: 10px;
  box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 32px 20px 27px;
}
.yt-main-header .yt-no-search-found-wrap[data-v-2969037b] {
  padding: 54px 30px 46px;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-2969037b] {
  border-bottom: 2px solid #cae2fe;
  margin: 0;
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-black);
  padding: 15px 0 !important;
}
.myIcon[data-v-2969037b] {
  height: auto;
  width: 25px;
  position: absolute;
  left: 20px;
  color: white;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-2969037b]:first-child {
  padding-top: 0 !important;
}
.yt-main-header .recent-search-list-wrap .list-group-item[data-v-2969037b]:last-child {
  padding-bottom: 0 !important;
}
.yt-main-header .search-no-ttl[data-v-2969037b] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-top: 35px;
  margin-bottom: 15px;
}
.yt-main-header .search-no-sub-ttl[data-v-2969037b] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.yt-main-header .yt-main-menu .nav-item[data-v-2969037b] {
  cursor: pointer;
}
.yt-main-header .logoimage[data-v-2969037b] {
  max-width: 280px;
}
.yt-head-login-btn[data-v-2969037b] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: 0.36px;
  text-align: center;
  color: #ffffff;
  background-color: var(--color-dark-black);
  padding: 15px 26px;
}
.user_Name[data-v-2969037b] {
  cursor: pointer;
}
.yt-mobile-side-nav-wrap[data-v-2969037b] {
  display: none;
}
.cart-item[data-v-2969037b] {
  position: absolute;
  top: 0;
  right: 18px;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.wishlist-item[data-v-2969037b] {
  position: absolute;
  top: 15px;
  /* right: 170px; */
  /* right: 218px; */
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
.cartcontainer .nav-link[data-v-2969037b] {
  padding: 0;
  margin-right: 30px;
}
@media (min-width: 1900px) {
.yt-main-header .cm-big-drop-menu[data-v-2969037b] {
    max-width: 1421px;
}
.main_header
    .cm-big-drop-menu
    .yt-main-header
    .cm-big-menu-inner
    .dropdown-item[data-v-2969037b] {
    font-size: 20px;
    line-height: 1.15;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-2969037b] {
    font-size: 28px;
    line-height: 1.14;
}
.yt-main-header .searchinput[data-v-2969037b] {
    padding-top: 13px;
    padding-bottom: 13px;
}
}
@media (max-width: 1366px) {
.yt-main-header .logoimage[data-v-2969037b] {
    max-width: 220px;
}
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 100px !important;
}
}
@media only screen and (min-width: 992px) {
.yt-main-header .order-sm-1[data-v-2969037b] {
    order: 0;
}
.yt-main-header .searchicon[data-v-2969037b] {
    left: 30px;
}
.yt-main-header ul.navbar-nav[data-v-2969037b] {
    padding: 0 226px;
}
}
@media only screen and (max-width: 768px) {
.yt-only-mobile-vw[data-v-2969037b] {
    display: none;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-2969037b] {
    display: none;
}
}
@media only screen and (max-width: 992px) {
.yt-main-header .order-sm-1[data-v-2969037b] {
    order: 1;
}
.yt-main-header .searchicon[data-v-2969037b] {
    left: 32px;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767px) {
.yt-main-header .logocontainer .yt-head-col[data-v-2969037b] {
    flex: 0 0 50%;
    max-width: 50%;
}
.yt-main-header .yt-head-hide-srch-col[data-v-2969037b],
  .yt-main-header .topbar[data-v-2969037b] {
    display: none;
}
.yt-main-header .logocontainer[data-v-2969037b] {
    padding-top: 15px;
    padding-bottom: 15px;
}
.yt-main-header .supportbox[data-v-2969037b] {
    display: none;
}
.yt-main-header .logoimage[data-v-2969037b] {
    max-width: 140px;
}
.yt-main-header .logobox[data-v-2969037b] {
    margin-left: 30px;
}
.yt-main-header .loginbutton span[data-v-2969037b] {
    display: none;
}
.yt-main-header .loginbutton span.yt-head-lgn-btn[data-v-2969037b] {
    display: block !important;
}
.yt-main-header .loginbutton span[data-v-2969037b] {
    font-size: 13px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    color: white;
}
.yt-main-header .loginbutton[data-v-2969037b] {
    background: transparent;
    width: auto;
    height: auto;
    border-radius: 0;
}
.cartcontainer .nav-link[data-v-2969037b] {
    margin-right: 15px;
}
.yt-main-header .carticon[data-v-2969037b] {
    width: 16px;
    height: auto;
}
.yt-main-header .yt-head-seachicn-mb[data-v-2969037b] {
    display: block !important;
    position: inherit;
    margin-right: 13px;
    width: 18px;
    padding: 0;
}
.yt-head-mb-search-panel[data-v-2969037b] {
    display: block !important;
}
.yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap[data-v-2969037b] {
    display: block;
}
.yt-mobile-side-nav-wrap[data-v-2969037b] {
    position: fixed;
    background-color: rgb(62 69 79 / 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.yt-mobile-side-inner-content[data-v-2969037b] {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 550;
    max-width: 221px;
    overflow: auto;
    height: 100%;
    width: 100%;
}
.yt-mb-user-profile[data-v-2969037b] {
    padding: 20px;
    background-color: var(--color-dark-black);
    color: #fff;
}
.yt-mobile-side-inner-content .yt-inner-cnt-logo[data-v-2969037b] {
    padding: 15px 20px 20px;
}
.yt-mobile-side-inner-content .logobox-mb img[data-v-2969037b] {
    background-color: var(--color-dark-black);
}
.yt-mobile-nav-content[data-v-2969037b] {
    padding: 17px 20px;
}
.yt-mb-user-profile .yt-head-name[data-v-2969037b] {
    font-size: 16px;
    line-height: 1.13;
}
.yt-mb-user-profile .yt-head-email[data-v-2969037b] {
    font-size: 8px;
    line-height: 1.13;
}
.yt-mobile-nav-content li:last-child a[data-v-2969037b] {
    padding-bottom: 0;
}
.yt-mb-user-prfl-lks li:first-child a[data-v-2969037b] {
    padding-top: 0;
}
.yt-mb-user-prfl-lks[data-v-2969037b] {
    border-top: solid 1px #dbdbdb;
    padding: 20px;
}
.yt-main-header .yt-mobile-nav-content .cm-big-drop-menu[data-v-2969037b] {
    padding: 0;
    border: none;
}
.yt-main-header
    .yt-mobile-nav-content
    li.cm-drop-down-wrap.dropdown.show
    > a[data-v-2969037b] {
    border-bottom: none;
}
.yt-main-header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider[data-v-2969037b] {
    border: solid 1px #dbdbdb;
}
.main_header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item[data-v-2969037b] {
    font-size: 10px;
    line-height: 1.1;
}
.main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item[data-v-2969037b] {
    font-size: 12px;
    line-height: 1.17;
    color: var(--color-black);
}
.yt-mb-user-profile .yt-mb-nav-login-btn[data-v-2969037b] {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: 0.28px;
    font-weight: 500;
}
.yt-mb-innet-search[data-v-2969037b] {
    padding: 14px 15px 0;
}
.yt-main-header .yt-mb-innet-search .searchicon[data-v-2969037b] {
    top: 50%;
    transform: translateY(-50%);
    max-width: 16px;
    left: 12px;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b] {
    padding: 10px 10px 10px 38px !important;
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b]::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b]::-moz-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b]:-ms-input-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
.yt-main-header .yt-mb-innet-search .searchinput[data-v-2969037b]:-moz-placeholder {
    font-size: 12px;
    line-height: 1.17;
}
}
/* @media (max-width: 992px) {
    .main_header .logocontainer .yt-head-col {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .main_header .yt-head-hide-srch-col,
    .main_header .topbar {
      display: none;
    }
    .main_header .logocontainer {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .main_header .supportbox {
      display: none;
    }
    .main_header .logoimage {
      max-width: 140px;
    }
    .main_header .logobox {
      margin-left: 30px;
    }
    .main_header .loginbutton span {
      display: none;
    }
    .main_header .loginbutton span.yt-head-lgn-btn {
      display: block !important;
    }
    .main_header .loginbutton span {
      font-size: 13px;
      line-height: 1.14;
      letter-spacing: 0.28px;
      color: white;
    }
    .main_header .loginbutton {
      background: transparent;
      width: auto;
      height: auto;
      border-radius: 0;
    }
    .cartcontainer .nav-link {
      margin-right: 15px;
    }
    .main_header .carticon {
      width: 16px;
      height: auto;
    }
    .main_header .yt-head-seachicn-mb {
      display: block !important;
      position: inherit;
      margin-right: 13px;
      width: 18px;
      padding: 0;
    }
    .yt-head-mb-search-panel {
      display: block !important;
    }
    .yt-only-mobile-vw.tr .yt-mobile-side-nav-wrap {
      display: block;
    }
    .yt-mobile-side-nav-wrap {
      position: fixed;
      background-color: rgb(62 69 79 / 0.8);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 500;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }
    .yt-mobile-side-inner-content {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 550;
      max-width: 221px;
      overflow: auto;
      height: 100%;
      width: 100%;
    }
    .yt-mb-user-profile {
      padding: 20px;
      background-color: var(--color-dark-black);
      color: #fff;
    }
    .yt-mobile-side-inner-content .yt-inner-cnt-logo {
      padding: 15px 20px 20px;
    }
    .yt-mobile-side-inner-content .logobox-mb img {
      background-color: var(--color-dark-black);
    }
    .yt-mobile-nav-content {
      padding: 17px 20px;
    }
    .yt-mb-user-profile .yt-head-name {
      font-size: 16px;
      line-height: 1.13;
    }
    .yt-mb-user-profile .yt-head-email {
      font-size: 8px;
      line-height: 1.13;
    }
    .yt-mobile-nav-content li:last-child a {
      padding-bottom: 0;
    }
    .yt-mb-user-prfl-lks li:first-child a {
      padding-top: 0;
    }
    .yt-mb-user-prfl-lks {
      border-top: solid 1px #dbdbdb;
      padding: 20px;
    }
    .main_header .yt-mobile-nav-content .cm-big-drop-menu {
      padding: 0;
      border: none;
    }
    .main_header
      .yt-mobile-nav-content
      li.cm-drop-down-wrap.dropdown.show
      > a {
      border-bottom: none;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-divider {
      border: solid 1px #dbdbdb;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-inner .dropdown-item {
      font-size: 10px;
      line-height: 1.1;
    }
    .main_header .yt-mobile-nav-content .cm-big-menu-head .dropdown-item {
      font-size: 12px;
      line-height: 1.17;
      color: var(--color-black);
    }
    .yt-mb-user-profile .yt-mb-nav-login-btn {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: 0.28px;
      font-weight: 500;
    }
    .yt-mb-innet-search {
      padding: 14px 15px 0;
    }
    .main_header .yt-mb-innet-search .searchicon {
      top: 50%;
      transform: translateY(-50%);
      max-width: 16px;
      left: 12px;
    }
    .main_header .yt-mb-innet-search .searchinput {
      padding: 10px 10px 10px 38px !important;
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput::-webkit-input-placeholder {
    
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput::-moz-placeholder {
     
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput:-ms-input-placeholder {
    
      font-size: 12px;
      line-height: 1.17;
    }
    .main_header .yt-mb-innet-search .searchinput:-moz-placeholder {
      
      font-size: 12px;
      line-height: 1.17;
    }
  } */
.userbox[data-v-2969037b] {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.userbox .usericon[data-v-2969037b] {
  width: 42px;
  min-width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block;
}
.userbox .wishlistIcon[data-v-2969037b] {
  width: 27px;
  /* min-width: 42px; */
  height: 42px;
  /* border-radius: 50%; */
  /* border: 1px solid #fff; */
  display: inline-block;
  color: white;
  margin-right: 22px;
}
.userbox .uname[data-v-2969037b] {
  margin-left: 10px;
  color: #fff;
  max-width: 70px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 1366px) and (max-width: 1900px) {
.yt-main-header .supportnumber .title[data-v-2969037b] {
    font-size: 14px;
}
.yt-main-header .supportnumber .number[data-v-2969037b] {
    font-size: 16px;
}
.yt-main-header .searchinput[data-v-2969037b] {
    font-size: 16px;
}
.yt-main-header .loginbutton[data-v-2969037b] {
    font-size: 16px;
}
.yt-main-header .yt-main-menu .nav-item[data-v-2969037b] {
    font-size: 18px;
}
.yt-main-header .cm-big-menu-inner .cm-big-menu-head button[data-v-2969037b] {
    font-size: 20px;
}
}
.main_header[data-v-2969037b] {
  width: 100%;
  z-index: 111;
}
@-webkit-keyframes myanimation-data-v-2969037b {
0% {
    background-color: transparent;
}
35% {
    background-color: black;
}
100% {
    background-color: black;
}
}
@keyframes myanimation-data-v-2969037b {
0% {
    background-color: transparent;
}
35% {
    background-color: black;
}
100% {
    background-color: black;
}
}
.myHeaderStyle[data-v-2969037b] {
  background-color: black;
  position: relative;
}
.myHeaderStyle2[data-v-2969037b] {
  position: fixed;
  transform: translateY(10);
  transition: transform 6s ease;
  -webkit-animation: myanimation-data-v-2969037b 3s;
          animation: myanimation-data-v-2969037b 3s;
  background-color: #000000f0;
}
.position-absolute[data-v-2969037b] {
  position: absolute;
}
.main_header .navbar[data-v-2969037b] {
  padding: 15px 80px;
}
.main_header .other_page[data-v-2969037b] {
  box-shadow: 0 3px 99px 0 #d9d9da !important;
}
.main_header .navbar .navbar-brand[data-v-2969037b] {
  padding: 0;
  margin: 0;
}
.main_header .navbar .navbar-brand img[data-v-2969037b] {
  width: auto;
  max-width: 110px;
}
.main_header .navbar .navbar-nav[data-v-2969037b] {
  align-items: center;
}
.main_header .navbar .navbar-nav .nav-item[data-v-2969037b] {
  display: inline-flex;
}
.main_header .navbar .navbar-nav .nav-item[data-v-2969037b]:not(:last-child) {
  margin-right: 125px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-2969037b] {
  padding: 0;
  font-size: 20px;
  color: white;
  letter-spacing: 1px;
  /* display: inline-flex; */
  padding-bottom: 6px;
}
.searchinput[data-v-2969037b] {
  background-color: black;
  color: white;
  border: none;
  font-size: 19px;
  letter-spacing: 2px;
  width: 100%;
}
.searchInput-icon[data-v-2969037b] {
  font-size: 27px;
  color: white;
  margin-right: 6px;
}
.borderTop[data-v-2969037b] {
  border-top: 1px solid #ffffffb3;
  width: 100%;
}
.cancel-search-icon[data-v-2969037b] {
  color: #8b8f95;
  font-size: 20px;
}
.user-name[data-v-2969037b] {
  font-size: 14px;
  left: 0;
  right: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.user-icon[data-v-2969037b] {
  font-size: 30px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon[data-v-2969037b] {
  position: relative;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .nav-link[data-v-2969037b] {
  font-size: 30px;
  cursor: pointer;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
  border-bottom: 1px solid #e6e6e6;
  position: absolute;
  right: 0;
  left: auto;
  top: 0px;
  display: flex;
  min-width: 225px;
  align-items: center;
  transition: 0.5s linear;
  min-height: 28px;
  opacity: 0;
  visibility: hidden;
  background-color: black;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input.active[data-v-2969037b] {
  opacity: 1;
  visibility: visible;
}
@-webkit-keyframes myDropdown-data-v-2969037b {
0% {
    background-color: transparent;
}
35% {
    background-color: white;
}
100% {
    background-color: white;
}
}
@keyframes myDropdown-data-v-2969037b {
0% {
    background-color: transparent;
}
35% {
    background-color: white;
}
100% {
    background-color: white;
}
}
.main_header .cm_big_dropmenu[data-v-2969037b] {
  min-width: 250px;
  max-height: 600px;
  -webkit-animation: myDropdown-data-v-2969037b 2s;
          animation: myDropdown-data-v-2969037b 2s;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b] {
  font-size: 20px;
  padding: 15px 20px;
  font-weight: 500;
  color: black;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b]:hover {
  color: #8b8f95;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b]:hover {
  color: black;
}
.main_header a[data-v-2969037b] {
  display: inline-block;
  text-decoration: none;
}
.main_header a[data-v-2969037b]::after {
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: white;
  transition: width 0.3s ease-out;
}
.main_header a.iamactive[data-v-2969037b]::after {
  width: 100%;
}
.hoverMe[data-v-2969037b]:hover::after {
  width: 100%;
}
.main_header a[aria-expanded="true"][data-v-2969037b]::after {
  width: 100%;
}
.main_header a[aria-expanded="false"] > .iconDown[data-v-2969037b] {
  display: none;
}
.main_header a[aria-expanded="true"] > .iconRight[data-v-2969037b] {
  display: none;
}
.my-cart-divider[data-v-2969037b] {
  border-bottom: solid 1px #e6e6e6 !important;
  display: block;
  margin: auto;
  width: 85%;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b]:last-child {
  border-bottom: none;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b]:hover,
.main_header .cm_big_dropmenu .category_name[data-v-2969037b]:focus {
  background-color: transparent;
}
nav.bg-dark[data-v-2969037b] {
  border-bottom: none;
}
.square[data-v-2969037b] {
  position: absolute;
  top: -5px;
  left: 10px;
  width: 9px;
  height: 9px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  transform: rotate(45deg);
  -webkit-animation: myDropdown-data-v-2969037b 3s;
          animation: myDropdown-data-v-2969037b 3s;
}
@media (max-width: 1774px) {
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 200px;
}
}
@media (max-width: 1601px) and (min-width: 1428px) {
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 60px !important;
}
}
@media (max-width: 1427px) {
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 50px !important;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 150px;
}
}
@media (max-width: 1276px) {
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 35px !important;
}
.main_header .navbar .nav-item .nav-link[data-v-2969037b] {
    font-size: 15px;
}
}
@media (max-width: 1201px) {
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 25px !important;
}
.main_header .navbar .nav-item .nav-link[data-v-2969037b] {
    font-size: 17px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.user-icon[data-v-2969037b] {
    font-size: 22px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link.search-icon[data-v-2969037b] {
    font-size: 22px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 160px;
}
}
@media (max-width: 992px) {
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 66px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-2969037b] {
    font-size: 17px;
}
}
@media (max-width: 812px) {
.main_header .navbar .navbar-brand img[data-v-2969037b] {
    max-width: 70px;
}
.main_header .navbar .navbar-nav .nav-item .nav-link[data-v-2969037b] {
    font-size: 15px;
}
.main_header .navbar[data-v-2969037b] {
    padding: 14px 35px;
}
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 15px !important;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    min-width: 150px;
}
}
/* iPhone [portrait + landscape] */
@media only screen and (max-device-width: 480px) {
.navbar-brand[data-v-2969037b] {
    margin: 0 auto !important;
}
.navbar-collapse[data-v-2969037b] {
    padding-top: 50px !important;
}
.navbar-collapse ul li[data-v-2969037b] {
    padding-top: 5px !important;
}
.main_header .navbar .nav-item[data-v-2969037b]:not(:last-child) {
    margin-right: 0 !important;
}
.navbar-collapse ul:last-child li[data-v-2969037b]:last-child {
    margin-right: 0 !important;
}
.user-name[data-v-2969037b] {
    left: 0;
}
.yt-recent-search-wrap[data-v-2969037b] {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 300px;
}
.main_header .navbar .navbar-nav .nav-item.search-icon .search-input[data-v-2969037b] {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 250px;
    height: 35px;
}
.main_header .cm_big_dropmenu[data-v-2969037b] {
    top: 112% !important;
    transform: translate(-50px, 0px);
}
.square[data-v-2969037b] {
    left: 120px;
}
.main_header .cm_big_dropmenu .category_name[data-v-2969037b] {
    font-size: 18px;
}
.main_header .cm_big_dropmenu .sub_drop_menu .dropdown-item[data-v-2969037b] {
    font-size: 16px;
}
}
