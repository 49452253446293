.quotes-wrapper[data-v-e195f14c] {
  width: 95%;
  margin: 50px auto;
  text-align: center;
}
.quotes-wrapper .title[data-v-e195f14c] {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  margin: 20px 0;
}
.carousal-arrow[data-v-e195f14c] {
  padding: 0;
  height: 30px;
  width: 30px;
  align-self: center;
}
.myReviewbtn[data-v-e195f14c]{
  background-color: black;
  color: white;
}
.myReviewbtn[data-v-e195f14c]:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}
.dont-show-me[data-v-e195f14c] {
  display: none;
}
.show-me[data-v-e195f14c] {
  color: #94979d;
  border: 2px solid #94979d;
  border-radius: 50%;
}
.main-carousal .carousal-item[data-v-e195f14c] {
  min-height: 270px;
  width: 100%;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  box-shadow: -1px 0px 15px 2px #f4f4f4;
  padding: 25px;
  position: relative;
}
.carousal-item img[data-v-e195f14c] {
  width: 20px;
  height: 20px;
}
.carousal-item .summary[data-v-e195f14c] {
  color: #444444;
  margin: 20px 0;
}
.carousal-item .info[data-v-e195f14c] {
  color: #444444;
  line-height: 1.4;
}
.carousal-item .user-info[data-v-e195f14c] {
  display: flex;
  bottom: 25px;
}
.user-info p[data-v-e195f14c] {
  font-weight: bold;
  margin: 0;
  line-height: 1.6;
}
.user-info .location span[data-v-e195f14c] {
  color: #94979d;
  font-size: 12px;
  font-weight: 300;
}
.btn-view-all[data-v-e195f14c] {
  display: block;
  margin: 25px auto;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  padding: 8px 20px;
}
@media (max-width: 974px) {
.dont-show-me[data-v-e195f14c] {
    display: block;
    color: #94979d;
    border: 2px solid #94979d;
    border-radius: 50%;
}
}
