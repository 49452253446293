@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre&display=swap");

body {
  background-color: #fff;
  color: #000;
  font: 15px "AbhayaLibre";
  font-family: "Abhaya Libre", serif;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font: 15px "Abhaya Libre", "serif";
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
  border: 3px solid black;
}
.modal-backdrop.show {
  opacity: 0.8;
  background-color: var(--color-black);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
div:focus {
  outline: none !important;
}

#root {
  max-width: 1920px;
  margin: 0 auto;
}
.modal-content {
  border-radius: 10px;
}
.styles_scroll-to-top__2A70v:hover {
  color: white !important;
  background-color: var(--color-dark-black) !important;
}
.styles_scroll-to-top__2A70v:hover > svg {
  fill: #fff;
}
/*
* Global Remove Number field arrow
*/
.yt-fp-form
  .yt-number-filed.form-group
  .form-control::-webkit-outer-spin-button,
.yt-fp-form
  .yt-number-filed.form-group
  .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.yt-fp-form .yt-number-filed.form-group .form-control[type="number"] {
  -moz-appearance: textfield;
}

/*END*/
@media (min-width: 576px) {
  .cm-small-modal {
    max-width: 350px;
  }
}
@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}
@media (min-width: 768px) {
  .cm-small-modal-4 {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-4 {
    max-width: 286px;
    margin-left: auto;
    margin-right: auto;
  }
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1281px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 88%;
  }
}
@media (min-width: 1900px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 88.9%;
  }
}
/* @media (max-width: 1280px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
} */
