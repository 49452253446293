/*
* Home Page CSS
*/
/*
* Global CSS
*/
.yt-component-wrapper[data-v-7c293060]{
    position: relative;
}


/*End*/
.deals[data-v-7c293060] {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 500;
}
.products[data-v-7c293060] {
    background-color: white;
    padding: 30px;
    margin-bottom: 20px;
}
.more[data-v-7c293060] {
    float: right;
    font-size: 13px;
    cursor: pointer;
}
.fullscreen[data-v-7c293060] {
    width: 100%;
}
.oneintwo[data-v-7c293060] {
    margin-top: 20px;
    margin-bottom: 20px;
}
.shopnowbutton[data-v-7c293060] {
    width: 140px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
}
.savetext[data-v-7c293060] {
    color: black;
    font-weight: 600;
    font-size: 20px;
}
.textblock1[data-v-7c293060] {
    margin-top: -150px;
    margin-left: 80px;
}
.welcometext[data-v-7c293060] {
    color: black;
    font-weight: 600;
    font-size: 40px;
}
.textblock2[data-v-7c293060] {
    margin-top: -350px;
    margin-left: 80px;
}
.headtop[data-v-7c293060] {
    color: white;
    font-weight: 200px;
}
.uptooff[data-v-7c293060] {
    color: white;
    font-weight: 600;
    font-size: 60px;
}
.textblock3[data-v-7c293060] {
    margin-top: -300px;
    margin-left: 350px;
}
.logohead1[data-v-7c293060] {
    margin-left: 30px;
    font-weight: 500px;
    margin-top: -60px;
    font-size: 20px;
    color: white;
}
.shopnowgoldenbutton[data-v-7c293060] {
    width: 100px;
    background-color: var(--color-primary);
    color: white;
    font-weight: 400px;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    margin-top: -70px;
    float: right;
    margin-right: 10px;
}
.shopnowgoldenbutton1[data-v-7c293060] {
    width: 100px;
    background-color: var(--color-primary);
    color: white;
    font-weight: 400px;
    padding: 10px;
    border-radius: 5px;
    height: 40px;
    text-align: center;
    cursor: pointer;
    float: left;
    margin-top: 20px;
}
.dontmiss[data-v-7c293060] {
    font-size: 30px;
}
.offthirty[data-v-7c293060] {
    font-size: 70px;
    font-weight: 600;
}
.textbox4[data-v-7c293060] {
    margin-left: 120px;
    margin-top: -500px;
}
.hurryup[data-v-7c293060] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.dailydeal[data-v-7c293060] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.lowprice[data-v-7c293060] {
    color: white;
}
.textbox5[data-v-7c293060] {
    margin-left: 50px;
    margin-top: -200px;
}
.happytimes[data-v-7c293060] {
    font-size: 30px;
    color: white;
    font-weight: 600px;
}
.flat[data-v-7c293060] {
    font-size: 40px;
    color: white;
    font-weight: 700px;
}
.textbox7[data-v-7c293060] {
    margin-top: -230px;
    margin-left: auto;
    margin-right: auto;
}
.stores[data-v-7c293060] {
    width: 30%;
    margin-top: 70px;
    cursor: pointer;
}
.playbuttons[data-v-7c293060] {
    margin-left: 190px;
    margin-right: auto;
}
.download[data-v-7c293060] {
    font-size: 40px;
    font-weight: 400;
    color: black;
    margin-left: 100px;
}
.imglast[data-v-7c293060] {
    width: 100%;
    height: 100%;
}
.home-banner[data-v-7c293060], .banner-container[data-v-7c293060] {
    position: relative;
    min-height: 357px;
}
.home-banner .content[data-v-7c293060] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.banner-container .content[data-v-7c293060] {
    position: absolute;
    top: 50%;
    transform: translateY(-40%);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.home-banner .content .scheme[data-v-7c293060] {
    font-size: 80px;
    font-weight: 500;
}
.mytextbanner2[data-v-7c293060]{
    margin: 0 0 67px;
    font-size: 50px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #ffffff;
    text-align: left;
    font-style: italic;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-banner .content .info[data-v-7c293060] {
    font-size: 24px;
    color: #000;
}
.banner-container .content .scheme[data-v-7c293060] {
    font-size: 102px;
    font-weight: bold;
}
.banner-container .content .info[data-v-7c293060] {
    font-size: 24px;
    color: #8B8F95;
}
.myVideo[data-v-7c293060]{
    max-width: 100%;
    height: auto;
}
.my-btn[data-v-7c293060]{
    opacity: .99;
    background-color: transparent;
    border-color: white;
    font-weight: 500;
    line-height: 1.11;
    color: #fff;
    max-width: 200px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 90px;
    width: 200px;
    height: 55px;
    font-size: 18px;
    letter-spacing: 3.8px;
    text-align: center;
}
.my-btn[data-v-7c293060]:hover{
    background-color: white;
    color: black;
}
.content .btn-shop[data-v-7c293060] {
    color: #fff;
    background-color: #000;
    padding: 8px 40px;
    border: 1px solid #000;
    border-radius: 6px;
    margin-top: 270px;
    font-weight: bold;
}
@media (max-width: 1471px){
.my-btn[data-v-7c293060]{
        bottom: 100px !important;
}
}
@media (max-width: 1399px) {
.home-banner[data-v-7c293060], .banner-container[data-v-7c293060]{
        min-height: auto;
}
}
@media (max-width: 1199px) {
.home-banner .content .scheme[data-v-7c293060]{
        font-size: 36px;
        margin-top: 0;
}
.home-banner .content .info[data-v-7c293060]{
        font-size: 18px;
}
.banner-container .content .scheme[data-v-7c293060] {
        font-size: 36px;
        margin-top: 0;
}
.banner-container .content .info[data-v-7c293060]{
        font-size: 18px;
}
}
@media (max-width: 1198px){
.mytextbanner2[data-v-7c293060]{
        font-size: 40px;
}
}
/* for mobile */
@media only screen and (max-width: 992px) {
.shopnowbutton[data-v-7c293060] {
        width: 100px;
        padding: 5px;
        height: 30px;
}
.shopnowgoldenbutton1[data-v-7c293060] {
        width: 100px;
        padding: 5px;
        height: 30px;
}
.savetext[data-v-7c293060] {
        font-size: 15px;
}
.textblock1[data-v-7c293060] {
        margin-top: -90px;
        margin-left: 50px;
}
.welcometext[data-v-7c293060] {
        color: black;
        font-weight: 600;
        font-size: 20px;
}
.textblock2[data-v-7c293060] {
        margin-top: -200px;
        margin-left: 40px;
        width: 50%;
}
.headtop[data-v-7c293060] {
        font-weight: 200px;
}
.uptooff[data-v-7c293060] {
        color: white;
        font-weight: 600;
        font-size: 30px;
}
.textblock3[data-v-7c293060] {
        margin-top: -250px;
        margin-left: 200px;
        width: 50%;
}
.three[data-v-7c293060] {
        padding-bottom: 10px;
}
.logohead1[data-v-7c293060] {
        width: 100%;
}
.hurryup[data-v-7c293060] {
        font-size: 20px;
        color: white;
        font-weight: 600px;
}
.dailydeal[data-v-7c293060] {
        font-size: 15px;
        color: white;
        font-weight: 600px;
}
.lowprice[data-v-7c293060] {
        color: white;
        font-size: 10px;
}
.textbox5[data-v-7c293060] {
        margin-left: 30px;
        margin-top: -160px;
        width: 50%;
}
.happytimes[data-v-7c293060] {
        font-size: 20px;
        color: white;
        font-weight: 600px;
}
.flat[data-v-7c293060] {
        font-size: 25px;
        color: white;
        font-weight: 700px;
}
.stores[data-v-7c293060] {
        width: 30%;
        margin-top: 0px;
}
.playbuttons[data-v-7c293060] {
        margin-left: 120px;
        margin-right: auto;
}
.download[data-v-7c293060] {
        font-size: 15px;
        font-weight: 400;
        color: black;
        margin-left: 120px;
}
.textbox7[data-v-7c293060] {
        margin-top: -60px;
        margin-left: auto;
        margin-right: auto;
}
.imglast[data-v-7c293060] {
        width: 100%;
        height: 100%;
}
.dontmiss[data-v-7c293060] {
        font-size: 15px;
}
.offthirty[data-v-7c293060] {
        font-size: 25px;
        font-weight: 600;
}
.textbox4[data-v-7c293060] {
        margin-left: 40px;
        margin-top: -150px;
        width: 50%;
}
}

/* for desktop */
@media (max-width: 991px) {
.home-banner >img[data-v-7c293060], .banner-container>img[data-v-7c293060]{
        height: 250px;
        object-fit: cover;
        width: 100%;
}
.mytextbanner2[data-v-7c293060]{
        font-size: 30px;
        max-width: 250px;
}
.my-btn[data-v-7c293060]{
        bottom: 20px !important;
        width: 150px;
    height: 40px;
}
}
@media (max-width: 767px) {
.home-banner .content .scheme[data-v-7c293060]{
        font-size: 22px;
        margin-bottom: 15px;
}
.home-banner .content .info[data-v-7c293060]{
        font-size: 14px;
}
.home-banner >img[data-v-7c293060], .banner-container>img[data-v-7c293060]{
        height: 160px;
}
.banner-container .content .scheme[data-v-7c293060] {
        font-size: 22px;
        margin-top: 0;
}
.banner-container .content .info[data-v-7c293060]{
        font-size: 14px;
}
.content .btn-shop[data-v-7c293060]{
        margin-top: 0px;
        font-size: 13px;
        padding: 6px 20px;
}
.mytextbanner2[data-v-7c293060]{
        font-size: 20px;
    max-width: 200px;
}
.my-btn[data-v-7c293060]{
        bottom: 15px !important;
        width: 150px;
        height: 30px;
}
}
.featurebarcontainer[data-v-7c293060]{
    margin:40px 0px;
}