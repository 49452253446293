.notify-me-title-bar .modal-title[data-v-a7102f6c] {
  margin: 0;
  text-align: center;
  flex: 1 1;
  color: var(--color-dark-grey);
}
.notify-me-body-text[data-v-a7102f6c] {
  font-size: 16px;
  line-height: 1.13;
  text-align: center;
  color: var(--color-dark-grey);
}
.notify-me-title-bar span[data-v-a7102f6c] {
  position: absolute;
  top: 27px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: var(--color-black);
}
.pp-notify-me-btn-modal[data-v-a7102f6c] {
  width: 100%;
  margin: 0 !important;
  padding: 0;
  border-radius: 0;
  background: transparent;
  border-color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-black);
}
.pp-notify-me-btn-modal[data-v-a7102f6c]:hover {
  border-color: #fff;
}
.pp-notify-me-btn-light-grey[data-v-a7102f6c] {
  color: var(--color-dark-grey);
}
.pp-notify-me-btn-dark-grey[data-v-a7102f6c] {
  color: var(--color-black);
}
/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.notify-me-body-text[data-v-a7102f6c] {
    text-align: center !important;
}
}
