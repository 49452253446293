.custom-modal .close-btn[data-v-ced84b62] {
  cursor: pointer;
}
.custom-modal .custom-body[data-v-ced84b62] {
  padding: 20px;
}
.pw_input[data-v-ced84b62]{
  position: relative;
}
.pw_input svg[data-v-ced84b62]{
  position: absolute;
  right: 0;
  top: 25px;
}
.custom-modal .dark-btn[data-v-ced84b62] {
  display: block;
  color: #fff;
  background-color: #000;
  letter-spacing: 0.5px;
  font-size: 16px;
  padding: 10px 0;
  transition: .5s ease;
}
.dark-btn[data-v-ced84b62]:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}
.validation-error[data-v-ced84b62] {
  font-size: 12px;
  line-height: 1.17;
  color: var(--color-orange);
  text-align: center;
}
.main-form .custom-label[data-v-ced84b62] {
  margin: 0;
  color: #92969B;
  font-size: 14px;
}
.main-form .custom-input[data-v-ced84b62] {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 0;
  color: #000;
  height: 30px;
  font-size: 20px;
}
.main-form .error[data-v-ced84b62] {
  margin: 8px 0 0 -3px;
  font-size: 12px;
  color: red;
}
.main-form .form-control.invalid[data-v-ced84b62] {
  color: red;
  border-color: red;
}