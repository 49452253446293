:root {
  --color-primary: #d4c96d;
  --color-white: #ffffff;
  --color-black: black;
  --color-dark-grey: #8b8f95;
  --color-dark-black: #000000;
  --color-green: #43b7a7;
  --color-orange: #e65e52;
  --color-blue: #30458c;
}

html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.btn:focus, .form-control:focus, .btn:active, .form-control:active, 
textarea, textarea:focus, textarea:active,
input, input:focus, input:active,
select, select:focus, select:active,
button, button:focus, button:active {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
}