.radius-10[data-v-7f7e3126] {
  border-radius: 10px;
}
.cart-pg-mb-30[data-v-7f7e3126] {
  margin-bottom: 30px;
}
.cart-pg-breadcrumbs[data-v-7f7e3126] {
  color: var(--color-dark-grey);
  font-size: 12px;
  line-height: 1.17;
  margin-bottom: 30px;
}
.cartno-wrap[data-v-7f7e3126] {
  margin-top: 52px;
  margin-bottom: 40px;
}
.cart-pg-current[data-v-7f7e3126] {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.17;
}
.empty-cartn-ttl[data-v-7f7e3126] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 17px;
}
.empty-cart-text[data-v-7f7e3126] {
  font-size: 16px;
  line-height: 1.13;
  color: var(--color-dark-grey);
}
.empty-cart-btn[data-v-7f7e3126] {
  opacity: 0.99;
  border-radius: 6px;
  background-color: var(--color-dark-black);
  border-color: var(--color-dark-black);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  color: #ffffff;
}
.cart-page-main-title[data-v-7f7e3126] {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-black);
  margin-bottom: 30px;
}
.cart-product-image[data-v-7f7e3126] {
  border-radius: 9px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}
.yt-cart-pg-middle-sep[data-v-7f7e3126] {
  height: 23px;
  width: 1px;
  background: #707070;
  margin: 0 33px;
}
.cart-product-title[data-v-7f7e3126] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.17;
  color: var(--color-black);
  margin-bottom: 12px;
  max-width: 310px;
}
.cart-prodict-type th[data-v-7f7e3126] {
  font-size: 13px;
  line-height: 1.15;
  letter-spacing: 0.5px;
}
.cart-product-delete[data-v-7f7e3126],
.yt-cart-mv-list[data-v-7f7e3126],
.cart-prodict-type th[data-v-7f7e3126] {
  color: var(--color-dark-grey);
}
.cart-product-delete[data-v-7f7e3126]{
  cursor: pointer;
}
.cart-prodict-type th[data-v-7f7e3126],
.cart-prodict-type td[data-v-7f7e3126] {
  padding: 3px;
  vertical-align: middle;
}
.card-product-delete[data-v-7f7e3126],
.yt-cart-mv-list[data-v-7f7e3126] {
  cursor: pointer;
}
.cart-product-price[data-v-7f7e3126] {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: 1.08px;
  color: var(--color-dark-black);
}
.cart-subttl-box[data-v-7f7e3126] {
  font-size: 16px;
  line-height: 1.25;
  color: var(--color-dark-grey);
  max-width: 80%;
}
.cart-product-delete svg[data-v-7f7e3126] {
  font-size: 22px;
  color: #8d9096;
  cursor: pointer;
}
.cart-prodict-amount th[data-v-7f7e3126] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-dark-black);
}
.cart-product-amount-ttl[data-v-7f7e3126] {
  font-size: 18px;
  line-height: 1.11;
  text-align: left;
  color: var(--color-dark-grey);
}
.cart-product-amount-price[data-v-7f7e3126] {
  font-size: 18px;
  line-height: 1.11;
  text-align: right;
  color: var(--color-dark-black);
  display: block;
}
.cart-prodict-amount thead th[data-v-7f7e3126] {
  padding-top: 0;
  padding-bottom: 18px;
  padding-left: 0;
  padding-right: 0;
}
.cart-price-column[data-v-7f7e3126]{
  min-width: 140px;
}
.cart-prodict-amount tbody td[data-v-7f7e3126],
.cart-prodict-total-amount tbody td[data-v-7f7e3126],
.ccart-prodict-sub-total-amount tbody td[data-v-7f7e3126],
.cart-prodict-sub-total-amount tbody td[data-v-7f7e3126] {
  padding: 10px 0;
}
.cart-divider[data-v-7f7e3126] {
  border-bottom: solid 1px #cae2fe !important;
  display: block;
  margin: 15px 0 20px;
}
.cart-coupon .form-group[data-v-7f7e3126] {
  position: relative;
}
.cart-coupon .form-control[data-v-7f7e3126] {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  height: auto;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.13;
  text-align: left;
  color: var(--color-black);
  padding: 21px;
}
.cart-coupon-btn[data-v-7f7e3126] {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: #4cb122;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
  border-color: #4cb122;
}
.cart-remove-coupon-btn[data-v-7f7e3126] {
  position: absolute;
  top: 50%;
  right: 12px;
  opacity: 0.99;
  border-radius: 5px;
  background-color: #e0502f;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 1.15;
  padding: 8px 15px;
  border-color: #e0502f;
}
.cart-proceed-to-checkput[data-v-7f7e3126] {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  text-align: center;
  color: #ffffff;
  opacity: 0.99;
  border-radius: 5px;
  background-color: var(--color-dark-black);
}
.cart-checkout-tile[data-v-7f7e3126] {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.15;
  text-align: left;
  color: var(--color-dark-black);
}
.cart-select-aar-btn[data-v-7f7e3126] {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: var(--color-dark-black);
}
.checkout-form-wrap .form-group .form-control[data-v-7f7e3126] {
  box-shadow: none;
  border-bottom: 1px solid #dbdbdb !important;
  border-radius: 0;
}
.checkout-form-label[data-v-7f7e3126] {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-dark-grey);
}
.checkout-checkbox .form-group[data-v-7f7e3126],
.yt-chekout-radio .form-group[data-v-7f7e3126] {
  padding-left: 25px;
  position: relative;
  line-height: 25px;
}
.yt-empty-cart-wrap[data-v-7f7e3126] {
  max-width: 820px;
  margin-left: auto;
  margin-right: auto;
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input[data-v-7f7e3126] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  margin: 0;
}
.cart-pg-inner-wrap .yt-checkout-form-chk-box[data-v-7f7e3126],
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-7f7e3126] {
  position: absolute;
  cursor: pointer;
  margin: 0;
  left: 0;
  top: 0;
}
.cart-pg-inner-wrap .checkout-checkbox .yt-checkout-form-chk-box[data-v-7f7e3126]:before,
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-7f7e3126]:before {
  content: "";
  -webkit-appearance: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: solid 1px #979797;
  background-color: #ffffff;
  border-radius: 3px;
  box-sizing: border-box;
}
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-7f7e3126]:before {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  padding: 5px;
}
.yt-checkout-chekbox-label[data-v-7f7e3126] {
  font-size: 14px;
  line-height: 1.14;
  text-align: left;
  color: var(--color-black);
}
.cart-pg-inner-wrap
  .checkout-checkbox
  .form-check-input:checked
  + label[data-v-7f7e3126]:before {
  background-color: var(--color-dark-black);
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input:checked + label[data-v-7f7e3126]:after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 5;
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label[data-v-7f7e3126]:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  width: 7px;
  height: 7px;
  z-index: 5;
  background: var(--color-blue);
  border-radius: 25px;
  left: 4px;
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input:checked + label[data-v-7f7e3126]:before {
  border-color: var(--color-blue);
}
.cart-pg-inner-wrap .yt-chekout-radio .form-check-input[data-v-7f7e3126] {
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 5;
  opacity: 0;
  right: 0;
  width: 100%;
  margin-top: 0;
  bottom: 0;
  height: 100%;
}
.checkout-form-wrap .form-check-label[data-v-7f7e3126] {
  line-height: 1.5;
}
.cart-coupon .error .cart-coupon-code-message[data-v-7f7e3126] {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-orange);
}
.cart-coupon .success .cart-coupon-code-message[data-v-7f7e3126] {
  font-size: 13px;
  line-height: 1.46;
  text-align: left;
  color: var(--color-green);
}
.cart-coupon-change-btn[data-v-7f7e3126] {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--color-orange);
  text-decoration: underline;
}
.cart-coupon .cart-coupon-bottom-wrapper[data-v-7f7e3126] {
  position: absolute;
  bottom: -42px;
  left: 0;
  right: 0;
  opacity: 0;
}
.cart-coupon .error .cart-coupon-bottom-wrapper[data-v-7f7e3126],
.cart-coupon .success .cart-coupon-bottom-wrapper[data-v-7f7e3126] {
  opacity: 1;
}
.cart-coupon .success .cart-coupon-bottom-wrapper .error-message[data-v-7f7e3126],
.cart-coupon .error .cart-coupon-bottom-wrapper .success-message[data-v-7f7e3126],
.login-on-cart-page .lp-bottom-links[data-v-7f7e3126] {
  display: none;
}
.yt-login-wrap[data-v-7f7e3126] {
  margin-top: 0 !important;
}
.cart-pg-list-prdt-info .cart-prodict-info[data-v-7f7e3126] {
  padding-left: 30px;
}
.cart-action-btm-wrap[data-v-7f7e3126] {
  position: absolute;
  top: 100%;
  left: 30px;
  right: 0;
  border-top: 1px solid #dbdbdb;
  padding: 20px 0;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:last-child {
  text-align: right;
}
.cart-prodict-type tr td[data-v-7f7e3126] {
  font-size: 17px;
  line-height: 1.24;
  color: var(--color-dark-black);
  text-transform: capitalize;
}
.cart-pg-list-image[data-v-7f7e3126] {
  width: 20%;
}
.cart-pg-list-prdt-info[data-v-7f7e3126] {
  width: 80%;
  position: relative;
}
.cart-list-other-act[data-v-7f7e3126] {
  position: relative;
  max-height: 100px;
  min-width: 140px;
  text-align: right;
}
.cart-quantity-box[data-v-7f7e3126] {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 115px;
}
.checkout-form-wrap .yt-remove-arrow[data-v-7f7e3126]::-webkit-outer-spin-button,
.checkout-form-wrap .yt-remove-arrow[data-v-7f7e3126]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.checkout-form-wrap .yt-remove-arrow[type="number"][data-v-7f7e3126] {
  -moz-appearance: textfield;
}
.cart-produt-list-wrap[data-v-7f7e3126] {
  padding: 40px 40px 65px;
}
.cart-produt-list-wrap[data-v-7f7e3126],
.yt-cart-price-lister[data-v-7f7e3126],
.checkout-form-wrap .cart-pg-inner-wrap[data-v-7f7e3126] {
  border: solid 1px #dbdbdb;
}
.yt-cart-price-lister[data-v-7f7e3126] {
  padding: 40px 30px 56px;
  margin-bottom: 30px;
}
.checkout-form-wrap .cart-pg-inner-wrap[data-v-7f7e3126] {
  padding: 40px 30px 30px;
}
.yt-cm-lt-col[data-v-7f7e3126] {
  padding-right: 7px;
}
.yt-cm-rt-col[data-v-7f7e3126] {
  padding-left: 7px;
}
@media (min-width: 768px) {
.cart-proceed-to-checkput.cart-btn-bill-pg[data-v-7f7e3126] {
    display: none;
}
.cart-pg-list-image .cart-product-image img[data-v-7f7e3126] {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.cart-pg-list-image .cart-product-image[data-v-7f7e3126] {
    width: 133px;
    height: 133px;
    overflow: hidden;
}
}
@media (min-width: 1280px) {
.empty-main-ttl[data-v-7f7e3126] {
    display: none;
}
}
@media (min-width: 1280.88px) {
.yt-cm-lt-col[data-v-7f7e3126] {
    padding-right: 10px;
}
.yt-cm-rt-col[data-v-7f7e3126] {
    padding-left: 10px;
}
}
@media (min-width: 1900px) {
.yt-cm-lt-col[data-v-7f7e3126] {
    padding-right: 20px;
}
.yt-cm-rt-col[data-v-7f7e3126] {
    padding-left: 20px;
}
.cart-product-title[data-v-7f7e3126] {
    line-height: 1.67;
    max-width: 100%;
}
.cart-produt-list-wrap[data-v-7f7e3126] {
    padding: 40px 40px 37px;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.92px) {
.empty-cartn-ttl[data-v-7f7e3126] {
    font-size: 24px;
    line-height: 1.17;
}
.empty-cart-text[data-v-7f7e3126] {
    font-size: 14px;
    line-height: 1.14;
}
.empty-cart-btn[data-v-7f7e3126] {
    font-size: 16px;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.cart-action-btm-wrap[data-v-7f7e3126] {
    position: absolute;
    top: 100%;
    left: 30px;
    right: 0;
    border-top: 1px solid #dbdbdb;
    padding: 3px 0;
}
.yt-empty-cart-wrap.cart-pg-inner-wrap[data-v-7f7e3126] {
    margin-top: 10px !important;
}
.cart-page-main-title[data-v-7f7e3126] {
    margin-bottom: 10px !important;
    font-size: 24px;
    line-height: 1.17;
}
.cart-produt-list-wrap[data-v-7f7e3126] {
    padding: 28px;
    margin-bottom: 15px;
}
.yt-cart-price-lister[data-v-7f7e3126] {
    padding: 20px 15px;
}
.cart-product-title[data-v-7f7e3126] {
    font-size: 16px;
    line-height: 1.13;
}
.cart-prodict-type th[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.46px;
}
.cart-prodict-type tr td[data-v-7f7e3126] {
    font-size: 16px;
    line-height: 1.31;
}
.cart-product-price[data-v-7f7e3126] {
    font-size: 20px;
    line-height: 1.15;
    letter-spacing: 0.77px;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:first-child {
    font-size: 18px;
    line-height: 1.11;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:last-child {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.13;
}
.cart-product-amount-ttl[data-v-7f7e3126] {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
}
.cart-product-amount-price[data-v-7f7e3126] {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
}
.cart-prodict-amount tbody td[data-v-7f7e3126],
  .cart-prodict-total-amount tbody td[data-v-7f7e3126],
  .cart-prodict-sub-total-amount tbody td[data-v-7f7e3126],
  .cart-prodict-sub-total-amount tbody td[data-v-7f7e3126] {
    padding: 7.5px 0;
}
.cart-divider[data-v-7f7e3126] {
    margin: 15px 0;
}
.cart-proceed-to-checkput[data-v-7f7e3126] {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    letter-spacing: 0.32px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}
.cart-coupon .form-control[data-v-7f7e3126] {
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
}
.cart-coupon .error .cart-coupon-code-message[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.58;
}
.cart-coupon-change-btn[data-v-7f7e3126] {
    font-size: 14px;
    line-height: 1.14;
}
}

/*
* Screen for under 768 Resolution 
*/
@media (max-width: 767.92px) {
.cart-pg-list-image .cart-product-image img[data-v-7f7e3126] {
    width: 57px;
    height: 57px;
    object-fit: cover;
    object-position: center;
}
.cart-pg-list-image .cart-product-image[data-v-7f7e3126] {
    width: 70px;
    height: 70 px;
}
.cart-checkout-wrap .cart-proceed-to-checkput[data-v-7f7e3126] {
    display: none;
}
.yt-cm-row[data-v-7f7e3126] {
    margin-left: -20px;
    margin-right: -20px;
}
.yt-cm-lt-col[data-v-7f7e3126] {
    padding-right: 11px;
    padding-left: 11px;
}
.yt-cm-rt-col[data-v-7f7e3126] {
    padding: 0;
}
.empty-cartn-ttl[data-v-7f7e3126] {
    font-size: 17px;
    line-height: 1.12;
}
.empty-cart-text[data-v-7f7e3126] {
    font-size: 15px;
    line-height: 1.2;
}
.empty-cart-btn[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
}
.cart-page-main-title[data-v-7f7e3126] {
    font-size: 18px;
    line-height: 1.11;
}
.cart-produt-list-wrap[data-v-7f7e3126] {
    padding: 15px;
    margin-bottom: 10px;
}
.cart-product-title[data-v-7f7e3126] {
    font-size: 14px;
    line-height: 1.36;
}
.cart-prodict-type th[data-v-7f7e3126] {
    font-size: 10px;
    line-height: 1.1;
    letter-spacing: 0.38px;
}
.cart-prodict-type tr td[data-v-7f7e3126] {
    font-size: 14px;
    line-height: 1.5;
}
.cart-product-price[data-v-7f7e3126] {
    font-size: 15px;
    line-height: 1.2;
}
.cart-product-delete svg[data-v-7f7e3126] {
    font-size: 15px;
}
.cart-page-main-title[data-v-7f7e3126] {
    margin-bottom: 10px !important;
}
.yt-cart-price-lister[data-v-7f7e3126] {
    border-radius: 0;
    padding: 20px 18px;
    margin-bottom: 10px;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:first-child {
    font-size: 16px;
    line-height: 1.19;
}
.cart-prodict-amount thead th[data-v-7f7e3126]:last-child {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.27;
}
.cart-product-amount-ttl[data-v-7f7e3126] {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
}
.cart-product-amount-price[data-v-7f7e3126] {
    font-size: 15px;
    line-height: 1.2;
    font-weight: 500;
}
.cart-prodict-amount tbody td[data-v-7f7e3126],
  .cart-prodict-total-amount tbody td[data-v-7f7e3126],
  .cart-prodict-sub-total-amount tbody td[data-v-7f7e3126],
  .cart-prodict-sub-total-amount tbody td[data-v-7f7e3126] {
    padding: 8px 0;
}
.cart-divider[data-v-7f7e3126] {
    margin: 3px 0 7px;
}
.cart-coupon .form-control[data-v-7f7e3126] {
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
}
.cart-proceed-to-checkput[data-v-7f7e3126] {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: 0.4px;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.checkout-form-wrap .cart-pg-inner-wrap[data-v-7f7e3126] {
    padding: 20px;
    margin-bottom: 10px;
}
.cart-checkout-tile[data-v-7f7e3126] {
    font-size: 15px;
    line-height: 1.2;
}
.cart-select-aar-btn[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
}
.checkout-form-label[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
}
.cart-pg-inner-wrap .checkout-checkbox .form-check-input[data-v-7f7e3126] {
    font-size: 10px;
    line-height: 1.1;
}
.checkout-form-wrap .yt-chekout-radio .form-check-label[data-v-7f7e3126] {
    font-size: 12px;
    line-height: 1.17;
}
.checkout-form-wrap .yt-chekout-radio .form-check-label .form-check-input[data-v-7f7e3126] {
    margin-top: 0px;
}
.checkout-form-wrap .yt-chekout-radio > div[data-v-7f7e3126] {
    width: 100%;
    margin-bottom: 10px;
}
.cart-pg-inner-wrap .yt-checkout-form-rado-box[data-v-7f7e3126] {
    top: 6px;
}
}
@media (max-width: 575.98px) {
.empty-cart-text[data-v-7f7e3126] {
    max-width: 233px;
    margin-left: auto;
    margin-right: auto;
}
.empty-cart-btn[data-v-7f7e3126] {
    width: 100%;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.empty-cart-btn[data-v-7f7e3126] {
    font-size: 16px;
}
.cart-page-main-title[data-v-7f7e3126] {
    font-size: 26px;
}
.cart-proceed-to-checkput[data-v-7f7e3126] {
    font-size: 16px;
}
}
@media (min-width: 1900px) {
.cart-action-btm-wrap[data-v-7f7e3126] {
    position: absolute;
    top: 100%;
    left: 30px;
    right: 0;
    border-top: 1px solid #dbdbdb;
    padding: 7px 0;
}
}
.spinner[data-v-7f7e3126] {
  margin: 20px auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 10px;
}
.spinner > div[data-v-7f7e3126] {
  background-color: #707070;
  height: 100%;
  border-radius: 8px;
  width: 5px;
  margin: 0 2px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay-data-v-7f7e3126 1.2s infinite ease-in-out;
  animation: sk-stretchdelay-data-v-7f7e3126 1.2s infinite ease-in-out;
}
.spinner .rect2[data-v-7f7e3126] {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3[data-v-7f7e3126] {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
.spinner .rect4[data-v-7f7e3126] {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5[data-v-7f7e3126] {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay-data-v-7f7e3126 {
0%, 40%, 100% { -webkit-transform: scaleY(0.4)
}
20% { -webkit-transform: scaleY(1.0)
}
}
@keyframes sk-stretchdelay-data-v-7f7e3126 {
0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
}
20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
}
}
.payment-alert[data-v-7f7e3126] {
  background-color: #f4f4f4;
  text-align: center;
  border: none;
}
.payment-alert p[data-v-7f7e3126] {
  color: #707070;
  font-size: 18px;
}


