.feature_head[data-v-3f5906c1] {
  font-size: 22px;
  font-weight: 500;
  margin-left: 4px;
  color: #000;
  padding-bottom: 5px;
  line-height: 1.14;
}
.feature_text[data-v-3f5906c1] {
  color: var(--color-dark-grey);
  margin-left: 5px;
  font-size: 14px;
  line-height: 1.14;
}
.first[data-v-3f5906c1] {
  margin-left: 10px;
}
.yt-featured-bar-main[data-v-3f5906c1] {
  margin: 60px 0px;
}
.logoimage[data-v-3f5906c1] {
  width: 40px;
  height: 40px;
  width: auto;
}
.customcontainer > div.row[data-v-3f5906c1] {
  padding: 20px 10px;
}
.divider[data-v-3f5906c1] {
  border-right: 18px solid #e7e7e7;
}
.hp-featured-wrapper[data-v-3f5906c1] {
  padding: 0 15px;
  background: #fff;
  overflow: hidden;
}
.yt-cm-fb-row[data-v-3f5906c1] {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
}
.hp-fb-inner-wrap[data-v-3f5906c1] {
  border-right: 1px solid #e7e7e7;
}
@media (min-width: 992px) {
.border-md-0[data-v-3f5906c1] {
    border-width: 0;
}
}

/*
* Screen for 1920 Resolution 
*/
@media (min-width: 1900px) {
.feature_head[data-v-3f5906c1] {
    font-size: 28px;
}
.feature_text[data-v-3f5906c1] {
    font-size: 20px;
    line-height: 1.15;
}
}
@media (max-width: 1279.92px) and (min-width: 768px) {
.yt-cm-fb-row .yt-cm-fb-col:last-child .yt-fb-inner-wrap[data-v-3f5906c1] {
    border: none;
}
}
/*
* Screen for 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.yt-featured-bar-main[data-v-3f5906c1] {
    margin: 20px 0 !important;
}
.yt-fb-inner-wrap[data-v-3f5906c1] {
    border-right: solid 2px #cae2fe;
}
.hp-fb-inner-wrap[data-v-3f5906c1] {
    text-align: center;
}
.feature_head[data-v-3f5906c1] {
    font-size: 16px;
    line-height: 1.13;
    margin: 0 !important;
}
.feature_text[data-v-3f5906c1] {
    font-size: 12px;
    line-height: 1.17;
    max-width: 106px;
    margin: 0 auto !important;
}
.yt-ft-img .logoimage[data-v-3f5906c1] {
    display: none;
}
.yt-ft-img .logoimage.logo-white[data-v-3f5906c1] {
    display: block !important;
}
.yt-ft-img[data-v-3f5906c1] {
    background-color: var(--color-primary);
    width: 75px;
    margin: 0 auto;
    padding: 19.5px 0;
    border-radius: 100%;
}
.hp-fb-inner-wrap[data-v-3f5906c1] {
    border: none !important;
}
}

/*
* Screen for under 1280 Resolution 
*/
@media (max-width: 1279.98px) {
.yt-cm-fb-inner-col[data-v-3f5906c1] {
    max-width: 100% !important;
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
}
.feature_head[data-v-3f5906c1] {
    text-align: center;
}
}
/*
* Screen for 768 Resolution 
*/
@media (max-width: 767.98px) {
.yt-cm-fb-col[data-v-3f5906c1] {
    max-width: 50% !important;
    flex: 0 0 50%;
    -ms-flex: 0 0 50%;
}
.yt-cm-fb-row .yt-cm-fb-col:last-child .yt-fb-inner-wrap[data-v-3f5906c1],
  .yt-cm-fb-row .yt-cm-fb-col:nth-child(2) .yt-fb-inner-wrap[data-v-3f5906c1] {
    border-right: none;
}
.yt-cm-fb-row .yt-cm-fb-col:nth-child(2) .yt-fb-inner-wrap[data-v-3f5906c1],
  .yt-cm-fb-row .yt-cm-fb-col:first-child .yt-fb-inner-wrap[data-v-3f5906c1] {
    border-bottom: solid 2px #cae2fe;
}
.yt-fb-inner-wrap[data-v-3f5906c1] {
    padding: 15px 0 0;
}
.yt-ft-img[data-v-3f5906c1] {
    width: 54px;
    padding: 17px 0;
}
.yt-ft-img .logoimage[data-v-3f5906c1] {
    height: 20px;
}
.feature_head[data-v-3f5906c1] {
    line-height: 1.17em;
    font-size: 12px;
}
.feature_text[data-v-3f5906c1] {
    font-size: 10px;
    line-height: 1.1;
    max-width: 100%;
    /* min-height: 37px; */
}
.yt-cm-fb-row.row[data-v-3f5906c1] {
    align-items: flex-start !important;
}
.yt-cm-fb-inner-col[data-v-3f5906c1] {
    padding: 0;
}
}
@media (min-width: 1366px) and (max-width: 1900px) {
.feature_head[data-v-3f5906c1] {
    font-size: 16px;
}
}
